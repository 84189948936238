// SERVICE WORKER MANAGER

import { noop, onceEvent } from "@/scripts/globals.js";

const POLL_SLOW = 10 * 1000;
const POLL_FAST = 3 * 1000;

let timeout;
let registration;
let newWorker;

// START

export function start() {
  if ("serviceWorker" in navigator) {
    console.log("SERVICE WORKER - START");
    register();
  } else {
    console.log("SERVICE WORKER - not supported");
    setReady();
  }
}

function register() {
  console.log("attempt to register worker");
  navigator.serviceWorker
    .register(process.env.BASE_URL + "service-worker.js")
    .then(setRegistered, registrationError);
}

function registrationError(e) {
  console.log("SERVICE WORKER - registration error -", e);
  timeout = setTimeout(register, 500);
}

// READY STATE

export let ready = false;
let readyCallback = noop;

export function onReady(fn) {
  readyCallback = fn;

  // run instantly if ready
  if (ready) {
    fn();
  }
}

export function removeReady() {
  readyCallback = noop;
}

function setReady() {
  ready = true;
  readyCallback();
}

// REGISTERED

let registered = false;
let registeredCallback = noop;

export function onRegistered(fn) {
  registeredCallback = fn;
  if (registered) {
    fn();
  }
}

export function removeRegistered() {
  registeredCallback = false;
}

async function setRegistered(reg) {
  registration = reg;

  // if service worker is already active, check for update
  if (reg.active) {
    console.log("SERVICE WORKER - registered - active");
    await poll();
  }

  // otherwise monitor install progress
  else if (reg.installing) {
    console.log("SERVICE WORKER - registered - installing");
    setFound(reg.installing);
  } else if (reg.waiting) {
    console.log("SERVICE WORKER - registered - waiting");
    setFound(reg.waiting);
  } else {
    console.log("SERVICE WORKER - registeed - no worker?");
  }

  registered = true;
  registeredCallback();

  setReady();
}

// POLL
// poll for updates to the service worker
// stops when an update is found and waits for resume()

async function poll() {
  await registration.update().then(pollSuccess, pollError);
}

function pollSuccess(reg) {
  if (reg.installing) {
    console.log("SERVICE WORKER - update found!");
    setFound(reg.installing);
  } else {
    timeout = setTimeout(poll, POLL_SLOW);
  }
}

function pollError() {
  timeout = setTimeout(poll, POLL_FAST);
}

// found

let found = false;
let foundCallback = noop;

export function onFound(fn) {
  foundCallback = fn;
  if (found) {
    fn();
  }
}

export function removeFound() {
  foundCallback = noop;
}

function setFound(worker) {
  console.log("SERVICE WORKER - setFound()");

  newWorker = worker;
  newWorker.addEventListener("error", newWorkerError);
  newWorker.addEventListener("statechange", newWorkerState);

  found = true;
  foundCallback();

  // sometimes Safari gets stuck here
  // try testing state immediately
  newWorkerState();
}

function newWorkerError() {
  console.log("SERVICE WORKER - found worker error");
  stopWatchingWorker();
  cancelCallback();
  poll();
}

function newWorkerState() {
  const state = newWorker.state;

  console.log("SERVICE WORKER - onStateChange()", newWorker.state);

  if (state === "installed") {
    console.log("SERVICE WORKER - maybe waiting?", registration.waiting);
    timeout = setTimeout(setWaiting, 1000); // wait 1s, in case activation happens automatically
  }

  // sometimes the worker installs automatically...
  else if (state === "activating") {
    console.log("SERVICE WORKER - not waiting, installing");
    clearTimeout(timeout);
  }

  // worker has installed
  else if (state === "activated") {
    clearTimeout(timeout);

    // waiting workers need to be installed by reloading
    if (waiting) {
      console.log("SERVICE WORKER - installed from waiting");
      loaded = true;
      loadCallback();
    }

    // automatic installation
    else {
      console.log("SERVICE WORKER - installed without waiting");
      newWorkerError();
    }
  }

  // worker didn't install or is old
  else if (state === "redundant") {
    console.log("SERVICE WORKER - redundant?");
    clearTimeout(timeout);
    newWorkerError();
  }

  // installing / found
  else if (state === "installing") {
    console.log("SERVICE WORKER - installing (found)");
  }
}

function stopWatchingWorker() {
  console.log("SERVICE WORKER - stop watching worker");
  newWorker.removeEventListener("error", newWorkerError);
  newWorker.removeEventListener("statechange", newWorkerState);
  newWorker = null;

  waiting = false;
  found = false;
}

// WAITING

export let waiting = false;
let waitingCallback = noop;

export function onWaiting(fn) {
  waitingCallback = fn;
  if (waiting) {
    fn();
  }
}

function setWaiting() {
  console.log("SERVICE WORKER - setWaiting()");

  waiting = true;
  waitingCallback();
}

export function removeWaiting() {
  waitingCallback = noop;
}

// load

let loaded = false;
let loadCallback = noop;

export function onLoad(fn) {
  loadCallback = fn;
  if (loaded) {
    fn();
  }
}

export function removeLoad() {
  loadCallback = noop;
}

// update cancel
// sometimes updates are automatic or they error

let cancelCallback = noop;

export function onCancel(fn) {
  cancelCallback = fn;
}

export function removeCancel() {
  cancelCallback = noop;
}

// RESUME
// forcibly resume

export function resume() {
  poll();
}

// reload

export function reload() {
  window.location.reload();
}

export function update() {
  console.log("SERVICE WORKER - run update");
  if (waiting) {
    navigator.serviceWorker.addEventListener(
      "controllerchange",
      onControllerChange,
      onceEvent
    );
    console.log("SERVICE WORKER - skip waiting");
    newWorker.postMessage({ type: "SKIP_WAITING" });
  }
}

function onControllerChange() {
  console.log("SERVICE WORKER - controller changed to new worker");
  loadCallback();
}

export function stop() {
  // clear all callbacks
  removeReady();
  removeRegistered();
  removeFound();
  removeWaiting();
  removeLoad();
  removeCancel();
}
