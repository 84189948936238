<template>
  <img
    ref="img"
    class="image"
    :class="loadClass"
    draggable="false"
    @load="onLoad"
  />
</template>

<script>
export default {
  emits: ["load"],
  data() {
    return {
      loadClass: null,
    };
  },
  methods: {
    onLoad() {
      if (this.loaded) return;

      this.loaded = true;
      this.loadClass = this.complete ? "image--instant" : "image--show";
      this.$emit("load");
    },
  },
  mounted() {
    this.complete = this.$refs.img.complete;
  },
};
</script>

<style lang="scss">
.image {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;

  &--instant {
    opacity: 1;
  }

  &--show {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
}
</style>
