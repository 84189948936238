<template>
  <Transition>
    <component
      :is="type"
      :data="content"
      :key="pageIndex"
      class="default-page-anim"
      @next="next"
    />
  </Transition>
</template>

<script>
import ContentPage from "./story/ContentPage.vue";
import MessagePage from "./story/MessagePage.vue";
import NewsPage from "./story/NewsPage.vue";
import EmoteTransition from "./story/EmoteTransition.vue";
import SAMPage from "./story/SAMPage.vue";

import * as Background from "@/scripts/background.js";

const typeMap = {
  chat_history: "ContentPage",
  comment: "ContentPage",
  direct_message: "ContentPage",
  email: "ContentPage",
  emote_transition: "EmoteTransition",
  message: "MessagePage",
  news: "NewsPage",
  notification: "ContentPage",
  popup: "ContentPage",
  sam_commentary: "ContentPage",
  search_result: "ContentPage",
  social_image: "ContentPage",
  web_page: "ContentPage",
};

export default {
  emits: ["fin"],
  components: {
    ContentPage,
    MessagePage,
    NewsPage,
    EmoteTransition,
    SAMPage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    // test chapter emoji position
    let feedbackPos;
    for (let i = this.data.length - 1; i >= 0; --i) {
      const screen = this.data[i];
      if (
        (screen["0_path"] &&
          screen["0_path"][0].acf_fc_layout === "emote_transition") ||
        (screen["1_path"] &&
          screen["1_path"][0].acf_fc_layout === "emote_transition") ||
        (screen["2_path"] &&
          screen["2_path"][0].acf_fc_layout === "emote_transition")
      ) {
        feedbackPos = i;
        break;
      }
    }

    return {
      path: 0,
      pageIndex: -1,
      page: null,
      type: null,
      feedbackPos,
    };
  },
  methods: {
    next(path) {
      if (this.type === "EmoteTransition") {
        this.$root.setHeaderProgressStyle("none");
      }

      this.pageIndex++;

      if (this.pageIndex === this.data.length) {
        this.$emit("fin", this.path);
      } else {
        if (path !== undefined) {
          this.path = path;
        }

        const prevType = this.type;
        const step = this.data[this.pageIndex];
        const type = step.acf_fc_layout;

        if (type === "paths") {
          const page = step[`${this.path}_path`][0];

          if (page.acf_fc_layout === "skip") {
            this.next();
            return;
          }

          this.type = typeMap[page.acf_fc_layout];
          this.content = page;
        } else {
          this.type = typeMap[type];
          this.content = step;
        }

        // skip message animation if
        // - this is the first page in the story or
        // - this page immediately follows a news break
        if (
          this.type === "MessagePage" &&
          (this.pageIndex === 0 || prevType === "NewsPage")
        ) {
          this.type = "ContentPage";
        }

        Background.transitionDown();
      }

      if (this.type === "EmoteTransition") {
        this.$root.setHeaderProgress(this.pageIndex, this.pageIndex);
      } else {
        this.$root.setHeaderProgress(this.pageIndex, this.feedbackPos);
      }
    },
  },
  created() {
    this.next();
  },
  mounted() {
    this.$root.setHeaderProgressStyle("bar");
    this.$root.setHeaderProgress(this.pageIndex, this.feedbackPos);
  },
};
</script>
