import { Clock } from "@/scripts/clock.js";

let shapes = [];
let targetElement = null;
let prevTargetElement = null;
let isLeaving = false; // transitioning out
let isOut = true; // out of view
let outTimeout = null;

let vMin = 0;
let vMax = 0;
let vW = 0;
let vH = 0;
let rem = 16;

let stateKey = "circle";

const clock = new Clock();

function reset() {
  shapes.length = 0;
  targetElement = null;
  prevTargetElement = null;
  isLeaving = false;
  isOut = true;
  outTimeout = null;
}

export const globals = {
  shapes,
  targetElement,
  prevTargetElement,
  isLeaving,
  isOut,
  outTimeout,
  vMin,
  vMax,
  vW,
  vH,
  rem,
  stateKey,
  clock,
  reset,
};
