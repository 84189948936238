const FRAME_RATE_TARGET = 60;
const FRAME_TIME = 1000 / FRAME_RATE_TARGET;

export class Clock {
  constructor() {
    this.prevTime = performance.now();
    this.currentTime = performance.now();
    this.deltaRaw = 0;
    this.deltaTime = 0;
    this.paused = false;
  }

  pause() {
    this.paused = true;
  }

  resume() {
    this.paused = false;
    // reset delta
    this.prevTime = performance.now();
    this.currentTime = performance.now();
  }

  update() {
    if (this.paused) return;
    this.prevTime = this.currentTime;
    this.currentTime = performance.now();

    this.deltaRaw = this.currentTime - this.prevTime;
    this.deltaTime = this.deltaRaw / FRAME_TIME;
  }
}
