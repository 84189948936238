<template>
  <Transition>
    <ScrollPage :key="currentPage" @next="next" class="default-page-anim">
      <SAM
        v-if="hasSAM"
        ref="comment"
        :data="page.content"
        class="page-content-anim page-content-margin"
      />
      <PageContent
        v-if="hasContent"
        ref="content"
        :data="page"
        class="page-content-anim page-content-margin"
      />
    </ScrollPage>
  </Transition>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import PageContent from "@/components/content/PageContent.vue";

import * as AudioManager from "@/scripts/audioManager.js";
import * as Background from "@/scripts/background.js";
import UserData from "@/scripts/userData.js";
import * as Analytics from "@/scripts/analytics.js";
import { data } from "@/scripts/data.js";

export default {
  emits: ["next"],
  components: {
    ScrollPage,
    SAM,
    PageContent,
  },
  data() {
    const index = UserData.concept;
    const concepts = data.key_concepts;
    const variations = concepts[index % concepts.length];
    const concept =
      variations[Math.floor(index / concepts.length) % variations.length];
    const pages = concept.screens;
    const title = concept.title;

    Analytics.setItem("concept_id", concept.concept_id);
    Analytics.setItem("concept_name", title);

    return {
      lastPage: pages.length - 1,
      currentPage: 0,
      pages,
      page: pages[0],
      hasSAM: false,
      hasContent: false,
      title,
    };
  },
  methods: {
    checkPageContent() {
      if (this.page.acf_fc_layout === "comment") {
        this.hasContent = false;
        this.hasSAM = true;
      } else {
        this.hasContent = true;
        this.hasSAM = !!this.page.content;
      }
    },
    next() {
      // last page - update data and exit
      if (this.currentPage === this.lastPage) {
        Analytics.trackEvent("key_concept_end");
        UserData.setConcept(UserData.concept + 1);
        this.$emit("next");
      }

      // continue to the next page
      else {
        this.page = this.pages[++this.currentPage];
        this.checkPageContent();
        this.$nextTick(this.findFirstElement);
        this.$root.shapeNext();
        Background.transitionDown();
      }
    },
    findFirstElement() {
      if (this.hasSAM) {
        this.$root.shapeTarget(this.$refs.comment.$el.nextElementSibling);
      } else {
        this.$root.shapeTarget(this.$refs.content.$el);
      }
    },
  },
  created() {
    this.checkPageContent();
  },
  mounted() {
    Analytics.trackProgress("key_concepts");
    Analytics.trackEvent("key_concept_start");

    // set header state
    this.$root.setHeaderDisplay(true);
    this.$root.setHeaderProgress(0, 1);
    this.$root.setHeaderTitle(this.title);
    this.$root.setHeaderProgressStyle("none");

    // start bouncing shapes off the first element in the page
    this.findFirstElement();
    this.$root.shapeState("bounce");
    this.$root.shapeIn();
    AudioManager.loadGenreFiles();
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
    this.$root.shapeOut();
  },
};
</script>
