<template>
  <div class="emote-page">
    <div v-if="data.emoji" class="emote-page__emoji" :style="randomRotation">
      <div class="emote-page__wrap">
        <Emoji class="emote-page__img" :name="data.emoji" />
      </div>
    </div>
    <SAM :data="data.text" class="page-content-anim page-content-margin" />
  </div>
</template>

<script>
import Emoji from "@/components/content/Emoji.vue";
import SAM from "@/components/content/SAM.vue";
import * as AudioManager from "@/scripts/audioManager.js";

export default {
  components: {
    Emoji,
    SAM,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    sound: {
      type: String,
      default: "maybe",
    },
  },
  data() {
    return {
      randomRotation: `--rand-rotate: ${Math.round(
        20 - 40 * Math.random()
      )}deg`,
    };
  },
  methods: {
    feedbackSound() {
      switch (this.sound) {
        case "correct":
          AudioManager.play("chapter-activity-correct");
          break;
        case "maybe":
          AudioManager.play("chapter-activity-maybe");
          break;
        case "wrong":
          AudioManager.play("chapter-activity-wrong");
          break;
        default:
          AudioManager.play("chapter-activity-maybe");
          break;
      }
    },
  },
  mounted() {
    this.timeout = setTimeout(this.feedbackSound, 1250);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss">
.emote-page {
  &__emoji {
    @include scale-in($name: emote-page-in, $dur: 0.8s, $delay: 1s);
  }

  &__wrap {
    @include float-anim($name: emote-page-float);
  }

  &__img {
    width: 65%;
    height: auto;
    margin: 0 auto;

    @include float-rotate-anim(
      $name: emote-page-rotate,
      $amt: 3.5deg,
      $offset: var(--rand-rotate, 0deg)
    );
  }
}
</style>
