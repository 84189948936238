<template>
  <figure class="fragment">
    <div class="news-blurb__type">{{ type }}</div>
    <div class="news-blurb__time">{{ time }}</div>
    <div class="news-blurb__headline">{{ headline }}</div>
  </figure>
</template>

<script>
import { parse } from "@/scripts/insert.js";
import SessionData from "@/scripts/sessionData.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const translatedStrings = SessionData.lang.News;
    return {
      time: translatedStrings.notification_timestamp.replace(
        /\[hours\]/gi,
        2 + Math.floor(Math.random() * 7)
      ),
      headline: parse(this.data.headline),
      type: translatedStrings.news_app,
    };
  },
};
</script>

<style lang="scss">
.news-blurb {
  &__type {
    float: left;
    font-size: 0.75rem;
    font-weight: $semi-bold;
    text-transform: uppercase;
  }

  &__time {
    float: right;
    font-size: 0.75rem;
    font-weight: $semi-bold;
  }

  &__headline {
    clear: both;
    padding: 1rem 0 0;
    font-size: 1.375rem;
    font-weight: $bold;
  }
}
</style>
