<template>
  <Transition>
    <ScrollPage v-if="state === 0" class="default-page-anim" @next="toRating">
      <SAM
        :data="before"
        class="page-content-anim page-content-margin"
        ref="text"
      />
    </ScrollPage>

    <Page v-else-if="state === 1" class="default-page-anim">
      <form
        ref="ratingForm"
        class="fragment feedback__form page-content-anim"
        @submit="submitRating"
      >
        <div class="feedback__reaction">
          <Emoji
            :name="ratingEmojis.unset"
            class="feedback__emoji"
            :class="{ 'feedback__emoji--show': shapes === 0 }"
            :aria-hidden="shapes === 0 ? null : 'true'"
          />
          <Emoji
            v-for="i in 5"
            :name="ratingEmojis[i]"
            class="feedback__emoji"
            :class="{ 'feedback__emoji--show': shapes === i }"
            :aria-hidden="shapes === i ? null : 'true'"
          />
        </div>
        <fieldset class="feedback__fieldset">
          <legend class="feedback__question">{{ ratingQuestion }}</legend>
          <div class="feedback__group feedback__shapes">
            <div v-for="i in 5" class="feedback__shape">
              <input
                :id="`Radio${i}`"
                type="radio"
                name="rating"
                :value="i"
                class="feedback__radio"
                v-model="rating"
                @input="onRadioInput"
                @keydown="i === 1 ? onKeyFirst : i === 5 ? onKeyLast : null"
                required
              />
              <label
                :for="`Radio${i}`"
                class="feedback__label"
                :class="[
                  `feedback__label--${i}`,
                  {
                    'feedback__label--active': shapes > i - 1,
                  },
                ]"
              >
                <span class="feedback__text">{{ i }}</span>
                <svg
                  class="feedback__svg"
                  viewBox="0 0 100 100"
                  aria-hidden="true"
                >
                  <polygon
                    class="feedback__svg__background"
                    fill="#FFF"
                    points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
                  />
                  <polygon
                    fill="none"
                    stroke-width="9"
                    stroke-linejoin="round"
                    stroke="#FFF"
                    points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
                  />
                </svg>
              </label>
            </div>
          </div>
        </fieldset>
        <button
          type="submit"
          class="feedback__submit"
          :class="{
            'feedback__submit--show': rating,
          }"
          :disabled="shapes === 0"
        >
          {{ ratingButtonLabel }}
        </button>
      </form>
    </Page>

    <Page v-else-if="state === 2" class="default-page-anim">
      <SAM
        :data="recommendationQuestion"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="recommendationAnswers" @choice="recommendChoice" />
    </Page>

    <ScrollPage v-else-if="state === 3" class="default-page-anim" @next="fin">
      <SAM
        :data="after"
        class="page-content-anim page-content-margin"
        ref="text"
      />
    </ScrollPage>
  </Transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import Emoji from "@/components/content/Emoji.vue";

import * as Background from "@/scripts/background.js";
import UserData from "@/scripts/userData.js";
import SessionData from "@/scripts/sessionData.js";
import * as AudioManager from "@/scripts/audioManager.js";
import * as RemovalObserver from "@/scripts/removalObserver.js";
import * as Data from "@/scripts/data.js";
import * as Analytics from "@/scripts/analytics.js";

const TWOPI = 2 * Math.PI;

export default {
  emits: ["next"],
  components: {
    Page,
    ScrollPage,
    SAM,
    Dialogue,
    Emoji,
  },
  data() {
    const {
      data: {
        user_feedbacks: { intro, outro, rating, recommendation },
      },
    } = Data;

    return {
      state: 0,

      before: intro.content,
      after: outro.content,

      // rating
      ratingQuestion: rating.question,
      ratingEmojis: rating.reactions,
      rotations: [
        TWOPI * Math.random(),
        TWOPI * Math.random(),
        TWOPI * Math.random(),
        TWOPI * Math.random(),
        TWOPI * Math.random(),
      ],
      rating: null,
      ratingButtonLabel: SessionData.lang.Interactions.next_button_label,

      //recommend
      recommendationQuestion: recommendation.question,
      recommendationAnswers: [
        recommendation.reactions.yes,
        recommendation.reactions.no,
      ],
      recommended: null,
    };
  },
  computed: {
    shapes() {
      if (this.rating) {
        return parseInt(this.rating);
      } else {
        return 0;
      }
    },
  },
  methods: {
    startShapes() {
      this.$root.shapeTarget(this.$refs.text.$el.nextElementSibling);
      this.$root.shapeState("circle");
      this.$root.shapeIn();
    },

    next() {
      this.state++;
      Background.transitionDown();
    },
    toRating() {
      this.$root.shapeOut();
      this.next();

      this.$nextTick(this.startRatingAnimation);
    },
    fin() {
      UserData.setFeedback(true);
      this.$emit("next");
      Background.transitionDown();
    },

    // rating
    submitRating(e) {
      e.preventDefault();

      if (this.rating) {
        this.next();
        AudioManager.play("ui-input-button-select");
      }
    },
    onRadioInput() {
      AudioManager.play("ui-input-button-misc");
    },
    onKeyFirst(e) {
      if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
        e.preventDefault();
      }
    },
    onKeyLast(e) {
      if (e.key === "ArrowDown" || e.key === "ArrowRight") {
        e.preventDefault();
      }
    },
    endRatingAnimation() {
      cancelAnimationFrame(this.raf);
    },
    startRatingAnimation() {
      this.ratingElements = document.querySelectorAll(".feedback__svg");

      RemovalObserver.observe(this.$refs.ratingForm, this.endRatingAnimation);

      this.animateRating();
    },
    animateRating() {
      for (let i = 0; i < this.ratingElements.length; ++i) {
        this.rotations[i] += i < this.shapes ? 0.015 : 0.005;
        this.ratingElements[
          i
        ].style.transform = `rotate(${this.rotations[i]}rad)`;
      }

      this.raf = requestAnimationFrame(this.animateRating);
    },

    // recommend

    recommendChoice(choice) {
      this.recommended = choice === 0;
      Analytics.trackEvent("feedback", {
        rating: this.rating,
        recommended: this.recommended,
      });
      this.next();
      this.$nextTick(this.startShapes);
    },
  },
  mounted() {
    Analytics.trackProgress("feedback");
    this.startShapes();
    this.$root.iconShow();
    this.$root.setHeaderDisplay(false);
  },
  beforeUnmount() {
    this.$root.shapeOut();
    this.endRatingAnimation();
  },
};
</script>

<style lang="scss">
@keyframes rating-bounce {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  30% {
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes label-bounce {
  0% {
    transform: scale(0.8);
  }

  30% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes feedback-sway {
  @for $i from 0 through 100 {
    $a: 2 * math.$pi * ($i / 100);

    #{100% * $i / 100} {
      transform: translate(math.cos($a) * 0.3rem, math.sin($a * 2) * 0.15rem)
        rotate(3deg * math.sin($a));
    }
  }
}

.feedback {
  &__form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }

  &__fieldset {
    border: none;
    padding: 0.5rem 0 0;
    margin: 0;
  }

  &__question {
    float: left; // put the legend into normal flow
    margin: 0 0 1.5rem;
    padding: 0;
    font-size: 1.375rem;
    font-weight: $semi-bold;
    line-height: 2.125rem;
  }

  &__reaction {
    position: relative;
    padding: 0 0 60%;
    animation: feedback-sway 20s linear infinite both;
  }

  &__emoji {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: auto;

    &--show {
      visibility: visible;
      z-index: 10;
      animation: rating-bounce 0.3s $ease-out-cubic both;
    }
  }

  &__group {
    clear: both;
    display: flex;
    justify-content: space-between;
  }

  &__shapes {
    margin: 0 -0.25rem;
  }

  // shape

  &__shape {
    flex: 0 0 auto;
    width: 2.5rem;
    height: 2.5rem;
  }

  &__radio {
    display: block;
    appearance: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:focus-visible {
      & + label {
        outline: auto;
      }
    }

    &:checked + .feedback__label {
      animation: label-bounce 0.3s $ease-out-cubic both;
    }
  }

  &__label {
    display: block;
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7rem;
      height: 7rem;
      margin: -3.5rem;
      background-image: radial-gradient(closest-side, #fff, transparent);
      opacity: 0;
      pointer-events: none;
    }

    &--active {
      &::after {
        opacity: 0.2;
      }

      & > .feedback__svg {
        & > .feedback__svg__background {
          opacity: 1;
        }
      }
    }
  }

  &__text {
    display: block;
    position: absolute;
    overflow: hidden;
    overflow: clip;
    opacity: 0;
    width: 0;
    height: 0;
    font-size: 0;
  }

  &__svg {
    display: block;
    width: 100%;
    height: auto;

    &__background {
      opacity: 0;
    }
  }

  // SUBMIT

  &__submit {
    @include reset-button;

    align-self: flex-end;

    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    margin: 2.5rem 0 0;
    box-shadow: 0.125rem 0.125rem 0.5rem #000 inset;

    color: #000;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: $bold;

    cursor: auto;

    opacity: 0.1;

    &--show {
      background: #000;
      color: #fff;
      cursor: pointer;
      opacity: 1;
      box-shadow: 0.125 0.125 0.5rem rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
