import { globals } from "./_globals.js";
import {
  map,
  recursiveOffsetLeft,
  recursiveOffsetTop,
  easeInOutCubic,
  constrain,
} from "@/scripts/helpers.js";

const LERP_TIME = 5000;
const IN_DELAY = 2000;

export default {
  // state
  doIn: false,
  // maintain for constant updating
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  graphApex: globals.vH / 2,
  graphLength: globals.vW,
  graphLerp: 0,
  lerpStartTime: 0,
  init() {
    this.setTarget();
    this.doIn = false;
    for (const shape of globals.shapes) {
      shape.resetProps();
      shape.turnFactor = 0.5;
      shape.setPropLerp("decelDist", globals.rem * 2, IN_DELAY);
      if (globals.isOut)
        shape.setProp("pos", { x: globals.rem * -2, y: this.originY, z: 50 });
    }
  },
  update() {
    // {0 <= x <= 0.5} y = 1 - 4 * ((x - 0.5) ** 2)
    // {0.5 < x <= 1} y = 1 - 8 * ((x - 0.5) ** 2)

    if (this.doIn && !(globals.isLeaving || globals.isOut)) {
      this.graphLerp = map(
        performance.now(),
        this.lerpStartTime,
        this.lerpStartTime + LERP_TIME,
        0,
        1 + (globals.shapes.length - 1) * 0.1,
        true
      );
    }

    if (!(globals.isLeaving || globals.isOut)) this._graph();

    for (const shape of globals.shapes) {
      shape.update();
    }
  },
  _graph() {
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      const offsetLerp = constrain(this.graphLerp - i * 0.1, 0, 1);
      const steep = offsetLerp > 0.5 ? 12 : 4;
      const easeLerp = easeInOutCubic(offsetLerp);
      shape.setTarget(
        this.originX + easeLerp * this.graphLength,
        this.originY + this.graphApex * (1 - steep * (easeLerp - 0.5) ** 2),
        map(easeLerp, 0, 1, globals.vMin / 2, -globals.vMin, true)
      );
    }
  },
  setTarget() {
    if (globals.targetElement) {
      this.originX = recursiveOffsetLeft(globals.targetElement);
      this.originY = recursiveOffsetTop(globals.targetElement);
      this.graphApex =
        globals.vH / 4 - recursiveOffsetTop(globals.targetElement);
      this.graphLength = globals.targetElement.clientWidth;
    }
  },
  onResize() {
    this.setTarget();
  },
  in() {
    this.inTimeout = setTimeout(this.in2.bind(this), IN_DELAY);
  },
  in2() {
    if (globals.stateKey !== "updown") return;
    this.doIn = true;
    this.lerpStartTime = performance.now();
  },
  out() {
    clearTimeout(this.inTimeout);
    for (const shape of globals.shapes) {
      shape.setTarget(
        shape.pos.x,
        globals.vH + globals.rem * 2,
        -globals.vMin * 2
      );
    }
  },
  kill() {
    clearTimeout(this.inTimeout);
  },
  onVisibilityChange() {},
  next() {},
};
