import { globals } from "./_globals.js";
import { propLerpFunctions } from "./_mixins.js";
import { recursiveOffsetLeft, recursiveOffsetTop } from "@/scripts/helpers.js";

export default {
  // state
  doIn: false,
  doOut: false,
  inTimeout: null,
  // vals
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  gap1: globals.rem * 2.5,
  swingLerp: 0,
  swingOutLerp: 0,
  originAngle: -Math.PI / 2,
  swingAmt: 0.4,
  swingSpeed: 0.025,
  propLerp: {},
  init() {
    this.setTarget();
    this.doIn = false;
    this.doOut = false;
    this.swingLerp = 0;
    this.swingOutLerp = 0;
    this.propLerp = {};
    for (const shape of globals.shapes) {
      shape.resetProps();
      shape.turnFactor = 0.8;
      shape.decelDist = globals.rem;
      shape.accelExternalDamp = 0.1;

      if (globals.isOut) {
        shape.setProp("scale", 0);
        shape.pos.x = this.originX + 0.1;
        shape.pos.y = this.originY;
      }
      shape.setPropLerp("scale", 1);
    }
  },
  update() {
    this._updatePropLerp();
    if (this.doIn) {
      this._swing();
    } else if (this.doOut) {
      this._swingOut();
    } else {
      for (let i = 0; i < globals.shapes.length; i++) {
        const shape = globals.shapes[i];
        shape.setTarget(
          this.originX - (globals.shapes.length - 1 - i) * this.gap1,
          this.originY
        );
      }
    }

    for (const shape of globals.shapes) {
      shape.update();
    }
  },
  setTarget() {
    if (globals.targetElement) {
      this.originX =
        recursiveOffsetLeft(globals.targetElement) +
        globals.targetElement.clientWidth / 2;
      this.originY =
        recursiveOffsetTop(globals.targetElement) - 2 * globals.rem;
      this.gap1 = globals.targetElement.clientWidth / 6;
    } else {
      this.originX = globals.vW / 2;
      this.originY = globals.vH / 2;
      this.gap1 = globals.rem * 2.5;
    }
  },
  // prop lerping
  _setPropLerp: propLerpFunctions._setPropLerp,
  _updatePropLerp: propLerpFunctions._updatePropLerp,
  onResize() {
    this.originX = globals.vW / 2;
    this.originY = globals.vH / 2;
  },
  _swing() {
    this.swingLerp =
      (this.swingLerp + this.swingSpeed * globals.clock.deltaTime) % 1;
    this.originAngle =
      ((Math.sin(this.swingLerp * Math.PI * 2) + 1) * Math.PI) / 2;

    const angle1 =
      this.originAngle * -0.5 * this.swingAmt - Math.PI * 0.1 - Math.PI / 2;
    const angle2 =
      this.originAngle * -0.9 * this.swingAmt - Math.PI * 0.1 - Math.PI / 2;

    globals.shapes[3].setTarget(this.originX, this.originY);
    globals.shapes[2].setTarget(
      this.originX + Math.cos(angle1) * 1.75 * globals.rem,
      this.originY + Math.sin(angle1) * 1.75 * globals.rem
    );
    globals.shapes[1].setTarget(
      this.originX + Math.cos(angle2) * 4 * globals.rem,
      this.originY + Math.sin(angle2) * 4 * globals.rem
    );
    // alone
    globals.shapes[0].setTarget(
      this.originX - globals.rem * 2.75,
      this.originY
    );
  },
  _swingOut() {
    this.swingOutLerp = (this.swingOutLerp + this.swingSpeed) % 1;

    const angle1 =
      this.swingOutLerp * Math.PI * 2 - Math.PI * 0.1 - Math.PI / 2;

    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[globals.shapes.length - 1 - i];
      shape.setTarget(
        this.originX + Math.cos(angle1) * i * 1.75 * globals.rem,
        this.originY + Math.sin(angle1) * i * 1.75 * globals.rem
      );
    }
  },
  in() {
    this.timeout = setTimeout(() => {
      if (globals.stateKey !== "trust") return;
      this.doIn = true;
      this._setPropLerp("swingAmt", 0.03, 3000);
      this._setPropLerp("swingSpeed", 0, 5000);
    }, 1000);
  },
  out() {
    this.doOut = true;
    this.doIn = false;
    clearTimeout(this.timeout);
    // this._setPropLerp('swingAmt', -4)
    for (const shape of globals.shapes) {
      shape.turnFactor = 0.1;
      shape.decelDist = globals.vMin * 0.25;
    }
    this.timeout = setTimeout(() => {
      if (globals.stateKey !== "trust") return;
      this._setPropLerp("swingSpeed", 0.025, 100);
    }, 500);
  },
  kill() {
    clearTimeout(this.timeout);
  },
  onVisibilityChange() {},
  next() {},
};
