import { globals } from "./_globals.js";
import { recursiveOffsetLeft } from "@/scripts/helpers.js";

const FALL_TIME = 2000;

export default {
  // state
  doIn: false,

  originX: globals.vW / 2,
  originY: globals.vH / 2,
  originFarX: 0,
  originFarY: 0,
  farRadius: 0,
  radius: globals.rem * 1,
  fallTimeout: null,
  init() {
    this.setTarget();
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      const split = i % 2 == 0 ? -1 : 1;
      shape.resetProps();
      shape.turnFactor = 0.5;
      shape.decelDist = globals.vMin * 0.25;
      if (globals.isOut)
        shape.setProp("pos", {
          x: globals.vW / 2 + (globals.vW / 2) * split,
          y: this.originY,
          z: 50,
        });
    }
  },
  update() {
    if (!(globals.isLeaving || globals.isOut)) this._mainUpdate();

    for (const shape of globals.shapes) {
      shape.update();
    }
  },
  _mainUpdate() {
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];

      const split = i % 2 == 0 ? -1 : 1;
      const half = i < globals.shapes.length - 2;
      if (half) {
        shape.setTarget(this.originX + split * this.radius, this.originY, 0);
      } else {
        shape.setTarget(
          this.originFarX + split * this.farRadius,
          this.originFarY,
          0
        );
      }
    }
  },
  setTarget() {
    this.radius = this.doIn ? globals.rem * 1.5 : globals.rem * 6.5;
    this.farRadius = this.doIn ? globals.rem * 4 : globals.rem * 9;
    if (globals.targetElement && !globals.isLeaving) {
      this.originX =
        recursiveOffsetLeft(globals.targetElement) +
        globals.targetElement.offsetWidth / 2;
      this.originY = globals.targetElement.offsetTop - globals.rem * 2;

      this.originFarX =
        recursiveOffsetLeft(globals.targetElement) +
        globals.targetElement.offsetWidth * 0.5;
      this.originFarY = globals.targetElement.offsetTop - globals.rem * 4;
    }
  },
  onResize() {
    this.setTarget();
  },
  in() {
    this.fallTimeout = setTimeout(() => {
      if (globals.stateKey !== "connect") return;
      this.doIn = true;
      this.setTarget();
      for (const shape of globals.shapes) {
        shape.setPropLerp("accelStep", globals.rem * 0.01);
      }
    }, FALL_TIME);
  },
  out() {
    clearTimeout(this.fallTimeout);
    for (const shape of globals.shapes) {
      shape.addForce(
        (Math.random() - 0.5) * globals.rem * 0.5,
        (Math.random() - 0.5) * globals.rem * 0.5,
        (Math.random() - 0.5) * globals.rem * 0.5
      );
      shape.setTarget(globals.vW + globals.rem * 4, 0, 0);
    }
  },
  kill() {
    clearTimeout(this.fallTimeout);
  },
  onVisibilityChange() {},
  next() {},
};
