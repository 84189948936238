import storage from "@/scripts/storage.js";
import { capitalise } from "@/scripts/helpers.js";

// PERSISTENT STORAGE

const items = [];

// Data object for managing each (key, value) pair
// instantly stores the value in app memory
// then triggers a save of the value into storage
// does not wait for storage
// value in app memory can be ahead

class Data {
  value;
  key;

  constructor(key) {
    this.key = key;
  }

  async init() {
    this.value = await storage.getItem(this.key);
  }

  async set(val) {
    this.value = val;
    await storage.setItem(this.key, this.value);
  }

  async clear() {
    this.value = null;
    await storage.removeItem(this.key);
  }
}

function init() {
  return Promise.all(items.map((item) => item.init()));
}

function noStorage() {
  console.log("no storage");
}

let readyPromise;
const PersistentData = {
  start() {
    console.log("-- PERSISTENT DATA --- START");
    readyPromise = storage.ready().then(init, noStorage);
  },

  ready() {
    return readyPromise;
  },

  clear() {
    return Promise.all(items.map((item) => item.clear()));
  },
};

// build the interface functions
function addData(key) {
  const data = new Data(key);

  items.push(data);

  // getter
  Object.defineProperty(PersistentData, key, {
    get() {
      return data.value;
    },
  });

  // setter
  PersistentData[`set${capitalise(key)}`] = data.set.bind(data);
}

// UPDATE STATUS
// updates resume if app is restarted
addData("updating");
addData("appVersion");

// ANALYTICS
// offline analytics kept until back online
addData("gtmEvents");

// CONTENT
addData("data");
addData("currentLang");

// USER
addData("guestCode");

// MISC
addData("tabs");

export default PersistentData;
