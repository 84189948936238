import { globals } from "./_globals.js";
import { recursiveOffsetLeft } from "@/scripts/helpers.js";

export default {
  // maintain for constant updating
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  loneX: 0,
  loneY: 0,
  loneRadius: 0,
  radius: globals.rem * 1,
  fallTimeout: null,
  fallTime: 2000,
  isFall: false,
  init() {
    this.setTarget();
    this.isFall = false;
    for (const shape of globals.shapes) {
      shape.resetProps();
      shape.setPropLerp("scale", 1.25);
      if (globals.isOut)
        shape.setProp("pos", { x: globals.rem * -2, y: this.originY, z: 50 });
    }
  },
  update() {
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      const phase =
        performance.now() / 10000 +
        (i * Math.PI) / (this.isFall ? 1 : 2) +
        Math.PI * 0.66;
      if (!globals.isLeaving) {
        if (!this.isFall || i < globals.shapes.length - 2) {
          shape.setTarget(
            this.originX + Math.cos(phase) * this.radius,
            this.originY + Math.sin(phase) * this.radius
          );
        } else {
          const split = i % 2 == 0 ? -1 : 1;
          shape.setTarget(
            this.loneX + split * this.loneRadius,
            this.loneY,
            split * this.loneRadius
          );
        }
      }
      shape.update();
    }
  },
  setTarget() {
    this.radius = globals.rem * 2;
    if (globals.targetElement && !globals.isLeaving) {
      this.originX =
        recursiveOffsetLeft(globals.targetElement) +
        globals.targetElement.offsetWidth / 2;
      this.originY = globals.targetElement.offsetTop - globals.rem * 4;
      this.loneX =
        recursiveOffsetLeft(globals.targetElement) +
        globals.targetElement.offsetWidth * 0.5;
      this.loneY =
        globals.targetElement.offsetTop +
        globals.targetElement.offsetHeight +
        globals.rem * 4;
      this.loneRadius = globals.targetElement.offsetWidth / 2;
    }
  },
  onResize() {
    this.setTarget();
  },
  in() {
    this.fallTimeout = setTimeout(() => {
      this.isFall = true;
    }, this.fallTime);
  },
  out() {
    clearTimeout(this.fallTimeout);
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      const split = i % 2 == 0 ? -1 : 1;
      shape.setTarget(globals.vW / 2 + split * globals.vW, globals.vH / 2);
    }
  },
  kill() {
    clearTimeout(this.fallTimeout);
  },
  onVisibilityChange() {},
  next() {},
};
