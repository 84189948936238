<template>
  <ImageAsset
    :image="src"
    :alt="alt"
    :width="width"
    :height="height"
    class="emoji"
    decoding="sync"
  />
</template>

<script>
import ImageAsset from "@/components/content/image/ImageAsset.vue";
import emoji from "@/data/emoji.json";

export default {
  components: {
    ImageAsset,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    const { file, alt, width, height } = emoji[this.name];

    return {
      src: `emojis/${file}`,
      alt,
      width,
      height,
    };
  },
};
</script>

<style lang="scss">
.emoji {
  user-select: none;
}
</style>
