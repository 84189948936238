export class Batch {
  array = [];

  running = false;
  runIndex = 0;
  runLength = 0;

  add(fn) {
    // no duplicates
    if (this.array.indexOf(fn) === -1) {
      this.array.push(fn);
    }
  }

  remove(fn) {
    const foundIndex = this.array.indexOf(fn);
    if (foundIndex !== -1) {
      // update cursor and window
      if (this.running && foundIndex < this.runLength) {
        this.runLength--;

        if (foundIndex <= this.runIndex) {
          this.runIndex--;
        }
      }

      // remove item from array
      this.array.splice(foundIndex, 1);
    }
  }

  run() {
    this.running = true;
    this.runIndex = 0;
    this.runLength = this.array.length;

    while (this.runIndex < this.runLength) {
      this.array[this.runIndex]();
      this.runIndex++;
    }

    this.running = false;
  }

  clear() {
    this.array.length = 0;
    this.running = false;
  }
}
