<template>
  <figure class="fragment popup">
    <ImageAttachment
      v-if="data.has_image && data.image"
      :image="data.image"
      class="popup__image"
    />
    <div class="popup__content">
      <div v-if="data.title" class="popup__text popup__title">
        {{ parse(data.title) }}
      </div>
      <div v-if="data.has_body && data.body" class="popup__text">
        {{ parse(data.body) }}
      </div>
    </div>
  </figure>
</template>

<script>
import ImageAttachment from "@/components/content/image/ImageAttachment.vue";
import { parse } from "@/scripts/insert.js";

export default {
  components: {
    ImageAttachment,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parse,
  },
};
</script>

<style lang="scss">
.popup {
  padding: 0;

  &__image {
    border-radius: $content-radius $content-radius 0 0;
  }

  &__content {
    padding: 1rem $content-padding $content-padding;
  }

  &__text {
    margin: $fragment-content-margin 0 0;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: $bold;
  }
}
</style>
