<template>
  <Page
    class="splash"
    :class="{
      'default-page-anim': pageAnim,
    }"
    innerClass="splash__container"
  >
    <div
      v-if="downloadRequired"
      class="splash__download"
      :class="{ 'splash__download--show': downloadVisible }"
    >
      <label for="SplashProgress">Downloading...</label>
      <progress
        id="SplashProgress"
        ref="progress"
        :value="percent"
        max="100"
        class="splash__progress"
      >
        {{ percent }}%
      </progress>
    </div>
    <ChildFund
      class="splash__logo"
      :class="{ 'splash__logo--show': logoVisible }"
      :aria-hidden="!logoVisible"
    />
  </Page>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ChildFund from "@/components/svg/ChildFund.vue";
import PersistentData from "@/scripts/persistentData.js";
import * as AudioManager from "@/scripts/audioManager.js";
import OfflineMedia from "@/scripts/offlineMedia.js";
import * as Data from "@/scripts/data.js";

export default {
  emits: ["next"],
  components: {
    Page,
    ChildFund,
  },
  props: {
    startup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      downloadRequired:
        OfflineMedia.available && (Data.needsUpdate || PersistentData.updating),
      logoVisible: false,
      downloadVisible: false,
      pageAnim: !this.startup,
      percent: 0,
    };
  },
  methods: {
    soundPing() {
      AudioManager.play("ui-menu-update");
    },
    animate() {
      if (this.percent === 100) {
        this.unlock();
      } else {
        if (this.percent < 100 * OfflineMedia.getProgress()) {
          ++this.percent;
        }
        this.raf = requestAnimationFrame(this.animate);
      }
    },

    // inform parent that animation is done
    ready() {
      console.log("splash ready");
      this.$emit("ready");
    },

    // start outro animation
    fadeOut() {
      this.unlock();
    },

    unlock() {
      if (--this.lock === 0) {
        if (this.downloadVisible) {
          clearInterval(this.pingInterval);
          this.timeout = setTimeout(this.fadeOutBar, 200);
        } else {
          this.fadeOutLogo();
        }
      }
    },

    fadeOutBar() {
      this.downloadVisible = false;
      if (this.logoVisible) {
        this.timeout = setTimeout(this.fadeOutLogo, 200);
      } else {
        this.wait();
      }
    },

    fadeOutLogo() {
      this.logoVisible = false;
      this.wait();
    },

    wait() {
      this.timeout = setTimeout(this.next, 1000);
    },
    next() {
      this.$emit("next");
    },
  },
  mounted() {
    console.log("SPLASH");

    this.$root.setHeaderDisplay(false);

    // downloading?
    if (this.downloadRequired) {
      this.lock = 2;
      this.downloadVisible = true;
      this.raf = requestAnimationFrame(this.animate);

      // only emit sound if it was a manual update
      if (!this.startup) {
        this.soundPing();
        this.pingInterval = setInterval(this.soundPing, 1500);
      }
    } else {
      this.lock = 1;
    }

    // logo only shows during startup
    this.logoVisible = this.startup;
  },
  beforeUnmount() {
    cancelAnimationFrame(this.raf);
    clearTimeout(this.timeout);
    clearInterval(this.pingInterval);
  },
};
</script>

<style lang="scss">
.splash {
  &__container {
    &::before {
      content: "";
      display: block;
      height: 3rem;
      margin: 0 0 auto;
    }
  }

  &__download {
    opacity: 0;
    transition: opacity 0.5s ease-out;

    &--show {
      opacity: 1;
    }
  }

  &__progress {
    appearance: none;
    border: none;
    width: 100%;
    height: 0.5rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.25rem;

    &::-webkit-progress-bar {
      border-radius: 0.25rem;
      background: transparent;
    }

    // breaks safari if included with below?
    &::-moz-progress-bar {
      background: #fff;
      border-radius: 0.25rem;
    }

    &::-webkit-progress-value {
      background: #fff;
      border-radius: 0.25rem;
    }
  }

  &__logo {
    width: auto;
    height: 3rem;
    margin: auto 0 0 auto;

    opacity: 0;
    transition: opacity 0.5s ease-out;

    &--show {
      opacity: 1;
    }
  }
}
</style>
