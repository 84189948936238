<template>
  <Transition>
    <ScrollPage
      v-if="!isOut"
      :key="currentPage"
      @next="next"
      class="default-page-anim"
      :class="{
        'outro-out-anim': isLastPage,
      }"
    >
      <SAM
        ref="content"
        :data="markdown(page.content)"
        class="page-content-anim page-content-margin"
      />
    </ScrollPage>
  </Transition>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";

import { markdown } from "@/scripts/insert.js";
import UserData from "@/scripts/userData.js";
import * as Background from "@/scripts/background.js";
import * as Analytics from "@/scripts/analytics.js";
import { data } from "@/scripts/data.js";

export default {
  emits: ["fin"],
  components: {
    ScrollPage,
    SAM,
  },
  data() {
    return {
      currentPage: 0,
      lastPage: data.outro_sequence.screens.length - 1,
      page: data.outro_sequence.screens[0],
      prevPage: data.outro_sequence.screens[0],
      isLastPage: false,
      isOut: false,
    };
  },
  methods: {
    markdown,
    out() {
      this.isOut = true;
    },
    next() {
      if (this.currentPage === this.lastPage) {
        this.isLastPage = true;
        this.$root.shapeOut();
        this.frameDelay = requestAnimationFrame(this.out);
        this.timeout = setTimeout(this.fin, 1500);
      } else {
        this.currentPage++;
        this.prevPage = this.page;
        this.page = data.outro_sequence.screens[this.currentPage];
        this.frameDelay = requestAnimationFrame(this.updateShapes);
        Background.transitionDown();
      }
    },
    fin() {
      UserData.setOutro(true);
      this.$emit("fin");
    },
    updateShapes() {
      if (
        this.page.shape_animation &&
        (this.page.shape_animation !== this.prevPage.shape_animation ||
          this.currentPage == 0)
      ) {
        // only update if changed
        this.$root.shapeState(this.page.shape_animation);
        this.$root.shapeIn();
      } else if (this.page.shape_animation) {
        this.$root.shapeNext();
      } else {
        this.$root.shapeOut();
      }

      const ref = this.$refs.content;
      if (ref) this.$root.shapeTarget(ref.$el.nextElementSibling);
    },
  },
  mounted() {
    this.$root.setHeaderDisplay(false);
    Analytics.trackProgress("outro");
    this.updateShapes();
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
    cancelAnimationFrame(this.frameDelay);
  },
};
</script>

<style lang="scss">
@keyframes outro-leave-anim {
  0% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, $depth * 0.5);
  }
}

.outro-out-anim {
  &.v-leave-active {
    animation: outro-leave-anim 0.75s ease;
  }
}
</style>
