<template>
  <transition>
    <div v-if="state === 0" class="news-page">
      <div
        class="news-page__globe"
        :class="{
          'news-page__globe--show': showGlobe,
        }"
      >
        <div class="news-page__globe__wrap">
          <Globe ref="globe" show dimmed @loaded="onGlobeLoaded" />
        </div>
      </div>
      <Page>
        <div class="news-page__reel">
          <Transition
            name="news-item"
            mode="out-in"
            @after-enter="afterBlurbAnim"
          >
            <NewsBlurb
              v-if="showBlurb"
              :data="page"
              class="news-page__item"
              :key="index"
            />
          </Transition>
        </div>
      </Page>
    </div>
    <ScrollPage v-else class="default-page-anim" @next="fin">
      <SAM :data="data.after" class="page-content-margin page-content-anim" />
    </ScrollPage>
  </transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import NewsBlurb from "@/components/content/NewsBlurb.vue";
import Globe from "@/components/content/Globe.vue";
import SAM from "@/components/content/SAM.vue";

import { data } from "@/scripts/data.js";
import UserData from "@/scripts/userData.js";
import * as AudioManager from "@/scripts/audioManager.js";
import * as Background from "@/scripts/background.js";

export default {
  emits: ["next"],
  components: {
    Page,
    ScrollPage,
    NewsBlurb,
    Globe,
    SAM,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      state: 0,
      news: data.news,
      startIndex: UserData.news,
      index: 0,
      page: null,
      showGlobe: false,
      showBlurb: false,
    };
  },
  methods: {
    onGlobeLoaded() {
      AudioManager.play("chapter-news", true);
      this.frameDelay = requestAnimationFrame(this.doShowGlobe);
    },
    doShowGlobe() {
      this.showGlobe = true;
      this.timeout = setTimeout(this.startNews, 800);
    },
    startNews() {
      this.page = this.news[this.startIndex];
      this.showBlurb = true;
    },
    nextNews() {
      this.page =
        this.news[(++this.index + this.startIndex) % this.news.length];
    },
    afterBlurbAnim() {
      if (this.index === 2) {
        this.timeout = setTimeout(this.globeOut, 500);
      } else {
        this.timeout = setTimeout(this.nextNews, 2000);
      }
    },
    globeOut() {
      this.showGlobe = false;
      AudioManager.fadeOut("chapter-news", 1000);
      this.timeout = setTimeout(this.blurbOut, 1000);
    },
    blurbOut() {
      this.showBlurb = false;
      AudioManager.stop("chapter-news");
      this.timeout = setTimeout(this.playOutSound, 1000);
    },
    playOutSound() {
      AudioManager.play("chapter-news-after");
      this.timeout = setTimeout(this.afterGlobe, 500);
    },
    afterGlobe() {
      Background.restoreColors();
      this.$root.iconShow();

      if (this.data.after) {
        this.state = 1;
      } else {
        this.fin();
      }
    },
    fin() {
      this.$emit("next");
    },
  },
  mounted() {
    UserData.setNews((UserData.news + 3) % this.news.length);
    this.$root.iconHide();
    Background.dim();
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
    cancelAnimationFrame(this.frameDelay);
  },
};
</script>

<style lang="scss">
$globe-anim-time: 1250ms;
$page-hold-time: 4000ms;
$globe-passive-anim: $globe-anim-time * 2 + $page-hold-time * 3;

.news-page {
  position: absolute;
  width: 100%;
  height: 100%;

  &__globe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: clip;

    &--show {
      .news-page__globe__wrap {
        opacity: 1;
        transform: translate(0, -50%);
        transition: transform $globe-anim-time $ease-out-cubic 0.75s,
          opacity 0.2s linear 0.75s;
      }
    }

    &__wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      padding-top: 100%;
      opacity: 0.001; // force render
      transform: translate(50%, -50%);
      transition: transform $globe-anim-time $ease-in-cubic,
        opacity 0.2s linear $globe-anim-time - 200ms;

      .globe {
        animation: globe-passive $globe-passive-anim linear 1 both;
        @keyframes globe-passive {
          0% {
            transform: translate3d(0, 0%, 0) scale(1);
          }

          100% {
            transform: translate3d(12.5%, 25%, 0) scale(1.4);
          }
        }
      }
    }
  }

  &__reel {
    display: flex;
    width: 100%;
  }

  &__item {
    box-sizing: border-box;
    width: 100%;
    margin: 0 -100% 0 0;

    opacity: 1;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;

    transition-property: opacity, filter, transform;
    transition-duration: 1s;
    transition-timing-function: $ease-out-cubic;
  }
}

.news-item-enter-active {
  transition-property: opacity, filter, transform;
  transition-duration: 0.75s, 0.75s, 1s;
  transition-timing-function: $ease-out-sine;
}

.news-item-leave-active {
  transition-property: opacity, filter, transform;
  transition-duration: 1s, 1s, 1s;
  transition-timing-function: $ease-in-sine;
}

.news-item-enter-from {
  opacity: 0;
  filter: blur($blur);
  transform: translate3d(-25%, 0, 0) scale(0.7);
}

.news-item-leave-to {
  opacity: 0;
  filter: blur($blur);
  transform: translate3d(150%, 0, 0) scale(1.6);
}
</style>
