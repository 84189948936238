import { globals } from "./_globals.js";
import { lerp, map } from "@/scripts/helpers.js";

const SPIT_OFFSET = Math.PI;
const LOWER_VMAX = 0.25;
const LOWER_SELFVMAX = 0.02;
const LOWER_ACCEL = 0.01;
const UPPER_VMAX = 2;
const UPPER_SELFVMAX = 1;
const UPPER_ACCEL = 0.03;
const BACK_VMAX = 1;
const BACK_SELFVMAX = 0.5;
const BACK_ACCEL = 0.03;
const DEPTH_RAND = 0.5;
const PINCH_ANGLE = Math.PI / 10;

const SPIN_DECAY_TIME = 1000;

export default {
  radius: globals.vMin / 2,
  accelTimeoutThread: null,
  doIn: false,
  checkIn: false,
  rand: [],
  init() {
    this.rand = [];
    this.doIn = false;
    this.checkIn = false;
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      this.rand.push((Math.random() - 0.5) * 2);
      shape.resetProps();
      shape.orbitRadius = globals.vMin;
      shape.setProp("velSinLerp", 0);
      shape.setPropLerp("vMax", UPPER_VMAX * globals.rem);
      shape.setPropLerp("selfVMax", UPPER_SELFVMAX * globals.rem);
      shape.accelStep = UPPER_SELFVMAX * globals.rem;
      shape.accelExternalDamp = 0.98;
      if (globals.isOut)
        shape.setProp("pos", {
          x: globals.vW / 2,
          y: globals.vH / 2,
          z: 0,
        });
    }
    this.setTarget();
  },
  update() {
    this.setTarget();
    let totalDist = 0;
    for (const shape of globals.shapes) {
      if (this.doIn) {
        const dist = shape.targetDist.hyp;
        totalDist += dist;
        const distAlpha = map(dist, this.radius, 0, 0, 1, true);
        shape.setProp(
          "vMax",
          lerp(UPPER_VMAX * globals.rem, LOWER_VMAX * globals.rem, distAlpha)
        );
        shape.setProp(
          "selfVMax",
          lerp(
            UPPER_SELFVMAX * globals.rem,
            LOWER_SELFVMAX * globals.rem,
            distAlpha
          )
        );
        shape.setProp(
          "accelStep",
          lerp(UPPER_ACCEL * globals.rem, LOWER_ACCEL * globals.rem, distAlpha)
        );
      }
      if (
        this.doIn &&
        this.checkIn &&
        totalDist / globals.shapes.length < 0.1 * globals.vMin
      )
        this.endIn();
      shape.update();
    }
  },
  setTarget() {
    if (globals.targetElement) {
      this.radius = Math.sqrt(
        (globals.targetElement.clientWidth / 2) ** 2 +
          (globals.targetElement.clientHeight / 2) ** 2
      );
    }

    if (globals.isOut) return;
    const margin = globals.vMin / 2 - this.radius;

    if (!this.doIn) {
      for (let i = 0; i < globals.shapes.length; i++) {
        const shape = globals.shapes[i];
        shape.setTarget(
          globals.vW / 2,
          globals.vH / 2,
          this.rand[i] * globals.rem * DEPTH_RAND
        );
        shape.orbitRadius =
          this.radius +
          margin +
          (this.rand[i] / 2) * Math.min(globals.rem * 4, margin);
      }
    } else {
      for (let i = 0; i < globals.shapes.length; i++) {
        const shape = globals.shapes[i];
        const randOff = margin / 2;
        const pinchAngle = i % 2 !== 0 ? PINCH_ANGLE : 0;
        shape.setTarget(
          Math.sin((i * Math.PI) / 2 + pinchAngle + SPIT_OFFSET) *
            (this.radius + randOff) +
            globals.vW / 2,
          Math.cos((i * Math.PI) / 2 + pinchAngle + SPIT_OFFSET) *
            (this.radius + randOff) +
            globals.vH / 2,
          this.rand[i] * globals.rem * DEPTH_RAND
        );
        shape.orbitRadius = 0;
      }
    }
  },
  onResize() {
    this.setTarget();
  },
  _wave() {
    clearTimeout(this.accelTimeoutThread);
    if (globals.stateKey !== "pagecircle") return;
    for (const shape of globals.shapes) {
      shape.orbitDir = !shape.orbitDir;
      shape.setProp("vMax", BACK_VMAX * globals.rem);
      shape.setProp("selfVMax", BACK_SELFVMAX * globals.rem);
      shape.setPropLerp("accelStep", BACK_ACCEL * globals.rem, 800);
    }
    const step1 = () => {
      if (globals.stateKey !== "pagecircle") return;
      for (const shape of globals.shapes) {
        shape.orbitDir = !shape.orbitDir;
      }
      this.accelTimeoutThread = setTimeout(step2, 300);
    };
    const step2 = () => {
      if (globals.stateKey !== "pagecircle") return;
      for (const shape of globals.shapes) {
        shape.setPropLerp("vMax", LOWER_VMAX * globals.rem, 200);
        shape.setPropLerp("selfVMax", LOWER_SELFVMAX * globals.rem, 200);
        shape.setPropLerp("accelStep", LOWER_ACCEL * globals.rem, 200);
      }
    };
    this.accelTimeoutThread = setTimeout(step1, 500);
  },
  in() {
    this.doIn = true;
    this.inTimeout = setTimeout(this.startCheckIn.bind(this), 3000);
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      const pinchAngle = i % 2 !== 0 ? PINCH_ANGLE : 0;
      shape.setTarget(
        Math.sin((i * Math.PI) / 2 + pinchAngle + SPIT_OFFSET) *
          this.radius *
          1.5 +
          globals.vW / 2,
        Math.cos((i * Math.PI) / 2 + pinchAngle + SPIT_OFFSET) *
          this.radius *
          1.5 +
          globals.vH / 2,
        (Math.random() - 0.5) * 2 * globals.rem * DEPTH_RAND
      );
      shape.setProp("vMax", UPPER_VMAX * globals.rem);
      shape.setProp("selfVMax", UPPER_SELFVMAX * globals.rem);
      shape.setProp("accelStep", UPPER_ACCEL * globals.rem);
    }
  },
  startCheckIn() {
    this.checkIn = true;
  },
  endIn() {
    this.doIn = false;
    this.setTarget();
    for (const shape of globals.shapes) {
      shape.setPropLerp("vMax", LOWER_VMAX * globals.rem, 200);
      shape.setPropLerp("selfVMax", LOWER_SELFVMAX * globals.rem, 200);
      shape.setPropLerp("accelStep", LOWER_ACCEL * globals.rem, 200);
    }
  },
  out() {
    clearTimeout(this.inTimeout);
    clearTimeout(this.accelTimeoutThread);
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      shape.setPropLerp("orbitRadius", 0);
      shape.setPropLerp("vMax", UPPER_VMAX * globals.rem);
      shape.setPropLerp("selfVMax", UPPER_SELFVMAX * globals.rem);
      // project a line out for the current direction to offscreen
      const xOffset = shape.pos.x + shape.turnVector.x * globals.vMax * 2;
      const yOffset = shape.pos.y + shape.turnVector.y * globals.vMax * 2;
      shape.setTarget(xOffset, yOffset);
    }
  },
  kill() {
    clearTimeout(this.inTimeout);
    clearTimeout(this.accelTimeoutThread);
  },
  onVisibilityChange() {},
  next(options) {
    if (globals.isOut || globals.isLeaving) return;
    for (const shape of globals.shapes) {
      shape.setPropLerp("vMax", UPPER_VMAX * globals.rem, 200);
      shape.setPropLerp("selfVMax", UPPER_SELFVMAX * globals.rem, 200);
    }
    clearTimeout(this.accelTimeoutThread);
    this.accelTimeoutThread = setTimeout(() => {
      if (globals.stateKey !== "pagecircle") return;
      for (const shape of globals.shapes) {
        shape.setPropLerp("vMax", LOWER_VMAX * globals.rem, SPIN_DECAY_TIME);
        shape.setPropLerp(
          "selfVMax",
          LOWER_SELFVMAX * globals.rem,
          SPIN_DECAY_TIME
        );
      }
    }, 1500);
    if (options && options.isSam)
      this.accelTimeoutThread = setTimeout(this._wave, 2500);
  },
};
