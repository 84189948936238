<template>
  <Transition>
    <OfflineStatus v-if="state === 'offline'" />
    <Splash
      v-else-if="state === 'splash'"
      ref="splash"
      :startup="true"
      @next="next"
    />
  </Transition>
</template>

<script>
import UserData from "@/scripts/userData.js";
import PersistentData from "@/scripts/persistentData.js";
import * as Data from "@/scripts/data.js";
import * as Connection from "@/scripts/connection.js";
import * as ServiceWorker from "@/registerServiceWorker.js";

import OfflineStatus from "@/components/sections/OfflineStatus.vue";
import Splash from "@/components/sections/Splash.vue";

// BOOTUP LOGIC

// NULL STATE
// hold on an empty black screen until we know what to do
// wait for status from various async dependencies:
// - login status
// - data.js content updates
// - service worker updates
// - UserData synchronisation

// URGENT DATA UPDATES
// urgent updates should be done asap
// players can't play without these updates
// - content data unset
// - language is unset or not found on server (deleted probably)
// - js version changes

// OPTIONAL DATA UPDATES
// not all updates need to be done asap
// if an update isn't urgent, only trigger if...
// - user is logged out
// - user is new and hasn't completed the intro (ignore if offline mode)

// SERVICE WORKER UPDATES
// service worker updates are urgent, but unpredictable
// the service worker might not detect updates for many seconds
// update the worker if...
// - already waiting for install
// - found before splash timeout or
// - found before data download finishes
// service worker updates occur last and will trigger a page refresh

// OFFLINE MODE
// sometimes updates are unknowable
// but the game can still be played!
// - user is logged in
// - user has content data
// - user is confirmed offline - prefer checking for updates

// OFFLINE STATUS
// sometimes startup is blocked by tasks that need internet:
// - user needs to log in
// - app needs data
// offline state should only show once

export default {
  emits: ["next"],

  components: {
    Splash,
    OfflineStatus,
  },

  props: {
    authReady: {
      type: Boolean,
      required: true,
    },
    loggedIn: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      state: null,
    };
  },

  watch: {
    authReady() {
      this.testState();
    },
    loggedIn() {
      this.testState();
    },
  },

  methods: {
    testState() {
      // block callbacks after the choice has been made
      if (this.decided) {
        console.log("STARTUP - test state - already chosen - exit");
        return;
      }

      console.log("STARTUP - test state...");

      // UPDATE NEEDED
      if (Data.needsUpdate || PersistentData.updating) {
        // only show if connection is confirmed
        if (Connection.isOnline) {
          this.toUpdate();
        }

        // show offline if offline is confirmed
        else if (Connection.isOffline) {
          this.state = "offline";
        }

        // hold on black if connection status still unknown
      } else if (this.authReady) {
        // UPDATE DECIDED + ONLINE
        if (Data.ready) {
          // non-urgent update - do now if logged out or just starting the game
          if (Data.hasUpdate && !(this.loggedIn && UserData.intro)) {
            this.toUpdate();
          }

          // no updates - continue to game
          else {
            this.toGame();
          }
        }

        // offline states...
        else if (Connection.isOffline) {
          // OFFLINE MODE
          // if user is logged in and content already exists
          if (this.loggedIn && Data.hasData) {
            this.toGame();
          }

          // otherwise prompt the user to connect
          else {
            this.state = "offline";
          }
        }
      }

      // otherwise wait on the black screen for further changes...
    },

    // update events

    onUpdateNeeded() {
      console.log("STARTUP - update needed");
      this.testState();
    },
    onUpdateReady() {
      console.log("STARTUP - update ready");
      Data.removeUpdateReady(); // only one update!
      Data.update();
    },
    onUpdateComplete() {
      console.log("STARTUP - update complete");
      this.hasContent = true;
      this.finish();
    },

    // change state

    toUpdate() {
      console.log("STARTUP - to update");

      this.hasContent = false;

      this.showSplash();

      // trigger update when ready - often instant
      Data.onUpdateReady(this.onUpdateReady);
      Data.onUpdateComplete(this.onUpdateComplete);
    },
    toGame() {
      console.log("STARTUP - to game");

      this.showSplash();
    },

    // SPLASH

    showSplash() {
      // once decided, don't change path
      this.decided = true;

      // stop watcing data.js states
      Data.removeReady();
      Data.removeUpdateNeeded();

      // show splash screen for 3s then on to game
      this.state = "splash";
      this.timeout = setTimeout(this.splashTimeout, 3000);
    },
    splashTimeout() {
      this.hasWaited = true;
      this.finish();
    },

    // SW

    swReady() {
      console.log("STARTUP - service worker - ready");
      this.finish();
    },
    swFound() {
      console.log("STARTUP - service worker - found");
      this.hasCode = false;
    },
    swWaiting() {
      console.log("STARTUP - service worker - waiting");
      this.hasCode = false;

      // run the update immediately
      ServiceWorker.update();
    },
    swLoad() {
      console.log("STARTUP - service worker - loaded");

      this.needsRefresh = true;
      this.hasCode = true;
      this.finish();
    },
    swCancel() {
      console.log("STARTUP - service worker - auto loaded / error");

      this.hasCode = true;
      this.finish();
    },

    // end

    async finish() {
      // wait for all the tasks
      if (
        this.hasWaited &&
        this.hasCode &&
        this.hasContent &&
        ServiceWorker.ready
      ) {
        this.clearEvents();

        // if worker updated, refresh the app to apply
        if (this.needsRefresh) {
          ServiceWorker.reload();
        }

        // otherwise fade out and continue
        else {
          if (this.loggedIn) {
            await UserData.sync();
          }

          console.log("STARTUP - END");
          Data.resume();
          this.$refs.splash.fadeOut();
        }
      }
    },

    next() {
      this.$emit("next");
    },

    clearEvents() {
      // only run once
      // prevents duplicate removal on unmount
      if (this.eventsCleared) return;
      this.eventsCleared = true;

      clearTimeout(this.timeout);

      Connection.removeOfflineEvent(this.testState);
      Connection.removeOnlineEvent(this.testState);

      Data.removeUpdateNeeded();
      Data.removeUpdateReady();
      Data.removeUpdateComplete();
      Data.removeReady();
      Data.removeData();

      ServiceWorker.removeReady();
      ServiceWorker.removeFound();
      ServiceWorker.removeWaiting();
      ServiceWorker.removeLoad();
      ServiceWorker.removeCancel();
    },
  },

  created() {
    console.log("STARTUP - Startup.created()");

    this.decided = false;

    this.hasContent = true;
    this.hasCode = true;
    this.hasWaited = false;

    this.eventsCleared = false;

    // watch for connection changes
    Connection.addOfflineEvent(this.testState);
    Connection.addOnlineEvent(this.testState);

    // watch for data states
    Data.onUpdateNeeded(this.testState);
    Data.onData(this.testState);
    Data.onReady(this.testState);

    // watch for service worker updates
    ServiceWorker.onReady(this.swReady);
    ServiceWorker.onFound(this.swFound);
    ServiceWorker.onWaiting(this.swWaiting);
    ServiceWorker.onLoad(this.swLoad);
    ServiceWorker.onCancel(this.swCancel);
  },

  beforeUnmount() {
    this.clearEvents();
  },
};
</script>
