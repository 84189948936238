<template>
  <p class="card-text" v-html="text"></p>
</template>

<script>
import { markdown, parse } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: markdown(parse(this.data.content)),
    };
  },
};
</script>

<style lang="scss">
$fix: -0.375rem;

.card-text {
  line-height: 1.75;
  margin: 2rem + $fix 0 $fix;

  &:first-child {
    margin: $fix 0;
  }

  & + .card-text {
    margin: 1.875 + $fix 0 $fix;
  }
}
</style>
