const formatSettings = {
  timeStyle: "short",
};

let needsUpdate = true;
let needsString = false;

let locale;
let date;
let displayString;

function onTimeout() {
  needsUpdate = true;
}

function updateTime() {
  date = new Date();

  needsUpdate = false;
  setTimeout(onTimeout, 100);
}

function updateString() {
  displayString = date.toLocaleTimeString(locale, formatSettings);
  needsString = false;
}

export default {
  get hours() {
    return date.getHours();
  },

  get minutes() {
    return date.getMinutes();
  },

  get seconds() {
    return date.getSeconds();
  },

  update() {
    if (needsUpdate) {
      updateTime();
      needsString = true;
    }
  },

  getString() {
    if (needsUpdate) {
      updateTime();
      updateString();
    } else if (needsString) {
      updateString();
    }

    return displayString;
  },

  setLocale(newLocale) {
    locale = newLocale;
  },
};
