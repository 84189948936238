<template>
  <figure class="fragment notification">
    <div class="notification__time" aria-hidden="true">
      {{ time }}
    </div>
    <svg
      class="notification__dash"
      viewBox="0 0 65 12"
      fill="none"
      aria-hidden="true"
    >
      <path
        d="M41.9375 3.06876C41.9375 1.87215 42.9075 0.9021 44.1042 0.9021H60.0298C61.2264 0.9021 62.1965 1.87215 62.1965 3.06877V8.71831C62.1965 9.91492 61.2264 10.885 60.0298 10.885H44.1042C42.9075 10.885 41.9375 9.91492 41.9375 8.7183V3.06876Z"
        stroke="white"
      />
      <path
        d="M63.6641 3.95483V7.83114C64.4417 7.50284 64.9474 6.73912 64.9474 5.89299C64.9474 5.04685 64.4417 4.28313 63.6641 3.95483Z"
        fill="white"
      />
      <!-- <path
        d="M43.3711 3.43948C43.3711 2.83196 43.8636 2.33948 44.4711 2.33948H59.6648C60.2723 2.33948 60.7648 2.83196 60.7648 3.43948V8.34604C60.7648 8.95356 60.2723 9.44604 59.6648 9.44604H44.4711C43.8636 9.44604 43.3711 8.95355 43.3711 8.34604V3.43948Z"
        fill="white"
      /> -->
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.1958 2.60534C31.337 2.60544 33.3964 3.43055 34.9483 4.91013C35.0651 5.02436 35.2519 5.02292 35.367 4.9069L36.4841 3.77631C36.5423 3.71747 36.5748 3.63776 36.5743 3.55482C36.5739 3.47189 36.5404 3.39257 36.4815 3.33441C32.4084 -0.580221 25.9826 -0.580221 21.9094 3.33441C21.8504 3.39253 21.817 3.47182 21.8164 3.55476C21.8159 3.63769 21.8483 3.71742 21.9065 3.77631L23.0239 4.9069C23.1389 5.02309 23.3259 5.02454 23.4427 4.91013C24.9947 3.43045 27.0543 2.60534 29.1958 2.60534ZM29.1931 6.28416C30.3695 6.28409 31.504 6.72263 32.3761 7.51457C32.4941 7.62696 32.6799 7.62453 32.7948 7.50908L33.9106 6.37849C33.9694 6.31919 34.002 6.23874 34.0011 6.15514C34.0003 6.07154 33.9661 5.99177 33.9061 5.93368C31.2505 3.45635 27.1379 3.45635 24.4823 5.93368C24.4223 5.99177 24.3881 6.07158 24.3873 6.15521C24.3865 6.23883 24.4192 6.31927 24.4781 6.37849L25.5935 7.50908C25.7085 7.62453 25.8943 7.62696 26.0123 7.51457C26.8838 6.72315 28.0174 6.28465 29.1931 6.28416ZM31.4323 8.75826C31.434 8.84209 31.4012 8.92292 31.3415 8.98165L29.4115 10.935C29.3549 10.9924 29.2777 11.0247 29.1973 11.0247C29.1168 11.0247 29.0396 10.9924 28.9831 10.935L27.0527 8.98165C26.993 8.92287 26.9602 8.84202 26.962 8.75819C26.9638 8.67436 27 8.59497 27.062 8.53878C28.2946 7.49326 30.0999 7.49326 31.3325 8.53878C31.3945 8.59502 31.4306 8.67443 31.4323 8.75826Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9877 0.723999H15.0214C14.4877 0.723999 14.0551 1.15787 14.0551 1.69308V10.0917C14.0551 10.6269 14.4877 11.0608 15.0214 11.0608H15.9877C16.5214 11.0608 16.954 10.6269 16.954 10.0917V1.69308C16.954 1.15787 16.5214 0.723999 15.9877 0.723999ZM10.5109 2.98511H11.4772C12.0109 2.98511 12.4435 3.41898 12.4435 3.95418V10.0917C12.4435 10.6269 12.0109 11.0607 11.4772 11.0607H10.5109C9.97718 11.0607 9.54455 10.6269 9.54455 10.0917V3.95418C9.54455 3.41898 9.97718 2.98511 10.5109 2.98511ZM6.97053 5.24622H6.00421C5.47053 5.24622 5.03789 5.68009 5.03789 6.21529V10.0916C5.03789 10.6268 5.47053 11.0607 6.00421 11.0607H6.97053C7.50421 11.0607 7.93684 10.6268 7.93684 10.0916V6.21529C7.93684 5.68009 7.50421 5.24622 6.97053 5.24622ZM2.45998 7.1853H1.49366C0.959978 7.1853 0.527344 7.61917 0.527344 8.15438V10.0925C0.527344 10.6277 0.959978 11.0616 1.49366 11.0616H2.45998C2.99366 11.0616 3.42629 10.6277 3.42629 10.0925V8.15438C3.42629 7.61917 2.99366 7.1853 2.45998 7.1853Z"
        fill="white"
      />
    </svg>
    <div
      class="notification__battery"
      :style="{
        width: batteryWidth,
        backgroundColor: batteryColor,
      }"
      aria-hidden="true"
    ></div>
    <div class="notification__body" v-html="content"></div>
  </figure>
</template>

<script>
import Time from "@/scripts/time.js";
import { parse, markdown } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const rand = 1 - 0.9 * Math.random() ** 1.5;

    return {
      time: Time.getString(),
      batteryWidth: `${rand * 1.085}rem`,
      batteryColor: rand < 0.25 ? "#ff3b30" : null,
      content: parse(markdown(this.data.body)),
    };
  },
  methods: {
    updateTime() {
      this.time = Time.getString();
    },
  },
  mounted() {
    this.timeout = setInterval(this.updateTime, 100);
  },
  beforeUnmount() {
    clearInterval(this.timeout);
  },
};
</script>

<style lang="scss">
@keyframes notification-opacity-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes notification-bounce-in {
  0% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
  }
}

.notification {
  padding-top: $content-padding * 2;

  &__battery {
    position: absolute;
    top: 1.475rem;
    left: 86.66%;
    height: 0.4475rem;
    width: 1.085rem;
    background: #fff;
    border-radius: 0.06rem;
    transform: translate3d(0.1px, 0.2px, 0);
  }

  &__time {
    position: absolute;
    top: $content-padding * 0.666;
    font-size: 0.8125rem;
    line-height: 1em;
    font-weight: $semi-bold;
    letter-spacing: 0.1em;
    pointer-events: none;
    user-select: none;
  }

  &__dash {
    position: absolute;
    top: $content-padding * 0.666;
    right: $content-padding;
    width: auto;
    height: 0.75rem;
    pointer-events: none;
    user-select: none;
  }

  &__body {
    padding: $fragment-inner-padding;
    background: var(--color-2);
    border-radius: $fragment-radius;
    animation: notification-bounce-in 0.5s $ease-out-quart both,
      notification-opacity-in 0.5s $ease-out-quart both;
    animation-delay: calc(var(--delay) + 0.3s);
  }
}
</style>
