<template>
  <Browser :url="url">
    <div class="web__banner">
      <ImageAttachment v-if="has_image" :image="image" />
    </div>
    <div class="web__text" v-html="body"></div>
  </Browser>
</template>

<script>
import Browser from "@/components/content/Browser.vue";
import ImageAttachment from "@/components/content/image/ImageAttachment.vue";
import { parse, markdown } from "@/scripts/insert.js";

export default {
  components: {
    Browser,
    ImageAttachment,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { has_image, image, url, body } = this.data;

    return {
      has_image,
      image,
      url,
      body: parse(markdown(body)),
    };
  },
};
</script>

<style lang="scss">
.web {
  &__banner {
    background: #f6f6f6;
  }

  &__text {
    padding: $content-padding;
  }
}
</style>
