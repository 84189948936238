<template>
  <div
    ref="modal"
    class="modal"
    :class="{
      'modal--show': visible,
    }"
    @touchstart.stop
    @touchmove.stop
    @wheel.stop
  >
    <div class="modal__video">
      <iframe
        v-if="isVimeo"
        ref="iframe"
        :src="src"
        allow="autoplay"
        title="Vimeo"
        class="modal__iframe"
        @load="onVimeoLoaded"
      ></iframe>
      <iframe
        v-else
        ref="iframe"
        :src="src"
        allow="autoplay"
        title="YouTube"
        class="modal__iframe"
      ></iframe>
      <button
        v-if="canShowPlay && paused"
        type="button"
        class="modal__button"
        @click="onClickPlay"
        :aria-label="playButtonLabel"
      ></button>
    </div>
    <button
      type="button"
      class="modal__close"
      @click="onClickClose"
      :aria-label="closeButtonLabel"
    >
      <svg class="modal__cross" viewBox="0 0 32 33" aria-hidden="true">
        <path
          d="M30.143 2.00042L16 16.1432L1.857 2.00042L1 2.85741L15.143 17.0002L1 31.143L1.857 32L16 17.8572L30.143 32L31 31.1426L16.857 16.9998L31 2.85699L30.143 2V2.00042Z"
          fill="#FFF"
          stroke="#FFF"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import {
  focusElement,
  isVimeoUrl,
  isYoutubeUrl,
  getVimeoId,
  getYoutubeId,
} from "@/scripts/helpers.js";
import Player from "@vimeo/player";
import * as Youtube from "@/scripts/youtube.js";
import * as AudioManager from "@/scripts/audioManager.js";
import SessionData from "@/scripts/sessionData.js";
import * as Background from "@/scripts/background.js";

export default {
  props: {
    video: {
      type: String,
      required: true,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const video = this.video;
    const isVimeo = isVimeoUrl(video);
    const isYoutube = isYoutubeUrl(video);
    const id = isVimeo
      ? getVimeoId(video)
      : isYoutube
      ? getYoutubeId(video)
      : "";
    const src = isVimeo
      ? `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&keyboard=0&muted=1&pip=0&controls=0&dnt=1&app_id=122963&texttrack=en`
      : isYoutube
      ? `https://www.youtube.com/embed/${id}?controls=0&playsinline=1&rel=0&enablejsapi=1&disablekb=1`
      : video;
    return {
      ready: false,
      visible: false,
      canShowPlay: false,
      isVimeo,
      isYoutube,
      src,
      id,
      paused: false,
      playing: false,
      promise: Promise.resolve(),
      playButtonLabel: SessionData.lang.Video.play_button_label,
      closeButtonLabel: SessionData.lang.Video.close_button_label,
    };
  },
  methods: {
    async setVimeoState(state) {
      if (!(this.mounted && state)) return;

      // queue state change if pending promise
      if (this.vimeoChanging) {
        this.vimeoNext = state;
        return;
      }

      // state must be different
      if (this.vimeoState === state) return;

      this.vimeoChanging = true;

      // from null/pause to play
      if (state === "PLAY") {
        await this.vimeo.play();
      }

      // from null/play to pause
      else if (state === "PAUSE") {
        if (this.vimeoState === "PLAY") {
          await this.vimeo.pause();
        }
        if (this.placeholder) {
          await this.vimeo
            .getCurrentTime()
            .then(this.setCurrentTime, this.setDefaultTime);
        }
      }

      // complete state change
      this.vimeoChanging = false;
      this.vimeoState = state;

      // to queued state
      state = this.vimeoNext;
      this.vimeoNext = null;
      this.setVimeoState(state);
    },
    setCurrentTime(t) {
      this.$parent.setCurrentTime(t);
    },
    setDefaultTime() {
      this.setCurrentTime(0);
    },
    onPause() {
      this.playing = false;
      this.paused = true;
      if (this.vimeo) {
        this.vimeo.setMuted(true);
      }
    },
    onPlay() {
      this.paused = false;
      if (this.vimeo) {
        this.vimeo.setMuted(false);
      }
    },
    onClickPlay() {
      this.play();
      AudioManager.play("ui-input-button-misc");
    },
    onClickClose() {
      AudioManager.play("ui-input-button-misc");
      this.$root.modalHide();
    },
    play() {
      this.playing = true;
      if (this.vimeo) {
        this.setVimeoState("PLAY");
      } else if (this.youtube) {
        this.youtube.playVideo();
      }
    },
    show() {
      this.$root.modalShow(this.hide);
      this.visible = true;
      Background.pause();
      this.play();
      this.canShowPlay = true;
      focusElement(this.$refs.modal);
    },
    hide() {
      this.canShowPlay = false;
      this.visible = false;
      Background.resume();
      if (this.vimeo) {
        this.setVimeoState("PAUSE");
      } else if (this.youtube) {
        this.youtube.pauseVideo();
      }
    },
    onVimeoLoaded() {
      this.vimeo = new Player(this.$refs.iframe);
      if (this.vimeo) {
        this.vimeo.on("pause", this.onPause);
        this.vimeo.on("play", this.onPlay);
        this.onReady();
      }
    },
    onYoutubeScriptLoaded() {
      new YT.Player(this.$refs.iframe, {
        events: {
          onReady: this.onYoutubeLoaded,
          onStateChange: this.onYoutubeStateChange,
        },
      });
    },
    onYoutubeLoaded(event) {
      this.youtube = event.target;
      this.onReady();
    },
    onReady() {
      if (this.placeholder) {
        this.raf = requestAnimationFrame(this.$parent.setModalReady);
      }
    },
    onYoutubeStateChange(event) {
      switch (event.data) {
        case YT.PlayerState.PLAYING:
          this.onPlay();
          break;
        case YT.PlayerState.PAUSED:
          this.onPause();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.mounted = true;
    // only load youtube api if needed
    if (this.isYoutube) Youtube.onReady(this.onYoutubeScriptLoaded);
  },
  beforeUnmount() {
    this.mounted = false;
    cancelAnimationFrame(this.raf);
    if (this.vimeo) this.vimeo.destroy();
    if (this.youtube) this.youtube.destroy();
    if (this.isYoutube) Youtube.cancelReady();
    Background.resume();
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;

  z-index: -1;
  opacity: 0;
  transition: z-index 0s 0.3s ease, opacity 0.3s ease;

  &--show {
    z-index: $z-modal;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: min(100vw, 100vh * 16 / 9);
    transform: translate(-50%, -50%);
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      padding: 56.25% 0 0;
    }
  }

  &__iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: $content-radius;
  }

  &__button {
    @include reset-button;

    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: $content-radius;
  }

  &__play {
    width: 20vmin;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__close {
    @include reset-button;

    position: absolute;
    right: max(1rem, env(safe-area-inset-right, 0));
    top: max(1rem, env(safe-area-inset-top, 0));
    width: 2.12rem;
    height: 2.12rem;
    user-select: none;
    transition: transform 0.25s ease;

    @media (hover: hover) {
      &:hover,
      &:focus-within {
        transform: scale(1.1);
      }
    }
  }

  &__cross {
    width: 2.12rem;
    height: 2.12rem;
    filter: drop-shadow(0 0 0.5rem #000);
  }
}
</style>
