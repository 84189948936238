<template>
  <div class="placeholder info--black" ref="root">
    <!-- video -->
    <div v-if="isOnline" aria-hidden="true" class="placeholder__clip">
      <iframe
        v-if="isVimeo"
        :src="src"
        ref="iframe"
        class="placeholder__iframe"
        :class="{
          'placeholder__iframe--show': modalReady,
        }"
        @load="onVimeoIframeLoad"
      ></iframe>
      <img
        v-else-if="isYoutube"
        :src="src"
        alt=""
        class="placeholder__iframe"
        :class="{
          'placeholder__iframe--show': modalReady,
        }"
      />
      <iframe
        v-else
        :src="src"
        ref="iframe"
        class="placeholder__iframe"
        :class="{
          'placeholder__iframe--show': modalReady,
        }"
      ></iframe>
    </div>

    <!-- play button -->
    <button
      v-if="isOnline && modalReady"
      type="button"
      :aria-label="buttonLabel"
      class="placeholder__play"
      @click="onPlayButtonClick"
    >
      <GradientPlay class="placeholder__icon" aria-hidden="true" />
    </button>

    <!-- internet status text -->
    <div v-if="!isOnline" class="placeholder__error">
      <span class="placeholder__text"
        >Connect to the internet to see this video</span
      >
    </div>

    <Teleport to="body">
      <VideoModal ref="modal" :video="video" placeholder />
    </Teleport>
  </div>
</template>

<script>
import VideoModal from "@/components/content/video/VideoModal.vue";
import GradientPlay from "./gradientPlay.vue";
import Player from "@vimeo/player";
import * as Connection from "@/scripts/connection.js";
import * as RemovalObserver from "@/scripts/removalObserver.js";
import {
  isVimeoUrl,
  isYoutubeUrl,
  getVimeoId,
  getYoutubeId,
} from "@/scripts/helpers.js";
import * as AudioManager from "@/scripts/audioManager.js";
import SessionData from "@/scripts/sessionData.js";

export default {
  components: {
    VideoModal,
    GradientPlay,
  },
  props: {
    data: {
      type: [Object, String],
      required: true,
    },
  },
  data() {
    const video = this.data.video;
    const isVimeo = isVimeoUrl(video);
    const isYoutube = isYoutubeUrl(video);
    const id = isVimeo
      ? getVimeoId(video)
      : isYoutube
      ? getYoutubeId(video)
      : "";
    // load just high res thumbnail for yt
    const src = isVimeo
      ? `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&keyboard=0&muted=1&pip=0&controls=0&dnt=1&app_id=122963&texttrack=en`
      : isYoutube
      ? `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`
      : video;
    return {
      isOnline: Connection.isOnline,
      isVimeo,
      isYoutube,
      modalReady: false,
      video,
      src,
      buttonLabel: SessionData.lang.Video.play_button_label,
    };
  },
  methods: {
    // interface with the modal

    setModalReady() {
      this.modalReady = true;
    },
    async setCurrentTime(t) {
      await this.vimeoPromise;

      if (this.mounted && this.vimeoPlayer) {
        this.vimeoPlayer.setCurrentTime(t);
      }
    },

    // events

    onVimeoIframeLoad() {
      if (this.mounted && this.$refs.iframe) {
        this.vimeoPlayer = new Player(this.$refs.iframe);
      }
    },
    async onPlayButtonClick() {
      AudioManager.play("ui-input-button-misc");

      this.$refs.modal.show();

      // to enable setCurrentTime, play then immediately pause
      if (this.vimeoPlayer) {
        this.vimeoPromise = this.vimeoPlayer.play();
        await this.vimeoPromise;
        if (this.mounted) {
          this.vimeoPlayer.pause();
        }
      }
    },

    // only show video when online
    whenOnline() {
      this.isOnline = true;
      Connection.removeOnlineEvent(this.whenOnline);
    },

    // destroy vimeo player after leave anim
    destroyVideo() {
      if (this.vimeoPlayer) {
        this.vimeoPlayer.destroy();
      }
    },
  },
  mounted() {
    this.mounted = true;

    if (!this.isOnline) {
      Connection.addOnlineEvent(this.whenOnline);
    }
  },
  beforeUnmount() {
    this.mounted = false;

    if (!this.isOnline) {
      Connection.removeOnlineEvent(this.whenOnline);
    }

    if (this.vimeoPlayer) {
      RemovalObserver.observe(this.$el, this.destroyVideo);
    }
  },
};
</script>

<style lang="scss">
@keyframes placeholder-button-bounce {
  0% {
    transform: scale(0.7);
  }

  20% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes placeholder-button-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.placeholder {
  position: relative;
  width: 100%;
  padding: 0 0 56.25%;
  border-radius: $content-radius;

  &__clip {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: $content-radius;
    overflow: hidden;
    overflow: clip;
  }

  &__iframe {
    display: block;
    margin: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: none;

    opacity: 0;
    transition: opacity 1.5s $ease-out-quint;

    &--show {
      opacity: 1;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    user-select: none;
  }

  &__text {
    max-width: 55%;
  }

  &__play {
    @include reset-button;

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $content-radius;
  }

  &__icon {
    width: 3.5rem;
    height: auto;
    animation: placeholder-button-bounce 0.6s 0.15s $ease-out-cubic both,
      placeholder-button-fade 0.3s 0.15s $ease-out-quint both;
  }
}
</style>
