<template>
  <ScrollPage @next="next" class="default-page-anim">
    <SAM :data="data.content" class="page-content-anim page-content-margin" />
  </ScrollPage>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";

export default {
  emits: ["next"],
  components: {
    ScrollPage,
    SAM,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    next() {
      this.$emit("next");
    },
  },
};
</script>
