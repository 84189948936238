import { windowWidth, windowHeight } from "@/scripts/windowSize.js";
import { blackBackground, defaultGradient } from "@/scripts/globals.js";

const canP3 = window.matchMedia("(color-gamut: p3)").matches;

const webglContextSettings = {
  depth: false,
  stencil: false,
  antialias: false,
  preserveDrawingBuffer: false,
};

let canvasWidth;
let canvasHeight;

const rectVerts = [-1, -1, 1, -1, 1, 1, -1, 1];
const rectIndices = [0, 2, 3, 0, 1, 2];

const background = {
  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.95,
  update: false,
};

let angle = Math.random() * 0.5 * Math.PI;
const gradient1 = {
  x: 0,
  y: 0,
  dx: Math.cos(angle),
  dy: Math.sin(angle),

  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.96,
  update: false,
};

angle = 0.5 * Math.PI * Math.random() * 0.5 * Math.PI;
const gradient2 = {
  x: 0,
  y: 0,
  dx: Math.cos(angle),
  dy: Math.sin(angle),

  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.97,
  update: false,
};

angle = Math.PI * Math.random() * 0.5 * Math.PI;
const gradient3 = {
  x: 0,
  y: 0,
  dx: Math.cos(angle),
  dy: Math.sin(angle),

  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.98,
  update: false,
};

angle = 1.5 * Math.PI * Math.random() * 0.5 * Math.PI;
const gradient4 = {
  x: 0,
  y: 0,
  dx: Math.cos(angle),
  dy: Math.sin(angle),

  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.99,
  update: false,
};

const interface1 = {
  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.975,
  update: false,
};

const interface2 = {
  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.975,
  update: false,
};

const interface3 = {
  color: { r: 0, g: 0, b: 0, a: 0 },
  colorTo: { r: 0, g: 0, b: 0, a: 0 },
  rate: 0.975,
  update: false,
};

const appStyles = document.getElementById("app").style;
let gl;

const container = document.getElementById("background");
let canvas;
let program;
let vertexShader;
let fragmentShader;

// size variables

let overflowWidth;
let overflowHeight;
let xMin;
let xMax;
let yMin;
let yMax;
let v;
let x;
let y;
let dx;
let dy;

// UNIFORM LOCATIONS

let radiusInvLocation;
let overflowWidthLocation;
let overflowHeightLocation;

let gradientPositionLocation1;
let gradientPositionLocation2;
let gradientPositionLocation3;
let gradientPositionLocation4;

let gradientColorLocation1;
let gradientColorLocation2;
let gradientColorLocation3;
let gradientColorLocation4;

let backgroundColorLocation;

// attributes
let positionBuffer;
let indexBuffer;

// animations
let playing = false;

let dt;
let pt;
let transitionTime;
let animateScroll = false;
let delta;
let r, g, b, a;

const vert = `
  precision highp float;

  attribute vec4 a_position;

  void main() {
    gl_Position = a_position;
  }
`;

// FRAGMENT SHADER
// layer 4 gradients in order over a background color
// gradient interpolation is sinusoidal

// color interpolation made in oklab space
// srgb -> linear rgb -> oklab -> linear rgb -> p3
// conversions from w3c specification for css color level 4
// https://www.w3.org/TR/css-color-4/#color-conversion-code

const frag = `
  precision highp float;

  #define PI 3.14159265359

  uniform float u_overflowWidth;
  uniform float u_overflowHeight;

  uniform float u_radiusInv;

  uniform vec2 u_gradientPosition1;
  uniform vec2 u_gradientPosition2;
  uniform vec2 u_gradientPosition3;
  uniform vec2 u_gradientPosition4;

  uniform vec4 u_gradientColor1;
  uniform vec4 u_gradientColor2;
  uniform vec4 u_gradientColor3;
  uniform vec4 u_gradientColor4;

  uniform vec4 u_backgroundColor;

  vec2 range = vec2(u_overflowWidth);
  vec2 halfRange = 0.5 * range;
  float gradientValue(vec2 centre, float rad) {
    return 0.5 + 0.5 * cos(PI * min(length(mod(abs(gl_FragCoord.xy - centre) + halfRange, range) - halfRange) * u_radiusInv * rad, 1.0));
  }

  const mat3 srgbToXyz_d65_mat = mat3(
    ${506752.0 / 1228815.0}, ${87881.0 / 245763.0}, ${12673.0 / 70218.0},
		${87098.0 / 409605.0}, ${175762.0 / 245763.0}, ${12673.0 / 175545.0},
		${7918.0 / 409605.0}, ${87881.0 / 737289.0}, ${1001167.0 / 1053270.0}
  );
  vec3 srgbToXyz_d65(vec3 c) {
    return c * srgbToXyz_d65_mat;
  }

  const mat3 xyzToSrgb_d65_mat = mat3(
    ${12831.0 / 3959.0}, ${-329.0 / 214.0}, ${-1974.0 / 3959.0},
		${-851781.0 / 878810.0}, ${1648619.0 / 878810.0}, ${36519.0 / 878810.0},
		${705.0 / 12673.0}, ${-2585.0 / 12673.0}, ${705.0 / 667.0}
  );
  vec3 xyzToSrgb_d65(vec3 c) {
    return c * xyzToSrgb_d65_mat;
  }

  const mat3 p3ToXyz_d65_mat = mat3(
    ${608311.0 / 1250200.0}, ${189793.0 / 714400.0}, ${198249.0 / 1000160.0},
		${35783.0 / 156275.0}, ${247089.0 / 357200.0}, ${198249.0 / 2500400.0},
		0.0, ${32229.0 / 714400.0}, ${5220557.0 / 5000800.0}
  );
  vec3 p3ToXyz_d65(vec3 c) {
    return c * p3ToXyz_d65_mat;
  }

  const mat3 xyzToP3_d65_mat = mat3(
    ${446124.0 / 178915.0}, ${-333277.0 / 357830.0}, ${-72051.0 / 178915.0},
		${-14852.0 / 17905.0}, ${63121.0 / 35810.0}, ${423.0 / 17905.0},
		${11844.0 / 330415.0}, ${-50337.0 / 660830.0}, ${316169.0 / 330415.0}
  );
  vec3 xyzToP3_d65(vec3 c) {
    return c * xyzToP3_d65_mat;
  }

  const mat3 xyzToOklab_mat_lms = mat3(
    0.8190224379967030, 0.3619062600528904, -0.1288737815209879,
		0.0329836539323885, 0.9292868615863434, 0.0361446663506424,
		0.0481771893596242, 0.2642395317527308, 0.6335478284694309
  );
  const mat3 xyzToOklab_mat_ok = mat3(
    0.2104542683093140, 0.7936177747023054, -0.0040720430116193,
		1.9779985324311684, -2.4285922420485799, 0.4505937096174110,
		0.0259040424655478, 0.7827717124575296, -0.8086757549230774
  );
  vec3 xyzToOklab(vec3 c) {
    return pow(c * xyzToOklab_mat_lms, vec3(${1 / 3})) * xyzToOklab_mat_ok;
  }

  const mat3 oklabToXyz_mat_lms = mat3(
    1.0, 0.3963377773761749, 0.2158037573099136,
		1.0, -0.1055613458156586, -0.0638541728258133,
		1.0, -0.0894841775298119, -1.2914855480194092
  );
  const mat3 oklabToXyz_mat_ok = mat3(
    1.2268798758459243, -0.5578149944602171, 0.2813910456659647,
		-0.0405757452148008, 1.1122868032803170, -0.0717110580655164,
		-0.0763729366746601, -0.4214933324022432, 1.5869240198367816
  );
  vec3 oklabToXyz(vec3 c) {
    vec3 lms = c * oklabToXyz_mat_lms;
    return lms * lms * lms * oklabToXyz_mat_ok;
  }


  float toLin(float v) {
    return v > 0.04045 ? pow((v + 0.055) / 1.055, 2.4) : v / 12.92;
  }

  vec3 gammaToLinear(vec3 c) {
    return vec3(toLin(c.r), toLin(c.g), toLin(c.b));
  }

  float fromLin(float v) {
    return v > 0.0031308 ? 1.055 * pow(v, ${1 / 2.4}) - 0.055 : v * 12.92;
  }

  vec3 linearToGamma(vec3 c) {
    return vec3(fromLin(c.r), fromLin(c.g), fromLin(c.b));
  }

  vec3 toSpace(vec3 c) {
    return xyzToOklab(srgbToXyz_d65(gammaToLinear(c)));
  }

  vec3 fromSpace(vec3 c) {
    return linearToGamma(xyzTo${canP3 ? "P3" : "Srgb"}_d65(oklabToXyz(c)));
  }

  void main() {
    float amt1 = gradientValue(u_gradientPosition1, 1.0) * u_gradientColor1.a;
    float amt2 = gradientValue(u_gradientPosition2, 1.03) * u_gradientColor2.a;
    float amt3 = gradientValue(u_gradientPosition3, 1.06) * u_gradientColor3.a;
    float amt4 = gradientValue(u_gradientPosition4, 1.09) * u_gradientColor4.a;

    vec3 bg = toSpace(u_backgroundColor.rgb) * u_backgroundColor.a;
    vec3 g1 = toSpace(u_gradientColor1.rgb);
    vec3 g2 = toSpace(u_gradientColor2.rgb);
    vec3 g3 = toSpace(u_gradientColor3.rgb);
    vec3 g4 = toSpace(u_gradientColor4.rgb);

    vec3 c = bg;
    c = mix(c, g1, amt1);
    c = mix(c, g2, amt2);
    c = mix(c, g3, amt3);
    c = mix(c, g4, amt4);

    gl_FragColor = vec4(fromSpace(c), 1.0);
  }
`;

let colorDiff;
function channelIsClose(c1, c2) {
  colorDiff = c1 - c2;
  return colorDiff * colorDiff < 0.01;
}

let colorEaseRate;
let colorIsClose;
function updateColor(obj) {
  const { color, colorTo } = obj;

  colorIsClose =
    channelIsClose(color.a, colorTo.a) && // test alpha first as it's most likely to be different
    channelIsClose(color.r, colorTo.r) &&
    channelIsClose(color.g, colorTo.g) &&
    channelIsClose(color.b, colorTo.b);

  // if the color is close, snap to, end anim
  if (colorIsClose) {
    color.r = colorTo.r;
    color.g = colorTo.g;
    color.b = colorTo.b;
    color.a = colorTo.a;
    obj.update = false;
  }

  // continue easing
  else {
    colorEaseRate = obj.rate ** (0.06 * dt);
    color.r = colorTo.r - (colorTo.r - color.r) * colorEaseRate;
    color.g = colorTo.g - (colorTo.g - color.g) * colorEaseRate;
    color.b = colorTo.b - (colorTo.b - color.b) * colorEaseRate;
    color.a = colorTo.a - (colorTo.a - color.a) * colorEaseRate;
  }
}

function updateUniformColor(location, color) {
  gl.uniform4f(location, color.r, color.g, color.b, color.a);
}

function cssRgb(c) {
  return (c * 255).toFixed(3);
}
function updateCSS(cssVar, color) {
  ({ r, g, b, a } = color);
  a = 0.3 + 0.7 * a;

  appStyles.setProperty(
    cssVar,
    `rgb(${cssRgb(r * a)}, ${cssRgb(g * a)}, ${cssRgb(b * a)})`
  );
}

const hexColorRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
function hexToRGB(color, hex) {
  const result = hexColorRegex.exec(hex);

  if (result) {
    color.r = parseInt(result[1], 16) / 255;
    color.g = parseInt(result[2], 16) / 255;
    color.b = parseInt(result[3], 16) / 255;
    color.a = 1;
  }
}

function copyColor(from, to) {
  to.r = from.r;
  to.g = from.g;
  to.b = from.b;
  to.a = from.a;
}

// animate to new colours
function startColorAnimation() {
  background.update = true;
  gradient1.update = true;
  gradient2.update = true;
  gradient3.update = true;
  gradient4.update = true;
  interface1.update = true;
  interface2.update = true;
  interface3.update = true;
}

function initColor(obj, hex, a) {
  hexToRGB(obj.colorTo, hex);
  obj.colorTo.a = a;
  copyColor(obj.colorTo, obj.color);
}

export function initColors() {
  initColor(background, defaultGradient[0], 0);
  initColor(gradient1, defaultGradient[1], 0);
  initColor(gradient2, defaultGradient[2], 0);
  initColor(gradient3, defaultGradient[3], 0);
  initColor(gradient4, defaultGradient[4], 0);
  initColor(interface1, blackBackground[5], 1);
  initColor(interface2, blackBackground[6], 1);
  initColor(interface3, blackBackground[7], 1);

  startColorAnimation();
}

function setColor(obj, hex) {
  hexToRGB(obj.colorTo, hex);

  // if the alpha is 0, instantly change the rgb values
  if (obj.color.a === 0) {
    obj.color.r = obj.colorTo.r;
    obj.color.g = obj.colorTo.g;
    obj.color.b = obj.colorTo.b;
  }
}

export function setColors(colors) {
  setColor(background, colors[0]);
  setColor(gradient1, colors[1]);
  setColor(gradient2, colors[2]);
  setColor(gradient3, colors[3]);
  setColor(gradient4, colors[4]);
  setColor(interface1, colors[5]);
  setColor(interface2, colors[6]);
  setColor(interface3, colors[7]);

  startColorAnimation();
}

function multiplyColor(obj, magnitude) {
  copyColor(obj.color, obj.colorTo);
  obj.colorTo.a = magnitude;
}

export function dim() {
  multiplyColor(background, 0.1);
  multiplyColor(gradient1, 0.12);
  multiplyColor(gradient2, 0.14);
  multiplyColor(gradient3, 0.16);
  multiplyColor(gradient4, 0.18);
  multiplyColor(interface1, 0.18);
  multiplyColor(interface2, 0.18);
  multiplyColor(interface3, 0.18);

  startColorAnimation();
}

export function restoreColors() {
  multiplyColor(background, 1);
  multiplyColor(gradient1, 1);
  multiplyColor(gradient2, 1);
  multiplyColor(gradient3, 1);
  multiplyColor(gradient4, 1);
  multiplyColor(interface1, 1);
  multiplyColor(interface2, 1);
  multiplyColor(interface3, 1);

  startColorAnimation();
}

export function fadeToBlack() {
  multiplyColor(background, 0);
  multiplyColor(gradient1, 0);
  multiplyColor(gradient2, 0);
  multiplyColor(gradient3, 0);
  multiplyColor(gradient4, 0);
  hexToRGB(interface1, blackBackground[5]);
  hexToRGB(interface2, blackBackground[6]);
  hexToRGB(interface3, blackBackground[7]);

  startColorAnimation();
}

function updatePosition(location, gradient) {
  ({ x, y, dx, dy } = gradient);

  // update gradient position
  x += dx * v;
  while (x > xMax) {
    x -= overflowWidth;
  }
  while (x < xMin) {
    x += overflowWidth;
  }
  gradient.x = x;

  y += dy * v;
  while (y > yMax) {
    y -= overflowHeight;
  }
  while (y < yMin) {
    y += overflowHeight;
  }
  gradient.y = y;

  if (playing) gl.uniform2f(location, x, y);
}

function moveGradientsY(y) {
  gradient1.y += y * 0.4;
  gradient2.y += y * 0.6;
  gradient3.y += y * 0.8;
  gradient4.y += y;
}

export function updateScrollY(scrollDelta) {
  moveGradientsY(scrollDelta * 0.7);
}

function moveGradientsX(x) {
  gradient1.x += x * 0.4;
  gradient2.x += x * 0.6;
  gradient3.x += x * 0.8;
  gradient4.x += x;
}

export function updateScrollX(scrollDelta) {
  moveGradientsX(scrollDelta * 0.7);
}

export function transitionDown() {
  animateScroll = true;
  transitionTime = performance.now();
}

function draw(t) {
  dt = t - pt;
  pt = t;

  if (animateScroll) {
    delta = (t - transitionTime) / 1500;
    if (delta < 1) {
      moveGradientsY(Math.sin(Math.PI * delta) * canvasHeight * 0.03);
    } else {
      animateScroll = false;
    }
  }

  // update positions

  updatePosition(gradientPositionLocation1, gradient1);
  updatePosition(gradientPositionLocation2, gradient2);
  updatePosition(gradientPositionLocation3, gradient3);
  updatePosition(gradientPositionLocation4, gradient4);

  // update colors

  if (background.update) {
    updateColor(background);
    if (playing) updateUniformColor(backgroundColorLocation, background.color);
  }

  if (gradient1.update) {
    updateColor(gradient1);
    if (playing) updateUniformColor(gradientColorLocation1, gradient1.color);
  }

  if (gradient2.update) {
    updateColor(gradient2);
    if (playing) updateUniformColor(gradientColorLocation2, gradient2.color);
  }

  if (gradient3.update) {
    updateColor(gradient3);
    if (playing) updateUniformColor(gradientColorLocation3, gradient3.color);
  }

  if (gradient4.update) {
    updateColor(gradient4);
    if (playing) updateUniformColor(gradientColorLocation4, gradient4.color);
  }

  if (interface1.update) {
    updateColor(interface1);
    updateCSS("--color-1", interface1.color);
  }

  if (interface2.update) {
    updateColor(interface2);
    updateCSS("--color-2", interface2.color);
  }

  if (interface3.update) {
    updateColor(interface3);
    updateCSS("--color-3", interface3.color);
  }

  // draw

  if (playing) {
    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
  }

  requestAnimationFrame(draw);
}

// get all the measurements
let gradRad;
function getMeasurements() {
  canvasWidth = windowWidth;
  canvasHeight = windowHeight;

  gradRad = Math.sqrt(canvasWidth * canvasWidth + canvasHeight * canvasHeight);
  v = gradRad * 0.001;
  xMin = 0.5 * canvasWidth - gradRad;
  xMax = canvasWidth - xMin;
  yMin = 0.5 * canvasHeight - gradRad;
  yMax = canvasHeight - yMin;
  overflowWidth = gradRad * 2;
  overflowHeight = gradRad * 2;
}

function setViewport() {
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  gl.uniform1f(overflowWidthLocation, overflowWidth);
  gl.uniform1f(overflowHeightLocation, overflowHeight);
  gl.uniform1f(radiusInvLocation, 1 / gradRad);

  gl.viewport(0, 0, canvasWidth, canvasHeight);
}

function randomPosition(gradient) {
  gradient.x = xMin + overflowWidth * Math.random();
  gradient.y = yMin + overflowHeight * Math.random();
}

function normaliseGradientPosition(gradient) {
  gradient.x = (gradient.x - xMin) / overflowWidth;
  gradient.y = (gradient.y - yMin) / overflowHeight;
}

function restoreGradientPosition(gradient) {
  gradient.x = gradient.x * overflowWidth + xMin;
  gradient.y = gradient.y * overflowHeight + yMin;
}

function resize() {
  normaliseGradientPosition(gradient1);
  normaliseGradientPosition(gradient2);
  normaliseGradientPosition(gradient3);
  normaliseGradientPosition(gradient4);

  getMeasurements();
  setViewport();

  restoreGradientPosition(gradient1);
  restoreGradientPosition(gradient2);
  restoreGradientPosition(gradient3);
  restoreGradientPosition(gradient4);
}

function createShader(gl, type, source) {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  return shader;
}

function createCanvas() {
  // create new canvas element and add it to the document
  canvas = document.createElement("canvas");

  // create webgl2 context
  gl = canvas.getContext("webgl2", webglContextSettings);

  // fall back to webgl if webgl2 unavailable
  if (!gl) {
    gl = canvas.getContext("webgl", webglContextSettings);

    if (!gl) {
      console.log("can't create webgl context");
      return;
    }
  }

  // disable features
  gl.disable(gl.DITHER);

  // enable features
  gl.drawingBufferColorSpace = canP3 ? "display-p3" : "srgb";

  // create program
  program = gl.createProgram();

  vertexShader = createShader(gl, gl.VERTEX_SHADER, vert);
  gl.attachShader(program, vertexShader);

  gl.bindAttribLocation(program, 0, "a_position"); // ensure position is at 0

  fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, frag);
  gl.attachShader(program, fragmentShader);

  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error("Link failed: " + gl.getProgramInfoLog(program));
    console.error("vert: " + gl.getShaderInfoLog(vertexShader));
    console.error("frag: " + gl.getShaderInfoLog(fragmentShader));
  }

  gl.useProgram(program);

  // CREATE RECTANGLE BUFFER

  // vertices

  positionBuffer = gl.createBuffer();

  gl.enableVertexAttribArray(0);
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(rectVerts), gl.STATIC_DRAW);
  gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 0, 0);

  // indices

  indexBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
  gl.bufferData(
    gl.ELEMENT_ARRAY_BUFFER,
    new Uint16Array(rectIndices),
    gl.STATIC_DRAW
  );

  // GRADIENT UNIFORMS

  gradientPositionLocation1 = gl.getUniformLocation(
    program,
    "u_gradientPosition1"
  );
  gradientPositionLocation2 = gl.getUniformLocation(
    program,
    "u_gradientPosition2"
  );
  gradientPositionLocation3 = gl.getUniformLocation(
    program,
    "u_gradientPosition3"
  );
  gradientPositionLocation4 = gl.getUniformLocation(
    program,
    "u_gradientPosition4"
  );

  gradientColorLocation1 = gl.getUniformLocation(program, "u_gradientColor1");
  gradientColorLocation2 = gl.getUniformLocation(program, "u_gradientColor2");
  gradientColorLocation3 = gl.getUniformLocation(program, "u_gradientColor3");
  gradientColorLocation4 = gl.getUniformLocation(program, "u_gradientColor4");
  backgroundColorLocation = gl.getUniformLocation(program, "u_backgroundColor");

  // CANVAS SIZE CALCS

  overflowWidthLocation = gl.getUniformLocation(program, "u_overflowWidth");
  overflowHeightLocation = gl.getUniformLocation(program, "u_overflowHeight");
  radiusInvLocation = gl.getUniformLocation(program, "u_radiusInv");

  canvas.addEventListener("webglcontextlost", contextLost);
}

function contextLost() {
  canvas.removeEventListener("webglcontextlost", contextLost);

  container.innerHTML = "";
  playing = false;

  // free up some memory maybe?
  gl.deleteBuffer(positionBuffer);
  gl.deleteBuffer(indexBuffer);
  gl.deleteShader(fragmentShader);
  gl.deleteShader(vertexShader);
  gl.deleteProgram(program);
  canvas.width = 1;
  canvas.height = 1;

  createCanvas();
  setViewport();
  startColorAnimation();

  container.appendChild(canvas);
}

function onLoad() {
  window.addEventListener("resize", resize);

  getMeasurements();
  setViewport();

  randomPosition(gradient1);
  randomPosition(gradient2);
  randomPosition(gradient3);
  randomPosition(gradient4);

  // start animation
  playing = true;
  startColorAnimation();

  pt = performance.now();
  draw(pt);

  container.appendChild(canvas);
}

export function start() {
  createCanvas();
  window.addEventListener("load", onLoad);
}

export function pause() {
  playing = false;
}

export function resume() {
  startColorAnimation();
  playing = true;
}

export function loseContext() {
  gl.getExtension("WEBGL_lose_context").loseContext();
}
