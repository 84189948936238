<template>
  <svg viewBox="0 0 193 50" role="img" aria-label="ChildFund Australia">
    <path
      d="M71.9265 7.34517C64.9449 14.4943 55.3569 14.8128 46.9087 14.1842C46.4645 14.1507 46.0622 14.4273 45.9364 14.8547C45.9113 14.9469 45.8945 15.0307 45.8945 15.1229C45.8945 15.4581 46.0789 15.7766 46.3806 15.9443C48.5681 17.1595 51.3674 18.0982 54.1584 18.6095C53.6723 22.1379 49.4649 32.1786 46.9003 36.2686C46.7997 36.4195 46.7578 36.5955 46.7578 36.7715C46.7578 37.0397 46.8751 37.3079 47.0847 37.4923C47.4367 37.794 47.9647 37.794 48.3167 37.4923C50.9987 35.1623 56.195 29.1865 59.4804 25.306C59.6648 25.6999 59.8743 26.1441 60.0922 26.6135C61.5422 29.7061 63.9727 34.8941 65.7328 37.8107C65.9674 38.1963 66.4368 38.3639 66.8559 38.213C67.2414 38.0789 67.4844 37.7185 67.4844 37.3246C67.4844 37.2827 67.4844 37.2324 67.4761 37.1905C66.3697 29.329 66.1854 23.0347 66.1854 19.7493C66.1854 18.7352 66.2021 18.0395 66.2189 17.6456C70.1245 14.1172 72.4461 11.1502 73.4937 8.35091C73.5357 8.24195 73.5524 8.133 73.5524 8.02404C73.5524 7.70556 73.3932 7.40383 73.1166 7.22783C72.7394 6.98478 72.2449 7.04344 71.9348 7.36193"
      fill="#00833E"
    />
    <path
      d="M57.5733 0.596922C56.2407 1.26742 55.2517 2.41564 54.7824 3.82368C53.8185 6.74032 55.4026 9.90003 58.3108 10.8722C59.7189 11.3416 61.2275 11.2326 62.5517 10.5705C63.8843 9.90003 64.8733 8.75181 65.3426 7.33539C66.3065 4.41874 64.7224 1.25904 61.8142 0.286819C60.4061 -0.182527 58.8975 -0.0735722 57.5649 0.596922"
      fill="#00833E"
    />
    <path
      d="M21.0787 32.593C19.7377 33.1965 16.4522 34.4956 12.7142 34.4956C5.69082 34.4956 0 30.1457 0 17.6326C0 9.15926 2.77417 1.51562 12.0689 1.51562C18.5894 1.51562 22.3358 5.3123 22.3358 10.7768H16.9719C16.9719 8.83239 16.4187 5.22011 11.9348 5.22011C5.92549 5.22011 5.09575 12.5369 5.09575 16.8951C5.09575 24.9075 7.04019 30.7911 13.6446 30.7911C17.5753 30.7911 20.2573 28.7964 21.087 28.2935V32.6014L21.0787 32.593Z"
      fill="#FFF"
    />
    <path
      d="M26.5859 2.02734H30.743V16.6609H30.8352C31.5727 14.8086 33.3328 11.431 37.8167 11.431C42.9041 11.431 43.7338 15.9736 43.7338 18.7059V33.9932H39.5684V21.9494C39.5684 17.8761 39.5684 15.6048 36.5679 15.6048C33.1484 15.6048 30.743 20.2313 30.743 24.6314V33.9848H26.5859V2.02734Z"
      fill="#FFF"
    />
    <path d="M80.2508 2.02734H76.0938V33.9848H80.2508V2.02734Z" fill="#FFF" />
    <path
      d="M98.8299 30.5954H98.7377C98.2768 31.8442 96.4748 34.4843 92.6362 34.4843C87.2723 34.4843 85.1016 29.6232 85.1016 23.3206C85.1016 16.0038 87.8757 11.4193 92.8709 11.4193C96.5251 11.4193 98.2349 13.6906 98.7461 14.9813H98.8383V2.01562H102.995V33.973H98.8383V30.5954H98.8299ZM94.0694 31.2408C97.447 31.2408 98.8299 26.5641 98.8299 22.9015C98.8299 19.0546 97.7236 14.6544 94.0191 14.6544C90.784 14.6544 89.7196 18.317 89.7196 22.9434C89.7196 26.2791 90.1806 31.2324 94.0694 31.2324"
      fill="#FFF"
    />
    <path
      d="M110.074 2.02734H127.172V5.73182H114.692V15.5043H126.526V19.2088H114.692V33.9764H110.074V2.02734Z"
      fill="#FFF"
    />
    <path
      d="M142.799 29.2497H142.707C142.012 31.1019 140.21 34.4879 135.726 34.4879C130.638 34.4879 129.809 29.9453 129.809 27.213V11.9258H133.966V23.9695C133.966 28.0931 133.966 30.3141 136.966 30.3141C140.386 30.3141 142.791 25.6793 142.791 21.2792V11.9258H146.948V33.9683H142.791V29.2413L142.799 29.2497Z"
      fill="#FFF"
    />
    <path
      d="M153.105 11.9208H157.263V16.6478H157.355C158.092 14.7956 159.852 11.418 164.336 11.418C169.424 11.418 170.253 15.9522 170.253 18.6928V33.9801H166.096V21.9363C166.096 17.8631 166.096 15.5918 163.087 15.5918C159.668 15.5918 157.263 20.2182 157.263 24.6183V33.9717H153.105V11.9292V11.9208Z"
      fill="#FFF"
    />
    <path
      d="M188.334 30.5954H188.242C187.781 31.8442 185.979 34.4843 182.14 34.4843C176.776 34.4843 174.605 29.6232 174.605 23.3206C174.605 16.0038 177.38 11.4193 182.375 11.4193C186.029 11.4193 187.739 13.6906 188.25 14.9813H188.342V2.01562H192.499V33.973H188.342V30.5954H188.334ZM183.573 31.2408C186.951 31.2408 188.334 26.5641 188.334 22.9015C188.334 19.0546 187.228 14.6544 183.523 14.6544C180.288 14.6544 179.223 18.317 179.223 22.9434C179.223 26.2791 179.684 31.2324 183.573 31.2324"
      fill="#FFF"
    />
    <path
      d="M138.499 46.4384L137.401 49.7741H135.984L139.588 39.1719H141.239L144.86 49.7741H143.393L142.262 46.4384H138.499ZM141.977 45.3656L140.938 42.3148C140.703 41.6192 140.544 40.9906 140.384 40.3788H140.351C140.192 41.0073 140.024 41.6527 139.815 42.298L138.775 45.3656H141.969H141.977Z"
      fill="#FFF"
    />
    <path
      d="M152.658 47.6878C152.658 48.4757 152.674 49.1629 152.716 49.7664H151.493L151.417 48.5259H151.384C151.023 49.1378 150.219 49.9424 148.869 49.9424C147.671 49.9424 146.246 49.2802 146.246 46.6067V42.1562H147.629V46.372C147.629 47.8219 148.073 48.7941 149.33 48.7941C150.261 48.7941 150.906 48.1488 151.157 47.537C151.233 47.3358 151.283 47.0844 151.283 46.8246V42.1562H152.666V47.6962L152.658 47.6878Z"
      fill="#FFF"
    />
    <path
      d="M154.875 48.3457C155.286 48.6139 156.007 48.8988 156.703 48.8988C157.708 48.8988 158.178 48.396 158.178 47.7674C158.178 47.1053 157.784 46.7449 156.761 46.3677C155.395 45.8816 154.75 45.1273 154.75 44.2138C154.75 42.9901 155.739 41.9844 157.373 41.9844C158.144 41.9844 158.823 42.2023 159.242 42.4537L158.898 43.4595C158.597 43.2667 158.052 43.0153 157.34 43.0153C156.518 43.0153 156.066 43.4846 156.066 44.0545C156.066 44.6831 156.518 44.9681 157.516 45.3452C158.84 45.8481 159.51 46.5102 159.51 47.6417C159.51 48.9827 158.471 49.9381 156.661 49.9381C155.823 49.9381 155.051 49.7202 154.523 49.4017L154.867 48.3457H154.875Z"
      fill="#FFF"
    />
    <path
      d="M163.074 40.3209V42.148H165.052V43.204H163.074V47.3108C163.074 48.2579 163.342 48.7859 164.113 48.7859C164.49 48.7859 164.708 48.7523 164.918 48.6937L164.977 49.7329C164.708 49.8419 164.281 49.9341 163.753 49.9341C163.108 49.9341 162.588 49.7162 162.261 49.339C161.867 48.9284 161.725 48.2579 161.725 47.3527V43.1956H160.543V42.1396H161.725V40.7399L163.074 40.3125V40.3209Z"
      fill="#FFF"
    />
    <path
      d="M166.719 44.5205C166.719 43.6237 166.703 42.8527 166.652 42.1487H167.859L167.91 43.6405H167.977C168.32 42.618 169.158 41.9727 170.08 41.9727C170.239 41.9727 170.348 41.9894 170.474 42.0229V43.3304C170.332 43.2969 170.189 43.2801 170.005 43.2801C169.033 43.2801 168.337 44.0177 168.153 45.0569C168.119 45.2497 168.086 45.4676 168.086 45.7023V49.7588H166.719V44.5205Z"
      fill="#FFF"
    />
    <path
      d="M177.248 47.9434C177.248 48.6055 177.281 49.2509 177.373 49.7705H176.116L176.007 48.815H175.957C175.529 49.4101 174.716 49.9465 173.627 49.9465C172.085 49.9465 171.297 48.8653 171.297 47.759C171.297 45.9152 172.931 44.9094 175.873 44.9262V44.7669C175.873 44.1383 175.697 43.0069 174.146 43.0069C173.434 43.0069 172.697 43.2248 172.169 43.5768L171.85 42.6633C172.479 42.2526 173.392 41.9844 174.356 41.9844C176.686 41.9844 177.248 43.5768 177.248 45.1022V47.9518V47.9434ZM175.915 45.8816C174.406 45.8481 172.688 46.1163 172.688 47.5998C172.688 48.4966 173.283 48.924 173.996 48.924C174.985 48.924 175.613 48.2954 175.839 47.6501C175.89 47.5076 175.915 47.3483 175.915 47.2059V45.8816Z"
      fill="#FFF"
    />
    <path d="M180.973 38.5977H179.59V49.7698H180.973V38.5977Z" fill="#FFF" />
    <path
      d="M184.956 40.0195C184.973 40.4889 184.629 40.866 184.076 40.866C183.59 40.866 183.246 40.4889 183.246 40.0195C183.246 39.5502 183.606 39.1562 184.109 39.1562C184.612 39.1562 184.956 39.5334 184.956 40.0195ZM183.414 42.1567H184.797V49.7668H183.414V42.1567Z"
      fill="#FFF"
    />
    <path
      d="M192.548 47.9434C192.548 48.6055 192.582 49.2509 192.674 49.7705H191.417L191.308 48.815H191.258C190.83 49.4101 190.017 49.9465 188.928 49.9465C187.385 49.9465 186.598 48.8653 186.598 47.759C186.598 45.9152 188.232 44.9094 191.174 44.9262V44.7669C191.174 44.1383 190.998 43.0069 189.447 43.0069C188.735 43.0069 187.997 43.2248 187.469 43.5768L187.151 42.6633C187.779 42.2526 188.693 41.9844 189.648 41.9844C191.978 41.9844 192.54 43.5768 192.54 45.1022V47.9518L192.548 47.9434ZM191.216 45.8816C189.707 45.8481 187.989 46.1163 187.989 47.5998C187.989 48.4966 188.584 48.924 189.296 48.924C190.285 48.924 190.914 48.2954 191.14 47.6501C191.191 47.5076 191.216 47.3483 191.216 47.2059V45.8816Z"
      fill="#FFF"
    />
  </svg>
</template>
