import { focusOptions } from "./globals.js";
import * as Input from "./input.js";

// VIMEO

export function isVimeoUrl(url) {
  return !!url.match(/(vimeo)/i);
}

export function getVimeoId(url) {
  return url.split(".com/")[1];
}

// YOUTUBE

export function isYoutubeUrl(url) {
  return !!url.match(/(youtube|youtu.be)/i);
}

export function getYoutubeId(url) {
  // oembed api doesnt return an id
  // so we have to do whatever this is
  if (url.match(/(\.com\/watch\?v=)/i)) return url.split(".com/watch?v=")[1];
  if (url.match(/(\.be\/)/i)) return url.split(".be/")[1];
  if (url.match(/(\.com\/embed\/)/i)) return url.split(".com/embed/")[1];
  if (url.match(/(\.com\/v\/)/i)) return url.split(".com/v/")[1];
}

// get offsetLeft relative to page (ignores transforms compared to getBoundingClientRect)
// https://stackoverflow.com/a/5598797

export function recursiveOffsetLeft(el) {
  let offsetLeft = 0;
  do {
    if (!isNaN(el.offsetLeft)) {
      offsetLeft += el.offsetLeft;
    }
  } while ((el = el.offsetParent));
  return offsetLeft;
}

export function recursiveOffsetTop(el) {
  let offsetTop = 0;
  do {
    if (!isNaN(el.offsetTop)) {
      offsetTop += el.offsetTop;
    }
  } while ((el = el.offsetParent));
  return offsetTop;
}

// https://gist.github.com/drwpow/17f34dc5043a31017f6bbc8485f0da3c

export function smoothScrollTo(element, yPos, duration = 600) {
  const startY = element.scrollTop;
  const difference = yPos - startY;
  const startTime = performance.now();

  const step = () => {
    const progress = (performance.now() - startTime) / duration;
    const amount = easeOutCubic(progress);
    element.scrollTo({ top: startY + amount * difference });
    if (progress < 0.99) {
      requestAnimationFrame(step);
    }
  };

  step();
}

export function easeInBack(x) {
  const c1 = 1.70158;
  const c3 = c1 + 1;
  return c3 * x * x * x - c1 * x * x;
}

export function easeInBackMore(x) {
  const c1 = 2;
  const c3 = c1 + 1;
  return c3 * x * x * x - c1 * x * x;
}

export function easeInCustom(x, back = 0.6) {
  const c3 = back + 1;
  return c3 * x * x * x - back * x * x;
}

export function easeOutElastic(x) {
  const c4 = (2 * Math.PI) / 3;

  return x === 0
    ? 0
    : x === 1
    ? 1
    : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}

export function easeOutCubic(x) {
  return 1 - Math.pow(1 - x, 3);
}

export function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function easeInOutSine(x) {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

// for single case operation in keyframer, avoids branching
export function easeLinear(x) {
  return x;
}

export function keyframer(alpha, frames) {
  let currFrame = 0;
  for (let i = 0; i < frames.length; i++) {
    const frame = frames[i];
    if (frame.time > alpha) break;
    currFrame = i;
  }
  if (currFrame === frames.length - 1) return frames[frames.length - 1].value;
  const ease = frames[currFrame].ease || easeLinear;
  // from current to next
  frames[currFrame].value;
  return lerp(
    frames[currFrame].value,
    frames[currFrame + 1].value,
    ease(
      map(alpha, frames[currFrame].time, frames[currFrame + 1].time, 0, 1, true)
    )
  );
}

// P5JS MATH

export function lerp(start, stop, amt) {
  return amt * (stop - start) + start;
}

export function constrain(n, low, high) {
  return Math.max(Math.min(n, high), low);
}

export function map(n, start1, stop1, start2, stop2, withinBounds) {
  const newval = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
  if (start2 < stop2) {
    return constrain(newval, start2, stop2);
  } else {
    return constrain(newval, stop2, start2);
  }
}

export function dot(x1, y1, x2, y2) {
  return x1 * x2 + y1 * y2;
}

// VECTOR MATH

export function vec3Mag(vec) {
  return Math.sqrt(vec.x ** 2 + vec.y ** 2 + vec.z ** 2);
}

export function vec3MultScalar(vec, fac) {
  return {
    x: vec.x * fac,
    y: vec.y * fac,
    z: vec.z * fac,
  };
}

export function vec3Normalize(vec) {
  const mag = vec3Mag(vec);
  if (!mag) return vec; // no length, dont change
  return vec3MultScalar(vec, 1 / mag);
}

export function vec3Lerp(vec1, vec2, amt) {
  return {
    x: lerp(vec1.x, vec2.x, amt),
    y: lerp(vec1.y, vec2.y, amt),
    z: lerp(vec1.z, vec2.z, amt),
  };
}

export function vec3Rotate(vec, a) {
  let mag = vec3Mag(vec) || 1;
  let newHeading = Math.atan2(vec.y, vec.x) + a;
  return {
    x: Math.cos(newHeading) * mag,
    y: Math.sin(newHeading) * mag,
    z: vec.z,
  };
}

// ARRAY SHUFFLE

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; --i) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export function removeItemFromArray(item, array) {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
}

// DOM HELPERS

export function focusElement(el) {
  Input.ignoreFocus();
  el.focus(focusOptions);
}

// CHAPTER DATA HELPERS

export function createChapterProgress(chapter) {
  const activities = [];

  // hotspot activities can have multiple activities
  if (chapter.activity_type === "HotspotActivity") {
    const count = chapter.hotspot_activity.questions.length;
    for (let i = 0; i < count; ++i) {
      activities[i] = false;
    }
  }

  // multichoice and swipe activities only have the one series
  else {
    activities[0] = false;
  }

  return {
    timestamp: chapter.post_modified_time_gmt,
    story: false,
    activities,
    path: 0,
    score: 0,
  };
}

export function createProgress(chapters) {
  const progress = {};
  for (const group of chapters) {
    for (const chapter of group) {
      progress[chapter.post_id] = createChapterProgress(chapter);
    }
  }
  return progress;
}

export function chapterActivitiesCompleted(chapterProgress) {
  for (const activity of chapterProgress.activities) {
    if (!activity) {
      return false;
    }
  }

  return true;
}

export function chapterIsComplete(chapterProgress) {
  return chapterProgress.story && chapterActivitiesCompleted(chapterProgress);
}

export function allChaptersComplete(progress) {
  for (const key in progress) {
    if (!chapterIsComplete(progress[key])) {
      return false;
    }
  }

  return true;
}

export function testCompletionCount(progress, count) {
  let completed = 0;

  for (const key in progress) {
    if (chapterIsComplete(progress[key]) && ++completed === count) {
      return true;
    }
  }

  return false;
}

export function getUserLevel(chapters, progress) {
  // other users must complete all previous groups
  let level = 0;
  let completed;

  for (let i = chapters.length - 1; i >= 0; --i) {
    const group = chapters[i];

    // count how many chapters are completed in this group
    completed = 0;
    for (const chapter of group) {
      if (chapterIsComplete(progress[chapter.post_id])) {
        ++completed;
      }
    }

    // if all chapters are completed, users are at the next level
    if (completed === group.length) {
      level = i + 1;
      break;
    }

    // if some but not all are completed, this is the user's level
    else if (completed !== 0) {
      level = i;
      break;
    }
  }

  if (level < chapters.length) {
    return level + 1;
  }

  return chapters.length;
}

export function firstIncompleteChapter(chapters, progress) {
  let index = 0;

  for (const group of chapters) {
    for (const chapter of group) {
      if (chapterIsComplete(progress[chapter.post_id])) {
        ++index;
      } else {
        return index;
      }
    }
  }
}

// STRING UTILS

export function capitalise(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// OBJECT UTILS

export function sameObject(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

// data comparison

export function sameValue(a, b) {
  const aType = typeof a;

  if (aType === "object") {
    return sameObject(a, b);
  } else {
    return a === b;
  }
}
