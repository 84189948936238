import { lerp } from "@/scripts/helpers.js";

// similar to internal shape propLerp
// call _updatePropLerp in update()
// use _setPropLerp to initialise lerp object

export const propLerpFunctions = {
  propLerp: {},
  _setPropLerp(prop, value, time = 300) {
    let propIsObject;
    if (typeof this[prop] === "number") {
      propIsObject = false;
    } else if (typeof this[prop] === "object") {
      propIsObject = true;
    } else {
      console.warn("_mixins.js _setPropLerp: prop is not a number or object");
      return;
    }
    this.propLerp[prop] = {
      prop,
      propIsObject,
      time,
      startTime: performance.now(),
      start: !propIsObject
        ? this[prop]
        : JSON.parse(JSON.stringify(this[prop])),
      end: value,
      lerp: 0,
    };
  },
  _updatePropLerp() {
    for (const key in this.propLerp) {
      if (!Object.hasOwnProperty.call(this.propLerp, key)) continue;
      const prop = this.propLerp[key];

      prop.lerp = Math.min((performance.now() - prop.startTime) / prop.time, 1);
      // apply
      if (!prop.propIsObject) {
        this[prop.prop] = lerp(prop.start, prop.end, prop.lerp);
      } else {
        for (const propkey in this[prop.prop]) {
          if (!Object.hasOwnProperty.call(this[prop.prop], propkey)) continue;
          this[prop.prop][propkey] = lerp(
            prop.start[propkey],
            prop.end[propkey],
            prop.lerp
          );
        }
      }

      if (prop.lerp >= 1) {
        if (!prop.propIsObject) {
          this[prop.prop] = prop.end;
        } else {
          for (const propkey in this[prop.prop]) {
            if (!Object.hasOwnProperty.call(this[prop.prop], propkey)) continue;
            this[prop.prop][propkey] = prop.end[propkey];
          }
        }
        delete this.propLerp[key];
      }
    }
  },
};
