<template>
  <figure class="browser">
    <div class="browser__chrome">
      <svg class="browser__back" viewBox="0 0 21 19" aria-hidden="true">
        <path
          d="M8.32727 1.70233C8.47598 1.5186 8.69273 1.40783 8.92456 1.39712C9.1564 1.38624 9.38208 1.47644 9.54621 1.64561C9.71019 1.81478 9.79786 2.0472 9.7873 2.28615C9.77675 2.52526 9.66928 2.74865 9.49101 2.90175L3.5534 9.02825H19.2699C19.5552 9.04398 19.8125 9.20981 19.9508 9.46733C20.0893 9.72468 20.0893 10.0374 19.9508 10.2949C19.8125 10.5525 19.5552 10.7183 19.2699 10.7338H3.5534L9.50016 16.8535C9.64985 17.0151 9.7333 17.2298 9.7333 17.4532C9.7333 17.6766 9.64985 17.8914 9.50016 18.0529C9.34592 18.2124 9.13665 18.3019 8.91828 18.3019C8.69992 18.3019 8.49048 18.2124 8.33641 18.0529L0.98152 10.4725C0.823387 10.3156 0.733932 10.0992 0.733932 9.87299C0.733932 9.64659 0.823387 9.43006 0.98152 9.27327L8.32727 1.70233Z"
          fill="#000"
          stroke="#000"
        />
      </svg>
      <div class="browser__url">{{ url }}</div>
      <div class="browser__tabs" aria-hidden="true">{{ tabs }}</div>
    </div>
    <slot></slot>
  </figure>
</template>

<script>
import PersistentData from "@/scripts/persistentData.js";

function getTabCount() {
  let tabs = PersistentData.tabs;

  if (tabs < 100) {
    tabs += 2 + Math.floor(Math.random() * 13);
  } else {
    tabs = 7 + Math.floor(Math.random() * 5);
  }

  PersistentData.setTabs(tabs); // async

  if (tabs < 100) {
    return tabs;
  } else {
    return ":)";
  }
}

export default {
  props: {
    url: {
      type: String,
      default: "example.com",
    },
  },
  data() {
    return {
      tabs: getTabCount(),
    };
  },
};
</script>

<style lang="scss">
.browser {
  min-height: 17rem;
  margin: 0;
  border-radius: $content-radius;
  box-shadow: $fragment-shadow;
  background: #fff;
  color: #000;
}

.browser__chrome {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.5 * $content-padding;
  background: #ddd;
  border-radius: $content-radius $content-radius 0 0;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1.5rem;
    background: linear-gradient(#000, transparent);
    opacity: 0.05;
  }
}

.browser__back {
  flex: 0 0 auto;
  display: block;
  height: 1rem;
}

.browser__url {
  flex: 1 1 auto;
  overflow: hidden;
  overflow: clip;
  width: 100%;
  margin: 0 1rem 0 1.25rem;
  padding: 0.75rem 1.25rem;
  border-radius: 1.25rem;
  background: #fff;
  line-height: 1rem;
  font-size: 0.875rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

$tab-border-width: 0.125rem;
.browser__tabs {
  flex: 0 0 auto;
  border-radius: 0.5rem;
  border: solid $tab-border-width #000;
  width: 1.75rem - 2 * $tab-border-width;
  line-height: 1.75rem - 2 * $tab-border-width;
  text-align: center;
  font-size: 0.75rem;
  font-weight: $semi-bold;
}
</style>
