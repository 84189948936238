<template>
  <div class="shapes" :class="{ 'shapes--show': show }" aria-hidden="true">
    <div ref="shape-0" class="shape">
      <div class="shape__inner">
        <svg viewBox="0 0 100 100" class="shape__back">
          <polygon
            fill="#FFF"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="#FFF"
            points="88.04,62.36 37.64,88.04 11.96,37.64 62.36,11.96"
          />
        </svg>
        <svg viewBox="0 0 100 100" class="shape__front">
          <polygon
            fill="#FFF"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="#FFF"
            points="88.04,62.36 37.64,88.04 11.96,37.64 62.36,11.96"
          />
        </svg>
      </div>
    </div>

    <div ref="shape-1" class="shape">
      <div class="shape__inner">
        <svg viewBox="0 0 100 100" class="shape__back">
          <polygon
            fill="#FFF"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="#FFF"
            points="88.99,62.67 19.53,77.43 41.48,9.90"
          />
        </svg>
        <svg viewBox="0 0 100 100" class="shape__front">
          <polygon
            fill="#FFF"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="#FFF"
            points="88.99,62.67 19.53,77.43 41.48,9.90"
          />
        </svg>
      </div>
    </div>

    <div ref="shape-2" class="shape">
      <div class="shape__inner">
        <svg viewBox="0 0 100 100" class="shape__back">
          <polygon
            fill="#FFF"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="#FFF"
            points="87.09,62.05 50.00,89.00 12.91,62.05 27.08,18.45 72.92,18.45"
          />
        </svg>
        <svg viewBox="0 0 100 100" class="shape__front">
          <polygon
            fill="#FFF"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="#FFF"
            points="87.09,62.05 50.00,89.00 12.91,62.05 27.08,18.45 72.92,18.45"
          />
        </svg>
      </div>
    </div>

    <div ref="shape-3" class="shape">
      <div class="shape__inner">
        <svg viewBox="0 0 100 100" class="shape__back">
          <circle fill="#FFF" cx="50" cy="50" r="42" />
        </svg>
        <svg viewBox="0 0 100 100" class="shape__front">
          <circle fill="#FFF" cx="50" cy="50" r="42" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as Shapes from "@/scripts/shapes.js";

export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    update() {
      this.raf = requestAnimationFrame(this.update);
      if (this.paused) return;
      Shapes.update();
    },
    resize() {
      Shapes.onResize();
    },
    setState(type) {
      Shapes.setState(type);
    },
    setTarget(element) {
      Shapes.setTarget(element);
    },
    in() {
      Shapes.shapeIn();
      this.show = true;
    },
    out() {
      Shapes.shapeOut();
      this.show = false;
    },
    next(options) {
      Shapes.shapeNext(options);
    },
    visibilityChange() {
      Shapes.onVisibilityChange(document.hidden);
    },
  },
  mounted() {
    Shapes.initShape(this.$refs["shape-0"]);
    Shapes.initShape(this.$refs["shape-1"]);
    Shapes.initShape(this.$refs["shape-2"]);
    Shapes.initShape(this.$refs["shape-3"]);

    this.resize();
    window.addEventListener("resize", this.resize);
    document.addEventListener("visibilitychange", this.visibilityChange);

    this.raf = requestAnimationFrame(this.update);
  },
  beforeUnmount() {
    Shapes.killShapes();

    cancelAnimationFrame(this.raf);
    window.removeEventListener("resize", this.resize);
    document.removeEventListener("visibilitychange", this.visibilityChange);
  },
};
</script>

<style lang="scss">
$shape-width: 1.5rem;

.shapes {
  position: absolute;
  z-index: $z-sticky;
  width: 100%;
  height: 100%;
  pointer-events: none;

  opacity: 0;
  transition: opacity 1s ease-in;

  &--show {
    opacity: 1;
  }
}

.shape {
  position: absolute;
  top: -0.5 * $shape-width;
  left: -0.5 * $shape-width;

  will-change: transform, opacity;

  &__inner {
    will-change: filter;
    transform: translate3d(0, 0, 0);
  }

  &__back {
    display: block;
    width: $shape-width;
    height: $shape-width;
    filter: blur(5px);
  }

  &__front {
    display: block;
    width: $shape-width;
    height: $shape-width;
    margin-top: -$shape-width;
  }
}
</style>
