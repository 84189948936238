import {
  lerp,
  map,
  vec3Mag,
  vec3Normalize,
  vec3Lerp,
  vec3MultScalar,
  vec3Rotate,
} from "@/scripts/helpers.js";

import { globals } from "@/scripts/shapeStates/_globals.js";
import { windowWidth, windowHeight, rem } from "@/scripts/windowSize.js";

import circle from "./shapeStates/circle.js";
import bounce from "./shapeStates/bounce.js";
import pagecircle from "./shapeStates/pagecircle.js";
import heart from "./shapeStates/heart.js";
import time from "./shapeStates/time.js";
import location from "./shapeStates/location.js";
import friends from "./shapeStates/friends.js";
import loss from "./shapeStates/loss.js";
import separate from "./shapeStates/separate.js";
import trust from "./shapeStates/trust.js";
import connect from "./shapeStates/connect.js";
import updown from "./shapeStates/updown.js";

const SHAPE_OUT_TIME = 1000; // based on transition in Shapes.vue

const DEBUG_FLAG = false;
// const DEBUG_FLAG = true

if (DEBUG_FLAG && typeof window !== "undefined") {
  window.shapeDebug = () => {
    console.log(globals);
  };
}

// individual shapes are autonomous with logic following their target point
// states should as much as possible only manage their properties and targets

export const states = {
  bounce,
  circle,
  pagecircle,
  heart,
  time,
  location,
  friends,
  loss,
  separate,
  trust,
  connect,
  updown,
};

let state = states[globals.stateKey];
let shapeId = 0;
let paused = false;

export class Shape {
  constructor(element) {
    this.id = shapeId;

    // properties
    this.pos = { x: 0, y: 0, z: 0 };
    this.rot = 0;
    this.scale = 1;
    this.opacity = 1;

    this.velocity = { x: 0, y: 0, z: 0 }; // READ ONLY
    this.velocityExternal = { x: 0, y: 0, z: 0 };
    this.velocityInternal = { x: 0, y: 0, z: 0, hyp: 0 };
    this.vMax = globals.rem;
    this.selfVMax = globals.rem * 0.5;
    this.velSinLerp = 0; // how much to do start / stop pattern
    this.velSinFactor = 0.25;
    this.rotRate = 0.2;

    this.accelStep = 0.1;
    this.decelDist = globals.rem * 10;
    this.externalVelocityFactor = 0.9;

    this.accelerationExternal = {
      x: 0,
      y: 0,
      z: 0,
      prevX: 0,
      prevY: 0,
      prevZ: 0,
    };
    this.accelExternalDamp = 0.9;

    this.turnVector = { x: 0, y: 0, z: 0, angle: 0 };
    this.turnAngle2D = 0;
    this.turnFactor = 0.05;

    // targetting
    this.targetPoint = { x: 200, y: 200, z: 0 };
    this.targetDist = { x: 0, y: 0, z: 0, hyp: 0 };
    this.targetVector = { x: 0, y: 0, z: 0 };
    this.targetAngle = 0;
    this.targetPerpVector = { x: 0, y: 0, z: 0 };
    this.orbitDir = true;

    this.orbitRadius = 0;

    // dom
    this.el = element;

    // list of actively lerping props
    this.propLerp = {};

    if (DEBUG_FLAG) this.initDebug();
  }

  update() {
    this.updatePropLerp();
    this.calcTargetValues();

    // 0 - vector to target, 1 - vector to orbit
    const orbitLerp =
      map(
        this.targetDist.hyp,
        this.orbitRadius,
        this.orbitRadius * 3,
        1,
        0,
        true
      ) ** 4;

    // apply orbit
    const orbitTargetVec = vec3Lerp(
      this.targetVector,
      this.targetPerpVector,
      orbitLerp
    );

    // lerp raw vectors for easing
    this.turnVector.x = lerp(
      this.turnVector.x,
      orbitTargetVec.x,
      this.turnFactor
    );
    this.turnVector.y = lerp(
      this.turnVector.y,
      orbitTargetVec.y,
      this.turnFactor
    );
    this.turnVector.z = lerp(
      this.turnVector.z,
      orbitTargetVec.z,
      this.turnFactor
    );

    this.turnAngle2D = Math.atan2(this.turnVector.y, this.turnVector.x);

    if (this.targetDist.hyp > this.decelDist) {
      this.velocityInternal.hyp = Math.min(
        this.velocityInternal.hyp + this.accelStep,
        this.selfVMax
      );
    } else {
      this.velocityInternal.hyp = Math.min(
        this.velocityInternal.hyp + this.accelStep,
        map(this.targetDist.hyp, this.decelDist, 0, this.selfVMax, 0.01, true)
      );
    }

    // steering -> velocity
    this.velocityInternal.x = this.turnVector.x * this.velocityInternal.hyp;
    this.velocityInternal.y = this.turnVector.y * this.velocityInternal.hyp;
    this.velocityInternal.z = this.turnVector.z * this.velocityInternal.hyp;

    this.velocityExternal.x +=
      this.accelerationExternal.x * globals.clock.deltaTime;
    this.velocityExternal.y +=
      this.accelerationExternal.y * globals.clock.deltaTime;
    this.velocityExternal.z +=
      this.accelerationExternal.z * globals.clock.deltaTime;

    // clamp velocity
    this.velocity.x = Math.min(
      this.velocityInternal.x + this.velocityExternal.x,
      this.vMax
    );
    this.velocity.y = Math.min(
      this.velocityInternal.y + this.velocityExternal.y,
      this.vMax
    );
    this.velocity.z = Math.min(
      this.velocityInternal.z + this.velocityExternal.z,
      this.vMax
    );

    this.velocityExternal = vec3MultScalar(
      this.velocityExternal,
      this.externalVelocityFactor
    );

    const velPhase = lerp(
      1,
      lerp(
        1,
        Math.abs(Math.sin(performance.now() / 600)) * (1 - this.velSinFactor) +
          this.velSinFactor,
        orbitLerp
      ),
      this.velSinLerp
    );

    // actual position
    this.pos.x += this.velocity.x * velPhase * globals.clock.deltaTime;
    this.pos.y += this.velocity.y * velPhase * globals.clock.deltaTime;
    this.pos.z += this.velocity.z * velPhase * globals.clock.deltaTime;

    // passive rotation
    this.rot = (this.rot + this.rotRate * globals.clock.deltaTime) % 360;

    this.render();
    if (DEBUG_FLAG) {
      this.debug(`${this.id}`);
      this.debug2();
    }

    // RESET
    this.resetExternalAccel();
  }

  render() {
    this.el.style.transform = `translate3d(${this.pos.x}px, ${
      this.pos.y
    }px, 0) scale(${this.scale + this.pos.z * 0.002}) rotate(${this.rot}deg)`;
    this.el.style.opacity = this.opacity;

    // apply blur to the child of the transform as it changes much less frequently
    this.el.firstElementChild.style.filter =
      this.pos.z < 0 ? `blur(${-0.02 * this.pos.z})` : "none";
  }

  resetExternalAccel() {
    this.accelerationExternal.prevX = this.accelerationExternal.x;
    this.accelerationExternal.prevY = this.accelerationExternal.y;
    this.accelerationExternal.prevZ = this.accelerationExternal.z;
    this.accelerationExternal.x = 0;
    this.accelerationExternal.y = 0;
    this.accelerationExternal.z = 0;
  }

  // add to acceleration for a frame
  addForce(x, y, z = 0) {
    this.accelerationExternal.x += lerp(
      this.accelerationExternal.prevX,
      (x * globals.vMin) / 300,
      this.accelExternalDamp / globals.clock.deltaTime
    );
    this.accelerationExternal.y += lerp(
      this.accelerationExternal.prevY,
      (y * globals.vMin) / 300,
      this.accelExternalDamp / globals.clock.deltaTime
    );
    this.accelerationExternal.z += lerp(
      this.accelerationExternal.prevZ,
      (z * globals.vMin) / 300,
      this.accelExternalDamp / globals.clock.deltaTime
    );

    this.accelerationExternal.prevX = this.accelerationExternal.x;
    this.accelerationExternal.prevY = this.accelerationExternal.y;
    this.accelerationExternal.prevZ = this.accelerationExternal.z;
  }

  addForceJitter(x, y, z) {
    this.addForce(
      (Math.random() - 0.5) * x,
      (Math.random() - 0.5) * y,
      (Math.random() - 0.5) * z
    );
  }

  addForceRand(force) {
    let vec = {
      x: (Math.random() - 0.5) * 2,
      y: (Math.random() - 0.5) * 2,
      z: (Math.random() - 0.5) * 2,
    };
    vec = vec3Normalize(vec);
    this.addForce(vec.x * force, vec.y * force, vec.z * force);
  }

  // slower but removes proplerp entry
  setProp(prop, value) {
    let propIsObject;
    if (typeof this[prop] === "number") {
      propIsObject = false;
    } else if (typeof this[prop] === "object") {
      propIsObject = true;
    } else {
      console.warn("Shape setProp: prop is not a number or object");
      return;
    }
    if (this.propLerp[prop]) delete this.propLerp[prop];
    if (!propIsObject) {
      this[prop] = value;
    } else {
      for (const key in this[prop]) {
        if (!Object.hasOwnProperty.call(this[prop], key)) continue;
        this[prop][key] = value[key];
      }
    }
  }

  // lerp primitive number values, set once
  setPropLerp(prop, value, time = 300) {
    let propIsObject;
    if (typeof this[prop] === "number") {
      propIsObject = false;
    } else if (typeof this[prop] === "object") {
      propIsObject = true;
    } else {
      console.warn("Shape setPropLerp: prop is not a number or object");
      return;
    }
    this.propLerp[prop] = {
      prop,
      propIsObject,
      time,
      startTime: performance.now(),
      start: !propIsObject
        ? this[prop]
        : JSON.parse(JSON.stringify(this[prop])),
      end: value,
      lerp: 0,
    };
  }

  updatePropLerp() {
    for (const key in this.propLerp) {
      if (!Object.hasOwnProperty.call(this.propLerp, key)) continue;
      const prop = this.propLerp[key];

      prop.lerp = Math.min((performance.now() - prop.startTime) / prop.time, 1);
      // apply
      if (!prop.propIsObject) {
        this[prop.prop] = lerp(prop.start, prop.end, prop.lerp);
      } else {
        for (const propkey in this[prop.prop]) {
          if (!Object.hasOwnProperty.call(this[prop.prop], propkey)) continue;
          this[prop.prop][propkey] = lerp(
            prop.start[propkey],
            prop.end[propkey],
            prop.lerp
          );
        }
      }

      if (prop.lerp >= 1) {
        if (!prop.propIsObject) {
          this[prop.prop] = prop.end;
        } else {
          for (const propkey in this[prop.prop]) {
            if (!Object.hasOwnProperty.call(this[prop.prop], propkey)) continue;
            this[prop.prop][propkey] = prop.end[propkey];
          }
        }
        delete this.propLerp[key];
      }
    }
  }

  resetProps() {
    // cancel lerps
    this.propLerp = {};

    this.setPropLerp("scale", 1);
    this.setPropLerp("opacity", 1);
    this.setPropLerp("vMax", globals.rem);
    this.setPropLerp("selfVMax", globals.rem * 0.5);
    this.setPropLerp("velSinLerp", 0);
    this.setPropLerp("velSinFactor", 0.25);
    this.setPropLerp("rotRate", 0.2);

    this.accelStep = globals.rem / 160;
    this.decelDist = globals.rem * 10;
    this.setProp("externalVelocityFactor", 0.9);
    this.accelExternalDamp = 0.9;
    this.turnFactor = 0.05;

    this.orbitRadius = 0;
  }

  calcTargetValues() {
    this.targetDist.x = this.targetPoint.x - this.pos.x;
    this.targetDist.y = this.targetPoint.y - this.pos.y;
    this.targetDist.z = this.targetPoint.z - this.pos.z;
    this.targetDist.hyp = vec3Mag(this.targetDist);

    this.targetVector = vec3Normalize(this.targetDist);
    this.targetAngle =
      (Math.atan2(this.targetVector.y, this.targetVector.x) + Math.PI * 3.5) %
      (Math.PI * 2);
    this.targetPerpVector = vec3Rotate(
      this.targetVector,
      this.orbitDir ? Math.PI / 2 : -Math.PI / 2
    );
  }

  setTarget(x, y, z = 0) {
    this.targetPoint.x = x;
    this.targetPoint.y = y;
    this.targetPoint.z = z;

    this.calcTargetValues();
  }

  kill() {
    if (DEBUG_FLAG) this.killDebug();
  }

  initDebug() {
    const debug1Cont = document.createElement("div");
    debug1Cont.innerHTML =
      '<div style="width: 0; height: 0; font-size: 2rem; text-align: center; white-space: nowrap; transform: rotate(calc(var(--rot) * -1)) translate(1rem, 1rem);"></div>';
    this.debugEl = debug1Cont.firstChild;
    this.el.appendChild(this.debugEl);

    const debug2Cont = document.createElement("div");
    debug2Cont.innerHTML =
      '<div style="width: 1rem; height: 1rem; background: red; position: absolute; transform: translate(-50%, -50%);"></div>';
    this.debugEl2 = debug2Cont.firstChild;
    document.body.appendChild(this.debugEl2);
  }

  killDebug() {
    this.debugEl.remove();
    this.debugEl2.remove();
  }

  debug(str) {
    this.debugEl.textContent = str;
  }

  debug2() {
    this.debugEl2.style.left = `${this.targetPoint.x}px`;
    this.debugEl2.style.top = `${this.targetPoint.y}px`;
  }
}

export function onResize() {
  globals.vW = windowWidth;
  globals.vH = windowHeight;
  globals.vMin = Math.min(globals.vW, globals.vH);
  globals.vMax = Math.max(globals.vW, globals.vH);
  globals.rem = rem;

  state.onResize();
}

export function onVisibilityChange(hidden) {
  if (hidden === true) {
    paused = true;
    globals.clock.pause();
  } else {
    paused = false;
    globals.clock.resume();
  }
  state.onVisibilityChange(hidden);
}

export function setTarget(element) {
  if (globals.prevTargetElement !== element) {
    globals.prevTargetElement = globals.targetElement;
  }
  globals.targetElement = element;
  state.setTarget();
}

export function setState(newState) {
  globals.stateKey = newState;
  state = states[globals.stateKey];
  state.init();
}

export function initShape(el) {
  globals.shapes.push(new Shape(el));
  shapeId++;
}

export function shapeIn() {
  clearTimeout(globals.outTimeout);
  globals.isLeaving = false;
  globals.isOut = false;
  state.in();
}

export function shapeOut() {
  clearTimeout(globals.outTimeout);
  globals.isLeaving = true;
  globals.outTimeout = setTimeout(shapeOut2, SHAPE_OUT_TIME);
  state.out();
}

function shapeOut2() {
  globals.isLeaving = false;
  globals.isOut = true;
}

export function shapeNext(options) {
  state.next(options);
}

export function update() {
  if (paused) return;
  state.update();
  globals.clock.update();
}

export function killShapes() {
  clearTimeout(globals.outTimeout);

  state.kill();
  for (const shape of globals.shapes) {
    shape.kill();
  }

  globals.reset();
}
