// monitor user inputs to decide which form they are using (touch, mouse, keyboard)

import { passiveEvent } from "@/scripts/globals.js";
import { Batch } from "@/scripts/batch.js";

const callbacks = new Batch();

export function addCallback(fn) {
  callbacks.add(fn);
}

export function removeCallback(fn) {
  callbacks.remove(fn);
}

export const TOUCH = 0;
export const MOUSE = 1;
export const KEYBOARD = 2;

export let mode = TOUCH;

let notTouching = true;

// TOUCH
// if there are any touches active on the device

function setTouch() {
  notTouching = false;
  if (mode !== TOUCH) {
    mode = TOUCH;
    callbacks.run();
  }
}

let touchTimeoutID;
function touchTimeout() {
  notTouching = true;
}

function onTouchStart() {
  setTouch();
  ignoreFocus();
}

function onTouchEnd(e) {
  console.log("touchend, ignore focus");

  setTouch();

  if (e.touches.length === 0) {
    clearTimeout(touchTimeoutID);
    touchTimeoutID = setTimeout(touchTimeout, 50);
  }

  ignoreFocus();
}

// MOUSE
// if mouse is pressed (without touching)

function onMouseDown() {
  if (notTouching) {
    ignoreFocus();

    if (mode !== MOUSE) {
      mode = MOUSE;
      callbacks.run();
    }
  }
}

// KEYBOARD
// if focus changes without first using the mouse
// and viewport is already focused

let windowFocused = false;
let watchFocus = true;
let focusTimeout;

function endFocusFrame() {
  watchFocus = true;
}

export function ignoreFocus() {
  watchFocus = false;

  clearTimeout(focusTimeout);
  focusTimeout = setTimeout(endFocusFrame, 50);
}

export function keyboardMode() {
  if (mode !== KEYBOARD) {
    mode = KEYBOARD;
    callbacks.run();
  }
}

function onFocus() {
  if (windowFocused && watchFocus) {
    keyboardMode();
  }

  windowFocused = true;

  // ignore subsequent focus events on the same frame
  ignoreFocus();
}

function onBlur() {
  windowFocused = false;
}

// EVENTS

export function start() {
  window.addEventListener("touchstart", onTouchStart, passiveEvent);
  window.addEventListener("touchend", onTouchEnd, passiveEvent);
  window.addEventListener("touchcancel", onTouchEnd, passiveEvent);

  window.addEventListener("mousedown", onMouseDown);

  window.addEventListener("focus", onFocus, true);
  window.addEventListener("blur", onBlur);
}
