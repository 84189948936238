<template>
  <Image
    v-if="hasAttachment"
    :src="src"
    :width="width"
    :height="height"
    :alt="altText"
    :srcset="srcsetFinal"
    :sizes="sizesFinal"
    @load="onLoad"
  />
  <span v-else></span>
</template>

<script>
import Image from "./Image.vue";
import { data } from "@/scripts/data.js";
import OfflineMedia from "@/scripts/offlineMedia.js";
import * as Connection from "@/scripts/connection.js";

const allSizes = [
  // {
  //   name: "thumbnail",
  //   width: "thumbnail-width",
  //   height: "thumbnail-height",
  // },
  {
    name: "medium",
    width: "medium-width",
    height: "medium-height",
  },
  {
    name: "medium_large",
    width: "medium_large-width",
    height: "medium_large-height",
  },
  {
    name: "large",
    width: "large-width",
    height: "large-height",
  },
  {
    name: "1536x1536",
    width: "1536x1536-width",
    height: "1536x1536-height",
  },
  {
    name: "2048x2048",
    width: "2048x2048-width",
    height: "2048x2048-height",
  },
];

export default {
  emits: ["load"],
  components: {
    Image,
  },
  props: {
    image: {
      type: Number,
      required: true,
    },
    alt: {
      type: String,
      default: null,
    },
    sizes: {
      type: String,
      default: null,
    },
  },
  data() {
    const attachment = data.attachments[this.image];

    // do nothing if attachment data isn't set (deleted from library probably)
    if (!attachment) {
      return {
        hasAttachment: false,
      };
    }

    // use attachment data to derrive ideal image attributes
    const { width, height, alt, url, sizes } = attachment;

    const seen = [];
    let hasSizes = false;
    let w;

    // add original file
    let serverSrcset = `${url} ${width}w`;
    seen[width] = true;

    // add all unique resizes
    for (const size of allSizes) {
      const file = sizes[size.name];
      if (file) {
        w = sizes[size.width];
        if (!seen[w]) {
          serverSrcset += `, ${file} ${w}w`;
          seen[w] = true;
          hasSizes = true;
        }
      }
    }

    return {
      hasAttachment: true,
      hasSizes,

      // image attributes
      src: null, // set when local or server loads
      width,
      height,
      altText: this.alt || alt?.trim() || "",
      srcsetFinal: null, // set if server image loads
      sizesFinal: null, // set if server image loads

      // server image attributes
      url,
      serverSuccess: false,
      serverSrcset,
      serverSizes: hasSizes
        ? this.sizes || "(min-width: 720px) 612px, 85vw"
        : null,
    };
  },
  methods: {
    onLoad() {
      this.$emit("load");
    },

    // LOCAL
    // blob of "small" image size, stored in localforage
    tryStorage() {
      OfflineMedia.getItem(this.image.toString()).then(
        this.storageLoaded,
        this.storageError
      );
    },
    storageLoaded(cachedImage) {
      if (cachedImage && !this.serverSuccess) {
        this.src = URL.createObjectURL(cachedImage);
      }
    },
    storageError(error) {
      console.error("Error loading image from cache:", error);
    },

    // SERVER
    // much higher quality than local version
    // check even if offline, as it might be cached
    tryServer() {
      if (this.serverImg) return;

      this.serverImg = document.createElement("img");
      this.serverImg.addEventListener("load", this.serverLoaded);
      this.serverImg.addEventListener("error", this.serverError);

      if (this.hasSizes) {
        this.serverImg.sizes = this.serverSizes;
        this.serverImg.srcset = this.serverSrcset;
      }
      this.serverImg.src = this.url;
    },
    serverLoaded() {
      this.serverSuccess = true;

      if (this.hasSizes) {
        this.sizesFinal = this.serverSizes;
        this.srcsetFinal = this.serverSrcset;
      }
      this.src = this.url;

      this.destroyOffscreenImg();
    },
    serverError() {
      // try again
      if (Connection.isOnline) {
        this.raf = requestAnimationFrame(this.tryServer);
      } else {
        Connection.addOnlineEvent(this.onOnline);
      }

      this.destroyOffscreenImg();
    },
    destroyOffscreenImg() {
      if (this.serverImg) {
        this.serverImg.removeEventListener("load", this.serverLoaded);
        this.serverImg.removeEventListener("error", this.serverError);
        this.serverImg = null;
      }
    },

    onOnline() {
      this.tryServer();
      Connection.removeOnlineEvent(this.onOnline);
    },
  },
  created() {
    if (this.hasAttachment) {
      this.tryServer();
      if (OfflineMedia.available) {
        this.tryStorage();
      }
    }
  },
  beforeUnmount() {
    this.destroyOffscreenImg();
    cancelAnimationFrame(this.raf);
    Connection.removeOnlineEvent(this.onOnline);
  },
};
</script>
