<template>
  <figure class="fragment">
    <component
      v-for="message in data.chats"
      :is="message.acf_fc_layout"
      :data="message"
      class="group-chat__item"
    >
      {{ parse(message.content) }}
    </component>
  </figure>
</template>

<script>
import ChatMessage from "@/components/content/group-chat/ChatMessage.vue";
import ChatAction from "@/components/content/group-chat/ChatAction.vue";
import { parse } from "@/scripts/insert.js";

export default {
  components: {
    message: ChatMessage,
    action: ChatAction,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parse,
  },
};
</script>

<style lang="scss">
.group-chat__item {
  margin: $fragment-content-margin 0 0;

  &:first-of-type {
    margin: 0;
  }
}
</style>
