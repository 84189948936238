<template>
  <div class="inbox">
    <HorizontalScroll @horizontalscroll="onScroll" ref="horizontalScroll">
      <div
        v-for="(chapter, index) in chapterMeta"
        :key="chapter.name"
        class="inbox__page"
        ref="page"
      >
        <div class="container">
          <div class="inbox__wrapper" ref="chapter">
            <button
              type="button"
              class="fragment inbox__chapter"
              :class="{
                'inbox__chapter--selected':
                  selectedInFocus && index === selected,
                'inbox__chapter--hide': !chapter.visible,
                'inbox__chapter--interactive': introFinished,
              }"
              :style="chapter.delay"
              @click="onChapterClick"
              :disabled="index !== selected"
              :tabindex="introFinished && index === selected ? null : -1"
              ref="button"
            >
              <span class="fragment__message-header inbox__chapter__header">
                <ProfilePicture :image="chapter.avatar" class="inbox__avatar" />
                <span class="fragment__name inbox__name">{{
                  chapter.name
                }}</span>
              </span>

              <!-- story message -->
              <span class="inbox__message">{{ chapter.message }}</span>

              <!-- story feedback emoji -->
              <span
                v-if="chapter.emoji"
                class="inbox__emoji"
                :class="{
                  'inbox__emoji--show': selectedInFocus && index === selected,
                }"
                :style="chapter.emojiRotation"
              >
                <span class="inbox__emoji__inner">
                  <Emoji class="inbox__emoji__img" :name="chapter.emoji" />
                </span>
              </span>

              <span class="inbox__bar">
                <template v-if="chapter.shapeCount !== null">
                  <svg
                    v-for="i in 3"
                    class="inbox__shape"
                    :class="`inbox__shape--${i}`"
                    viewBox="0 0 100 100"
                  >
                    <polygon
                      v-if="i <= chapter.shapeCount"
                      class="inbox__shape__background"
                      fill="#FFF"
                      points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
                    />
                    <polygon
                      fill="none"
                      stroke-width="9"
                      stroke-linejoin="round"
                      stroke="#FFF"
                      points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
                    />
                  </svg>
                </template>
                <span
                  class="inbox__prompt"
                  :class="{
                    'inbox__prompt--show': introFinished,
                    'inbox__prompt--pulse': index === selected,
                  }"
                >
                  <span>{{ chapter.playLabel }}</span>
                  <svg
                    class="inbox__arrow"
                    viewBox="0 0 17 20"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M1.5 2.20577L15 10L1.5 17.7942L1.5 2.20577Z"
                      stroke="#FFF"
                      stroke-width="3"
                    />
                  </svg>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </HorizontalScroll>
    <div v-if="moreThanOne" class="inbox__controls">
      <button
        type="button"
        class="inbox__control inbox__control--prev"
        :aria-label="prevButtonLabel"
        @click="prevButton"
      ></button>
      <button
        type="button"
        class="inbox__control inbox__control--next"
        :aria-label="moreThanOne"
        @click="nextButton"
      ></button>
    </div>
  </div>
</template>

<script>
import HorizontalScroll from "@/components/content/HorizontalScroll.vue";
import ProfilePicture from "@/components/content/ProfilePicture.vue";
import Emoji from "@/components/content/Emoji.vue";

import * as Background from "@/scripts/background.js";
import { getUserLevel, focusElement } from "@/scripts/helpers.js";
import { reducedMotion } from "@/scripts/globals.js";
import * as AudioManager from "@/scripts/audioManager.js";

import * as Data from "@/scripts/data.js";
import UserData from "@/scripts/userData.js";
import SessionData from "@/scripts/sessionData.js";
import * as Analytics from "@/scripts/analytics.js";
import * as Input from "@/scripts/input.js";
import * as RemovalObserver from "@/scripts/removalObserver.js";
import { containerWidth } from "@/scripts/windowSize.js";

const THRESHOLD_NORMAL = 0.1;
const THRESHOLD_UNLOCK = 0.1;

function firstChapterAtLevel(lvl) {
  let index = 0;

  for (let i = 0; i < lvl - 1; ++i) {
    index += Data.data.chapters[i].length;
  }

  return index;
}

const chapterMeta = [];

function buildChapterMeta(chapters, level) {
  chapterMeta.length = 0;
  let index = 0;

  for (let i = 0; i < level; ++i) {
    const group = chapters[i];

    for (const chapter of group) {
      const chapterProgress = UserData.progress[chapter.post_id];

      let message;
      let emoji = null;
      let progress;
      let total;
      let shapeCount = null;
      let playLabel;
      let delay = 1000;

      if (chapterProgress.story) {
        delay += 500;
        total = chapterProgress.activities.length + 1; // assume chapter has a story

        // calculate completion amount
        progress = chapterProgress.story ? 1 : 0;
        for (const activity of chapterProgress.activities) {
          if (activity) {
            ++progress;
          }
        }

        // finished everything
        if (progress === total) {
          // calculate number of shapes

          // Hotspot Activity defaults to 3
          if (chapter.activity_type === "HotspotActivity") {
            shapeCount = 3;
          }

          // Swipe and Multichoice require calculating...
          else {
            const maxScore =
              chapter.activity_type === "SwipeActivity"
                ? chapter.swipe_activity.questions.length
                : chapter.multichoice_activity.questions.length * 2;
            const score = chapterProgress.score;

            if (maxScore * 0.8 <= score) {
              shapeCount = 3;
            } else if (maxScore * 0.5 <= score) {
              shapeCount = 2;
            } else {
              shapeCount = 1;
            }
          }

          delay += shapeCount * 200;

          const path =
            chapter.outro_inbox[`path_${(chapterProgress.path || 0) + 1}`];

          ({ message, emoji } = path);
          playLabel = SessionData.lang.Inbox.replay_button_label;
        }

        // haven't finished all activities
        else {
          message = chapter.mid_inbox.message;
          playLabel = SessionData.lang.Inbox.resume_button_label;
        }
      } else {
        message = chapter.intro_inbox.message;
        progress = 0;
        total = 1;
        playLabel = SessionData.lang.Inbox.start_button_label;
      }

      const { avatar, name } = chapter.character;

      chapterMeta.push({
        index,
        chapter,
        title: chapter.title,
        colors: Object.values(chapter.colors),
        avatar,
        name,
        message,
        emoji,
        emojiRotation: `--rand-rot: ${index % 2 == 0 ? 10 : -20}deg`,
        shapeCount,
        progress,
        total,
        playLabel,
        visible: SessionData.isFacilitated || i < UserData.playerLevel,
        delay,
      });

      ++index;
    }
  }

  // duplicate entries in meta if there are less than 6, to fix the animations
  const len = chapterMeta.length;
  if (len > 1 && len < 6) {
    for (let i = 0; i < len; ++i) {
      chapterMeta[i + len] = chapterMeta[i];
    }
  }
}

export default {
  components: {
    HorizontalScroll,
    ProfilePicture,
    Emoji,
  },
  emits: ["toChapter"],
  data() {
    const chapters = Data.data.chapters;

    // calculate completion level
    const level = SessionData.isFacilitated
      ? chapters.length
      : getUserLevel(chapters, UserData.progress);

    buildChapterMeta(chapters, level);

    console.log(chapterMeta);

    return {
      metaLength: chapterMeta.length,
      level,
      chapters,
      chapterMeta,
      introFinished: false,
      titleUpdateTimeout: null,

      moreThanOne: chapterMeta.length > 1,
      navBlocked: false,

      // custom scroll
      selected: UserData.chapterIndex,
      cursor: UserData.chapterIndex,
      distance: 0,
      selectedInFocus: false,

      labels: SessionData.lang.Inbox,
      nextButtonLabel: SessionData.lang.Interactions.next_button_label,
      prevButtonLabel: SessionData.lang.Interactions.previous_button_label,
    };
  },
  computed: {
    canNav() {
      return this.moreThanOne && !this.navBlocked;
    },
  },
  methods: {
    onChapterClick() {
      AudioManager.play("ui-input-button-misc");
      AudioManager.play("inbox-exit");
      const chapter = this.chapterMeta[this.selected];
      this.$emit("toChapter", chapter.chapter);
    },

    toSelected(selected, distance, easeIn) {
      // scroll current back to the top
      this.$refs.page[this.selected].scrollTop = 0;

      this.selected = selected;
      UserData.setChapterIndex(selected);
      this.selectedInFocus = false;

      this.distance = distance;

      const absDiff = Math.abs(this.distance);
      if (absDiff < 2) {
        this.animationEaseTarget = 0.925;
      } else {
        this.animationEaseTarget = 0.925 + 0.005 * Math.min(absDiff - 1, 5);
      }

      if (easeIn) {
        this.animationEase = 1;
      } else {
        this.animationEase = this.animationEaseTarget;
      }

      this.prevTime = performance.now();
      cancelAnimationFrame(this.raf);
      this.raf = requestAnimationFrame(this.animate);

      // header inbetween state
      clearTimeout(this.titleUpdateTimeout);
      this.titleUpdateTimeout = setTimeout(this.titleUpdate, 500);
      if (this.inUnlockAnim) return;
      if (distance > 0) {
        AudioManager.play("inbox-next");
      } else {
        AudioManager.play("inbox-previous");
      }
    },
    titleUpdate() {
      this.$root.setHeaderTitle(
        this.chapterMeta[this.selected].title,
        this.movingLeft
      );
    },

    getPrevChapter() {
      return this.selected === 0 ? this.metaLength - 1 : this.selected - 1;
    },
    getNextChapter() {
      return this.selected + 1 === this.metaLength ? 0 : this.selected + 1;
    },
    getLeftDistance(index) {
      const diff = index - this.cursor;
      return diff < 0 ? diff : diff - this.metaLength;
    },
    getRightDistance(index) {
      const diff = index - this.cursor;
      return diff < 0 ? diff + this.metaLength : diff;
    },

    // SCROLL
    onScroll(delta) {
      if (this.canNav) {
        this.blockNav();

        if (0 < delta) {
          const selected = this.getNextChapter();
          const distance = this.getRightDistance(selected);
          const absDist = Math.abs(distance);
          if (absDist < 2) {
            this.movingLeft = false;
            this.toSelected(selected, distance);
            focusElement(this.$refs.page[this.selected]);
          }
        } else {
          const selected = this.getPrevChapter();
          const distance = this.getLeftDistance(selected);
          const absDist = Math.abs(distance);
          if (absDist < 2) {
            this.movingLeft = true;
            this.toSelected(selected, distance);
            focusElement(this.$refs.page[this.selected]);
          }
        }
      }
    },

    // KEYBOARD
    prevButton() {
      if (this.canNav) {
        this.blockNav();
        const selected = this.getPrevChapter();
        const distance = this.getLeftDistance(selected);
        const absDist = Math.abs(distance);
        if (absDist < 2) {
          this.movingLeft = true;
          this.toSelected(selected, distance);
        }
        Input.keyboardMode();
      }
    },
    nextButton() {
      if (this.canNav) {
        this.blockNav();
        const selected = this.getNextChapter();
        const distance = this.getRightDistance(selected);
        const absDist = Math.abs(distance);
        if (absDist < 2) {
          this.movingLeft = false;
          this.toSelected(selected, distance);
        }
        Input.keyboardMode();
      }
    },
    focusSelected() {
      focusElement(this.$refs.button[this.selected]);
    },
    onKey(e) {
      if (!this.$root.menuOpen && this.canNav) {
        if (e.key === "ArrowRight") {
          this.blockNav();
          const selected = this.getNextChapter();
          const distance = this.getRightDistance(selected);
          const absDist = Math.abs(distance);
          if (absDist < 2) {
            this.movingLeft = false;
            this.toSelected(selected, distance);
            this.focusTimeout = setTimeout(this.focusSelected);
          }
          Input.keyboardMode();
        } else if (e.key === "ArrowLeft") {
          this.blockNav();
          const selected = this.getPrevChapter();
          const distance = this.getLeftDistance(selected);
          const absDist = Math.abs(distance);
          if (absDist < 2) {
            this.movingLeft = true;
            this.toSelected(selected, distance);
            this.focusTimeout = setTimeout(this.focusSelected);
          }
          Input.keyboardMode();
        }
      }
    },

    // NAVIGATION

    restoreNav() {
      if (this.navBlocked) {
        this.navBlocked = false;
        this.$refs.horizontalScroll.restore();
        clearTimeout(this.navTimeout);
      }
    },
    blockNav() {
      if (!this.navBlocked) {
        this.navBlocked = true;
        this.$refs.horizontalScroll.block();

        // 350ms timeout unless a touch gesture (assumedly a swipe) ends
        clearTimeout(this.navTimeout);
        this.navTimeout = setTimeout(this.restoreNav, 350);
      }
    },
    onTouchend(e) {
      // eagerly enable nav after touch
      // only mouse wheel needs the timeout
      if (e.touches.length === 0) {
        this.restoreNav();
      }
    },

    updatePositions() {
      let d1, d2, diff, absDiff, blur, scale, x, y, z;
      for (let i = 0; i < this.metaLength; ++i) {
        d1 = i - this.cursor;
        d2 = d1 < 0 ? d1 + this.metaLength : d1 - this.metaLength;

        diff = Math.abs(d1) < Math.abs(d2) ? d1 * 0.5 : d2 * 0.5;

        if (diff < 0) {
          absDiff = -diff;
        } else {
          absDiff = diff;
        }

        const pageStyle = this.pages[i].style;
        const buttonStyle = this.buttons[i].style;

        if (absDiff < 1) {
          blur = Math.floor(10 * (1 - (1 - absDiff) ** 2));
          scale = 1 - 0.5 * absDiff;
          x = 1.5 * diff * containerWidth;
          y = 25 * absDiff * absDiff;
          z = Math.ceil(3 - 3 * absDiff);

          pageStyle.display = "block";
          pageStyle.zIndex = z;
          buttonStyle.filter = `blur(${blur}px)`;
          if (reducedMotion) {
            buttonStyle.opacity = 1 - absDiff * 2;
            buttonStyle.transform = `translate3d(0, 0, ${z}px)`;
          } else {
            buttonStyle.opacity = 1 - absDiff;
            buttonStyle.transform = `translate3d(${x}px, ${y}%, ${z}px) scale(${scale})`;
          }
        } else {
          pageStyle.display = "none";
        }
      }
    },
    scrollBackground(delta) {
      Background.updateScrollX(-1.5 * containerWidth * delta);
    },
    animate(t) {
      const absDiff = Math.abs(this.distance);

      // trigger the focus animation when the selected chapter enters view
      if (!this.selectedInFocus && absDiff < this.threshold) {
        this.playSelectedAnimation();
        if (this.inUnlockAnim) {
          this.showNewChapter();
        }
      }

      // stop animating when the new position is reached
      if (absDiff < 0.001) {
        this.scrollBackground(this.distance);
        this.distance = 0;
        this.cursor = this.selected;
      }

      // ease toward the desired position
      else {
        const dt = t - this.prevTime;
        this.animationEase =
          this.animationEase * 0.975 + this.animationEaseTarget * 0.025;
        const ease = this.animationEase ** (0.06 * dt);
        const delta = this.distance * (1 - ease);

        this.scrollBackground(delta);
        this.distance *= ease;
        this.cursor += delta;

        // wrap back around at the ends
        if (this.cursor < 0) {
          this.cursor += this.metaLength;
        } else if (this.cursor > this.metaLength) {
          this.cursor -= this.metaLength;
        }

        this.prevTime = t;

        this.raf = requestAnimationFrame(this.animate);
      }

      this.updatePositions();
    },

    playSelectedAnimation() {
      this.selectedInFocus = true;

      const { progress, total } = this.chapterMeta[this.selected];
      this.$root.setHeaderProgress(progress, total);
      Background.setColors(this.chapterMeta[this.selected].colors);
    },
    start() {
      // animate the current chapter
      this.playSelectedAnimation();

      // show the header
      this.$root.setHeaderTitle(
        this.chapterMeta[this.selected].title,
        this.movingLeft
      );

      // no new chapters, just show
      if (SessionData.isFacilitated || UserData.playerLevel === this.level) {
        this.introFinished = true;
        this.threshold = THRESHOLD_NORMAL;
        if (this.chapterMeta.length !== 1) {
          this.restoreNav();
        }
      }

      // new chapters unlocked, do unlock anim
      else {
        this.threshold = THRESHOLD_UNLOCK;
        this.enterTimeout = setTimeout(
          this.playUnlockAnimation,
          this.chapterMeta[this.selected].delay
        );
      }
    },
    playUnlockAnimation() {
      this.selectedInFocus = false;
      this.$root.setHeaderDisplay(false);
      this.enterTimeout = setTimeout(this.toNewChapter, 500);
    },
    toNewChapter() {
      this.inUnlockAnim = true;

      const selected = firstChapterAtLevel(this.level);
      const distance = this.getRightDistance(selected);
      this.toSelected(selected, distance, true);
    },
    showNewChapter() {
      // GA4 compatible event
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#level_up
      Analytics.trackEvent("level_up", {
        level: this.level,
      });

      this.introFinished = true;
      this.threshold = THRESHOLD_NORMAL;

      let delay = 0;
      for (let i = 0; i < this.metaLength; ++i) {
        const meta = this.chapterMeta[i];
        if (!meta.visible) {
          meta.visible = true;
          meta.delay = `--delay: ${delay}s`;
          delay += 0.5;
        }
      }

      // WIP, just impact for now
      if (this.inUnlockAnim) AudioManager.play("inbox-unlock");
      this.inUnlockAnim = false;

      UserData.setChapterIndex(this.selected);
      UserData.setPlayerLevel(this.level);

      this.$root.setHeaderDisplay(true);
      this.$root.iconShow();

      if (this.chapterMeta.length !== 1) {
        this.restoreNav();
      }
    },
    afterAnimation() {
      cancelAnimationFrame(this.raf);
    },
  },
  mounted() {
    Analytics.trackProgress("inbox");
    AudioManager.play("inbox-load");

    this.pages = document.getElementsByClassName("inbox__page");
    this.buttons = document.getElementsByClassName("inbox__wrapper");

    // set header state and animate in
    this.$root.setHeaderTitle(this.chapterMeta[this.selected].title);
    Background.setColors(this.chapterMeta[this.selected].colors);
    this.$root.setHeaderDisplay(true);
    this.$root.setHeaderProgressStyle("pie");
    this.$root.setHeaderProgress(0, 1);

    if (SessionData.isFacilitated) {
      this.enterTimeout = setTimeout(this.start, 1000);
    } else if (UserData.playerLevel === 0) {
      this.enterTimeout = setTimeout(this.showNewChapter, 1000);
    } else {
      this.enterTimeout = setTimeout(this.start, 1000);
      if (UserData.playerLevel < this.level) {
        this.$root.iconHide();
      }
    }

    // set initial animation state
    this.updatePositions();

    // watch left/right keys
    window.addEventListener("keydown", this.onKey);

    // touch events cancel scroll block
    window.addEventListener("touchend", this.onTouchend);
    window.addEventListener("touchcancel", this.onTouchend);
  },
  beforeUnmount() {
    RemovalObserver.observe(
      this.$refs.horizontalScroll.$el,
      this.afterAnimation
    );

    clearTimeout(this.enterTimeout);
    clearTimeout(this.navTimeout);
    clearTimeout(this.titleUpdateTimeout);
    clearTimeout(this.focusTimeout);

    // remove keyboard events
    window.removeEventListener("keydown", this.onKey);

    // remove touch events
    window.addEventListener("touchend", this.onTouchend);
    window.addEventListener("touchcancel", this.onTouchend);
  },
};
</script>

<style lang="scss">
@keyframes inbox-prompt-nudge {
  0% {
    transform: none;
  }

  4% {
    transform: translateX(0.25rem);
  }

  8% {
    transform: none;
  }

  12% {
    transform: translateX(0.25rem);
  }

  16% {
    transform: none;
  }
}

@keyframes inbox-enter-anim {
  0% {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, 0) scale(0.9);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inbox-leave-anim {
  0% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, 0) scale(1.5);
  }
}

.inbox {
  position: absolute;
  width: 100%;
  height: 100%;

  $fade-top-start: calc($page-top + 5vw);
  $fade-bottom-start: calc($page-bottom + 5vw);
  $fade-top: 6rem;
  $fade-bottom: calc($page-bottom + 4rem);
  $fade-min: rgba(0, 0, 0, 0.1);
  // approx sine ease
  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    $fade-min $fade-top-start,
    rgba(0, 0, 0, 0.38) calc($fade-top-start + calc($fade-top * 0.25)),
    rgba(0, 0, 0, 0.71) calc($fade-top-start + calc($fade-top * 0.5)),
    rgba(0, 0, 0, 0.92) calc($fade-top-start + calc($fade-top * 0.75)),
    rgba(0, 0, 0, 1) calc($fade-top-start + $fade-top),
    rgba(0, 0, 0, 1) calc(100% - calc($fade-bottom-start + $fade-bottom)),
    rgba(0, 0, 0, 0.92)
      calc(100% - calc($fade-bottom-start + calc($fade-bottom * 0.75))),
    rgba(0, 0, 0, 0.71)
      calc(100% - calc($fade-bottom-start + calc($fade-bottom * 0.5))),
    rgba(0, 0, 0, 0.38)
      calc(100% - calc($fade-bottom-start + calc($fade-bottom * 0.25))),
    $fade-min calc(100% - $fade-bottom-start),
    $fade-min 100%
  );
  mask-size: 100% 100%;
  mask-position: center;

  $duration: 1.5s;
  $in-delay: 0.1s;
  $depth: -10vh;

  animation: inbox-enter-anim $duration ($in-delay + 0.75s) $ease-out-cubic
    backwards;

  &.v-leave-active {
    animation: inbox-leave-anim ($duration * 0.75) 0.5s $ease-in-sine;
  }

  &__page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    contain: strict;
    @include hide-scrollbar;
  }

  &__wrapper {
    transform-origin: top;
  }

  &__chapter {
    // @include reset-button;

    appearance: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    min-height: 50vh;
    padding-bottom: 0;
    border: none;

    color: inherit;
    font-size: inherit;
    font-family: inherit;
    text-align: left;

    pointer-events: none;
    user-select: none; // interferes with scroll action
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    transition: opacity 0.3s $ease-out-quint,
      transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-delay: var(--delay);

    &--hide {
      opacity: 0;
      transform: translateY(10%) scale(0.5);

      & > .inbox__chapter__header {
        & > .inbox__avatar {
          transform: scale(0.7);
          opacity: 0;
        }

        & > .inbox__name {
          transform: translateX(-1rem);
          opacity: 0;
        }
      }

      & > .inbox__message {
        transform: translateY(2rem);
        opacity: 0;
      }
    }

    &--selected {
      z-index: 3;

      & > .inbox__bar > .inbox__shape > .inbox__shape__background {
        transform: none;
        opacity: 1;
        transition: opacity 0.5s var(--show-delay) $ease-out-quart,
          transform 0.5s var(--hide-delay) cubic-bezier(0.34, 1.56, 0.64, 1);
      }
    }

    &--interactive {
      pointer-events: auto;
      cursor: pointer;
    }

    &__header {
      width: 100%;
    }
  }

  &__avatar {
    transition: opacity 0.4s $ease-out-quint,
      transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-delay: calc(var(--delay) + 0.25s);
  }

  &__name {
    transition: opacity 1s $ease-out-quint, transform 1s $ease-out-quart;
    transition-delay: calc(var(--delay) + 0.5s);
  }

  // STORY FEEDBACK EMOJI

  &__emoji {
    position: absolute;
    top: -3.5rem;
    right: -3rem;
    width: 11rem;

    opacity: 0;
    transform: scale(0);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;

    &--show {
      opacity: 1;
      transform: none;
      transition: opacity 0.3s $ease-out-quart,
        transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    &__inner {
      @include float-anim(
        $name: inbox-excerpt-emoji-float,
        $amt: 0.5rem,
        $delay: 0s
      );
    }

    &__img {
      width: 100%;
      height: auto;

      @include float-rotate-anim(
        $name: inbox-excerpt-emoji-rotate,
        $amt: 1.5deg,
        $offset: var(--rand-rot),
        $dur: 10s
      );
    }
  }

  // STORY MESSAGE

  &__message {
    display: block;
    margin: 0 0 auto;
    font-size: 1.625rem;
    font-weight: $bold;

    transition: opacity 1s $ease-out-quint, transform 1s $ease-out-quart;
    transition-delay: calc(var(--delay) + 0.75s);
  }

  &__bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.5rem;
    padding: 0 2rem;
    margin: 2rem -2rem 0;
    border-radius: 0 0 $content-radius $content-radius;
    background: rgba(255, 255, 255, 0.08);
  }

  &__shape {
    flex: 0 0 auto;
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;

    &__background {
      transform-origin: center;
      transform: scale(0);
      opacity: 0;
      transition: opacity 0.3s var(--hide-delay) ease-out,
        transform 0.3s var(--hide-delay) ease-out;
    }

    @for $i from 1 through 3 {
      &--#{$i} {
        @include rotate(
          $name: inbox-score-shape-rotate-#{$i},
          $dur: 20s,
          $delay: $i * -3s
        );

        --hide-delay: #{0.3s - 0.1s * $i};
        --show-delay: #{0.5s + ($i - 1) * 0.2s};
      }
    }
  }

  &__prompt {
    display: flex;
    align-items: center;
    height: 4.5rem;
    margin-left: auto;

    font-weight: $bold;
    text-transform: uppercase;

    opacity: 0;
    transition: opacity 0.5s ease-out;
    transform: translate3d(0, 0, 0);

    &--show {
      opacity: 1;
    }

    &--pulse {
      animation: inbox-prompt-nudge 4s 0.5s infinite;
    }
  }

  &__arrow {
    height: 1rem;
    width: auto;
    margin: 0 0 0 0.75rem;
  }

  // PREV & NEXT BUTTONS

  &__controls {
    opacity: 0;
    transition: opacity 0.1s ease-out;

    &:has(:focus-visible) {
      opacity: 1;
    }
  }

  &__control {
    @include reset-button;

    pointer-events: none;
    position: fixed;
    top: 50%;
    box-sizing: border-box;
    width: 2.25rem;
    height: 2.25rem;
    border: solid 0.125rem #fff;
    border-radius: 0.25rem;

    &--prev {
      left: var(--container-indent);
      margin: -1rem 0 0 -1rem;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.5rem;
        height: 0.5rem;
        border-top: solid 0.125rem #fff;
        border-left: solid 0.125rem #fff;
        transform: translate(-35%, -50%) rotate(-45deg);
      }
    }

    &--next {
      right: var(--container-indent);
      margin: -1rem -1rem 0 0;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.5rem;
        height: 0.5rem;
        border-top: solid 0.125rem #fff;
        border-right: solid 0.125rem #fff;
        transform: translate(-65%, -50%) rotate(45deg);
      }
    }
  }
}
</style>
