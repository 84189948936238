<template>
  <component :is="component" :data="data" />
</template>

<script>
import { markRaw } from "vue";
import Email from "@/components/content/Email.vue";
import GroupChat from "@/components/content/GroupChat.vue";
import DirectMessage from "@/components/content/DirectMessage.vue";
import NewsBlurb from "@/components/content/NewsBlurb.vue";
import Notification from "@/components/content/Notification.vue";
import Popup from "@/components/content/Popup.vue";
import SAMWrap from "@/components/content/SAMWrap.vue";
import SearchResults from "@/components/content/SearchResults.vue";
import SocialImage from "@/components/content/SocialImage.vue";
import StoryMessage from "@/components/content/StoryMessage.vue";
import Video from "@/components/content/video/VideoPlaceholder.vue";
import WebPage from "@/components/content/WebPage.vue";
import MusicEmojis from "@/components/content/MusicEmojis.vue";

const componentMap = {
  chat_history: GroupChat,
  comment: SAMWrap,
  direct_message: DirectMessage,
  email: Email,
  news: NewsBlurb,
  notification: Notification,
  popup: Popup,
  search_result: SearchResults,
  social_image: SocialImage,
  story_message: StoryMessage,
  video: Video,
  web_page: WebPage,
  music_genre: MusicEmojis,
};

export default {
  components: {
    GroupChat,
    SAMWrap,
    DirectMessage,
    Email,
    NewsBlurb,
    Notification,
    Popup,
    SearchResults,
    SocialImage,
    StoryMessage,
    Video,
    WebPage,
    MusicEmojis,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      component: markRaw(componentMap[this.data.acf_fc_layout]),
    };
  },
};
</script>
