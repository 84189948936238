import { globals } from "./_globals.js";
import {
  recursiveOffsetLeft,
  recursiveOffsetTop,
  lerp,
  constrain,
} from "@/scripts/helpers.js";

const SELF_V_MAX_MAX = 2;
const SELF_V_MAX_MIN = 0.3;
const SPEED_LERP_EASE = 0.015;

function getLerpSpeed(alpha) {
  return lerp(SELF_V_MAX_MIN, SELF_V_MAX_MAX, alpha);
}

export default {
  speedLerp: [],
  init() {
    this.speedLerp = [];
    const period = (Math.PI * 2) / globals.shapes.length;
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      this.speedLerp.push(0.5);
      shape.resetProps();
      shape.orbitRadius = globals.vMin;
      shape.setProp("vMax", globals.rem * 2, 750);
      shape.setPropLerp(
        "selfVMax",
        globals.rem * getLerpSpeed(this.speedLerp[i]),
        750
      );
      shape.setPropLerp("accelStep", globals.rem / 2, 2000);
      if (globals.isOut) {
        shape.setProp("pos", {
          x: (Math.cos(period * i) * globals.vMax) / 2 + globals.vMax / 2,
          y: (Math.sin(period * i) * globals.vMax) / 2 + globals.vMax / 2,
          z: 0,
        });
      } else {
        shape.externalVelocityFactor = 0.2;
        shape.setPropLerp("externalVelocityFactor", 0.9, 750);
      }
    }
    this.setTarget();
  },
  update() {
    // last shape should lead rotation, start iteration from back/start
    let angleDiff;
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];

      // try to get itself near 90deg or Math.PI/2 behind next
      // 180 - 90 behind: speed up
      // 90 - 0 behind, or 90 ahead: slow down
      // beyond these limits do nothing, TODO may be to check the next revolution of the angle to see if 0 has been crossed

      // look ahead
      if (i !== globals.shapes.length - 1) {
        const nextSibling = globals.shapes[i + 1];
        angleDiff = nextSibling.targetAngle - shape.targetAngle;
        if (angleDiff < -Math.PI / 2 && angleDiff > -Math.PI) {
          // speed up
          this.speedLerp[i] += (1 - this.speedLerp[i]) * SPEED_LERP_EASE;
        } else if (angleDiff >= -Math.PI / 2 && angleDiff < Math.PI) {
          // slow down
          this.speedLerp[i] += (0 - this.speedLerp[i]) * SPEED_LERP_EASE;
        } else {
          this.speedLerp[i] += (0.5 - this.speedLerp[i]) * SPEED_LERP_EASE;
        }
      }

      // look behind instead
      else {
        const prevSibling = globals.shapes[i - 1];
        angleDiff = shape.targetAngle - prevSibling.targetAngle;
        // speed up if 0 - 90 ahead, or 0 - 90 behind
        if (angleDiff < -Math.PI / 2 && angleDiff > -Math.PI) {
          this.speedLerp[i] += (0 - this.speedLerp[i]) * SPEED_LERP_EASE;
        }
        // slow down if 90 - 180 ahead
        else if (angleDiff > -Math.PI / 2 && angleDiff < Math.PI) {
          this.speedLerp[i] += (1 - this.speedLerp[i]) * SPEED_LERP_EASE;
        } else {
          this.speedLerp[i] += (0.5 - this.speedLerp[i]) * SPEED_LERP_EASE;
        }
      }

      this.speedLerp[i] = constrain(this.speedLerp[i], 0, 1);
      shape.update();
    }
  },
  setTarget() {
    let targetX;
    let targetY;
    let radius;
    if (globals.targetElement) {
      const halfWidth = globals.targetElement.clientWidth * 0.5;
      const halfHeight = globals.targetElement.clientHeight * 0.5;
      targetX = recursiveOffsetLeft(globals.targetElement) + halfWidth;
      targetY = recursiveOffsetTop(globals.targetElement) + halfHeight;
      radius = Math.sqrt(halfWidth * halfWidth + halfHeight * halfHeight);
    } else {
      targetX = globals.vW * 0.5;
      targetY = globals.vH * 0.5;
      radius = globals.vMin * 0.5;
    }

    for (const shape of globals.shapes) {
      shape.setTarget(targetX, targetY);
      shape.orbitRadius = radius + globals.vMin * 0.25 * Math.random() + 32;
    }
  },
  onResize() {
    if (globals.isOut || globals.isLeaving) return;
    this.setTarget();
  },
  in() {
    this.setTarget();

    for (const shape of globals.shapes) {
      shape.setPropLerp("orbitRadius", shape.orbitRadius);
      shape.setPropLerp("velSinLerp", 1, 2000);
      shape.setPropLerp("vMax", globals.rem * 1.5);
      shape.setPropLerp("selfVMax", globals.rem * 0.75);
      shape.setPropLerp("accelStep", globals.rem * 0.2, 2000);
    }
  },
  out() {
    for (const shape of globals.shapes) {
      shape.setPropLerp("orbitRadius", 0);
      shape.setPropLerp("velSinLerp", 0);
      shape.setPropLerp("vMax", globals.rem * 2);
      shape.setPropLerp("selfVMax", globals.rem);

      // project a line out for the current direction to offscreen
      const xOffset = shape.pos.x + shape.turnVector.x * globals.vMax * 2;
      const yOffset = shape.pos.y + shape.turnVector.y * globals.vMax * 2;
      shape.setTarget(xOffset, yOffset);
    }
  },
  kill() {},
  onVisibilityChange() {},
  next() {},
};
