import { Auth } from "@aws-amplify/auth";

const awsConfig = {
  aws_cognito_region: process.env.VUE_APP_AWS_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_project_region: process.env.VUE_APP_AWS_REGION,
  API: {
    endpoints: [
      {
        name: "AppAPI",
        endpoint: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_STAGE}`,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "PublicAPI",
        endpoint: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_STAGE}`,
        custom_header: async () => {
          return {
            "X-Api-Key": process.env.VUE_APP_PUBLIC_API_KEY,
          };
        },
      },
    ],
  },
};

export default awsConfig;
