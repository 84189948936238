<template>
  <div class="group-chat-message">
    <div class="group-chat-message__body">
      <span class="group-chat-message__name">{{ parse(data.sender) }}:</span>
      {{ parse(data.message) }}
    </div>
  </div>
</template>

<script>
import { parse } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parse,
  },
};
</script>

<style lang="scss">
@keyframes chat-message-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.7);
  }

  50% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.02);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes chat-text-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.group-chat-message {
  --bubble-delay: var(--delay);
  @for $i from 2 through 10 {
    &:nth-child(#{$i}) {
      --bubble-delay: calc(var(--delay) + #{($i - 1) * 0.25s});
    }
  }

  position: relative;
  width: fit-content;

  &__body {
    padding: $fragment-inner-padding;
    animation: chat-text-fade 1s $ease-out-cubic both;
    animation-delay: calc(var(--bubble-delay) + 0.3s);
  }

  &__name {
    font-weight: $bold;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 0 $fragment-radius $fragment-radius $fragment-radius;
    background: var(--color-2);

    transform-origin: 0 0;
    animation: chat-message-in 0.5s $ease-out-quart both;
    animation-delay: var(--bubble-delay);

    transform: translate3d(0, 0, 0);
  }
}
</style>
