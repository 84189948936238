import Storage from "@/scripts/storage.js";
import PersistentData from "@/scripts/persistentData.js";
import SessionData from "@/scripts/sessionData.js";
import OfflineMedia from "@/scripts/offlineMedia.js";
import UserData from "@/scripts/userData.js";
import * as Connection from "@/scripts/connection.js";
import * as Analytics from "@/scripts/analytics.js";
import { noop, onceEvent } from "@/scripts/globals.js";
import Time from "@/scripts/time.js";

import assets from "@/data/assets.json";
import emojis from "@/data/emoji.json";
import musicGenres from "@/data/musicGenres.json";
import sentiments from "@/data/sentiments.json";

const latestAppVersion = process.env.VUE_APP_BUILD_NUM;
let fileUrl;

let lastSeenTimestamp = null;

const POLL_INTERVAL = 10000;
const RETRY_INTERVAL = 3000;

// CACHE IMAGES

// recursively explore the data looking for 'emoji' props
const emoji_data_keys = [
  "emoji",
  "foreground_emoji",
  "background_emoji_1",
  "background_emoji_2",
];

function findEmojis(obj, foundEmojis) {
  if (obj instanceof Array) {
    for (const item of obj) {
      findEmojis(item, foundEmojis);
    }
  } else if (obj instanceof Object) {
    for (const key of emoji_data_keys) {
      const val = obj[key];
      if (val && typeof val === "string" && emojis[val]) {
        foundEmojis[val] = true;
      }
    }

    for (const prop in obj) {
      findEmojis(obj[prop], foundEmojis);
    }
  }
}

function getUsedEmojis() {
  const foundEmojis = {};

  // recursively visit all data looking for emojis
  findEmojis(newData, foundEmojis);

  // emojis used for music genres
  for (const genre in musicGenres) {
    const emojis = musicGenres[genre].emojis;
    for (const emoji of emojis) {
      foundEmojis[emoji.name] = true;
    }
  }

  // emojis used for sentiment
  for (const id in sentiments) {
    const emojis = sentiments[id];
    for (const emoji of emojis) {
      foundEmojis[emoji.name] = true;
    }
  }

  return foundEmojis;
}

async function downloadAssets() {
  if (!OfflineMedia.available) {
    console.log("offline media unavailable - skip downloads");
    return;
  }

  console.log("DOWNLOADS - start");

  const downloads = [];

  await clearDownloads();
  mediaClear = null;

  // store wordpress attachments
  for (const id in newData.attachments) {
    const key = id.toString();
    const url = newData.attachments[id].sizes.medium;
    downloads.push(OfflineMedia.download(key, url));
  }

  // store assets
  for (const file of assets) {
    const url = require(`@/assets/images/${file}`);
    downloads.push(OfflineMedia.download(url, url));
  }

  // store the earth model
  const url = require("@/assets/models/low_poly_earth.glb");
  downloads.push(OfflineMedia.download(url, url));

  // store emojis
  for (const emoji in getUsedEmojis()) {
    const url = require(`@/assets/images/emojis/${emojis[emoji].file}`);
    downloads.push(OfflineMedia.download(url, url));
  }

  await Promise.all(downloads);

  console.log("DOWNLOADS - complete!");
}

// GET DATA

let xhr;
let fetchTimeout;
let newData;

function updateWithLang(lang) {
  console.log("Data.updateWithLang()", lang.slug);
  setNewLang(lang);
  setUpdateReady();
}

function decideUpdate() {
  if (PersistentData.updating || lastSeenTimestamp !== newData.timestamp) {
    lastSeenTimestamp = newData.timestamp;

    // check current lang and file are compatible

    if (hasLang) {
      // check that default language is the same
      if (lang.default) {
        console.log("lang is default");

        // current lang still default
        const defaultLang = findDefaultLang(newData.languages);
        if (defaultLang.slug === lang.slug) {
          console.log("lang is the same + default");
          updateWithLang(defaultLang);
        }

        // default changed - find suitable lang
        else {
          console.log("lang is no longer default");

          // look for current lang - maybe default changed
          const sameLang = findLang(newData.languages, lang.slug);
          if (sameLang) {
            console.log("found same lang");
            forceNewLang(sameLang);
          }

          // current lang no longer exists - find best option
          else {
            console.log("lang deleted - force restart");

            emitLangError(); // logout

            const bestLang = findBestLang(newData.languages);

            // new lang is default - continue with current json
            if (bestLang.default) {
              console.log("new lang is default - keep going with this file");
              forceLangUpdate(bestLang);
            }

            // fetch the new lang
            else {
              console.log("new lang found - fetching");
              forceNewLang(bestLang);
            }
          }
        }

        // end
      }

      // not default - use this lang
      else {
        console.log("not default lang - find match in data");
        const sameLang = findLang(newData.languages, lang.slug);
        updateWithLang(sameLang);
      }
    }

    // no lang set - use default - assumed to be default.json
    else {
      const defaultLang = findDefaultLang(newData.languages);
      updateWithLang(defaultLang);
    }
  }

  // no update
  else {
    pollForUpdates();
  }

  // all necessary decisions are made by now
  setReady();
}

export async function update() {
  console.log("UPDATE - START");

  // start downloads asap
  const downloadsPromise = downloadAssets();

  cancelFetch();

  PersistentData.setUpdating(true);

  Analytics.trackEvent("update_content", {
    content_timestamp: newData.timestamp,
  });

  // replace data with newData
  setData(newData);
  PersistentData.setData(data);

  UserData.validate();

  await downloadsPromise;

  console.log("UPDATE - COMPLETE!");

  PersistentData.setUpdating(false);
  hasUpdate = false;
  needsUpdate = false;
  langError = false;

  setUpdateComplete();
}

export function changeLanguage(lang) {
  console.log("Data.changeLanguage()", lang);
  forceNewLang(lang);
}

export function resume() {
  pollForUpdates();
}

// LANG UTILS

function findLang(languages, slug) {
  for (const lang of languages) {
    if (lang.slug === slug) {
      return lang;
    }
  }
}

function findDefaultLang(languages) {
  for (const lang of languages) {
    if (lang.default) {
      return lang;
    }
  }
}

function findBestLang(languages) {
  // get the language that best matches browser settings
  const rfc = navigator.language;

  console.log("match system language", rfc, languages);

  // look for a perfect match
  for (const lang of languages) {
    if (lang.locale === rfc) return lang;
  }

  // try matching language code
  const code = rfc.slice(0, 2);
  for (const lang of languages) {
    if (lang.locale.startsWith(code)) return lang;
  }

  // settle for the default
  for (const lang of languages) {
    if (lang.default) return lang;
  }
}

// FILE URL

function defaultFileUrl() {
  fileUrl = process.env.VUE_APP_DATA_PREFIX + "default.json";
}

function generateFileUrl(lang) {
  // work out which language data to use
  if (lang.default) {
    defaultFileUrl();
  } else {
    fileUrl = process.env.VUE_APP_DATA_PREFIX + lang.slug + ".json";
  }

  console.log("GENERATE FILE URL -", fileUrl);
}

// STATE

let started = false;
let fetched = false;

// READY
// run when initialisation completes
// ie. first fetch is underway

export let ready = false;
let readyCallback = noop;

export function onReady(fn) {
  readyCallback = fn;

  if (ready) {
    fn();
  }
}

function setReady() {
  if (!ready) {
    ready = true;
    readyCallback();
  }
}

export function removeReady() {
  readyCallback = noop;
}

// DATA EVENT
// run when there is data available to use

export let data;
export let hasData = false;
let dataCallback = noop;

export function onData(fn) {
  dataCallback = fn;

  if (hasData) {
    fn();
  }
}

export function removeData() {
  dataCallback = noop;
}

function setData(newData) {
  console.log("Data.setData()");
  data = newData;

  if (!hasData) {
    hasData = true;
    dataCallback();
  }
}

// HAS UPDATE
// run when new data is available for download

export let hasUpdate = false;
let updateReadyCallback = noop;

export function onUpdateReady(fn) {
  updateReadyCallback = fn;

  if (hasUpdate) fn();
}

function setUpdateReady() {
  if (!hasUpdate) {
    hasUpdate = true;
    updateReadyCallback();
  }
}

export function removeUpdateReady() {
  updateReadyCallback = noop;
}

// NEEDS UPDATE
// run when the update is urgent and destructive
// eg. content no longer exists

export let needsUpdate = false;
let updateNeededCallback = noop;

export function onUpdateNeeded(fn) {
  updateNeededCallback = fn;

  if (needsUpdate) fn();
}

export function removeUpdateNeeded() {
  updateNeededCallback = noop;
}

// UPDATE DONE

let updateCompleteCallback = noop;

export function onUpdateComplete(fn) {
  updateCompleteCallback = fn;
}

function setUpdateComplete() {
  updateCompleteCallback();
}

export function removeUpdateComplete() {
  updateCompleteCallback = noop;
}

// LANGUAGE EVENTS

let langReady = false;
let langError = false;
// TODO may need to refactor to take multiple callbacks
let languageErrorCallback = noop;
let languageReadyCallback = noop;

export function onLangReady(fn) {
  languageReadyCallback = fn;

  if (langReady) {
    fn();
  }
}

// run if the incoming course data is incompatible
export function onLangError(fn) {
  languageErrorCallback = fn;

  if (langError) {
    fn();
  }
}

export function removeLangReady() {
  languageReadyCallback = noop;
}

export function removeLangError() {
  languageErrorCallback = noop;
}

function emitLangReady() {
  languageReadyCallback();
}

async function emitLangError() {
  await languageErrorCallback(); // probably signout which is async
}

// QUEUED LANG OBJ

let hasLang = false;
let lang = null;

function setNewLang(newLang) {
  console.log("Data.setNewLang()", newLang);
  hasLang = true;
  lang = newLang;

  PersistentData.setCurrentLang(lang.slug);
  Time.setLocale(lang.locale);
  SessionData.setLang(lang);

  emitLangReady();
}

// START

export async function start() {
  console.log("Data.start()");

  // wait for existing data and lang
  await PersistentData.ready();

  // existing data
  const existingData = PersistentData.data;
  if (existingData) {
    setData(existingData);
  }

  // existing lang + fetch asap
  if (hasData && PersistentData.currentLang) {
    const lang = findLang(data.languages, PersistentData.currentLang);
    generateFileUrl(lang);
    fetch();
    setNewLang(lang);
  } else {
    await emitLangError();
    defaultFileUrl();
    fetch();
  }

  // wait for rest of storage
  await UserData.ready();
  await OfflineMedia.ready();

  // decide initial update state
  if (PersistentData.appVersion !== latestAppVersion) {
    console.log("NEW VER");
    await forceNewVer();
  } else if (PersistentData.updating) {
    console.log("RESUME UPDATE");
    forceUpdate();
  } else if (hasLang && hasData) {
    console.log("POLL FOR UPDATES");
    lastSeenTimestamp = data.timestamp;
  } else {
    console.log("MISSING DATA");
    forceUpdate();
  }

  // started - check now if fetched
  started = true;
  if (fetched) {
    decideUpdate();
  }
}

// UPDATE TYPES

function forceUpdate() {
  if (!needsUpdate) {
    needsUpdate = true;

    clearDownloads();

    PersistentData.setUpdating(true);
    updateNeededCallback();
  }
}

// new version - clear everything then force update
async function forceNewVer() {
  await Storage.clear();

  PersistentData.clear();
  UserData.clear();

  PersistentData.setAppVersion(latestAppVersion);

  forceUpdate();
}

// new lang - fetch new file then force update
function forceNewLang(lang) {
  generateFileUrl(lang);
  fetch();

  setNewLang(lang);
  lastSeenTimestamp = null;

  forceUpdate();
}

// invalid lang - fetch default then froce update
function resetLanguage() {
  // use default content
  defaultFileUrl();
  fetch();

  lastSeenTimestamp = null;
  hasLang = false;
  lang = null;
  PersistentData.setCurrentLang(null);

  forceUpdate();
}

function forceLangUpdate(lang) {
  setNewLang(lang);
  forceUpdate();
}

// PROMISE MANAGERS

// create only ONE clear at a time
let mediaClear;
function clearDownloads() {
  if (!mediaClear) {
    mediaClear = OfflineMedia.clear();
  }
  return mediaClear;
}

// FETCH

function pollForUpdates() {
  fetchAfterDelay(POLL_INTERVAL);
}

function fetchAfterDelay(delay) {
  fetchTimeout = setTimeout(fetch, delay);
}

function fetch() {
  // ensure only one active fetch
  cancelFetch();

  if (navigator.onLine) {
    createXhr();
  } else {
    console.log("fetch - offline - waiting for online event");
    window.addEventListener("online", createXhr, onceEvent);
  }
}

function createXhr() {
  xhr = new XMLHttpRequest();
  xhr.responseType = "json";
  xhr.addEventListener("progress", Connection.fetchSuccess);
  xhr.addEventListener("readystatechange", fetchState);
  xhr.addEventListener("error", fetchError);
  xhr.open("GET", `${fileUrl}?t=${Date.now()}`); // force cache bypass
  xhr.send();
}

function deleteXhr() {
  xhr.removeEventListener("progress", Connection.fetchSuccess);
  xhr.removeEventListener("readystatechange", fetchState);
  xhr.removeEventListener("error", fetchError);
  xhr.abort();
  xhr = null;
}

function fetchState() {
  // watch ready state because Chrome will call load prematurely
  // possibly cache related? Service worker related?

  // console.log("fetch state -", xhr.readyState);

  // 4 - DONE
  if (xhr.readyState === 4) {
    // success!
    if (xhr.status === 200) {
      Connection.fetchSuccess();
      newData = xhr.response;

      fetched = true;
      if (started) {
        decideUpdate();
      }
    }
  }

  // 2 - HEADERS_RECEIVED
  // 3 - LOADING
  else if (xhr.readyState > 1) {
    Connection.fetchSuccess();
  }
}

function fetchError() {
  // console.log("fetch error");

  // bad url - lang doesn't exist
  if (xhr.status === 403) {
    setTimeout(resetLanguage);
  }

  // assume error from connection fail
  else {
    Connection.fetchError();

    // keep retrying...
    fetchAfterDelay(RETRY_INTERVAL);
  }
}

function cancelFetch() {
  if (xhr) {
    deleteXhr();
  }
  window.removeEventListener("online", createXhr, onceEvent);
  clearTimeout(fetchTimeout);
}

export function stop() {
  // remove callbacks

  removeReady();
  removeData();

  removeUpdateNeeded();
  removeUpdateReady();
  removeUpdateComplete();

  removeLangReady();
  removeLangError();
}
