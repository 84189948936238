<template>
  <svg version="1.1" viewBox="0 0 10.474 12.117" class="gradient-play">
    <defs>
      <radialGradient
        id="radialGradient5860"
        ref="gradient1"
        cx="-40.699"
        cy="34.468"
        r="6.7097"
        gradientTransform="matrix(1.8791 0 0 2.1158 34.994 -68.002)"
        gradientUnits="userSpaceOnUse"
        class="gradient-play__gradient"
      >
        <!-- <animate
          attributeName="r"
          values="4.5;8;4.5"
          dur="6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="cy"
          values="36;32;36"
          dur="6s"
          repeatCount="indefinite"
        /> -->
        <stop stop-color="#3cd4b9" stop-opacity="0.8" offset="0" />
        <stop stop-color="#3cd4b9" stop-opacity="0" offset="1" />
      </radialGradient>
      <radialGradient
        id="radialGradient5860-8"
        ref="gradient2"
        cx="-24.415"
        cy="25.774"
        r="6.75"
        gradientTransform="matrix(1.2288 0 0 1.3836 4.8029 -40.525)"
        gradientUnits="userSpaceOnUse"
        class="gradient-play__gradient"
      >
        <!-- <animate
          attributeName="r"
          values="6.75;11;6.75"
          dur="6s"
          begin="2.5s"
          repeatCount="indefinite"
        /> -->
        <stop stop-color="#fbc903" stop-opacity="0.6" offset="0" />
        <stop stop-color="#fbc903" stop-opacity="0" offset="1" />
      </radialGradient>
    </defs>
    <g transform="translate(-79.419 -70.613)">
      <g transform="matrix(.53934 0 0 .55414 102.35 79.308)">
        <path
          d="m-42.52-14.109v18.646c-5.83e-4 1.2593 1.363 2.0464 2.4531 1.416l8.0742-4.6621 8.0742-4.6602c1.0904-0.62914 1.0904-2.2029 0-2.832l-16.148-9.3242c-1.2061-0.52404-2.4296 0.26129-2.4531 1.416z"
          fill="#28e231"
          stroke-linejoin="round"
        />
        <path
          d="m-42.52-14.109v18.646c-5.82e-4 1.2593 1.363 2.0464 2.4531 1.416l8.0742-4.6621 8.0742-4.6602c1.0904-0.62914 1.0904-2.2029 0-2.832l-16.148-9.3242c-1.2061-0.52404-2.4296 0.26129-2.4531 1.416z"
          fill="url(#radialGradient5860-8)"
        />
        <path
          d="m-42.52-14.109v18.646c-5.83e-4 1.2593 1.363 2.0464 2.4531 1.416l8.0742-4.6621 8.0742-4.6602c1.0904-0.62914 1.0904-2.2029 0-2.832l-16.148-9.3242c-1.2061-0.52404-2.4296 0.26129-2.4531 1.416z"
          fill="url(#radialGradient5860)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
import { map } from "@/scripts/helpers.js";

export default {
  methods: {
    // run animations in js
    // svg animations can break entirely in older ios (evil)
    animate() {
      const time = Math.sin(((performance.now() % 6000) / 6000) * Math.PI);
      this.$refs.gradient1.setAttribute("r", map(time, 0, 1, 4.5, 8));
      this.$refs.gradient1.setAttribute("cy", map(time, 0, 1, 36, 32));
      this.$refs.gradient2.setAttribute("r", map(time, 0, 1, 6.75, 11));
      this.raf = requestAnimationFrame(this.animate);
    },
  },
  mounted() {
    this.raf = requestAnimationFrame(this.animate);
  },
  beforeUnmount() {
    cancelAnimationFrame(this.raf);
  },
};
</script>
<style lang="scss">
.gradient-play {
  transform: translateZ(0.01px);
  isolation: isolate;

  &__gradient {
    color-interpolation: sRGB;
    color-interpolation-filters: sRGB;
  }
}
</style>
