// PREVENT FOCUS SCROLL

// when focusing elements, browsers will attempt to scroll the element into view
// this causes glitches if the element is offscreen, even when animating transforms

// force the root and body back to (0, 0) on focus

const html = document.documentElement;
const body = document.body;

function resetScroll() {
  html.scrollLeft = 0;
  html.scrollTop = 0;
  body.scrollLeft = 0;
  body.scrollTop = 0;
}

function onFocus() {
  requestAnimationFrame(resetScroll);
}

html.addEventListener("focusin", onFocus);
