import { API } from "@aws-amplify/api";
import * as Connection from "@/scripts/connection.js";
import UserData from "@/scripts/userData.js";

const API_NAME = "AppAPI";
const API_PATH = "/app/user";

const RETRY_RATE = 30000;
let timeout;
let raf;

// STATE

let started = false;
let waiting = false;
let pending = false;
let running = false;

// CONNECTION
// run pending when back online

function setOnline() {
  if (started && pending && !running && !waiting) {
    createPromise();
    runOnFrame();
  }
}

Connection.addOnlineEvent(setOnline);

// PROMISE
// create when sync is run
// resolve when complete or failed

// multiple api calls can be chained
// create a separate promise
// resolve manually

let promiseController = null;
let promise = Promise.resolve();

class PromiseController {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

function createPromise() {
  console.log("UserApi.createPromise()", !!promiseController);

  if (!promiseController) {
    promiseController = new PromiseController();
    promise = promiseController.promise;
  }

  return promise;
}

function resolvePromise() {
  console.log("UserApi.resolvePromise()", !!promiseController);

  if (promiseController) {
    console.log("resolve api promise");

    // resolve after clearing controller reference
    const prev = promiseController;
    promiseController = null;
    prev.resolve();
  }
}

// DELAY POST

// delay until frame (debounce)
function runOnFrame() {
  console.log("UserApi.runOnFrame()");

  cancelTimers();

  waiting = true;
  pending = true;

  raf = requestAnimationFrame(run);
}

// delay for 30s
function runOnTimer() {
  console.log("UserApi.runOnTimer()");

  cancelTimers();

  waiting = true;
  pending = true;

  timeout = setTimeout(runOnFrame, RETRY_RATE);
}

function run() {
  waiting = false;

  if (started && Connection.isOnline) {
    if (!running) {
      post(); // send data to server
    }
  } else {
    resolvePromise(); // resolve early - aborted or offline
  }
}

function post() {
  pending = false;
  running = true;

  API.post(API_NAME, API_PATH, {
    body: {
      userData: UserData.getPostData(),
    },
  }).then(onSuccess, onError);
}

function onSuccess(remoteData) {
  console.log("UserApi.onSuccess()");

  running = false;

  if (started) {
    if (pending) {
      runOnFrame(); // app data is ahead of remote data - run again
    } else {
      UserData.pullData(remoteData);
      resolvePromise(); // successful sync
    }
  } else {
    resolvePromise(); // resolve early - aborted
  }
}

function onError(error) {
  console.log("UserApi.onError()", error);

  running = false;

  if (started) {
    if (pending || !Connection.isOnline) {
      runOnFrame(); // app data changed - run again instantly
    } else {
      runOnTimer(); // wait before retry
    }
  } else {
    resolvePromise(); // resolve early - aborted
  }
}

function cancelTimers() {
  clearTimeout(timeout);
  cancelAnimationFrame(raf);
  waiting = false;
}

// INTERFACE

const UserApi = {
  start() {
    started = true;
  },

  async sync() {
    console.log("USER API SYNC");

    createPromise();
    runOnFrame();

    await promise;
  },

  async stop() {
    cancelTimers();

    started = false;
    pending = false;

    await promise;
  },

  async clear() {
    try {
      await API.post(API_NAME, API_PATH, { body: { clear: true } });
    } catch (error) {
      console.error("can't clear remote data", error);
    }
  },
};

export default UserApi;
