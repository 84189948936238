<template>
  <Page class="default-page-anim">
    <SAM :data="comment" class="page-content-anim page-content-margin" />
    <Dialogue :options="options" @choice="choice" />
  </Page>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";

import { data } from "@/scripts/data.js";
import { parseCount, getMessageCount } from "@/scripts/insert.js";
import * as AudioManager from "@/scripts/audioManager.js";

export default {
  emits: ["toInbox", "toKeyConcepts"],
  components: {
    Page,
    SAM,
    Dialogue,
  },
  data() {
    return {
      comment: parseCount(
        getMessageCount() === 1
          ? data.return.return_fork.content_single
          : data.return.return_fork.content_multiple
      ),
      options: [
        data.return.return_fork.response_yes,
        data.return.return_fork.response_no,
      ],
    };
  },
  methods: {
    newMessageSound() {
      AudioManager.play("ui-new-message");
    },
    choice(index) {
      this.$emit(index === 0 ? "toInbox" : "toKeyConcepts");
    },
  },
  mounted() {
    this.$root.setHeaderDisplay(false);
    this.$root.iconShow();
    this.timeout = setTimeout(this.newMessageSound, 1250);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
};
</script>
