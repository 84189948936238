<template>
  <svg
    viewBox="0 0 100 100"
    class="header__progress header__progress--pie"
    stroke="#FFF"
    fill="none"
  >
    <circle pathLength="100" r="46" cx="50" cy="50" stroke-width="8" />
    <circle
      ref="pie"
      pathLength="100"
      r="50"
      cx="50"
      cy="50"
      class="header__progress__pie"
    />
  </svg>
</template>
<script>
import { lerp, easeInOutSine } from "@/scripts/helpers.js";

const ANIM_TIME = 500;

export default {
  props: {
    // 0 - 1
    progress: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    progress() {
      this.bindIn();
    },
  },
  methods: {
    bindIn() {
      this.oldProgress = this.animProgress;
      this.newProgress = this.progress;
      this.startTime = performance.now();
    },
    animate(t) {
      this.diff = t - this.startTime;

      if (this.diff < ANIM_TIME) {
        this.animProgress = lerp(
          this.oldProgress,
          this.newProgress,
          easeInOutSine(this.diff / ANIM_TIME)
        );
      } else {
        this.animProgress = this.newProgress;
      }

      this.style.strokeDasharray = `${this.animProgress * 101}px 100px`;

      if (this.animProgress < 0.01) {
        this.style.opacity = "0";
      } else {
        this.style.opacity = "1";
      }

      this.raf = requestAnimationFrame(this.animate);
    },
  },
  created() {
    this.animProgress = this.progress;
  },
  mounted() {
    this.style = this.$refs.pie.style;
    this.bindIn();
    this.animate(performance.now());
  },
  beforeUnmount() {
    cancelAnimationFrame(this.raf);
  },
};
</script>
