<template>
  <div class="intro default-page-out">
    <IntroShapes
      ref="introShapes"
      @colortrans="toggleColorTrans"
      @colortransend="endColorTrans"
      aria-hidden="true"
    />

    <Transition appear>
      <ScrollPage
        v-if="currentPage === 0"
        :key="`welcome-${welcomeIndex}`"
        @next="nextWelcome"
        class="default-page-anim"
      >
        <SAM
          v-if="data.bootup.welcome_messages[welcomeIndex]"
          :data="data.bootup.welcome_messages[welcomeIndex].message"
          :key="`welcome-sam-${welcomeIndex}`"
          class="page-content-anim page-content-margin"
          gradient
        />
      </ScrollPage>

      <Page
        ref="musicGenre"
        v-else-if="currentPage === 1"
        class="default-page-anim intro__music"
      >
        <SAM
          :data="data.bootup.music_genre.question"
          class="page-content-margin intro__music__text"
          :class="{ 'intro__music__text--hide': musicGenreTextWhite }"
          gradient
        />
        <MusicEmojis
          :point="musicGenreSplashPoint"
          :show="musicGenreSplashShow"
          :delay="0"
          class="intro__music__splash"
        />
        <Dialogue
          gradient
          :options="musicGenreLabels"
          @dialogueclick="musicChoice"
        />
      </Page>

      <Page v-else-if="currentPage === 2" class="default-page-anim">
        <SAM
          class="page-content-anim page-content-margin"
          :data="data.bootup.nickname.question"
          gradient
        />
        <TextInput
          class="page-content-margin"
          :inputLabel="data.bootup.nickname.input_label"
          :submitLabel="data.bootup.nickname.submit_label"
          autocomplete="name"
          maxlength="40"
          trim
          @next="nameSubmit"
        />
      </Page>

      <Page
        v-else-if="currentPage === 3"
        :key="`pretrans-${preTransitionIndex}`"
        class="intro-pretrans-anim"
      >
        <SAM
          class="page-content-margin intro__sam"
          :class="{
            'intro__sam--hide': !colorTransText,
          }"
          :data="data.bootup.screens[preTransitionIndex].message"
          gradient
        />
      </Page>

      <!-- transiton to colour -->

      <Page
        v-else-if="currentPage === 4"
        :key="`hello-${helloIntroIndex}`"
        class="intro-color-sam-anim"
      >
        <HelloUser
          ref="helloContent"
          :data="data.intro.hello[helloIntroIndex].message"
          class="page-content-anim"
        />
      </Page>

      <ScrollPage
        v-else-if="currentPage === 5"
        :key="`teach-${teachIntroIndex}`"
        @next="nextTeach"
        :class="
          teachIntroIndex === 0
            ? 'intro-color-sam-in default-page-out'
            : 'default-page-anim'
        "
      >
        <SAM
          :data="data.intro.teach[teachIntroIndex].message"
          ref="teachContent"
          class="page-content-margin"
          :class="
            teachIntroIndex === 0 ? 'intro-color-content' : 'page-content-anim'
          "
        />
      </ScrollPage>
    </Transition>
  </div>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import TextInput from "@/components/content/TextInput.vue";
import IntroShapes from "@/components/sections/intro/IntroShapes.vue";
import MusicEmojis from "@/components/content/MusicEmojis.vue";
import HelloUser from "@/components/content/HelloUser.vue";

import * as Background from "@/scripts/background.js";
import UserData from "@/scripts/userData.js";
import { data } from "@/scripts/data.js";
import * as Analytics from "@/scripts/analytics.js";
import * as AudioManager from "@/scripts/audioManager.js";
import musicGenres from "@/data/musicGenres.json";
import {
  smoothScrollTo,
  recursiveOffsetLeft,
  recursiveOffsetTop,
} from "@/scripts/helpers.js";
import { windowHeight } from "@/scripts/windowSize.js";

export default {
  components: {
    Page,
    ScrollPage,
    SAM,
    Dialogue,
    TextInput,
    IntroShapes,
    MusicEmojis,
    HelloUser,
  },
  emits: ["fin"],
  data() {
    return {
      data: data,
      welcomeIndex: 0,
      preTransitionIndex: 0,
      helloIntroIndex: 0,
      teachIntroIndex: 0,

      currentPage: 0,
      musicGenreLabels: Object.values(musicGenres).map(
        (musicGenre) => data.bootup.music_genre.options[musicGenre.labelKey]
      ),

      name: "",

      musicGenreSplashShow: false,
      musicGenreTextWhite: false,
      musicGenreSplashPoint: {
        x: 0,
        y: 0,
      },
      // based on gradient.scss
      musicGenreGradientPattern: [
        [
          "#ff5c2b",
          "#fbc903",
          "#ff5c2b",
          "#ff5c2b",
          "#fbc903",
          "#ff5c2b",
          "#ff5c2b",
          "#fbc903",
        ],
        [
          "#ff5c2b",
          "#b901d7",
          "#ff5c2b",
          "#b901d7",
          "#ff5c2b",
          "#b901d7",
          "#ff5c2b",
          "#b901d7",
        ],
        [
          "#318ef3",
          "#fbc903",
          "#28e231",
          "#318ef3",
          "#fbc903",
          "#28e231",
          "#318ef3",
          "#fbc903",
        ],
        [
          "#fbc903",
          "#28e231",
          "#fa2065",
          "#fbc903",
          "#28e231",
          "#fa2065",
          "#fbc903",
          "#28e231",
        ],
        [
          "#3cd4b9",
          "#fa2065",
          "#318ef3",
          "#3cd4b9",
          "#fa2065",
          "#318ef3",
          "#3cd4b9",
          "#fa2065",
        ],
      ],

      splashShow: false,
      colorTrans: false,
      colorTransText: true,
    };
  },
  methods: {
    next() {
      this.currentPage++;
      this.$root.shapeNext();
    },

    // WELCOME

    nextWelcome() {
      this.$refs.introShapes.startAnim("splash");
      this.$refs.introShapes.doSplash();

      if (this.welcomeIndex < this.data.bootup.welcome_messages.length - 1) {
        this.welcomeIndex++;
      } else {
        this.next();
      }
    },

    // MUSIC

    musicChoice(e) {
      this.musicGenreTextWhite = true;
      this.musicColor = this.musicGenreGradientPattern[e.chosen % 5];
      const scrollParent = this.$refs.musicGenre;
      const scrollAmt =
        e.target.offsetTop -
        (scrollParent.$el.offsetTop +
          scrollParent.scrollTop +
          windowHeight / 2);

      smoothScrollTo(scrollParent.$el, scrollParent.scrollTop + scrollAmt);

      UserData.setMusic(Object.keys(musicGenres)[e.chosen]);

      this.timeout = setTimeout(this.musicChoice2, 250);
    },
    musicChoice2() {
      Background.setColors(this.musicColor);
      this.timeout = setTimeout(this.musicChoice3, 500);
    },
    musicChoice3() {
      // get element for splash pivot point
      const el = document.querySelector(".dialogue__button--chosen");
      this.musicGenreSplashShow = true;
      this.musicGenreSplashPoint.x = recursiveOffsetLeft(el);
      this.musicGenreSplashPoint.y = recursiveOffsetTop(el);
      this.timeout = setTimeout(this.musicChoice4, 2250);
    },
    musicChoice4() {
      Background.fadeToBlack();
      this.timeout = setTimeout(this.next, 1250);
    },

    // NAME

    nameSubmit(name) {
      this.next();
      UserData.setNickname(name);
      this.timeout = setTimeout(this.nextPreTrans, 4000);
    },

    // TRANSITION

    nextPreTrans() {
      this.preTransitionIndex++;

      if (this.preTransitionIndex === this.data.bootup.screens.length - 1) {
        this.timeout = setTimeout(this.initialiseAnimation, 500);
      }
    },
    initialiseAnimation() {
      this.$refs.introShapes.startAnim("expand");
      AudioManager.play("intro-initiate");
      this.timeout = setTimeout(this.initialiseAnimation2, 3750);
    },
    initialiseAnimation2() {
      this.$refs.introShapes.setExpandOriginTarget(0, 0);
      this.colorTransText = false;
      this.timeout = setTimeout(this.$refs.introShapes.doExpand, 1000);
    },

    // HELLO

    startHello() {
      this.next();
      Analytics.trackProgress("app_initiated");
      AudioManager.play("intro-hello");
      this.$root.shapeTarget(document.body);
      this.$root.shapeState("pagecircle");
      this.$root.shapeIn();
      this.shapeDelay = requestAnimationFrame(this.helloShape);
      this.timeout = setTimeout(this.nextHello, 5500);
    },
    helloShape() {
      if (this.$refs.helloContent) {
        this.$root.shapeTarget(this.$refs.helloContent.$el);
      }
    },
    nextHello() {
      if (this.helloIntroIndex < this.data.intro.hello.length - 1) {
        this.timeout = setTimeout(this.nextHello, 5000);
      }

      if (this.helloIntroIndex <= this.data.intro.hello.length - 1) {
        this.helloIntroIndex++;
        this.shapeDelay = requestAnimationFrame(this.helloShape);
      }

      if (this.helloIntroIndex > this.data.intro.hello.length - 1) {
        this.endHello();
      }

      if (this.helloIntroIndex === 1) {
        this.$root.shapeNext({ isSam: true });
      } else {
        this.$root.shapeNext();
      }
    },
    endHello() {
      this.next();
      this.shapeDelay = requestAnimationFrame(this.shapeTeach);
    },

    // TEACH

    shapeTeach() {
      if (this.$refs.teachContent) {
        this.$root.shapeTarget(this.$refs.teachContent.$el.nextElementSibling);
      }
    },
    nextTeach() {
      if (this.teachIntroIndex === this.data.intro.teach.length - 1) {
        this.$root.shapeOut();
        this.$emit("fin");
      } else {
        this.$root.shapeNext();
        this.teachIntroIndex++;
        Background.transitionDown();
      }
    },

    toggleColorTrans() {
      this.colorTrans = true;
    },
    endColorTrans() {
      this.startHello();
    },
  },
  mounted() {
    Analytics.trackProgress("bootup");
    AudioManager.loadIntroFiles();
    AudioManager.loadGenreFiles();
    UserData.setStartTime(Date.now());
    this.$root.setHeaderDisplay(false);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.initialiseAnimationTimeout);
    cancelAnimationFrame(this.shapeDelay);
  },
};
</script>

<style lang="scss">
@keyframes intro-color-content-in {
  0% {
    opacity: 0;
    filter: blur(10px);
    transform: translate3d(0, 0, 10vh);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
}

.intro-splash-anim {
  opacity: 1;
  filter: blur(0px);
  transform: translate3d(0, 0, 0);

  transition-property: opacity, filter;
  transition-duration: 0.5s;
  transition-timing-function: $ease-out-cubic;

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
    filter: blur($blur);
  }
}

.intro-pretrans-anim {
  transition-property: opacity, filter, transform;
  transition-duration: 0.75s, 0.5s, 1s;
  transition-timing-function: ease, $blur-curve, ease;

  &.v-enter-active {
    transition-delay: 0.25s;
  }

  &.v-enter-from {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 2rem, -$depth);
  }

  &.v-leave-to {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, $depth * 0.5);
  }
}

.intro-color-sam-in {
  &.v-enter-active {
    transition-property: opacity, filter, transform;
    transition-duration: 1.75s, 1s, 1.75s;
    transition-timing-function: $ease-in-out-cubic, $blur-curve, $ease-out-cubic;
  }

  &.v-enter-from {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, -$depth);
  }
}

.intro-color-sam-anim {
  transition-property: opacity, filter, transform;
  transition-duration: 1.75s, 1s, 1.75s;
  transition-timing-function: $ease-in-out-cubic, $blur-curve,
    $ease-in-out-cubic;

  &.v-enter-from {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, -$depth);
  }

  &.v-leave-to {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, $depth * 0.5);
  }
}

.intro-color-content {
  animation: intro-color-content-in 1.5s ease 0.5s 1 both;
}

.intro {
  position: absolute;
  width: 100%;
  height: 100%;

  &__sam {
    &--hide {
      transition-property: opacity, filter;
      transition-timing-function: $ease-out-cubic;
      opacity: 0;
      filter: blur(20px);
      transition-duration: 2s;
    }
  }

  &__music {
    &__splash {
      z-index: 1;
    }

    &__text {
      margin-top: 30vh;
      transition: opacity 0.25s;

      &--hide {
        opacity: 0;
      }
    }
  }
}
</style>
