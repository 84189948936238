<template>
  <Page>
    <div
      class="emote-trans"
      :class="{
        'emote-trans--in': transState === 1,
        'emote-trans--out': transState === 2,
      }"
    >
      <div class="emote-trans__wrap">
        <div
          v-for="(item, i) in emoteSet"
          :key="`emoji-${i}`"
          class="emote-trans__emoji"
          :class="`emote-trans__emoji--${i + 1}`"
          :style="`width: ${item.size}rem`"
        >
          <div class="emote-trans__emoji__wrap">
            <Emoji :name="item.name" class="emote-trans__emoji__img" />
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import Emoji from "@/components/content/Emoji.vue";

import sentiments from "@/data/sentiments.json";
import * as AudioManager from "@/scripts/audioManager.js";

const SCREEN_DURATION = 500;
const SCREEN_DELAY = 1000;
const TRANS_IN_OUT_TIME = 1250;

export default {
  components: {
    Page,
    Emoji,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["next"],
  data() {
    return {
      transState: 0,
      emoteSet: sentiments[this.data.emote],
    };
  },
  methods: {
    startScreen() {
      AudioManager.play("chapter-story-score");
      this.transState = 1;
      this.timeout = setTimeout(
        this.endScreen,
        SCREEN_DURATION + TRANS_IN_OUT_TIME
      );
    },
    endScreen() {
      this.transState = 2;
      this.timeout = setTimeout(this.onEnded, TRANS_IN_OUT_TIME);
    },
    onEnded() {
      this.$emit("next");
    },
  },
  mounted() {
    this.timeout = setTimeout(this.startScreen, SCREEN_DELAY);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss">
$screen-duration: 500ms;
$screen-delay: 1000ms;
$trans-in-out-time: 1250ms;

.emote-trans {
  display: flex;
  visibility: hidden;

  @keyframes emote-trans-passive {
    0% {
      transform: translateX(2rem);
    }

    100% {
      transform: translateX(-2rem);
    }
  }

  animation: emote-trans-passive #{2 * $trans-in-out-time + $screen-duration} ease
    $screen-delay 1;

  &--in {
    justify-content: flex-end;
    visibility: visible;

    .emote-trans__wrap {
      animation: emote-trans-expand-in $trans-in-out-time $ease-out-cubic 1 both;
    }

    .emote-trans__emoji {
      transform-origin: top left;
      @include scale-in-bounce(
        $name: emote-trans-in,
        $dur: 1s,
        $delay: 0s,
        $transforms: translate(-50%, -50%)
      );

      &--1 {
        animation-delay: 0.15s;
      }

      &--2 {
        animation-delay: 0.3s;
      }
    }
  }

  &--out {
    justify-content: flex-start;
    visibility: visible;

    .emote-trans__wrap {
      animation: emote-trans-expand-out $trans-in-out-time $ease-in-cubic 1 both;
    }

    .emote-trans__emoji {
      transform-origin: top left;
      @include scale-out(
        $name: emote-trans-out,
        $dur: 0.25s,
        $delay: $trans-in-out-time - 0.25s,
        $transforms: translate(-50%, -50%)
      );

      &--2 {
        animation-delay: $trans-in-out-time - 0.25s - 0.35s;
      }

      &--3 {
        animation-delay: $trans-in-out-time - 0.25s - 0.15s;
      }
    }
  }

  &__wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;

    @keyframes emote-trans-expand-in {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }
    }

    @keyframes emote-trans-expand-out {
      0% {
        width: 100%;
      }

      100% {
        width: 0;
      }
    }
  }

  &__emoji {
    position: absolute;
    height: fit-content;
    transform: translate(-50%, -50%);

    &--1 {
      top: 25%;
      left: 30%;

      & > .emote-trans__emoji__wrap {
        @include float-anim($name: emote-trans-float-1, $amt: 0.5rem);

        & > .emote-trans__emoji__img {
          @include float-rotate-anim(
            $name: emote-page-rotate-1,
            $amt: 3deg,
            $offset: -7deg,
            $dur: 10s
          );
        }
      }
    }

    &--2 {
      top: 55%;
      left: 70%;
      z-index: 1;

      & > .emote-trans__emoji__wrap {
        @include float-anim(
          $name: emote-trans-float-2,
          $amt: 0.7rem,
          $dur: 7s,
          $delay: -2.5s
        );

        & > .emote-trans__emoji__img {
          @include float-rotate-anim(
            $name: emote-page-rotate-2,
            $amt: -2deg,
            $offset: 10deg,
            $dur: 12s
          );
        }
      }
    }

    &--3 {
      top: 85%;
      left: 40%;

      & > .emote-trans__emoji__wrap {
        @include float-anim(
          $name: emote-trans-float-3,
          $amt: 0.3rem,
          $dur: 6s,
          $delay: -4s
        );

        & > .emote-trans__emoji__img {
          @include float-rotate-anim(
            $name: emote-page-rotate-3,
            $amt: -4deg,
            $offset: -10deg,
            $dur: 9s
          );
        }
      }
    }

    &__wrap {
      width: 100%;
      height: fit-content;
    }

    &__img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
