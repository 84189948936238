<template>
  <Transition>
    <!-- before -->
    <Page v-if="state === 0" class="default-page-anim">
      <SAM
        ref="calcMessage"
        :data="transitionMessage"
        class="page-content-anim page-content-margin"
      />
      <AutoplayAudio id="chapter-searching" />
    </Page>

    <!-- results animation (shapes, commentary) -->
    <ScrollPage
      v-else-if="state === 1"
      class="default-page-out"
      :swipe="showFeedback"
      @next="toAfter"
    >
      <div
        class="results__wrap"
        v-for="i in 3"
        :class="[
          {
            'results__wrap--solid': i <= shapeCount,
          },
          `results__wrap--${i}`,
        ]"
      >
        <svg
          class="results__shape"
          :class="{ 'results__shape--solid': i <= shapeCount }"
          viewBox="0 0 100 100"
        >
          <polygon
            class="results__background"
            :class="{ 'results__background--solid': i <= shapeCount }"
            fill="#FFF"
            stroke-width="6"
            stroke-linejoin="round"
            stroke="#FFF"
            points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
          />
          <polygon
            class="results__foreground"
            fill="none"
            stroke-width="6"
            stroke-linejoin="round"
            stroke="#FFF"
            points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
          />
        </svg>
      </div>
      <SAM
        :data="feedback"
        class="results__feedback page-content-margin"
        :class="{ 'results__feedback--show': showFeedback }"
      />
    </ScrollPage>

    <!-- activity outro -->
    <ResultsAfter v-else :data="data.activity_outro" @next="next" />
  </Transition>
</template>

<script>
import ResultsAfter from "@/components/sections/chapter/results/ResultsAfter.vue";

import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import AutoplayAudio from "@/components/content/AutoplayAudio.vue";

import { parseEllipsis } from "@/scripts/insert.js";
import * as Background from "@/scripts/background.js";
import * as AudioManager from "@/scripts/audioManager.js";
import SessionData from "@/scripts/sessionData.js";

export default {
  emits: ["next"],
  components: {
    ResultsAfter,
    Page,
    ScrollPage,
    SAM,
    AutoplayAudio,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
  },
  data() {
    const results = this.data.activity_results;
    const type = this.data.activity_type;

    let totalCount;
    let feedback;
    let state;

    // Hotspot activities don't really have a score - only completion
    if (type === "HotspotActivity") {
      feedback = results.completed;
      totalCount = 3;
      state = 1; // skip the "calculating results" screen
    }

    // Decide how many shapes the user gets, and which feedback content they will see
    else {
      const max =
        type === "MultichoiceActivity"
          ? this.data.multichoice_activity.questions.length * 2
          : this.data.swipe_activity.questions.length;

      if (max * 0.8 <= this.score) {
        totalCount = 3;
        feedback = results.good;
      } else if (max * 0.5 <= this.score) {
        totalCount = 2;
        feedback = results.okay;
      } else {
        totalCount = 1;
        feedback = results.bad;
      }

      state = 0;
    }

    return {
      state,
      totalCount,
      shapeCount: 0,
      feedback,
      showFeedback: false,
      transitionMessage: parseEllipsis(
        SessionData.lang.Chapter.activity_result_transition
      ),
    };
  },
  methods: {
    showShape() {
      if (this.shapeCount === this.totalCount) {
        this.showFeedback = true;
      } else {
        this.timeout = setTimeout(this.showShape, 500);
        this.shapeCount++;
      }
    },
    startShapes() {
      this.showShape();
      AudioManager.play("chapter-score");
    },
    toResults() {
      this.$root.shapeOut();
      this.state = 1;
      Background.transitionDown();
      this.timeout = setTimeout(this.startShapes, 1500);
    },
    toAfter() {
      this.state = 2;
      Background.transitionDown();
    },
    next() {
      this.$emit("next");
    },
  },
  mounted() {
    if (this.state === 0) {
      this.$root.setHeaderProgressStyle("none");
      this.$root.shapeState("circle");
      this.$root.shapeTarget(this.$refs.calcMessage.$el.nextElementSibling);
      this.$root.shapeIn();

      this.timeout = setTimeout(this.toResults, 3000);
    } else {
      this.toResults();
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss">
@keyframes appear1 {
  0% {
    transform: translateY(50vh);
    opacity: 0;
  }

  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes appear2 {
  0% {
    transform: translateY(50vh);
    opacity: 0;
  }

  100% {
    transform: rotate(-24deg);
    opacity: 1;
  }
}

@keyframes appear3 {
  0% {
    transform: translateY(50vh);
    opacity: 0;
  }

  100% {
    transform: rotate(-48deg);
    opacity: 1;
  }
}

@keyframes solid {
  0% {
    transform: rotate(0);
  }

  5% {
    transform: rotate(3.6deg) scale(1.05);
  }

  10% {
    transform: rotate(7.2deg);
  }

  100% {
    transform: rotate(72deg);
  }
}

.results {
  &__wrap {
    display: block;
    position: relative;
    width: 30%;
    height: auto;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: radial-gradient(closest-side, #fff, transparent);
      transform: scale(3);
      opacity: 0;
    }

    &--1 {
      // 0deg
      margin: 0 0 0 25%;
      animation: appear1 2s 0.1s $ease-out-quart both;
    }

    &--2 {
      // -24deg
      margin: -7% 18% 0 auto;
      animation: appear2 2s 0.1s $ease-out-cubic both;
    }

    &--3 {
      // -48deg
      margin: -19% 0 0 18%;
      animation: appear3 2s 0.1s $ease-out-quad both;
    }

    &--solid {
      &::before {
        opacity: 0.2;
      }
    }
  }

  &__shape {
    &--solid {
      animation: solid 5s linear infinite;
    }
  }

  &__feedback {
    opacity: 0;
    filter: blur($blur);
    transform: translateY(5vh);

    transition-property: opacity, filter, transform;
    transition-duration: 1s;
    transition-timing-function: $ease-out-cubic;

    @for $i from 2 through 5 {
      &:nth-of-type(#{$i}) {
        transition-delay: 0.2s * ($i - 1);
      }
    }

    &--show {
      opacity: 1;
      filter: blur(0px);
      transform: none;
    }
  }

  &__background {
    opacity: 0;
    transform-origin: center;
    transform: scale(0.666);
    transition: opacity 1s $ease-out-cubic, transform 1s $ease-out-cubic;

    &--solid {
      opacity: 1;
      transform: scale(1);
    }
  }
}
</style>
