<template>
  <div class="page static-page" tabindex="0" ref="page" @focusin="onFocusIn">
    <div class="page__inner container" :class="innerClass" ref="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import * as Background from "@/scripts/background.js";
import { focusElement } from "@/scripts/helpers.js";

export default {
  props: {
    innerClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      scrollTop: 0,
      scrollPrev: 0,
    };
  },
  methods: {
    resize() {
      this.topThreshold = parseFloat(
        this.containerStyle.getPropertyValue("padding-top")
      );
      this.bottomThreshold =
        window.innerHeight -
        parseFloat(this.containerStyle.getPropertyValue("padding-bottom"));
    },
    onScroll() {
      this.scrollTop = this.$refs.page.scrollTop;
      Background.updateScrollY(this.scrollTop - this.scrollPrev);
      this.$root.hideSwipePrompt();
      this.scrollPrev = this.scrollTop;
    },
    onFocusIn() {
      if (document.activeElement !== this.$refs.page) {
        const box = document.activeElement.getBoundingClientRect();

        if (box.bottom > this.bottomThreshold) {
          this.$refs.page.scrollTop += box.bottom - this.bottomThreshold;
        } else if (box.top < this.topThreshold) {
          this.$refs.page.scrollTop += box.top - this.topThreshold;
        }
      }
    },
  },
  mounted() {
    this.containerStyle = window.getComputedStyle(this.$refs.container);

    window.addEventListener("resize", this.resize);
    this.resize();

    // reset
    this.onScroll();
    this.$refs.page.addEventListener("scroll", this.onScroll);
    focusElement(this.$refs.page);
  },
  beforeUnmount() {
    this.$refs.page.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.static-page {
  @include hide-scrollbar;

  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  outline: none;
  scroll-behavior: smooth;
  touch-action: pan-y;
}
</style>
