// WINDOW SIZE

// measure width and height of the app
// run once on startup/resize and then reuse the same value

export let windowWidth;
export let windowHeight;
export let containerWidth;
export let rem;

const bg = document.getElementById("background");
const bodyStyle = window.getComputedStyle(document.body);
function resize() {
  windowWidth = window.innerWidth;
  windowHeight = bg.offsetHeight;
  containerWidth = Math.min(windowWidth * 0.85, 612);
  rem = parseFloat(bodyStyle.getPropertyValue("font-size"));
}

function setup() {
  window.addEventListener("resize", resize);
  resize();
}

window.addEventListener("load", setup);
