<template>
  <ScrollPage @next="next" class="default-page-anim">
    <SAM
      ref="text"
      :data="message"
      class="page-content-anim page-content-margin"
    />
  </ScrollPage>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import { data } from "@/scripts/data.js";

export default {
  components: {
    ScrollPage,
    SAM,
  },
  data() {
    return {
      message: data.account.update.updated,
    };
  },
  emits: ["next"],
  methods: {
    next() {
      this.$emit("next");
    },
    startShapes() {
      this.$root.shapeTarget(this.$refs.text.$el.nextElementSibling);
      this.$root.shapeState("circle");
      this.$root.shapeIn();
    },
  },
  mounted() {
    this.$root.setHeaderDisplay(false);

    this.$root.iconShow();
    this.$nextTick(this.startShapes);
  },
  beforeUnmount() {
    this.$root.shapeOut();
  },
};
</script>
