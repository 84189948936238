<template>
  <figure class="fragment">
    <div class="fragment__message-header">
      <ProfilePicture :image="avatar" />
      <div class="fragment__name">{{ name }}</div>
    </div>
    <div v-for="message in data.messages" class="message">
      <div class="message__body" ref="message">
        <span ref="text" v-html="markdown(parse(message.message))"></span>
      </div>
    </div>
  </figure>
</template>

<script>
import ProfilePicture from "@/components/content/ProfilePicture.vue";

import { parse, markdown } from "@/scripts/insert.js";

export default {
  components: {
    ProfilePicture,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatar: this.data.avatar || null,
      name: parse(this.data.name),
    };
  },
  methods: {
    parse,
    markdown,
    resize() {
      for (let i = 0; i < this.data.messages.length; ++i) {
        const messageStyle = this.$refs.message[i].style;
        messageStyle.removeProperty("width");
        messageStyle.width = `${this.$refs.text[i].offsetWidth + 1}px`;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="scss">
@keyframes message-in {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.02);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes message-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.message {
  @for $i from 2 through 10 {
    &:nth-child(#{$i}) {
      --bubble-delay: calc(var(--delay) + #{($i - 1) * 0.25s});
    }
  }

  position: relative;
  width: fit-content;
  max-width: 100%;
  margin: $fragment-content-margin 0 0 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 0 $fragment-radius $fragment-radius $fragment-radius;
    background: var(--color-2);

    transform-origin: 0 0;
    animation: message-in 0.5s $ease-out-quart both;
    animation-delay: var(--bubble-delay);
  }

  &__body {
    padding: $fragment-inner-padding;
    animation: message-fade 0.5s $ease-out-cubic both;
    animation-delay: calc(var(--bubble-delay) + 0.3s);
  }
}
</style>
