<template>
  <figure class="fragment">
    <div class="fragment__message-header">
      <ProfilePicture :image="avatar" />
      <div class="fragment__name">{{ name }}</div>
    </div>
    <div class="social-text__body">{{ message }}</div>
  </figure>
</template>

<script>
import ProfilePicture from "@/components/content/ProfilePicture.vue";
import * as AudioManager from "@/scripts/audioManager.js";
import SessionData from "@/scripts/sessionData.js";
import { parse } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProfilePicture,
  },
  data() {
    if (this.data.name) {
      return {
        avatar: null,
        name: parse(this.data.name),
        message: parse(this.data.message),
      };
    } else {
      const { avatar, name } = SessionData.character;
      return {
        avatar,
        name,
        message: parse(this.data.message),
      };
    }
  },
  methods: {
    playSound() {
      AudioManager.play("chapter-message-notification");
    },
  },
  mounted() {
    this.timeout = setTimeout(this.playSound, 250);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss">
.social-text__body {
  font-size: 1.625rem;
  font-weight: $bold;
}
</style>
