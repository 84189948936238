<template>
  <ul class="dialogue">
    <li ref="item" v-for="(label, index) in labels" class="dialogue__item">
      <button
        type="button"
        ref="button"
        class="dialogue__button"
        :class="[gradientClass(index), chosenClass(index)]"
        @click="choice(index)"
      >
        <span ref="label">{{ label }}</span>
      </button>
    </li>
  </ul>
</template>

<script>
import { parse } from "@/scripts/insert.js";
import * as AudioManager from "@/scripts/audioManager.js";

const labels = [];

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    labels.length = this.options.length;
    for (let i = 0; i < this.options.length; ++i) {
      labels[i] = parse(this.options[i]);
    }

    return {
      hasChosen: false,
      chosen: 0,
      labels,
    };
  },
  emits: ["dialogueclick", "choice"],
  methods: {
    gradientClass(index) {
      if (this.gradient) {
        return `dialogue__button--gradient dialogue__button--gradient--${
          (index % 5) + 1
        }`;
      } else {
        return "dialogue__button--plain";
      }
    },
    chosenClass(index) {
      if (this.hasChosen) {
        if (this.chosen === index) {
          return "dialogue__button--chosen";
        } else {
          return "dialogue__button--destroy";
        }
      }
    },
    choice(index) {
      if (this.hasChosen) return;
      this.hasChosen = true;
      this.chosen = index;
      this.$emit("dialogueclick", {
        chosen: this.chosen,
        target: this.$refs.item[index],
      });
      AudioManager.play("ui-input-button-select");
      this.timeout = setTimeout(this.fin, 750);
    },
    fin() {
      this.$emit("choice", this.chosen);
    },
    resize() {
      for (let i = 0; i < this.options.length; ++i) {
        const buttonStyle = this.$refs.button[i].style;
        buttonStyle.removeProperty("width");
        buttonStyle.width = `${this.$refs.label[i].offsetWidth + 1}px`;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/generate-gradient.scss";

@keyframes dialogue-cover {
  0% {
    color: #fff;
    background-color: #000;
  }
  100% {
    color: #000;
    background-color: #fff;
  }
}

@keyframes dialogue-press {
  0% {
    transform: scale(1);
  }
  7% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes dialogue-destroy {
  0% {
    transform: translate3d(0, 0, 0);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, -3rem);
    filter: blur(10px);
    opacity: 0;
  }
}

@keyframes dialogue-destroy-reduced-motion {
  0% {
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    filter: blur(10px);
    opacity: 0;
  }
}

@keyframes dialogue-show {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dialogue-show-reduced-motion {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dialogue {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0 0;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      --delay: calc(var(--page-delay) + #{$i * 0.25s + 0.5s});
    }
  }
}

.dialogue__item {
  transform-origin: bottom right;
  animation: dialogue-show 0.5s $ease-out-cubic both;
  @media (prefers-reduced-motion) {
    animation-name: dialogue-show-reduced-motion;
  }

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      animation-delay: calc(var(--delay) + #{($i - 1) * 0.15s});
    }
  }
}

.dialogue__button {
  @include reset-button;

  position: relative;
  width: auto;
  box-sizing: content-box;
  min-width: calc(20% - 3rem);
  max-width: calc(83.333333% - 3rem);
  padding: 1rem 1.5rem;
  margin-block-start: 1rem;
  margin-inline-start: auto;
  border-radius: 1.5rem 1.5rem 0 1.5rem;

  font-size: $dialogue-font-size;
  font-weight: $bold;
  text-align: right;
  line-height: 1.5;
  text-wrap: balance;

  transform-origin: right bottom;

  @media (hover: hover) {
    &:hover,
    &:focus-within,
    &:focus-visible {
      transform: scale(1.05);
    }
  }

  // 1 - ((1 - 0.07) ^ (1 / 4)) = t
  &--chosen {
    pointer-events: none;
    animation-name: dialogue-press, dialogue-cover;
    animation-duration: 1s, 0.982s;
    animation-delay: 0s, 0.018s;
    animation-timing-function: $ease-out-quart, $ease-out-quint;
    animation-fill-mode: both;
  }

  &--destroy {
    pointer-events: none;
    animation: dialogue-destroy 1s $ease-out-quart both;
    @media (prefers-reduced-motion) {
      animation-name: dialogue-destroy-reduced-motion;
    }
  }

  &--gradient {
    background-size: 150% 100%;
    background-position: right center;

    @media (hover: hover) {
      &:hover,
      &:focus-within {
        background-size: 250% 140%;
      }
    }

    $blocks: 5;
    $colors: ($swipesafe-orange $swipesafe-yellow $swipesafe-orange),
      ($swipesafe-orange $swipesafe-magenta),
      ($swipesafe-blue $swipesafe-yellow $swipesafe-green),
      ($swipesafe-yellow $swipesafe-green $swipesafe-red),
      ($swipesafe-aqua $swipesafe-red $swipesafe-blue);
    $rotations: (-80deg -10deg 90deg -100deg -80deg);

    @for $i from 1 through $blocks {
      $color: nth($colors, $i);
      $rotation: nth($rotations, $i);

      &--#{$i} {
        @include generate-gradient($color, $rotation);
      }
    }

    color: #000;
    transition: transform 0.3s ease-out, background-size 0.45s ease;

    &.dialogue__button--chosen {
      background-size: 300% 140%;
      animation: dialogue-press 1s $ease-out-quart both;
    }
  }

  &--plain {
    background: #000;
    color: #fff;
    transition: transform 0.3s ease-out;
  }
}
</style>
