<template>
  <component v-for="block in blocks" :is="block.acf_fc_layout" :data="block" />
</template>

<script>
import CardText from "./card_content/CardText.vue";
import CardImage from "./card_content/CardImage.vue";
import CardList from "./card_content/CardList.vue";
import CardEmojiList from "./card_content/CardEmojiList.vue";
import CardEmojis from "./card_content/CardEmojis.vue";

export default {
  components: {
    text: CardText,
    image: CardImage,
    list: CardList,
    emoji_list: CardEmojiList,
    emojis: CardEmojis,
  },
  props: {
    blocks: {
      type: Object,
      required: true,
    },
  },
};
</script>
