// SESSION DATA
// misc globals

export default {
  character: {},
  lang: {},
  isFacilitated: false,
  hasProfiles: false,

  setCharacter(value) {
    this.character = value;
  },

  setLang(value) {
    this.lang = value;
  },

  setIsFacilitated(value) {
    this.isFacilitated = value;
  },

  setHasProfiles(value) {
    this.hasProfiles = value;
  },
};
