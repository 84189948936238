<template>
  <Transition>
    <ScrollPage :key="currentPage" class="default-page-anim" @next="next">
      <SAM :data="page.content" class="page-content-anim page-content-margin" />
      <SafetyPlan
        v-if="page.show_safety_plan"
        class="page-content-anim page-content-margin"
      />
    </ScrollPage>
  </Transition>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import SafetyPlan from "@/components/content/SafetyPlan.vue";

import * as Background from "@/scripts/background.js";

export default {
  emits: ["next"],
  components: {
    ScrollPage,
    SAM,
    SafetyPlan,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 0,
      lastPage: this.data.length - 1,
      page: this.data[0],
    };
  },
  methods: {
    next() {
      if (this.currentPage === this.lastPage) {
        this.$emit("next");
      } else {
        this.page = this.data[++this.currentPage];
        Background.transitionDown();
      }
    },
  },
};
</script>
