<template>
  <figure class="safety-plan info info--black">
    <h2 class="safety-plan__heading">{{ title }}</h2>
    <ul class="safety-plan__list">
      <li v-for="item in items" class="safety-plan__item">
        <div class="safety-plan__emoji" aria-hidden="true">
          {{ item.emoji }}
        </div>
        <div class="safety-plan__label">{{ item.full }}</div>
      </li>
    </ul>
  </figure>
</template>

<script>
import { data } from "@/scripts/data.js";
import UserData from "@/scripts/userData.js";

export default {
  data() {
    return {
      items: UserData.safetyPlan,
      title: data.create_safety_plan.safety_plan_title,
    };
  },
};
</script>

<style lang="scss">
.safety-plan {
  margin: 0;

  &__heading {
    margin: 0 0 1.5rem;
    font-weight: $bold;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 1rem 0 0;
  }

  &__emoji {
    flex: 0 0 2.5rem;
    width: 2.5rem;
    user-select: none;
  }

  &__label {
    flex: 1 1 auto;
  }
}
</style>
