<template>
  <Transition>
    <Login
      v-if="state === 'login'"
      :guestId="guestId"
      @next="afterLogin"
      @toLanguage="toLanguage"
      @startGuestMode="startGuestMode"
    />
    <Language
      v-else-if="state === 'language'"
      @next="changeLanguage"
      @toLogin="toLogin"
    />
  </Transition>
</template>

<script>
import Login from "@/components/sections/login/Login.vue";
import Language from "@/components/sections/login/Language.vue";
import SessionData from "@/scripts/sessionData.js";

export default {
  emits: ["afterLogin", "startGuestMode", "changeLanguage"],
  components: {
    Login,
    Language,
  },
  props: {
    guestId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      state: "login",
    };
  },
  methods: {
    toLogin() {
      this.state = "login";
    },
    toLanguage() {
      this.state = "language";
    },

    afterLogin(cognitoUser) {
      this.$emit("afterLogin", cognitoUser);
    },
    startGuestMode(groupId) {
      this.$emit("startGuestMode", groupId);
    },
    changeLanguage(newLanguage) {
      // if the language is the same, return to login screen
      if (newLanguage.slug === SessionData.lang.slug) {
        this.toLogin();
      }

      // broadcast out
      else {
        this.$emit("changeLanguage", newLanguage);
      }
    },
  },
};
</script>
