<template>
  <Browser :url="data.url">
    <div class="search">
      <div v-if="hasQuery" class="search__query">
        <svg class="search__icon" viewBox="0 0 21 23">
          <path
            d="M2.4244 2.60025C5.65689 -0.86675 10.8983 -0.86675 14.1309 2.60025C16.9941 5.67115 17.3173 10.4012 15.1237 13.8683L20.5498 19.688C21.1501 20.3319 21.1501 21.3969 20.5498 22.0407C19.9495 22.6845 18.9565 22.6845 18.3563 22.0407L12.9302 16.2209C9.69772 18.5735 5.28749 18.2268 2.42436 15.1561C-0.808121 11.6891 -0.808121 6.06738 2.42436 2.60029L2.4244 2.60025ZM12.3761 13.2739C14.6388 10.847 14.6388 6.93411 12.3761 4.50715C10.1133 2.08019 6.46511 2.08023 4.20231 4.50715C1.93952 6.93407 1.93956 10.847 4.20231 13.2739C6.46507 15.7009 10.1133 15.6761 12.3761 13.2739Z"
            fill="#000"
          />
        </svg>
        <div class="search__string">{{ displayQuery }}</div>
      </div>
      <ul
        v-if="hasResults"
        class="search__results search__body"
        :class="bodyClass"
      >
        <li v-for="result in results" class="search__result">
          <div class="search__site">{{ result.url }}</div>
          <div class="search__title">{{ result.title }}</div>
          <div :class="result.blurb ? 'search__blurb' : 'search__placeholder'">
            {{ result.blurb }}
          </div>
        </li>
      </ul>
      <div v-else class="search__empty search__body" :class="bodyClass">
        {{ noResultsMessage }}
      </div>
    </div>
  </Browser>
</template>

<script>
import Browser from "@/components/content/Browser.vue";
import SessionData from "@/scripts/sessionData.js";

export default {
  components: {
    Browser,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { show_search_bar, search_terms, has_results, results } = this.data;

    return {
      hasQuery: show_search_bar && search_terms,
      query: search_terms,
      hasResults: has_results && results.length !== 0,
      results,
      noResultsMessage: SessionData.lang.Chapter.no_results_message,

      displayQuery: null,
      len: 0,
      bodyClass: null,
    };
  },
  methods: {
    show() {
      this.bodyClass = "search__body--show";
    },
    type() {
      if (this.len !== this.query.length) {
        this.len++;
        this.displayQuery = this.query.substring(0, this.len);
        this.timeout = setTimeout(this.type, 100 + 100 * Math.random() ** 2);
      } else {
        this.timeout = setTimeout(this.show, 200);
      }
    },
  },
  mounted() {
    this.timeout = setTimeout(this.type, 300);
  },
};
</script>

<style lang="scss">
.search {
  padding: $content-padding;
}

.search__query {
  display: flex;
  padding: 0.75rem 1.25rem 0.75rem 1rem;
  background: #eee;
  border-radius: 1.25rem;
}

.search__icon {
  flex: 0 0 auto;
  display: block;
  height: 1rem;
  width: auto;
  margin: 0 0.875rem 0 0;
}

.search__string {
  line-height: 1rem;
}

.search__body {
  opacity: 0;
  transition: opacity 0.5s ease-out;

  &--show {
    opacity: 1;
  }
}

.search__results {
  list-style-type: none;
  margin: $content-padding 0 0;
  padding: 0;
}

.search__result {
  margin: $fragment-content-margin 0;
}

.search__site {
  font-size: 0.75rem;
  letter-spacing: 0.02em;
}

.search__title {
  margin: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: $bold;
}

.search__placeholder {
  &::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0.75rem;
    background: #eee;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 80%;
    height: 0.75rem;
    background: #eee;
  }
}

.search__empty {
  margin: $fragment-content-margin 0;
}
</style>
