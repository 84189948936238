<template>
  <ScrollPage @next="next" class="return default-page-out">
    <HelloUser ref="text" :data="message" />
  </ScrollPage>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import HelloUser from "@/components/content/HelloUser.vue";
import { trackProgress } from "@/scripts/analytics.js";
import { data } from "@/scripts/data.js";

export default {
  components: {
    ScrollPage,
    HelloUser,
  },
  data() {
    return {
      message: data.return.welcome.content,
    };
  },
  emits: ["next"],
  methods: {
    next() {
      this.$emit("next");
    },
    startShapes() {
      this.$root.shapeTarget(this.$refs.text.$el);
      this.$root.shapeState("circle");
      this.$root.shapeIn();
    },
  },
  mounted() {
    trackProgress("return_to_app");
    this.$root.iconShow();
    this.$root.setHeaderDisplay(false);
    this.$nextTick(this.startShapes);
  },
  beforeUnmount() {
    this.$root.shapeOut();
  },
};
</script>

<style lang="scss">
@keyframes return-enter-anim {
  0% {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 0, $depth);
  }

  100% {
    opacity: 1;
    filter: none;
    transform: translate3d(0, 0, 0);
  }
}

.return {
  animation: return-enter-anim 3s $ease-in-out-cubic;
}
</style>
