<template>
  <Page
    ref="page"
    class="menu"
    :class="{
      'menu--disabled': hasClicked,
    }"
    :tabindex="tabIndex"
  >
    <!-- greeting -->
    <div
      class="page-content-anim"
      :class="{ 'menu__content--remove': hasClicked }"
    >
      <SAM
        v-if="showUpdate"
        :data="greetingUpdate"
        class="page-content-margin"
      />
      <SAM v-else :data="greeting" class="page-content-margin" />
    </div>

    <!-- download update -->
    <button
      v-if="showUpdate"
      type="button"
      class="update-prompt info info--black menu-content-anim menu__content page-content-margin"
      :class="downloadButtonClass"
      @click="downloadClick"
      :disabled="hasClicked"
      :tabindex="tabIndex"
    >
      <span class="update-prompt__flex">
        <span class="update-prompt__label">
          <span class="update-prompt__heading">{{ updateHeading }}</span>
          <span class="update-prompt__body">{{ updateBody }}</span>
        </span>
        <span class="update-prompt__icon" aria-hidden="true">
          <ImageAsset
            image="menu-download-triangle.svg"
            width="25"
            height="20"
            alt=""
            class="update-prompt__triangle"
          />
          <ImageAsset
            image="menu-download-line.svg"
            width="25"
            height="26"
            alt=""
            class="update-prompt__line"
          />
        </span>
      </span>
    </button>

    <!-- safety plan -->
    <SafetyPlan
      class="menu-content-anim"
      :class="[
        showUpdate ? 'card-margin' : 'page-content-margin',
        {
          'menu__content--remove': hasClicked,
        },
      ]"
    />

    <!-- resources link -->
    <a
      :href="resourcesUrl"
      rel="noopener"
      target="_blank"
      class="menu-content-anim card-margin menu__link info info--black"
      :class="{
        'menu__content--remove': hasClicked,
      }"
      :aria-disabled="hasClicked"
      :tabindex="tabIndex"
      @click="resourcesClick"
    >
      <span>{{ resourcesLabel }}</span>
      <ImageAsset
        image="menu-resources.svg"
        alt=""
        width="25"
        height="34"
        class="menu__icon"
        aria-hidden="true"
      />
    </a>

    <!-- key concepts -->
    <div
      class="menu-content-anim card-margin menu__concepts info info--black"
      :class="{
        'menu__content--remove': hasClicked,
      }"
    >
      <button
        type="button"
        class="menu__concepts__button"
        @click="toggleKeyConcepts"
        :aria-expanded="showKeyConcepts"
        :disabled="hasClicked"
        :tabindex="tabIndex"
      >
        <span>{{ keyConceptsTitle }}</span>
        <span class="menu__icon menu__group" aria-hidden="true">
          <ImageAsset
            image="menu-concepts.svg"
            width="25"
            height="34"
            alt=""
            class="menu__group__icon"
            :class="keyConceptIconOpenClass"
          />
          <ImageAsset
            image="menu-cross.svg"
            width="24"
            height="24"
            alt=""
            class="menu__group__icon"
            :class="keyConceptIconCloseClass"
          />
        </span>
      </button>
      <div
        class="menu__concepts__wrapper"
        :class="{
          'menu__concepts__wrapper--visible':
            showKeyConcepts || animateKeyConcepts,
          'menu__concepts__wrapper--animate': animateKeyConcepts,
        }"
        :style="{ height: conceptsHeight }"
        ref="concepts"
      >
        <div ref="inner">
          <ol class="menu__concepts__list">
            <li
              v-for="concept in keyConceptsList"
              :key="concept.key"
              class="menu__concepts__item"
            >
              <div class="menu__concepts__emoji" aria-hidden="true">
                {{ concept.emoji }}
              </div>
              <div v-html="concept.body"></div>
            </li>
          </ol>
          <button
            v-if="isOnline"
            type="button"
            class="menu__link menu__concepts__video"
            @click="playKeyConceptsVideo"
            :disabled="videoDisabled"
            :tabindex="videoTabIndex"
          >
            <span>{{ keyConceptsVideoTitle }}</span>
            <ImageAsset
              image="menu-video.svg"
              width="36"
              height="37"
              alt=""
              class="menu__icon"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>

    <!-- change profile -->
    <button
      v-if="accountHasProfiles"
      type="button"
      class="menu-content-anim card-margin menu__link info info--black"
      :class="profileButtonClass"
      @click="profileClick"
      :disabled="hasClicked"
      :tabindex="tabIndex"
    >
      <span>{{ profilesLabel }}</span>
      <ImageAsset
        image="menu-profile.svg"
        width="35"
        height="35"
        alt=""
        class="menu__icon"
        aria-hidden="true"
      />
    </button>

    <!-- log out -->
    <button
      v-else
      type="button"
      class="menu-content-anim card-margin menu__link info info--black"
      :class="logoutButtonClass"
      @click="logoutClick"
      :disabled="hasClicked"
      :tabindex="tabIndex"
    >
      <span>{{ logoutLabel }}</span>
      <ImageAsset
        image="menu-logout.svg"
        width="35"
        height="35"
        alt=""
        class="menu__icon"
        aria-hidden="true"
      />
    </button>

    <!-- logo -->
    <ChildFund class="menu__logo" :class="logoClass" />

    <Teleport to="body">
      <VideoModal ref="modal" :video="keyConceptsVideoUrl" />
    </Teleport>
  </Page>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import SafetyPlan from "@/components/content/SafetyPlan.vue";
import ImageAsset from "@/components/content/image/ImageAsset.vue";
import VideoModal from "@/components/content/video/VideoModal.vue";
import ChildFund from "@/components/svg/ChildFund.vue";

import * as Data from "@/scripts/data.js";
import * as AudioManager from "@/scripts/audioManager.js";
import { chapterIsComplete } from "@/scripts/helpers.js";
import { trackEvent } from "@/scripts/analytics.js";
import { markdown, parse } from "@/scripts/insert.js";
import * as Connection from "@/scripts/connection.js";
import UserData from "@/scripts/userData.js";
import SessionData from "@/scripts/sessionData.js";

const CLICK_DURATION = 700;

export default {
  components: {
    Page,
    SAM,
    SafetyPlan,
    ImageAsset,
    VideoModal,
    ChildFund,
  },
  props: {
    updateReady: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const {
      data: { account },
    } = Data;

    // extract all the content from the json
    const {
      greetings: { greeting, greeting_pending_update: greetingUpdate },
      key_concepts: {
        title: keyConceptsTitle,
        excerpts,
        video: { title: keyConceptsVideoTitle, url: keyConceptsVideoUrl },
      },
      resources: {
        link: { url: resourcesUrl, title: resourcesLabel },
      },
      update: { heading: updateHeading, body: updateBody },
    } = account;

    // create the list of key concepts
    const keyConceptsList = [];

    for (let i = 0; i < excerpts.length; ++i) {
      const { emoji, excerpt } = excerpts[i];
      keyConceptsList.push({
        key: "" + i,
        emoji,
        body: markdown(parse(excerpt)),
      });
    }

    return {
      // CMS data
      account,

      // SAM greeting message
      greeting,
      greetingUpdate,

      // update
      updateHeading,
      updateBody,

      // ChildFund resources link
      resourcesUrl,
      resourcesLabel,

      // key concepts
      keyConceptsTitle,
      keyConceptsList,
      showKeyConcepts: false,
      animateKeyConcepts: false,
      conceptsHeight: "0px",

      keyConceptsVideoTitle,
      keyConceptsVideoUrl,

      animateOut: false,
      hasClicked: false,
      clicked: null,
      exiting: false,

      isOnline: Connection.isOnline,
      accountHasProfiles: SessionData.hasProfiles,

      // logout
      logoutLabel: SessionData.lang.Menu.log_out_button_label || "Log out",

      // profiles
      profilesLabel:
        SessionData.lang.Menu.profiles_button_label || "Manage profile",
    };
  },
  computed: {
    showUpdate() {
      return this.updateReady && this.isOnline;
    },

    // disable interactive elements
    tabIndex() {
      return this.hasClicked ? "-1" : null;
    },

    // download button

    downloadButtonClass() {
      return this.hasClicked
        ? this.clicked === "download"
          ? "menu__content--chosen"
          : "menu__content--remove"
        : null;
    },

    // login button

    logoutButtonClass() {
      return this.hasClicked
        ? this.clicked === "logout"
          ? "menu__content--chosen"
          : "menu__content--remove"
        : null;
    },

    // profile button

    profileButtonClass() {
      return this.hasClicked
        ? this.clicked === "profile"
          ? "menu__content--chosen"
          : "menu__content--remove"
        : null;
    },

    logoClass() {
      return this.hasClicked ? "menu__logo--remove" : null;
    },

    // key concepts state

    keyConceptIconOpenClass() {
      return this.showKeyConcepts ? null : "menu__group__icon--show";
    },
    keyConceptIconCloseClass() {
      return this.showKeyConcepts ? "menu__group__icon--show" : null;
    },

    videoDisabled() {
      return this.hasClicked || !this.showKeyConcepts || !this.isOnline;
    },
    videoTabIndex() {
      return this.hasClicked || this.videoDisabled ? "-1" : null;
    },
  },
  methods: {
    clickButton(name) {
      AudioManager.play("ui-input-button-select");

      this.hasClicked = true;
      this.clicked = name;

      this.$root.setHeaderDisplay(false);
      this.$root.iconHide();
    },

    // download
    downloadClick() {
      if (this.hasClicked) return;

      this.clickButton("download");
      this.clickTimeout = setTimeout(this.$root.menuUpdate, CLICK_DURATION);
    },

    resourcesClick(e) {
      if (this.hasClicked) {
        e.preventDefault();
      } else {
        AudioManager.play("ui-input-button-select");
      }
    },

    showMenuHeader() {
      let progress = 0;
      const pattern = [];

      // iterate over all chapters
      // tally completed chapters and mark them in array
      for (const group of Data.data.chapters) {
        for (const chapter of group) {
          if (chapterIsComplete(UserData.progress[chapter.post_id])) {
            progress++;
            pattern.push(true);
          } else {
            pattern.push(false);
          }
        }
      }

      // update header state
      this.$root.setHeaderDisplay(true);
      this.$root.setHeaderTitle(
        `${progress} / ${pattern.length} ${
          SessionData.lang.PostTypes.chapters_plural || "Chapters"
        }`
      );
      this.$root.setHeaderProgress(progress, pattern.length, pattern);
      this.$root.setHeaderProgressStyle("dot");
    },

    // KEY CONCEPTS ACCORDION
    animationEnd() {
      this.animateKeyConcepts = false;
    },
    removeKeyConceptsEvents() {
      this.$refs.concepts.removeEventListener(
        "transitionend",
        this.animationEnd
      );
      window.removeEventListener("resize", this.resize);
    },
    resize() {
      this.conceptsHeight = `${this.$refs.inner.offsetHeight}px`;
    },
    toggleKeyConcepts() {
      if (this.hasClicked) return;

      AudioManager.play("ui-input-button-misc");

      this.animateKeyConcepts = true;

      if (this.showKeyConcepts) {
        this.showKeyConcepts = false;
        this.conceptsHeight = "0px";
        this.removeKeyConceptsEvents();
      } else {
        this.showKeyConcepts = true;

        this.$refs.concepts.addEventListener(
          "transitionend",
          this.animationEnd
        );

        window.addEventListener("resize", this.resize);
        this.resize();
      }
    },

    // video
    playKeyConceptsVideo() {
      if (this.videoDisabled) return;

      if (this.$refs.modal) {
        AudioManager.play("ui-input-button-misc");
        this.$refs.modal.show();
      }
    },

    // logout
    logoutClick() {
      if (this.hasClicked) return;

      const promise = this.$root.signout();

      this.clickButton("logout");

      this.clickTimeout = setTimeout(() => {
        this.$root.menuLogout(promise);
      }, CLICK_DURATION);
    },

    // profile
    profileClick() {
      if (this.hasClicked) return;

      UserData.setActiveProfile(null);
      const promise = UserData.sync();

      this.clickButton("profile");

      this.clickTimeout = setTimeout(() => {
        this.$root.menuProfile(promise);
      }, CLICK_DURATION);
    },

    // toggle online state
    onlineEvent() {
      console.log("menu online chage");

      this.isOnline = Connection.isOnline;

      if (this.showKeyConcepts) {
        this.raf = requestAnimationFrame(this.resize);
      }
    },

    markdown,
    parse,
  },
  mounted() {
    trackEvent("open_menu", {
      has_update: this.hasUpdate,
    });
    AudioManager.play("ui-menu-open");
    this.$root.saveHeaderState();
    this.showMenuHeader();
    Connection.addOnlineEvent(this.onlineEvent);
    Connection.addOfflineEvent(this.onlineEvent);
  },
  beforeUnmount() {
    trackEvent("close_menu");

    // restore header on normal menu close
    if (!this.hasClicked) this.$root.restoreHeaderState();

    Connection.removeOnlineEvent(this.onlineEvent);
    Connection.removeOfflineEvent(this.onlineEvent);
    cancelAnimationFrame(this.raf);
    clearTimeout(this.clickTimeout);
    this.removeKeyConceptsEvents();
  },
};
</script>

<style lang="scss">
@keyframes triangle {
  0% {
  }

  50% {
    transform: translateY(0.125rem);
  }

  100% {
  }
}

@keyframes menu-remove {
  0% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0px);
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
    filter: blur(10px);
  }
}

@keyframes menu-chosen {
  0% {
  }

  20% {
    transform: scale(0.95);
  }

  100% {
  }
}

.update-prompt {
  position: relative;
  z-index: 2;

  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  color: inherit;
  font-size: inherit;
  font-family: inherit;
  text-align: inherit;

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    display: block;
    font-size: 1.5rem;
    font-weight: $bold;
    margin: -0.125rem 0 0;
  }

  &__body {
    display: block;
    margin: 1rem 0 0;
    line-height: 1.5;
  }

  &__icon {
    flex: 0 0 auto;
    display: block;
    position: relative;
    width: 1.75rem;
    height: 2.25rem;
    margin: 0 0 0 1rem;
  }

  &__triangle {
    position: absolute;
    top: 0;
    animation: triangle 1s $ease-in-out-sine infinite;
  }

  &__line {
    position: absolute;
    bottom: 0;
  }
}

.menu-content-anim {
  position: relative;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: #{0.1s + ($i - 1) * 0.25s};
    }
  }

  animation: ease-up 1.25s $ease-out-quart both;
  @media (prefers-reduced-motion) {
    animation-name: ease-up-reduced-motion;
  }

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: inherit;
    box-shadow: 0 0 0.5rem #fff;

    animation: from-white 2.5s $ease-out-quart both;
    animation-delay: inherit;
  }
}

.menu {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0); // this fixes the scroll somehow
  transform-origin: calc(50vw - min(300px, 42.5vw)) calc(100vh - 7.5vmin);

  &--disabled {
    pointer-events: none;
    overflow: hidden;
  }

  &__content {
    &--chosen {
      animation-delay: 0s !important;
      animation: menu-chosen 0.4s ease-out both;
    }

    &--remove {
      animation: menu-remove 0.4s ease-out both;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: 0.1s + $i * 0.05s;
        }
      }
    }

    &--disabled {
      pointer-events: none;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  &__link {
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: inherit;
    font-size: 1.5rem;
    font-weight: $bold;
    font-family: inherit;
    text-decoration: none;
    text-align: start;
    border: 0;
    cursor: pointer;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &__icon {
    display: block;
    flex: 0 0 auto;
    height: 1.75rem;
    width: 1.75rem;
    object-fit: contain;
  }

  &__group {
    position: relative;
    width: 2rem;

    &__icon {
      position: absolute;
      top: 0;
      right: 0;

      width: auto;
      height: 100%;

      transition: opacity 0.5s $ease-in-quad;
      opacity: 0;

      &--show {
        transition: opacity 0.5s 0.5s $ease-out-quad;
        opacity: 1;
      }
    }
  }

  &__concepts {
    padding: 0;

    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: $content-padding;
      border: none;
      border-radius: $content-radius;
      background: transparent;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      color: white;
      font-family: inherit;
      font-size: 1.5rem;
      font-weight: $bold;
      text-align: left;

      cursor: pointer;
    }

    &__wrapper {
      overflow: hidden;
      visibility: hidden;

      &--visible {
        visibility: visible;
      }

      &--animate {
        transition: height 1s $ease-out-cubic;
      }
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0 $content-padding $content-padding;
    }

    &__video {
      background: transparent;
      margin: -0.75 * $content-padding 0 0;
      padding: 0.75 * $content-padding $content-padding $content-padding;
      border-radius: 0 0 $content-radius $content-radius;
      animation: menu-video-in 0.5s ease 1 both;

      @keyframes menu-video-in {
        from {
          opacity: 0;
        }
      }
    }

    &__item {
      display: flex;
      margin: 1rem 0 0;
      line-height: 1.5;

      &:first-child {
        margin: 0;
      }
    }

    &__emoji {
      font-size: 2rem;
      margin: 0 1.5rem 0 -0.5rem;
    }
  }

  @keyframes menu-logo-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes menu-logo-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &__logo {
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{0.1s + ($i - 1) * 0.25s};
      }
    }

    justify-self: flex-end;
    display: block;
    width: 10rem;
    max-width: 100%;
    height: auto;
    margin: $content-margin auto 0;
    opacity: 0;
    animation: menu-logo-in 0.5s ease-out both;

    &--remove {
      animation: menu-remove 0.4s ease-out both;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: #{0.1s + $i * 0.05s};
        }
      }
    }
  }
}

@keyframes from-white {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes to-white {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes unblur {
  0% {
    filter: blur($blur);
  }
  100% {
    filter: blur(0px);
  }
}

@keyframes ease-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 20vh, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes ease-up-reduced-motion {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// menu anims

.menu-enter-active {
  transition: opacity 0.5s 0.5s ease-out, filter 0.5s 0.5s ease-out,
    transform 1s 0.5s $ease-out-quint;
}

.menu-leave-active {
  transition: opacity 0.5s ease-out, filter 0.5s ease-out,
    transform 0.5s ease-in;

  & > .page__inner > .menu-content-anim::after {
    animation: to-white 0.5s 0s $ease-out-quad both;
  }
}

.menu-enter-from {
  opacity: 0;
  filter: blur($blur);
  transform: scale(0.8);

  @media (prefers-reduced-motion) {
    transform: none;
  }
}

.menu-leave-to {
  opacity: 0;
  filter: blur($blur);
  transform: scale(0.9);

  @media (prefers-reduced-motion) {
    transform: none;
  }
}
</style>
