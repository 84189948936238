<template>
  <button
    type="button"
    class="nav-prompt"
    :class="{ 'nav-prompt--show': visible && active }"
    @click="onClick"
    @focus="show"
    :tabindex="tabindex"
  >
    <!-- scroll and swipe -->
    <span
      class="nav-prompt__scroll"
      :class="{ 'nav-prompt__scroll--show': !usingKeys }"
    >
      <span class="nav-prompt__label nav-prompt__label--swipe">{{
        prompts.touch_idle_prompt
      }}</span>
      <span class="nav-prompt__label nav-prompt__label--scroll">{{
        prompts.mouse_idle_prompt
      }}</span>
      <span ref="icon" class="nav-prompt__icon" aria-hidden="true"></span>
      <span ref="echo" class="nav-prompt__echo" aria-hidden="true"></span>
    </span>

    <!-- keys -->
    <span
      class="nav-prompt__focus"
      :class="{ 'nav-prompt__focus--show': usingKeys }"
    >
      <span class="nav-prompt__label">{{ prompts.keyboard_idle_prompt }}</span>
      <span
        class="nav-prompt__arrow"
        :class="keyPressClass"
        @animationend="animEnd"
      ></span>
    </span>
  </button>
</template>

<script>
import * as Input from "@/scripts/input.js";
import SessionData from "@/scripts/sessionData.js";

export default {
  props: {
    menuOpen: {
      type: Boolean,
      required: true,
    },
    modalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      scrolledToEnd: false,
      value: 0,
      usingKeys: Input.mode === 2,
      keyPressClass: null,
      y: 0,
      prompts: SessionData.lang.Interactions,
    };
  },
  computed: {
    active() {
      return this.scrolledToEnd && !this.menuOpen && !this.modalOpen;
    },
    tabindex() {
      return this.active ? null : -1;
    },
  },
  methods: {
    animEnd() {
      this.keyPressClass = null;
    },
    onClick() {
      this.$root.nextPage();
      this.keyPressClass = "nav-prompt__arrow--press";
    },
    onKey(e) {
      if (!this.$root.menuOpen && this.active && e.key === "ArrowRight") {
        this.onClick();
        Input.keyboardMode();
      }
    },
    show() {
      this.visible = true;
    },
    setEnd(bool) {
      this.scrolledToEnd = bool;
    },
    reset() {
      this.visible = false;
      this.scrolledToEnd = false;
    },
    setValue(v) {
      this.value = v;
      if (v !== 0) {
        this.visible = true;
      }
    },
    modeChange() {
      this.usingKeys = Input.mode === 2;
    },
    animate(t) {
      const yTarget = this.value * this.value;
      const y = (this.y =
        yTarget - (yTarget - this.y) * 0.9 ** (0.06 * (t - this.pt)));
      const y2 = y + (1 - y) * (0.5 + 0.5 * Math.sin(t * 0.002));
      const scale = 1 - 0.1 * Math.sin(y * Math.PI);

      this.$refs.icon.style.transform = `translate3d(0, -${y}rem, 0) scale(${scale})`;
      this.$refs.icon.style.backgroundColor = `rgba(255, 255, 255, ${y})`;

      this.$refs.echo.style.transform = `translate3d(0, -${y2}rem, 0) scale(${scale})`;
      this.$refs.echo.style.opacity =
        0.333 * Math.max(0, Math.cos(t * 0.002)) ** 2;

      this.pt = t;
      this.raf = requestAnimationFrame(this.animate);
    },
  },
  mounted() {
    Input.addCallback(this.modeChange);
    window.addEventListener("keydown", this.onKey);

    this.pt = performance.now();
    this.animate(this.pt);
  },
  beforeUnmount() {
    cancelAnimationFrame(this.raf);
    Input.removeCallback(this.modeChange);
    window.removeEventListener("keydown", this.onKey);
  },
};
</script>

<style lang="scss">
@keyframes prompt-key-press {
  0% {
  }
  20% {
    transform: scale(0.8);
  }
  100% {
  }
}

.nav-prompt {
  @include reset-button;

  pointer-events: none; // only allow keyboard clicks

  position: fixed;
  bottom: $page-bottom;
  right: var(--container-indent);

  width: fit-content;
  height: 1rem;

  opacity: 0;
  transition: opacity 1s ease-in-out;

  &--show {
    opacity: 1;
  }

  &__scroll {
    display: none;

    &--show {
      display: flex;
    }
  }

  &__focus {
    display: none;

    &--show {
      display: flex;
    }
  }

  // force the keyboard label to show when the buton is focused
  &:focus-visible {
    opacity: 1;

    & > .nav-prompt__scroll {
      display: none;
    }

    & > .nav-prompt__focus {
      display: flex;
    }
  }

  &__label {
    display: block;
    white-space: nowrap;

    margin: 0 0.5rem 0 0;

    font-weight: $semi-bold;
    text-transform: uppercase;
    line-height: 1rem;

    &--scroll {
      display: none;
    }

    @media (hover: hover) {
      &--scroll {
        display: block;
      }

      &--swipe {
        display: none;
      }
    }
  }

  &__icon {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border: solid 0.125rem #fff;
    border-radius: 50%;
  }

  &__echo {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 0.75rem;
    height: 0.75rem;
    border: solid 0.125rem #fff;
    border-radius: 50%;
  }

  &__arrow {
    position: relative;
    display: block;
    width: 1rem;
    height: 1rem;
    margin: -0.125rem 0;
    border: solid 0.125rem #fff;
    border-radius: 20%;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-top: solid 0.125rem #fff;
      border-right: solid 0.125rem #fff;
      width: 0.2rem;
      height: 0.2rem;
      transform: translate(-70%, -50%) rotate(45deg);
    }

    &--press {
      animation: prompt-key-press 0.3s ease-out;
    }
  }
}
</style>
