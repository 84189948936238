<template>
  <component
    :is="rootComponent"
    innerClass="message-page"
    :swipe="canSwipe"
    @next="next"
  >
    <div :class="hasOptions ? 'message-page__container' : null" ref="container">
      <figure
        class="fragment message-page__message page-content-anim"
        :style="{
          height: `${messageHeight}px`,
          'margin-top': `${messageMargin}px`,
        }"
      >
        <div class="fragment__message-header" ref="header">
          <ProfilePicture :image="avatar" />
          <span class="fragment__name">{{ name }}</span>
        </div>
        <div
          class="message-page__dots"
          :class="{ 'message-page__dots--hide': showMessage }"
        >
          <div class="message-page__dot message-page__dot--1"></div>
          <div class="message-page__dot message-page__dot--2"></div>
          <div class="message-page__dot message-page__dot--3"></div>
        </div>
        <div
          class="message-page__body"
          :class="{ 'message-page__body--show': showMessage }"
          ref="body"
        >
          {{ message }}
        </div>
      </figure>

      <div v-if="showOptions" class="message-page__options" ref="wrapper">
        <SAM
          :data="data.comment"
          class="page-content-anim page-content-margin"
        />
        <Dialogue :options="optionLabels" @choice="choice" />
      </div>
    </div>
  </component>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import ProfilePicture from "@/components/content/ProfilePicture.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";

import * as AudioManager from "@/scripts/audioManager.js";
import { shuffleArray } from "@/scripts/helpers.js";
import { rem } from "@/scripts/windowSize.js";
import SessionData from "@/scripts/sessionData.js";
import { parse } from "@/scripts/insert.js";

export default {
  emits: ["next"],
  components: {
    Page,
    ScrollPage,
    ProfilePicture,
    SAM,
    Dialogue,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { message, has_comment, comment, response, options } = this.data;

    const { avatar, name } = SessionData.character;

    const hasComment = has_comment && comment;
    let optionLabels;
    let hasOptions;
    let rootComponent;
    let canSwipe;

    if (response && options.length !== 0) {
      hasOptions = true;
      optionLabels = [];
      shuffleArray(options);
      for (let i = 0; i < options.length; ++i) {
        optionLabels[i] = options[i].message;
      }

      rootComponent = "Page";
    } else {
      hasOptions = false;
      rootComponent = "ScrollPage";
      canSwipe = false;
    }
    return {
      rootComponent,
      canSwipe,

      message: parse(message),
      name,
      avatar,

      hasComment,

      hasOptions,
      optionLabels,

      showMessage: false,
      showOptions: false,

      messageHeight: null,
      messageMargin: null,
    };
  },
  methods: {
    setMessageMargin() {
      if (this.hasOptions) {
        this.messageMargin =
          (this.$refs.container.offsetHeight - this.messageHeight) * 0.5;
      }
    },
    setMessageDefaultHeight() {
      this.messageHeight = this.$refs.header.offsetHeight + 2.875 * rem;
      this.setMessageMargin();
    },
    setMessageRevealedHeight() {
      this.messageHeight =
        this.$refs.header.offsetHeight + this.$refs.body.offsetHeight + rem;
      this.setMessageMargin();
    },
    animateOptions() {
      if (this.hasOptions || this.hasComment) {
        this.showOptions = true;
        window.removeEventListener("resize", this.resize);
        this.messageMargin = 0;
      }

      if (!this.hasOptions) {
        this.canSwipe = true;
      }
    },
    animate() {
      this.showMessage = true;
      AudioManager.play("chapter-message-notification");
      this.setMessageRevealedHeight();
      this.timeout = setTimeout(this.animateOptions, 1500);
    },
    choice(index) {
      this.$emit("next", parseInt(this.data.options[index].path));
    },
    next() {
      this.$emit("next");
    },
    resize() {
      if (this.showMessage) {
        this.setMessageRevealedHeight();
      } else {
        this.setMessageDefaultHeight();
      }
    },
    playVibrateSound() {
      AudioManager.play("chapter-message-vibration");
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.$nextTick(this.setMessageDefaultHeight);
    this.audioTimeout = setTimeout(this.playVibrateSound, 1000);
    this.timeout = setTimeout(this.animate, 3000);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
    clearTimeout(this.timeout);
    clearTimeout(this.audioTimeout);
  },
};
</script>

<style lang="scss">
@keyframes dot-bounce {
  0% {
    transform: none;
  }

  20% {
    transform: translateY(-0.3rem);
  }

  40% {
    transform: none;
  }
}

@keyframes fade-text-in {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-dots-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.message-page__container {
  flex: 1 1 100%;
  height: 100%;
}

.message-page__message {
  overflow: hidden;
  overflow: clip;
  transition-property: height, margin;
  transition-duration: 1s;
  transition-delay: 0.1s;
  transition-timing-function: $ease-out-cubic;
}

.message-page__body {
  position: absolute;
  visibility: hidden;
  font-size: 1.625rem;
  font-weight: $bold;
  width: calc(100% - #{$content-padding * 2});

  &--show {
    visibility: visible;
    animation: fade-text-in 2s 0.5s $ease-out-cubic both;
  }
}

.message-page__dots {
  position: absolute;
  display: flex;
  height: 0.75rem;
  margin: 1rem 0 0 0.125rem;

  &--hide {
    animation: fade-dots-out 0.5s $ease-out-quad both;
  }
}

.message-page__dot {
  flex: 0 0 auto;

  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.5rem 0 0;
  border-radius: 50%;
  background: #fff;

  animation: dot-bounce 1s $ease-out-quad 3 both;

  &--1 {
    animation-delay: var(--delay);
  }

  &--2 {
    animation-delay: calc(var(--delay) + 0.25s);
  }

  &--3 {
    animation-delay: calc(var(--delay) + 0.5s);
  }
}

.message-page__options {
  transition: height 3s 0.25s $ease-out-cubic;

  &::before {
    content: "";
    display: block;
    height: $content-margin;
  }
}
</style>
