<template></template>
<script>
import * as AudioManager from "@/scripts/audioManager.js";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 0,
    },
    fadeOut: {
      type: [Number, Boolean],
      default: false,
    },
  },
  data() {
    return {
      delayTimeout: null,
      isPlayed: false,
    };
  },
  methods: {
    play() {
      // in case already playing
      if (AudioManager.audioList[this.id]) this.stop();
      this.isPlayed = true;
      AudioManager.play(this.id, this.loop);
    },
    stop() {
      AudioManager.stop(this.id);
    },
  },
  mounted() {
    this.delayTimeout = setTimeout(this.play, this.delay);
  },
  beforeUnmount() {
    clearTimeout(this.delayTimeout);
    if (this.fadeOut && this.isPlayed)
      AudioManager.fadeOut(this.id, this.fadeOut);
  },
};
</script>
