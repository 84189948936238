<template>
  <div class="mute" :class="{ 'mute--show': show }">
    <button
      type="button"
      class="mute__button"
      :class="{
        'mute__button--tap': tapState,
      }"
      @click="toggle"
      :aria-label="label"
      :aria-pressed="isMuted"
      :disabled="!show"
    >
      <svg
        draggable="false"
        viewBox="0 0 44 26"
        class="mute__shape"
        aria-hidden="true"
      >
        <path
          class="mute__background"
          :class="{ 'mute__background--show': !isMuted }"
          d="M 19.5878,0.12665 C 16.0538,2.2109 12.5183,4.29324 8.98582,6.37941 8.93028,6.41238 8.87811,6.44535 8.82933,6.47832 c -1.96384,0 -3.92767,0 -5.891508,0 -0.276205,0 -0.485986,0.10273 -0.636098,0.25684 C 2.292718,6.74321 2.284461,6.75203 2.275455,6.76046 2.267574,6.76928 2.258943,6.77733 2.251437,6.78653 2.100575,6.93987 2,7.15416 2,7.43668 2,11.1456 2,14.8548 2,18.5637 c 0,0.5225 0.426692,0.9584 0.938197,0.9584 1.960453,0 3.920543,0 5.880623,0 3.58918,2.118 7.17948,4.2348 10.76938,6.3512 C 20.2037,26.2364 21,25.7752 21,25.0461 21,17.0154 21,8.98464 21,0.953911 20.9996,0.224787 20.2033,-0.236379 19.5878,0.12665 Z"
          fill="#FFF"
        />
        <path
          d="M 19.5878,0.12665 C 16.0538,2.2109 12.5183,4.29324 8.98582,6.37941 8.93028,6.41238 8.87811,6.44535 8.82933,6.47832 c -1.96384,0 -3.92767,0 -5.891508,0 -0.276205,0 -0.485986,0.10273 -0.636098,0.25684 C 2.292718,6.74321 2.284461,6.75203 2.275455,6.76046 2.267574,6.76928 2.258943,6.77733 2.251437,6.78653 2.100575,6.93987 2,7.15416 2,7.43668 2,11.1456 2,14.8548 2,18.5637 c 0,0.5225 0.426692,0.9584 0.938197,0.9584 1.960453,0 3.920543,0 5.880623,0 3.58918,2.118 7.17948,4.2348 10.76938,6.3512 C 20.2037,26.2364 21,25.7752 21,25.0461 21,17.0154 21,8.98464 21,0.953911 20.9996,0.224787 20.2033,-0.236379 19.5878,0.12665 Z"
          fill="none"
          stroke="#FFF"
          stroke-width="2"
          stroke-linecap="round"
        />
        <Transition name="mute-icon-side">
          <g v-if="!isMuted">
            <path
              class="mute__shape__waves"
              d="M26 6C26 6 28 9 28 13C28 17 26 20 26 20"
              stroke="#FFF"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              class="mute__shape__waves"
              d="M31 6C31 6 33 9 33 13C33 17 31 20 31 20"
              stroke="#FFF"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
            />
          </g>
          <g v-else>
            <path
              d="M33.4854 17.4854L25.0001 9.00007"
              stroke="#FFF"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M33.4854 9L25.0001 17.4853"
              stroke="#FFF"
              stroke-width="2"
              stroke-linecap="round"
            />
          </g>
        </Transition>
      </svg>
      <svg
        draggable="false"
        viewBox="0 0 44 26"
        class="mute__glow"
        :class="{ 'mute__glow--show': !isMuted }"
        aria-hidden="true"
      >
        <path
          d="M 19.5878,0.12665 C 16.0538,2.2109 12.5183,4.29324 8.98582,6.37941 8.93028,6.41238 8.87811,6.44535 8.82933,6.47832 c -1.96384,0 -3.92767,0 -5.891508,0 -0.276205,0 -0.485986,0.10273 -0.636098,0.25684 C 2.292718,6.74321 2.284461,6.75203 2.275455,6.76046 2.267574,6.76928 2.258943,6.77733 2.251437,6.78653 2.100575,6.93987 2,7.15416 2,7.43668 2,11.1456 2,14.8548 2,18.5637 c 0,0.5225 0.426692,0.9584 0.938197,0.9584 1.960453,0 3.920543,0 5.880623,0 3.58918,2.118 7.17948,4.2348 10.76938,6.3512 C 20.2037,26.2364 21,25.7752 21,25.0461 21,17.0154 21,8.98464 21,0.953911 20.9996,0.224787 20.2033,-0.236379 19.5878,0.12665 Z"
          fill="none"
          stroke="#FFF"
          stroke-width="2"
          stroke-linecap="round"
        />
        <Transition name="mute-icon-side">
          <g v-if="!isMuted">
            <path
              class="mute__shape__waves"
              d="M26 6C26 6 28 9 28 13C28 17 26 20 26 20"
              stroke="#FFF"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              class="mute__shape__waves"
              d="M31 6C31 6 33 9 33 13C33 17 31 20 31 20"
              stroke="#FFF"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
            />
          </g>
          <g v-else>
            <path
              d="M33.4854 17.4854L25.0001 9.00007"
              stroke="#FFF"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M33.4854 9L25.0001 17.4853"
              stroke="#FFF"
              stroke-width="2"
              stroke-linecap="round"
            />
          </g>
        </Transition>
      </svg>
    </button>
  </div>
</template>

<script>
import * as AudioManager from "@/scripts/audioManager.js";
import SessionData from "@/scripts/sessionData.js";

const TAP_TIME = 100;

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isMuted: AudioManager.muted,
      tapState: false,
      tapTimeout: null,
      impulse: 0,
      baseScale: 1,
      label: SessionData.lang.Menu.mute_button_label || "Mute",
    };
  },
  methods: {
    tap() {
      this.tapState = false;
      AudioManager.play("ui-input-button-misc");
    },
    toggle() {
      AudioManager.toggleMute();
      this.isMuted = AudioManager.muted;
      this.tapState = true;
      clearTimeout(this.tapTimeout);
      this.tapTimeout = setTimeout(this.tap, TAP_TIME);
    },
  },
  beforeUnmount() {
    clearTimeout(this.tapTimeout);
  },
};
</script>

<style lang="scss">
$icon-size: 3.25rem;
$icon-padding: 0.25rem;
$icon-side-transform: 8px;
$tap-time: 100ms;

.mute {
  position: fixed;
  bottom: calc($page-bottom - $icon-side-transform / 2);
  right: var(--container-indent);
  z-index: $z-menu;
  user-select: none;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  filter: blur(10px);
  transform: scale(0.7);
  transition: opacity, filter, transform, visibility;
  transition-duration: 0.5s;
  transition-delay: 0s, 0s, 0s, 0.5s;
  transition-timing-function: ease-out;

  &--show {
    pointer-events: auto;
    visibility: visible;
    filter: none;
    opacity: 1;
    transform: none;
    transition: opacity, filter, transform, visibility;
    transition-duration: 0.5s;
    transition-delay: 1.5s, 1.5s, 1.5s, 1.5s;
    transition-timing-function: ease-out;
  }

  &__button {
    @include reset-button;

    backface-visibility: hidden;
    padding: $icon-padding;
    border-radius: 50%;

    transition: transform $tap-time $ease-in-out-cubic;
    transform-origin: center;

    &--tap {
      transform: scale(1.1) rotate(5deg);
    }
  }

  &__shape {
    width: $icon-size;
    height: $icon-size;
    transform: translateX($icon-side-transform);

    &__waves {
      animation: mute-icon-waves-passive 2s ease infinite;
      @keyframes mute-icon-waves-passive {
        0% {
          transform: translate3d(-1px, 0, 0);
        }
        50% {
          transform: translate3d(1px, 0, 0);
        }
        100% {
          transform: translate3d(-1px, 0, 0);
        }
      }
    }
  }

  &__glow {
    position: absolute;
    top: $icon-padding;
    left: $icon-padding;
    width: $icon-size;
    height: $icon-size;
    transform: translateX($icon-side-transform);
    opacity: 0;
    filter: blur(10px);
    transition: opacity 0.2s ease-in-out;

    &--show {
      opacity: 1;
    }
  }

  &__background {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &--show {
      opacity: 1;
    }
  }
}

.mute-icon-side-enter-active,
.mute-icon-side-leave-active {
  transition: opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: $ease-in-out-cubic;
}

.mute-icon-side-enter-from {
  opacity: 0;
  transform: translateX(-$icon-side-transform);
}

.mute-icon-side-leave-to {
  opacity: 0;
  transform: translateX($icon-side-transform);
}
</style>
