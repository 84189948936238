<template>
  <component :is="rootComponent" @next="next">
    <SAM
      v-if="!hasOptions && hasComment"
      :data="data.comment"
      class="page-content-anim page-content-margin"
    />
    <component
      :is="acf_fc_layout"
      :data="data"
      class="page-content-anim page-content-margin"
    />
    <SAM
      v-if="hasOptions && hasComment"
      :data="data.comment"
      class="page-content-anim page-content-margin"
    />
    <Dialogue v-if="hasOptions" :options="optionLabels" @choice="choice" />
  </component>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";

import DirectMessage from "@/components/content/DirectMessage.vue";
import Email from "@/components/content/Email.vue";
import GroupChat from "@/components/content/GroupChat.vue";
import Notification from "@/components/content/Notification.vue";
import Popup from "@/components/content/Popup.vue";
import SAMWrap from "@/components/content/SAMWrap.vue";
import SearchResults from "@/components/content/SearchResults.vue";
import SocialImage from "@/components/content/SocialImage.vue";
import StoryMessage from "@/components/content/StoryMessage.vue";
import WebPage from "@/components/content/WebPage.vue";

import { shuffleArray } from "@/scripts/helpers.js";

export default {
  emits: ["next"],
  components: {
    Page,
    ScrollPage,
    SAM,
    Dialogue,

    chat_history: GroupChat,
    comment: SAMWrap,
    direct_message: DirectMessage,
    email: Email,
    message: StoryMessage,
    notification: Notification,
    popup: Popup,
    sam_commentary: SAMWrap,
    search_result: SearchResults,
    social_image: SocialImage,
    web_page: WebPage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { acf_fc_layout, has_comment, comment, response, options } =
      this.data;
    const hasComment = has_comment && comment;
    let hasOptions;
    let optionLabels;
    let rootComponent;

    if (response && options.length !== 0) {
      hasOptions = true;
      optionLabels = [];
      shuffleArray(options);
      for (let i = 0; i < options.length; ++i) {
        optionLabels[i] = options[i].message;
      }

      rootComponent = "Page";
    } else {
      hasOptions = false;
      rootComponent = "ScrollPage";
    }

    return {
      rootComponent,
      acf_fc_layout,

      hasComment,
      hasOptions,
      optionLabels,
    };
  },
  methods: {
    choice(index) {
      this.$emit("next", parseInt(this.data.options[index].path));
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>
