import { globals } from "./_globals.js";

import { recursiveOffsetLeft } from "@/scripts/helpers.js";
import Time from "@/scripts/time.js";
import * as AudioManager from "@/scripts/audioManager.js";

const HALFPI = 0.5 * Math.PI;
const TWOPI = 2 * Math.PI;

const HOUR_ARC = TWOPI / 12;
const MINUTE_ARC = TWOPI / 60;
const SECOND_ARC = TWOPI / 60;

export default {
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  secondLength: globals.vMin / 2,
  minuteLength: (0.7125 * globals.vMin) / 2,
  hourLength: (0.5 * globals.vMin) / 2,
  secDeg: 0,
  canTick: false,
  allClose: true,
  init() {
    this.setTarget();
    for (const shape of globals.shapes) {
      shape.resetProps();
      shape.setProp("vMax", globals.rem * 2);
      shape.setProp("selfVMax", globals.rem * 1.25);
      shape.turnFactor = 0.9;
      shape.setPropLerp("decelDist", globals.vMin * 0.25);
      shape.setTarget(this.originX, this.originY);
    }
    this.canTick = false;
  },
  update() {
    Time.update();

    // pivot
    globals.shapes[0].setTarget(this.originX, this.originY);

    // hour
    const hourDeg = Time.hours * HOUR_ARC;
    globals.shapes[1].setTarget(
      this.originX + Math.cos(hourDeg - HALFPI) * this.hourLength,
      this.originY + Math.sin(hourDeg - HALFPI) * this.hourLength
    );

    // minute
    const minDeg = Time.minutes * MINUTE_ARC;
    globals.shapes[2].setTarget(
      this.originX + Math.cos(minDeg - HALFPI) * this.minuteLength,
      this.originY + Math.sin(minDeg - HALFPI) * this.minuteLength
    );

    // second
    const secDeg = Time.seconds * SECOND_ARC;
    globals.shapes[3].setTarget(
      this.originX + Math.cos(secDeg - HALFPI) * this.secondLength,
      this.originY + Math.sin(secDeg - HALFPI) * this.secondLength
    );

    // play tick sound every second
    if (this.canTick && secDeg !== this.secDeg) {
      // second change
      AudioManager.play("concepts-time");
    }
    this.secDeg = secDeg;

    // update shape positions
    // check if close to formation
    this.allClose = true;
    for (const shape of globals.shapes) {
      if (this.allClose && shape.targetDist.hyp > globals.rem) {
        this.allClose = false;
      }
      shape.update();
    }
    if (this.allClose && !this.canTick) {
      this.canTick = true;
    }
  },
  setTarget() {
    if (globals.targetElement) {
      const radius = globals.targetElement.clientWidth * 0.5;

      this.originX = recursiveOffsetLeft(globals.targetElement) + radius;
      this.originY = globals.targetElement.offsetTop + radius;

      this.hourLength = radius * 0.4;
      this.minuteLength = radius * 0.7;
      this.secondLength = radius * 0.9;
    }
  },
  onResize() {
    this.setTarget();
  },
  in() {},
  out() {},
  kill() {},
  onVisibilityChange() {},
  next() {
    for (const shape of globals.shapes) {
      shape.addForce(
        (Math.random() - 0.5) * 2 * 8,
        -20 * (Math.random() - 0.5) * 2,
        0
      );
    }
  },
};
