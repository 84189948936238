<template>
  <figure class="fragment">
    <div class="email__title">{{ title }}</div>
    <div class="email__about">
      from: <span class="email__name">{{ sender }}</span>
      <span v-if="is_unknown" class="email__warning">
        <svg class="email__icon" viewBox="0 0 14.722 12.75">
          <path d="m 7.36122,0 7.3612,12.75 H 0 Z" fill="currentColor" />
          <path
            d="m6.9202 8.928-0.216-4.212h1.332l-0.225 4.212zm0.45 2.151c-0.21 0-0.384-0.069-0.522-0.207s-0.207-0.303-0.207-0.495 0.069-0.354 0.207-0.48599c0.138-0.138 0.312-0.207 0.522-0.207s0.381 0.069 0.513 0.207c0.132 0.13199 0.198 0.29399 0.198 0.48599s-0.066 0.357-0.198 0.495-0.303 0.207-0.513 0.207z"
            fill="#FFF"
          />
        </svg>
      </span>
    </div>
    <div v-html="body"></div>
  </figure>
</template>

<script>
import { markdown, parse } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { title, Sender, is_unknown, body } = this.data;

    return {
      title: parse(title),
      sender: parse(Sender),
      is_unknown,
      body: markdown(parse(body)),
    };
  },
};
</script>

<style lang="scss">
.email {
  &__title {
    margin: 0;
    font-weight: $bold;
  }

  &__about {
    margin: $fragment-content-margin 0;
    border-bottom: solid 1px #fff;
    padding: 0.75rem 0;
    font-size: 0.75rem;
  }

  &__name {
    margin-inline-start: 0.5em;
    font-size: 1rem;
    font-weight: $bold;
  }

  &__icon {
    color: var(--color-2);
    width: 1.125em;
    height: auto;
    margin-bottom: -0.125em;
  }

  &__warning {
    padding: 0.5rem 0;
    border-radius: 0.75rem;
    font-size: 1rem;
    margin-inline-start: 0.65rem;
  }
}
</style>
