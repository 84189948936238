<template>
  <SAM :data="text" />
</template>

<script>
import SAM from "./SAM.vue";

export default {
  components: {
    SAM,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: this.data.content,
    };
  },
};
</script>
