<template>
  <ul v-if="data.list.length" class="emoji-list">
    <li v-for="item in data.list" class="emoji-list__item">
      <Emoji :name="item.emoji" class="emoji-list__emoji" aria-hidden="true" />
      <div v-html="markdown(parse(item.text))"></div>
    </li>
  </ul>
</template>

<script>
import Emoji from "@/components/content/Emoji.vue";
import { markdown, parse } from "@/scripts/insert.js";

export default {
  components: {
    Emoji,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    markdown,
    parse,
  },
};
</script>

<style lang="scss">
.emoji-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0 0 -0.375rem;
  font-weight: $medium;
  font-size: 1rem;
  line-height: 1.5;

  &:first-child {
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0.75rem 0 0;
    padding: 0;

    &:first-child {
      margin: 0;
    }
  }

  &__emoji {
    flex: 0 0 4em;
    width: 4em;
    height: auto;
    margin: auto 0.875rem auto 0;
  }
}
</style>
