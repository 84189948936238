import { Howler, Howl } from "./howler.js";
import { passiveEvent } from "./globals.js";

// load once at app startup
// call audio when needed
// automatically handle multiple instancing (if any)

const INBOX_FILES = [
  "inbox-exit",
  "inbox-load",
  "inbox-unlock",
  { name: "inbox-next", options: { instances: 5, baseVolume: 0.8 } },
  { name: "inbox-previous", options: { instances: 5, baseVolume: 0.8 } },
];
const UI_FILES = [
  "ui-input-button-select",
  { name: "ui-input-button-misc", options: { instances: 5 } },
  "ui-input-correct",
  { name: "ui-input-wrong", options: { baseVolume: 0.8 } },
  "ui-input-next",
  "ui-menu-open",
  "ui-menu-close",
  "ui-menu-update",
  "ui-new-message",
];
const GENRE_FILES = [
  "acoustic",
  "classical",
  "electronic",
  "hip-hop",
  "jazz",
  "pop",
  "reggae",
  "rnb",
  "rock",
];
const CONCEPTS_FILES = [
  "concepts-location-scan",
  "concepts-location-ping",
  "concepts-time",
];
const INTRO_FILES = ["safety-plan-alert", "intro-initiate", "intro-hello"];
const CHAPTERS_FILES = [
  { name: "chapter-score", options: { baseVolume: 0.9 } },
  { name: "chapter-story-score", options: { baseVolume: 0.9 } },
  "chapter-message-notification",
  "chapter-message-vibration",
  { name: "chapter-news", options: { baseVolume: 0.6 } },
  "chapter-news-after",
  { name: "chapter-searching", options: { baseVolume: 0.8 } },
  "chapter-activity-correct",
  "chapter-activity-maybe",
  { name: "chapter-activity-wrong", options: { baseVolume: 0.9 } },
];
const LOAD_GROUPS = {
  ui: {
    files: UI_FILES,
    path: "ui",
    ext: "mp3",
  },
  inbox: {
    files: INBOX_FILES,
    path: "inbox",
    ext: "mp3",
  },
  genres: {
    files: GENRE_FILES,
    path: "genres",
    ext: "mp3",
  },
  concepts: {
    files: CONCEPTS_FILES,
    path: "concepts",
    ext: "mp3",
  },
  chapters: {
    files: CHAPTERS_FILES,
    path: "chapters",
    ext: "mp3",
  },
  intro: {
    files: INTRO_FILES,
    path: "intro",
    ext: "mp3",
  },
};
const LOAD_CHECKS = {};

const UNLOCK_DELAY = 200;
const UNLOCK_PLAY_QUEUE_MAX_TIME = UNLOCK_DELAY + 50;

export const audioList = {};

let isUnlocked = false;
const unlockQueue = [];

export class OneshotAudio {
  constructor(id, url, options) {
    this.id = id;
    this.url = url;
    this.instances = options.instances || 1;
    this.baseVolume = options.baseVolume || 1;
    this.sounds = [];
    for (let i = 0; i < this.instances; i++) {
      this.instanceSound();
    }
    this.robinIndex = 0;
    this.fadeOutFallbackTimeout = null;
  }

  instanceSound() {
    const sound = new Howl({
      src: [this.url],
      // html5: true,
      pool: 1, // manually manage pool for html5 fallback
    });
    sound.volume(this.baseVolume);
    this.sounds.push(sound);
  }

  onEnded() {
    this.audioElement.currentTime = 0;
  }

  play(loop = false) {
    this.setVolume(1.0);
    this.sounds[this.robinIndex].play();
    this.sounds[this.robinIndex].loop(loop);
    this.robinIndex = (this.robinIndex + 1) % this.sounds.length;
    clearTimeout(this.fadeOutFallbackTimeout);
  }

  stop() {
    for (const sound of this.sounds) {
      sound.stop();
      sound.loop(false);
    }
    clearTimeout(this.fadeOutFallbackTimeout);
  }

  setVolume(vol = 0) {
    for (const sound of this.sounds) {
      sound.volume(vol * this.baseVolume);
      sound.mute(vol === 0);
    }
  }

  fadeOut(time = 0) {
    for (const sound of this.sounds) {
      const currentVol = sound.volume();
      if (currentVol !== 0) sound.mute(false);
      sound.fade(currentVol, 0.0, time);
    }

    clearTimeout(this.fadeOutFallbackTimeout);
    this.fadeOutFallbackTimeout = setTimeout(this.doFadeOut.bind(this), time);
  }
  doFadeOut() {
    this.setVolume(0);
  }
}

export async function initialiseFile(id, url, options) {
  if (audioList[id]) {
    console.warn(
      `audioManager (initialiseFile): Audio object ${id} already exists!`
    );
    return;
  }

  audioList[id] = new OneshotAudio(
    id,
    require(`@/assets/audio/${url}`),
    options
  );
  return;
}

export function play(id, loop = false) {
  // queue sounds before unlock
  if (!isUnlocked) {
    const queueObject = {
      id,
      loop,
      timestamp: performance.now(),
    };
    unlockQueue.push(queueObject);
    return;
  }

  // play sound now
  if (audioList[id]) {
    audioList[id].play(loop);
    return;
  }

  console.warn(`audioManager (play): Audio object ${id} doesn"t exist!`);
}

export function stop(id) {
  if (audioList[id]) {
    audioList[id].stop();
    return;
  }
  console.warn(`audioManager (stop): Audio object ${id} doesn"t exist!`);
}

export function fadeOut(id, time = 500) {
  if (audioList[id]) {
    audioList[id].fadeOut(time);
    return;
  }
  console.warn(`audioManager (fadeOut): Audio object ${id} doesn"t exist!`);
}

export let muted = false;
export function toggleMute() {
  muted = !muted;
  if (muted) {
    Howler.volume(0);
    Howler.mute(true);
  } else {
    Howler.volume(1);
    Howler.mute(false);
  }
}

export async function batchInitByName(array, directory, extension) {
  await Promise.all(
    array.map(async (file) => {
      const isString = typeof file === "string";
      const isObject = typeof file === "object";
      if (!isString && !isObject) return;
      const filename = isString ? file : file.name;
      let instances = 1;
      let baseVolume = 1;
      if (isObject && file.options) instances = file.options.instances || 1;
      if (isObject && file.options) baseVolume = file.options.baseVolume || 1;
      await initialiseFile(filename, `${directory}/${filename}.${extension}`, {
        instances,
        baseVolume,
      });
    })
  );
  return;
}

export function groupInit(group) {
  if (LOAD_CHECKS[group]) return; // init once
  LOAD_CHECKS[group] = true;
  const loadGroup = LOAD_GROUPS[group];
  batchInitByName(loadGroup.files, loadGroup.path, loadGroup.ext);
}

export function loadInitFiles() {
  groupInit("ui");
}

export function loadIntroFiles() {
  groupInit("intro");
}

export function loadGenreFiles() {
  groupInit("genres");
}

export function loadInboxFiles() {
  groupInit("inbox");
}

export function loadConceptsFiles() {
  groupInit("concepts");
}

export function loadChapterFiles() {
  groupInit("chapters");
}

// IOS MUTE BYPASS
// based on https://stackoverflow.com/a/46839941 to implement feature missing from howler
let audioTest = document.createElement("audio");
audioTest.controls = false;
audioTest.preload = "auto";
audioTest.loop = false;
audioTest.src =
  "data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

function unlock() {
  removeUnlock();
  setTimeout(doUnlock, UNLOCK_DELAY); // wait 200ms or Chrome silently fails
}

async function doUnlock() {
  try {
    await audioTest.play();
  } catch (error) {
    // ignore error anyway
    console.warn(error);
  } finally {
    isUnlocked = true;
    playQueued();
    audioTest = null; // delete the audio element
  }
}

function playQueued() {
  const now = performance.now();
  for (const sound of unlockQueue) {
    // if sound should be looping or the timestamp hasnt expired
    if (sound.loop || sound.timestamp + UNLOCK_PLAY_QUEUE_MAX_TIME >= now) {
      play(sound.id, sound.loop);
    }
  }
  unlockQueue.splice(0, unlockQueue.length);
}

function removeUnlock() {
  window.removeEventListener("touchstart", unlock, passiveEvent);
  window.removeEventListener("touchend", unlock, passiveEvent);
  window.removeEventListener("click", unlock);
  window.removeEventListener("keydown", unlock);
}

export function attachUnlock() {
  window.addEventListener("touchstart", unlock, passiveEvent);
  window.addEventListener("touchend", unlock, passiveEvent);
  window.addEventListener("click", unlock);
  window.addEventListener("keydown", unlock);
}
