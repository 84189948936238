<template>
  <div ref="scroll" class="horizontal-scroll" :class="debounceClass">
    <div class="horizontal-scroll__scroll">
      <div class="horizontal-scroll__inner">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
const SCROLL_MARGIN = 2000; // large to prevent ios behaviour when hitting scroll edge
const SCROLL_MINIMUM = 20;
const SCROLL_MINIMUM_VW = 0.05;

export default {
  data() {
    return {
      debounceClass: null,
    };
  },
  emits: ["horizontalscroll"],
  methods: {
    onScroll() {
      this.delta = this.$refs.scroll.scrollLeft - SCROLL_MARGIN;

      // reset scroll on element
      this.$refs.scroll.removeEventListener("scroll", this.onScroll);
      this.$refs.scroll.scrollLeft = SCROLL_MARGIN;

      if (Math.abs(this.delta) < this.threshold) {
        // reject scroll - keep watching
        this.$refs.scroll.addEventListener("scroll", this.onScroll);
      } else {
        // accept scroll - prevent scroll and emit event
        this.debounceClass = "horizontal-scroll--debounce";
        this.$emit("horizontalscroll", this.delta);
      }
    },
    onKey(e) {
      // block keyboard left/right scroll
      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        e.preventDefault();
      }
    },
    block() {
      this.$refs.scroll.removeEventListener("scroll", this.onScroll);
      this.$refs.scroll.scrollLeft = SCROLL_MARGIN;
      this.debounceClass = "horizontal-scroll--debounce";
    },
    restore() {
      this.$refs.scroll.addEventListener("scroll", this.onScroll);
      this.debounceClass = null;
    },
    resize() {
      this.threshold = Math.max(
        SCROLL_MINIMUM,
        SCROLL_MINIMUM_VW * window.innerWidth
      );
    },
  },
  mounted() {
    this.$refs.scroll.scrollLeft = SCROLL_MARGIN;
    this.$refs.scroll.addEventListener("scroll", this.onScroll);
    this.$refs.scroll.addEventListener("keydown", this.onKey);

    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
    this.$refs.scroll.removeEventListener("scroll", this.onScroll);
    this.$refs.scroll.removeEventListener("keydown", this.onKey);
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="scss">
$scroll-margin: 2000px;

.horizontal-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  overscroll-behavior: contain;
  touch-action: pan-x;
  @include hide-scrollbar;

  &--debounce {
    overflow: hidden;
  }

  &__scroll {
    width: calc(100% + #{$scroll-margin * 2});
    height: 100%;
  }

  &__inner {
    position: fixed;
    position: sticky;
    left: 0;
    width: calc(100% - #{$scroll-margin * 2});
    height: 100%;
  }
}
</style>
