import localForage from "localforage";
import { removeItemFromArray } from "./helpers.js";

const storage = new localForage.createInstance({ name: "media" });
const downloads = [];

let readyPromise;

let total = 0;
let loaded = 0;
let progress = 0;

class AssetDownload {
  key;
  url;
  alive = true;
  progress = 0;
  query;

  constructor(key, url) {
    this.key = key;
    this.url = url;

    this.progressEvent = this.xhrProgress.bind(this);
    this.stateEvent = this.xhrState.bind(this);
    this.errorEvent = this.xhrError.bind(this);
  }

  start(resolve, reject) {
    this.resolve = resolve;
    this.reject = reject;

    this.xhrStart();
  }

  // DOWNLOAD FROM SERVER
  // get file from server + track progress

  xhrStart() {
    this.progress = 0;

    this.xhr = new XMLHttpRequest();
    this.xhr.responseType = "blob";
    this.xhr.addEventListener("progress", this.progressEvent);
    this.xhr.addEventListener("readystatechange", this.stateEvent);
    this.xhr.addEventListener("error", this.errorEvent);
    this.xhr.open("GET", `${this.url}?t=${Date.now()}`); // force cache bypass
    this.xhr.send();
  }

  xhrDelete() {
    this.xhr.removeEventListener("progress", this.progressEvent);
    this.xhr.removeEventListener("readystatechange", this.stateEvent);
    this.xhr.removeEventListener("error", this.errorEvent);
    this.xhr.abort();
  }

  xhrState() {
    if (this.xhr.readyState === 4 && this.xhr.status === 200) {
      this.progress = 1;
      this.save();
    }
  }

  xhrError() {
    this.retryTimeout = setTimeout(this.xhrStart.bind(this), 1000);
  }

  xhrProgress(e) {
    if (e.lengthComputable) {
      this.progress = e.loaded / e.total;
    }
  }

  // STORE ON DEVICE
  // save binary into local db using localForge

  save() {
    this.query = storage
      .setItem(this.key, this.xhr.response)
      .then(this.fin.bind(this), this.retry.bind(this));
  }

  retry() {
    // don't retry if stopped
    if (this.alive) {
      this.save();
    }
  }

  fin() {
    ++loaded;
    removeItemFromArray(this, downloads);
    this.resolve();
  }

  async abort() {
    this.alive = false;

    this.xhrDelete();

    clearTimeout(this.retryTimeout);
    removeItemFromArray(this, downloads);

    await this.query;

    this.reject();
  }
}

function start() {
  readyPromise = storage.ready().then(onReady, noStorage);
}

function onReady() {
  OfflineMedia.available = true;
}

function noStorage() {
  OfflineMedia.available = false;
}

function ready() {
  return readyPromise;
}

function getProgress() {
  if (total === 0) {
    return 0;
  } else {
    progress = 0;
    for (const download of downloads) {
      progress += download.progress;
    }

    progress = (progress * 0.9 + loaded) / total;

    return progress;
  }
}

async function download(key, url) {
  ++total;

  return new Promise((resolve, reject) => {
    const download = new AssetDownload(key, url);
    downloads.push(download);
    download.start(resolve, reject);
  });
}

function getItem(key) {
  return storage.getItem(key);
}

async function stop() {
  downloads.length = total = loaded = 0;
  await Promise.all(downloads.map((download) => download.abort()));
}

async function clear() {
  if (OfflineMedia.available) {
    await stop(); // abort downloads in progress
    await storage.clear(); // clear localForage instance
  }
}

const OfflineMedia = {
  available: false,
  getItem,
  download,
  getProgress,
  clear,
  start,
  ready,
  stop,
};

export default OfflineMedia;
