<template>
  <div
    class="login-warning"
    :class="{ 'login-warning--show': shown }"
    :style="{ '--open-height': `${showHeight}px` }"
    role="alert"
  >
    <p class="login-warning__text" ref="text">{{ text }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showHeight: 0,
      shown: false,
      text: "",
    };
  },
  methods: {
    calcHeight() {
      this.showHeight = this.$refs.text.clientHeight;
    },
    show(text) {
      this.text = text;
      this.shown = true;
      cancelAnimationFrame(this.raf);
      this.raf = requestAnimationFrame(this.calcHeight);
    },
    hide() {
      this.shown = false;
    },
  },
  mounted() {
    this.calcHeight();
  },
  beforeUnmount() {
    cancelAnimationFrame(this.raf);
  },
};
</script>
<style lang="scss">
.login-warning {
  height: 0;
  opacity: 0;
  overflow: hidden;
  overflow: clip;
  margin-top: 0;
  transition: height, opacity, margin-top;
  transition-duration: 0.35s;
  transition-delay: 0.1s, 0s, 0.1s;
  transition-timing-function: $ease-in-out-cubic;

  &--show {
    height: var(--open-height, fit-content);
    opacity: 1;
    transition-duration: 0.65s, 0.5s;
    transition-delay: 0s, 0.35s;
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &__text {
    margin: 0;
  }
}
</style>
