<template>
  <div class="emoji-group">
    <Emoji :name="first" class="emoji-group__1" />
    <div class="emoji-group__back">
      <Emoji :name="second" :class="secondClass" />
      <Emoji v-if="third" :name="third" class="emoji-group__3" />
    </div>
  </div>
</template>

<script>
import Emoji from "@/components/content/Emoji.vue";

export default {
  components: {
    Emoji,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const first = this.data.foreground_emoji;
    let second = this.data.background_emoji_1;
    let third;

    if (second) {
      third = this.data.background_emoji_2;
    } else {
      second = this.data.background_emoji_2;
    }

    return {
      first,
      second,
      third,
      secondClass: third ? "emoji-group__2" : "emoji-group__alt",
    };
  },
};
</script>

<style lang="scss">
$keyframes: 100;
@mixin swayAnim($name, $width, $rotation, $phase) {
  @keyframes #{$name} {
    @for $i from 0 through $keyframes {
      $alpha: $i/$keyframes;
      $angle: $alpha * 2 * math.$pi + $phase;
      $x: 0.01 * math.cos($angle) - 0.495;
      $y: 0.01 * math.sin($angle) - 0.495;

      #{100*$alpha + "%"} {
        transform: translate(#{$x * $width}, #{$y * $width})
          rotate($x * 2deg + $rotation);
      }
    }
  }
}

@include swayAnim("sway1", 10rem, -10deg, random() * 2 * math.$pi);
@include swayAnim("sway2", 6rem, 10deg, random() * 2 * math.$pi);
@include swayAnim("sway3", 6rem, -20deg, random() * 2 * math.$pi);

.emoji-group {
  position: relative;
  margin: 2rem auto 0;

  &:first-child {
    margin: 0;
  }

  &__back {
    // new stacking context too flatten overflow
    // https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
    position: relative;
    z-index: 0;
    overflow: hidden;
    overflow: clip;

    width: 40%;
    padding: 40% 0 0;
    margin: 0 0 20% 40%;
    border-radius: 50%;
    background: #000;
  }

  &__1 {
    position: absolute;
    z-index: 2;
    top: 80%;
    left: 38%;
    width: 10rem;
    height: auto;
    transform: translate(-50%, -50%) rotate(-10deg);
    animation: sway1 10s ease-in-out infinite both;
  }

  &__2 {
    position: absolute;
    top: 15%;
    left: 50%;
    width: 6rem;
    height: auto;
    transform: translate(-50%, -50%) rotate(10deg);
    animation: sway2 10s ease-in-out infinite both;
  }

  &__3 {
    position: absolute;
    top: 75%;
    left: 80%;
    width: 6rem;
    height: auto;
    transform: translate(-50%, -50%) rotate(-20deg);
    animation: sway3 10s ease-in-out infinite both;
  }

  &__alt {
    position: absolute;
    top: 30%;
    left: 70%;
    width: 6rem;
    height: auto;
    transform: translate(-50%, -50%) rotate(20deg);
    animation: sway2 10s ease-in-out infinite both;
  }
}
</style>
