<template>
  <component :is="component" v-bind="attributes" />
</template>

<script>
import ImageAttachment from "@/components/content/image/ImageAttachment.vue";

export default {
  components: {
    ImageAttachment,
  },
  props: {
    image: {
      type: Number,
      default: null,
    },
  },
  data() {
    if (this.image) {
      return {
        component: "ImageAttachment",
        attributes: {
          image: this.image,
          alt: "",
          sizes: "3rem",
          class: "profile-picture profile-picture--image",
        },
      };
    } else {
      return {
        component: "div",
        attributes: {
          class: "profile-picture profile-picture--unset",
        },
      };
    }
  },
};
</script>

<style lang="scss">
.profile-picture {
  width: 3rem;
  height: 3rem;
  margin: 0 1rem 0 0;
  border-radius: 50%;

  &--image {
    object-fit: cover;
  }

  &--unset {
    position: relative;
    overflow: hidden;
    overflow: clip;
    background: var(--color-3);

    &::before {
      content: "";
      position: absolute;
      top: 13%;
      left: 25%;
      width: 45%;
      height: 45%;
      border-radius: 50%;
      background: var(--color-2);
    }

    &::after {
      content: "";
      position: absolute;
      top: 59%;
      left: 24%;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      background: var(--color-2);
    }
  }
}
</style>
