<template>
  <ScrollPage class="default-page-anim" @next="next">
    <SAM
      v-if="data.comment"
      :data="data.comment"
      class="page-content-anim page-content-margin"
    />
    <StoryMessage
      v-if="data.message"
      :data="data"
      class="page-content-anim page-content-margin"
    />
  </ScrollPage>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import StoryMessage from "@/components/content/StoryMessage.vue";

export default {
  emits: ["next"],
  components: {
    ScrollPage,
    SAM,
    StoryMessage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    next() {
      this.$emit("next");
    },
  },
};
</script>
