<template>
  <Transition>
    <component
      :is="pageType"
      :key="currentPage"
      class="default-page-anim"
      @next="next"
    >
      <SAM
        :data="parseEllipsis(page.content)"
        ref="content"
        class="page-content-anim page-content-margin"
      />
    </component>
  </Transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";

import * as AudioManager from "@/scripts/audioManager.js";
import * as Background from "@/scripts/background.js";
import { parseEllipsis } from "@/scripts/insert.js";

export default {
  emits: ["next"],
  components: {
    Page,
    ScrollPage,
    SAM,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    const lastPage = this.data.length - 1;

    return {
      currentPage: 0,
      lastPage,
      page: this.data[0],
      pageType: lastPage === 0 ? "Page" : "ScrollPage",
    };
  },
  methods: {
    parseEllipsis,
    trackFirstElement() {
      this.$root.shapeTarget(this.$refs.content.$el.nextElementSibling);
    },
    reachedLastPage() {
      this.$root.shapeState("circle");
      AudioManager.play("chapter-searching");
      this.timeout = setTimeout(this.fin, 3000);
    },
    next() {
      this.page = this.data[++this.currentPage];
      Background.transitionDown();

      if (this.currentPage === this.lastPage) {
        this.pageType = "Page";
        this.reachedLastPage();
      }

      this.timeout = setTimeout(this.trackFirstElement);
    },
    fin() {
      this.$root.shapeOut();
      this.$emit("next");
    },
  },
  mounted() {
    if (this.lastPage === 0) {
      this.reachedLastPage();
    } else {
      this.$root.shapeState("bounce");
    }

    this.trackFirstElement();
    this.$root.shapeIn();
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
};
</script>
