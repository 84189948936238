<template>
  <Image :src="src" />
</template>

<script>
import Image from "./Image.vue";
import OfflineMedia from "@/scripts/offlineMedia.js";

export default {
  components: {
    Image,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      url: require(`@/assets/images/${this.image}`),
      src: null,
    };
  },
  methods: {
    onSuccess(cachedImage) {
      // if the image is cached, use the cached blob
      if (cachedImage) {
        this.src = URL.createObjectURL(cachedImage);
      }

      // otherwise use the url
      else {
        this.fromServer();
      }
    },
    onError(error) {
      console.error("Error loading image from cache:", error);
      this.fromServer();
    },
    fromServer() {
      this.src = this.url;
    },
  },
  created() {
    if (OfflineMedia.available) {
      OfflineMedia.getItem(this.url).then(this.onSuccess, this.onError);
    } else {
      this.fromServer();
    }
  },
};
</script>
