<template>
  <Page class="default-page-anim">
    <SAM
      :data="prompt"
      gradient
      class="page-content-anim page-content-margin"
    />
    <Dialogue :options="options" gradient @choice="languageChoice" />
  </Page>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import { data } from "@/scripts/data.js";
import SessionData from "@/scripts/sessionData.js";

export default {
  emits: ["next", "toLogin"],
  components: {
    Page,
    SAM,
    Dialogue,
  },
  data() {
    return {
      options: [],
      prompt: SessionData.lang.Login.language_prompt,
    };
  },
  methods: {
    languageChoice(index) {
      // back button
      if (index === this.languages.length) {
        this.$emit("toLogin");
      } else {
        this.$emit("next", this.languages[index]);
      }
    },
  },
  created() {
    this.languages = [];

    // filter out the curent language
    for (const lang of data.languages) {
      if (lang.slug !== SessionData.lang.slug) {
        this.options.push(lang.name);
        this.languages.push(lang);
      }
    }

    // add back button to the options
    this.options.push(SessionData.lang.Login.language_prompt_dismiss || "Back");
  },
};
</script>
