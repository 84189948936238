<template>
  <Page class="default-page-anim">
    <SAM
      :data="message"
      class="page-content-anim page-content-margin"
      gradient
    />
  </Page>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import SessionData from "@/scripts/sessionData.js";
import PersistentData from "@/scripts/persistentData.js";

export default {
  components: {
    Page,
    SAM,
  },
  data() {
    const message =
      SessionData.lang.Errors.network_error ||
      PersistentData.data.languages.find((language) => language.default).Errors
        .network_error;
    return {
      message,
    };
  },
  mounted() {
    this.$root.setHeaderDisplay(false);
  },
};
</script>
