<template>
  <div
    class="interest-splash"
    :class="{
      'interest-splash--flow': !this.point,
    }"
    :style="cssVars"
  >
    <div
      v-if="interestData"
      class="interest-splash__emojis"
      :class="{
        'interest-splash__emojis--show': show,
        'interest-splash__emojis--unshown': unshown,
        'interest-splash__emojis--flow': !this.point,
      }"
    >
      <div
        v-for="(emoji, i) in interestData.emojis"
        class="interest-splash__emojis__wrap"
        :class="[`interest-splash__emojis__wrap--${i}`, emoji.motion]"
        :style="{
          '--anim-delay': `${0.125 * i}s`,
          '--anim-rot': `${i % 2 ? -10 : 20}deg`,
        }"
      >
        <Emoji class="interest-splash__emojis__img" :name="emoji.name" />
      </div>
    </div>
  </div>
</template>

<script>
import Emoji from "@/components/content/Emoji.vue";
import musicGenres from "@/data/musicGenres.json";
import * as AudioManager from "@/scripts/audioManager.js";
import UserData from "@/scripts/userData.js";

export default {
  components: {
    Emoji,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    point: {
      type: Object,
      default: null,
    },
    delay: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      unshown: this.show ? false : true,
      music: UserData.music,
      interestData: null,
    };
  },
  watch: {
    show() {
      if (this.show) this.unshown = false;
      // force update from unreactive source
      this.music = UserData.music;
      this.interestData = musicGenres[UserData.music];
      if (this.show) AudioManager.play(this.interestData.music);
    },
  },
  computed: {
    cssVars() {
      return {
        "--x": this.point ? `${this.point.x}px` : null,
        "--y": this.point ? `${this.point.y}px` : null,
        "--show-delay": `${this.delay}ms`,
      };
    },
  },
  mounted() {
    if (this.show && this.music) {
      this.interestData = musicGenres[this.music];
      AudioManager.play(this.interestData.music);
    }
  },
};
</script>

<style lang="scss">
$emoji-size: 12rem;

.interest-splash {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: calc(50% - 50vw);
  z-index: -1;

  &--flow {
    position: relative;
    left: 0;
  }

  &__emojis {
    position: absolute;
    top: var(--y, 50%);
    left: var(--x, 75%);
    width: 0;
    height: 0;
    pointer-events: none;

    &--flow {
      top: $emoji-size / 2;
    }

    // ANIMATION TYPES

    // OUT/BASE STATE
    .scale-bounce {
      transform-origin: top left;
      @include scale-out(
        $name: interest-splash-emoji-scale-bounce-out,
        $dur: 0.75s,
        $delay: var(--anim-delay, 0s),
        $transforms: translateX(-50%)
      );

      .interest-splash__emojis__img {
        transform-origin: top center;
        $frames: 10;

        @keyframes interest-splash-emoji-scale-bounce-img-in {
          @for $i from 0 through $frames {
            #{$i * $frames * 1%} {
              transform: rotate(
                  2.5deg * math.sin($i / $frames * 3.14159) * ($i % 2 - 0.5) * 2
                )
                translateY(-50%);
            }
          }
        }

        animation: interest-splash-emoji-scale-bounce-img-in 1s ease
          calc(var(--anim-delay, 0s) + var(--show-delay, 0s)) infinite both;
      }
    }

    .fade-up {
      transform-origin: top left;
      @include scale-out(
        $name: interest-splash-emoji-fade-up-out,
        $dur: 0.75s,
        $delay: var(--anim-delay, 0s),
        $transforms: translate(-50%, -50%)
      );

      .interest-splash__emojis__img {
        @include float-rotate-anim(
          $name: interest-splash-emoji-fade-up-passive,
          $amt: var(--anim-rot, 10deg),
          $dur: 10s
        );
      }
    }

    .rock-in {
      transform-origin: top left;
      transform: translate(-50%, -50%);

      .interest-splash__emojis__img {
        transform-origin: 50% bottom;
        @include scale-out(
          $name: interest-splash-emoji-rock-in-out,
          $dur: 0.75s,
          $delay: var(--anim-delay, 0s),
          $transforms: rotate(-30deg)
        );
      }
    }

    .swing-up {
      transform-origin: top left;
      @include scale-out(
        $name: interest-splash-emoji-swing-up-out,
        $dur: 0.75s,
        $delay: var(--anim-delay, 0s),
        $transforms: translate(-50%, -50%)
      );

      .interest-splash__emojis__img {
        transform-origin: bottom right;

        @keyframes interest-splash-emoji-swing-passive {
          0% {
            transform: rotate(0deg);
            animation-timing-function: ease-in-out;
          }

          50% {
            transform: rotate(-10deg);
            animation-timing-function: ease-in-out;
          }

          100% {
            transform: rotate(0deg);
          }
        }

        animation: interest-splash-emoji-swing-passive 2.5s
          calc(var(--anim-delay, 0s) + var(--show-delay, 0s)) infinite both;
      }
    }

    &--unshown {
      display: none;
    }

    &--show {
      .scale-bounce {
        transform-origin: top left;
        @include scale-in-bounce(
          $name: interest-splash-emoji-scale-bounce-in,
          $amt: 2,
          $dur: 1s,
          $delay: calc(var(--anim-delay, 0s) + var(--show-delay, 0s)),
          $transforms: translateX(-50%)
        );

        .interest-splash__emojis__img {
          transform-origin: top center;
        }
      }

      .fade-up {
        @keyframes interest-splash-emoji-fade-up {
          0% {
            transform: translateY(2rem) translate(-50%, -50%);
            opacity: 0;
          }

          100% {
            transform: translateY(0) translate(-50%, -50%);
            opacity: 1;
          }
        }

        animation: interest-splash-emoji-fade-up 0.75s ease
          calc(var(--anim-delay, 0s) + var(--show-delay, 0s)) 1 both;
      }

      .rock-in {
        transform-origin: top left;
        @include scale-in-bounce(
          $name: interest-splash-emoji-rock-in,
          $dur: 1s,
          $delay: calc(var(--anim-delay, 0s) + var(--show-delay, 0s)),
          $transforms: translate(-50%, -50%)
        );

        .interest-splash__emojis__img {
          transform-origin: 50% bottom;

          @keyframes interest-splash-emoji-rock-in-rotate {
            0% {
              transform: rotate(0deg);
            }

            15% {
              transform: rotate(-30deg);
            }

            50% {
              transform: rotate(21deg);
            }

            100% {
              transform: rotate(-30deg);
            }
          }

          animation: interest-splash-emoji-rock-in-rotate 1.25s ease
            calc(calc(var(--anim-delay, 0s) + 0.25s) + var(--show-delay, 0s)) 1
            both;
        }
      }

      .swing-up {
        transform-origin: top center;
        $transforms: translate(-50%, -50%);

        @keyframes interest-splash-emoji-swing-up {
          0% {
            transform: rotate(-30deg) $transforms;
            opacity: 0;
            animation-timing-function: ease-in;
          }

          30% {
            transform: rotate(20deg) $transforms;
            opacity: 1;
            animation-timing-function: ease-out;
          }

          50% {
            transform: rotate(30deg) $transforms;
            animation-timing-function: ease-in;
          }

          60% {
            transform: rotate(-5deg) $transforms;
            animation-timing-function: ease-in-out;
          }

          70% {
            transform: rotate(2.5deg) $transforms;
            animation-timing-function: ease-in-out;
          }

          80% {
            transform: rotate(-2.5deg) $transforms;
            animation-timing-function: ease-in-out;
          }

          90% {
            transform: rotate(1.25deg) $transforms;
            animation-timing-function: ease-in-out;
          }

          100% {
            transform: rotate(0deg) $transforms;
            animation-timing-function: ease-in-out;
          }
        }

        animation: interest-splash-emoji-swing-up 1.75s
          calc(var(--anim-delay, 0s) + var(--show-delay, 0s)) 1 both;
      }
    }

    &__wrap {
      position: absolute;
      width: $emoji-size;
      font-size: $emoji-size;
      height: auto;

      &--0 {
        top: -$emoji-size * 0.6;
        left: 0;
      }

      &--1 {
        top: 0;
        left: -$emoji-size * 0.6;
      }
    }

    &__img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>
