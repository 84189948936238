<template>
  <div class="swipe-activity default-page-anim">
    <Transition>
      <!-- question -->
      <Page v-if="state === 0" class="default-page-anim">
        <SAM
          :data="page.question"
          class="page-content-anim page-content-margin"
        />
        <div class="page-content-anim page-content-margin">
          <PageContent :data="page.attachment[0]" ref="swipe" />
        </div>
      </Page>

      <!-- feedback -->
      <ScrollPage
        v-else-if="state === 1"
        class="default-page-anim"
        @next="toTip"
      >
        <EmojiFeedback :data="feedback" :sound="sound" />
      </ScrollPage>

      <!-- tip -->
      <ScrollPage
        v-else-if="state === 2"
        class="default-page-anim"
        @next="next"
      >
        <ActivityInfo :data="page.tip" class="page-content-anim" />
      </ScrollPage>
    </Transition>
    <div
      v-if="state === 0"
      ref="remind"
      class="swipe-activity__remind"
      :class="{
        'swipe-activity__remind--show': showReminder,
      }"
    >
      <div class="swipe-activity__remind__glow"></div>
      <div
        class="swipe-activity__remind__glow swipe-activity__remind__glow--right"
      ></div>
      <div class="swipe-activity__remind__text-wrap">
        <div
          class="swipe-activity__remind__text"
          :class="{
            'swipe-activity__remind__text--focus': inMoveLeft,
            'swipe-activity__remind__text--select': swipedLeft,
          }"
        >
          {{ swipe_left_prompt_short }}
        </div>
      </div>
      <div
        class="swipe-activity__remind__text-wrap swipe-activity__remind__text-wrap--right"
      >
        <div
          class="swipe-activity__remind__text swipe-activity__remind__text--right"
          :class="{
            'swipe-activity__remind__text--focus': inMoveRight,
            'swipe-activity__remind__text--select': swipedRight,
          }"
        >
          {{ swipe_right_prompt_short }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import PageContent from "@/components/content/PageContent.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import EmojiFeedback from "@/components/sections/chapter/activities/EmojiFeedback.vue";
import ActivityInfo from "@/components/content/ActivityInfo.vue";

import * as Background from "@/scripts/background.js";
import * as AudioManager from "@/scripts/audioManager.js";
import SessionData from "@/scripts/sessionData.js";
import * as Input from "@/scripts/input.js";
import { passiveEvent } from "@/scripts/globals.js";
import {
  constrain,
  recursiveOffsetLeft,
  recursiveOffsetTop,
} from "@/scripts/helpers.js";
import { windowWidth, containerWidth } from "@/scripts/windowSize.js";

// input modes
const USING_MOUSE = 0;
const USING_TOUCH = 1;

// swipe states
const SWIPE_DEFAULT = 0;
const SWIPE_START = 1;
const SWIPE_MOVE = 2;
const SWIPE_LEAVE = 3;
const SWIPE_DONE = 4;

const DRAG_THRESHOLD = 0.03;
const MOVE_THRESHOLD = 0.1;
const PROMPT_RESHOW_TIME = 5000;
const SOUND_MAP = ["wrong", "correct"];

export default {
  emits: ["next", "progress"],
  components: {
    Page,
    SAM,
    PageContent,
    ScrollPage,
    EmojiFeedback,
    ActivityInfo,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    progress: {
      type: Array,
      required: true,
    },
  },
  data() {
    const { swipe_left_prompt_short, swipe_right_prompt_short } =
      SessionData.lang.Interactions;

    return {
      pageIndex: 0,
      lastPage: this.data.questions.length - 1,
      state: 0,
      page: null,
      feedback: null,
      sound: "maybe",
      score: 0,

      swipeState: SWIPE_DEFAULT,

      x: 0,
      xInput: 0,
      xStart: 0,
      xOffset: 0,
      xMax: 0,

      yStart: 0,
      yOffset: 0,
      yMax: 0,

      centerDist: 0,

      swipedLeft: false,
      swipedRight: false,
      inMoveLeft: false,
      inMoveRight: false,

      showReminder: false,
      hideReminderTimeout: null,
      showFirstPromptTimeout: null,
      showPromptTimeout: null,
      swipe_left_prompt_short,
      swipe_right_prompt_short,
    };
  },
  methods: {
    nextQuestion() {
      this.page = this.data.questions[this.pageIndex];
      this.doHideReminder();
      this.resetSwipeVars();
    },
    answer(right) {
      const option = right ? this.page.options.right : this.page.options.left;
      const score = parseInt(option.score);

      this.feedback = option;
      this.score += score;
      this.sound = SOUND_MAP[score];

      this.killTimeouts();
      this.killPromptTimeout();
      this.removePromptEvents();
      this.hideReminderTimeout = setTimeout(this.doHideReminder, 500);
      this.feedbackTimeout = setTimeout(this.toFeedback, 750);
      this.$root.setHeaderProgress(
        this.pageIndex + 1,
        this.data.questions.length
      );
      Background.transitionDown();
      this.doShowReminder();
      AudioManager.play("ui-input-button-select");
    },
    toFeedback() {
      this.state = 1;
    },
    toTip() {
      if (this.page.has_tip && this.page.tip) {
        this.state = 2;
        Background.transitionDown();
      } else {
        this.next();
      }
    },
    next() {
      if (this.pageIndex === this.lastPage) {
        this.$emit("progress", 0);
        this.$emit("next", this.score);
      } else {
        this.pageIndex++;
        this.nextQuestion();
        this.state = 0;
        this.killTimeouts();
        this.killPromptTimeout();
        this.resetPrompt();
        this.addPromptEvents();
        Background.transitionDown();
        this.swipeEventTimeout = setTimeout(this.addSwipeEvents, 500);
      }
    },

    // keyboard interaction
    onKey(e) {
      if (!this.$root.menuOpen && this.swipeState === SWIPE_DEFAULT) {
        if (e.key === "ArrowRight") {
          this.removeSwipeEvents();

          this.swipedLeft = false;
          this.swipedRight = true;
          this.swipeState = SWIPE_LEAVE;
          this.vTarget = DRAG_THRESHOLD * windowWidth;
          this.v = this.vTarget * 1.5;

          Input.keyboardMode();
        } else if (e.key === "ArrowLeft") {
          this.removeSwipeEvents();

          this.swipedLeft = true;
          this.swipedRight = false;
          this.swipeState = SWIPE_LEAVE;
          this.vTarget = -DRAG_THRESHOLD * windowWidth;
          this.v = this.vTarget * 1.5;

          Input.keyboardMode();
        }
      }
    },

    // swipe interaction
    addSwipeEvents() {
      this.swipeElement = this.$refs.swipe.$el;
      this.remindElement = this.$refs.remind;

      // reset the posiiton variables
      this.x = 0;

      // reset the interaction state
      this.swipeState = SWIPE_DEFAULT;

      this.swipeElement.addEventListener(
        "touchstart",
        this.touchstart,
        passiveEvent
      );
      window.addEventListener("touchmove", this.touchmove, passiveEvent);
      window.addEventListener("touchend", this.touchend, passiveEvent);
      window.addEventListener("touchcancel", this.touchend, passiveEvent);

      this.swipeElement.addEventListener("mousedown", this.mousedown);
      window.addEventListener("mousemove", this.mousemove);
      window.addEventListener("mouseup", this.mouseup);

      window.addEventListener("blur", this.blur);

      window.addEventListener("keydown", this.onKey);
    },
    removeSwipeEvents() {
      this.swipeElement.removeEventListener(
        "touchstart",
        this.touchstart,
        passiveEvent
      );
      window.removeEventListener("touchmove", this.touchmove, passiveEvent);
      window.removeEventListener("touchend", this.touchend, passiveEvent);
      window.removeEventListener("touchcancel", this.touchend, passiveEvent);

      this.swipeElement.removeEventListener("mousedown", this.mousedown);
      window.removeEventListener("mousemove", this.mousemove);
      window.removeEventListener("mouseup", this.mouseup);

      window.removeEventListener("blur", this.blur);

      window.removeEventListener("keydown", this.onKey);
    },
    resetSwipeVars() {
      this.xStart = this.xInput = 0;
      this.yStart = 0;
      this.inMoveLeft = false;
      this.inMoveRight = false;
      this.swipedLeft = false;
      this.swipedRight = false;
    },

    // SWIPE INTERACTION

    // START
    swipeStart(x, y) {
      this.xStart = this.xInput = x;
      this.yStart = y;
      this.xOffset = recursiveOffsetLeft(this.$refs.swipe.$el);
      this.yOffset = recursiveOffsetTop(this.$refs.swipe.$el);
      this.xMax = this.$refs.swipe.$el.clientWidth;
      this.yMax = this.$refs.swipe.$el.clientHeight;

      // factor to furthest edge
      this.centerDist =
        Math.sqrt(
          (this.xStart - this.xOffset - this.xMax / 2) ** 2 +
            (this.yStart - this.yOffset - this.yMax / 2) ** 2
        ) / Math.sqrt((this.xMax / 2) ** 2 + (this.yMax / 2) ** 2);
    },
    mousedown(e) {
      if (this.swipeState === SWIPE_DEFAULT) {
        this.swipeStart(e.clientX, e.clientY);
        this.showPrompt = false;
        this.swipeState = SWIPE_MOVE;
        this.inputMode = USING_MOUSE;
        this.killTimeouts();
        this.killPromptTimeout();
        this.doShowReminder();
      }
    },
    touchstart(e) {
      if (this.swipeState === SWIPE_DEFAULT && e.touches.length === 1) {
        const touch = e.touches[0];
        this.swipeStart(touch.clientX, touch.clientY);
        this.swipeState = SWIPE_START;
      }
    },

    // MOVE
    swipeMove(e) {
      this.v = e.clientX - this.xInput;
      this.xInput = e.clientX;
    },
    mousemove(e) {
      if (this.inputMode === USING_MOUSE && this.swipeState === SWIPE_MOVE) {
        this.swipeMove(e);
      }
    },
    touchmove(e) {
      if (this.inputMode === USING_TOUCH && this.swipeState === SWIPE_MOVE) {
        this.swipeMove(e.touches[0]);
      }

      // check that the x delta is greater than the y delta
      else if (this.swipeState === SWIPE_START) {
        const touchEvent = e.touches[0];
        this.v = touchEvent.clientX - this.xInput;
        this.xInput = touchEvent.clientX;

        if (
          Math.abs(this.xInput - this.xStart) >
          Math.abs(touchEvent.clientY - this.yStart)
        ) {
          this.showPrompt = false;
          this.killTimeouts();
          this.killPromptTimeout();
          this.doShowReminder();
          this.swipeState = SWIPE_MOVE;
          this.inputMode = USING_TOUCH;
        } else {
          this.swipeState = SWIPE_DEFAULT;
        }
      }
    },

    // END
    swipeEnd() {
      if (this.swipeState === SWIPE_MOVE) {
        const distance = this.xInput - this.xStart;

        // swipe left for no
        if (distance < -this.moveThreshold) {
          this.removeSwipeEvents();
          this.swipedLeft = true;
          this.swipedRight = false;
          this.vTarget = Math.min(this.v, -DRAG_THRESHOLD * windowWidth);

          this.swipeState = SWIPE_LEAVE;
        }

        // right for yes
        else if (distance > this.moveThreshold) {
          this.removeSwipeEvents();
          this.swipedLeft = false;
          this.swipedRight = true;
          this.vTarget = Math.max(this.v, DRAG_THRESHOLD * windowWidth);

          this.swipeState = SWIPE_LEAVE;
        }

        // didn't swipe enough
        else {
          this.swipeState = SWIPE_DEFAULT;
        }
      }
      this.killTimeouts();
      this.hideReminderTimeout = setTimeout(this.doHideReminder, 500);
      this.inputMode = null;
    },
    mouseup() {
      if (this.inputMode === USING_MOUSE) {
        this.swipeEnd();
      }
    },
    touchend() {
      if (this.inputMode === USING_TOUCH) {
        this.swipeEnd();
      }
    },
    blur() {
      // if the window loses focus due to the OS, keyboard inputs mid swipe, etc
      // reset the swipe state
      // this is the same as swipeEnd() but with no check for yes/no answer
      if (this.inputMode === USING_MOUSE || this.inputMode === USING_TOUCH) {
        this.swipeState = SWIPE_DEFAULT;
        this.killTimeouts();
        this.hideReminderTimeout = setTimeout(this.doHideReminder, 500);
        this.inputMode = null;
      }
    },

    animate() {
      if (
        this.swipeState === SWIPE_DEFAULT ||
        this.swipeState === SWIPE_START
      ) {
        this.x *= 0.9;
      } else if (this.swipeState === SWIPE_MOVE) {
        this.x = this.xInput - this.xStart;
      } else {
        this.v = this.v * 0.9 + this.vTarget * 0.1;
        this.x += this.v;

        if (
          this.swipeState === SWIPE_LEAVE &&
          Math.abs(this.x) > this.leaveThreshold
        ) {
          this.swipeState = SWIPE_DONE;
          this.answer(this.swipedRight);
        }
      }
      const distance = this.xInput - this.xStart;
      const pivot = (this.yStart - this.yOffset) / this.yMax - 0.5;

      this.inMoveLeft = distance < -this.moveThreshold;
      this.inMoveRight = distance > this.moveThreshold;

      if (pivot) {
        this.swipeElement.style.transform = `translate(${this.x}px) rotate(${
          (-10 * Math.sign(pivot) * this.centerDist * this.x) /
          this.leaveThreshold
        }deg)`;
        this.swipeElement.style.transformOrigin = `50% ${
          -Math.sign(pivot) * 50 + 50
        }%`;
      } else {
        this.swipeElement.style.transform = `translate(${this.x}px)`;
        this.swipeElement.style.transformOrigin = `50% 50%`;
      }

      this.remindElement.style.setProperty(
        "--swipe-delta",
        Math.round(constrain(this.x / this.moveThreshold, -1, 1) * 100) / 100
      );
      this.remindElement.style.setProperty(
        "--swipe-delta-yes",
        Math.round(constrain(this.x / this.moveThreshold, 0, 1) * 100) / 100
      );
      this.remindElement.style.setProperty(
        "--swipe-delta-no",
        Math.round(constrain(this.x / this.moveThreshold, -1, 0) * -100) / 100
      );

      this.raf = requestAnimationFrame(this.animate);
    },
    doShowReminder() {
      this.showReminder = true;
    },
    doHideReminder() {
      this.showReminder = false;
    },
    resetPrompt() {
      this.killPromptTimeout();
      this.showPromptTimeout = setTimeout(
        this.$root.showSwipePrompt,
        PROMPT_RESHOW_TIME
      );
    },
    killPromptTimeout() {
      clearTimeout(this.showPromptTimeout);
    },
    addPromptEvents() {
      window.addEventListener("mouseup", this.resetPrompt);
      window.addEventListener("touchend", this.resetPrompt);
    },
    removePromptEvents() {
      window.removeEventListener("mouseup", this.resetPrompt);
      window.removeEventListener("touchend", this.resetPrompt);
    },
    killTimeouts() {
      clearTimeout(this.swipeEventTimeout);
      clearTimeout(this.hideReminderTimeout);
    },
    resize() {
      this.moveThreshold = MOVE_THRESHOLD * containerWidth;
      this.leaveThreshold = 0.5 * (windowWidth + containerWidth);
    },
  },
  created() {
    this.$root.setHeaderProgressStyle("dot");
    this.$root.setHeaderProgress(this.pageIndex, this.data.questions.length);
    this.nextQuestion();
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();

    this.addSwipeEvents();
    this.addPromptEvents();
    this.animate();
    this.showFirstPromptTimeout = setTimeout(this.$root.showSwipePrompt, 1000);
  },
  beforeUnmount() {
    this.killTimeouts();
    this.killPromptTimeout();
    cancelAnimationFrame(this.raf);
    clearTimeout(this.showFirstPromptTimeout);
    clearTimeout(this.feedbackTimeout);
    this.removeSwipeEvents();
    this.removePromptEvents();
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="scss">
$delta: var(--swipe-delta, 0);
$delta-yes: var(--swipe-delta-yes, 0);
$delta-no: var(--swipe-delta-no, 0);

.swipe-activity {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  user-select: none;
  backface-visibility: hidden;

  &__remind {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s ease;

    &__glow {
      position: absolute;
      top: 0;
      left: 0;
      width: 25vw;
      height: 100%;
      background: radial-gradient(
        closest-side,
        rgb(255, 255, 255, 0.25) 0%,
        rgb(255, 255, 255, 0.15) 50%,
        transparent 100%
      );
      background-size: 200% 100%;
      background-position: right;
      background-repeat: no-repeat;
      opacity: $delta-no;

      &--right {
        left: unset;
        right: 0;
        background-position: left;
        opacity: $delta-yes;
      }
    }

    &__text-wrap {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 0;
      padding: 0 1rem;
      transform: translate(-200%, -50%);
      transition: transform 0.5s ease;

      &--right {
        left: unset;
        right: 0;
        transform: translate(200%, -50%);
      }
    }

    &__text {
      padding: 0.75rem 1.25rem;
      border-radius: 100vw;
      background-color: #000;
      color: #fff;
      backface-visibility: hidden;
      will-change: transform;
      transform: scale(Max(1 - $delta * 0.5, 0.8));
      transform-origin: left center;
      opacity: Max(1 - $delta, 0);
      filter: blur(Max($delta * 10px, 0px));
      overflow: hidden;
      overflow: clip;
      transition: background-color 0.35s $ease-out-quint,
        color 0.35s $ease-out-quint;
      font-weight: $bold;
      text-transform: uppercase;

      &--right {
        transform: scale(Max(1 + $delta * 0.5, 0.8));
        transform-origin: right center;
        opacity: Max(1 + $delta, 0);
        filter: blur(Max($delta * -10px, 0px));
      }

      &--focus {
        background-color: #fff;
        color: #000;

        .swipe-activity__remind__text-icon {
          fill: #000;
        }
      }

      &--select {
        transform: scale(1.75);
        background-color: #fff;
        color: #000;
        transition: transform 1s $ease-out-quart,
          background-color 0.35s $ease-out-quint, color 0.35s $ease-out-quint;
      }
    }

    &--show {
      opacity: 1;

      .swipe-activity__remind__text-wrap {
        transform: translateY(-50%);
      }
    }
  }
}
</style>
