<template>
  <div class="group-chat-action">{{ parse(data.action) }}</div>
</template>

<script>
import { parse } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parse,
  },
};
</script>

<style lang="scss">
@keyframes chat-action-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.group-chat-action {
  display: block;
  font-weight: $bold;

  animation: chat-action-fade 1s $ease-out-cubic both;

  @for $i from 2 to 10 {
    &:nth-child(#{$i}) {
      animation-delay: calc(var(--delay) + #{($i - 1) * 0.25s});
    }
  }
}
</style>
