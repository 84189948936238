<template>
  <Transition name="intro-shape">
    <div v-show="show" class="intro-shape">
      <div class="intro-shape__shape" ref="shape-0">
        <svg viewBox="0 0 100 100" class="intro-shape__shape__svg">
          <defs>
            <linearGradient
              id="intro-shape-grad1"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" style="stop-color: #318ef3; stop-opacity: 1" />
              <stop
                offset="100%"
                style="stop-color: #3cd4b9; stop-opacity: 1"
              />
            </linearGradient>
          </defs>
          <polygon
            fill="url(#intro-shape-grad1)"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="url(#intro-shape-grad1)"
            points="88.04,62.36 37.64,88.04 11.96,37.64 62.36,11.96"
          />
        </svg>
      </div>

      <div class="intro-shape__shape" ref="shape-1">
        <svg viewBox="0 0 100 100" class="intro-shape__shape__svg">
          <defs>
            <linearGradient
              id="intro-shape-grad2"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" style="stop-color: #fbc903; stop-opacity: 1" />
              <stop
                offset="100%"
                style="stop-color: #28e231; stop-opacity: 1"
              />
            </linearGradient>
          </defs>
          <polygon
            fill="url(#intro-shape-grad2)"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="url(#intro-shape-grad2)"
            points="88.99,62.67 19.53,77.43 41.48,9.90"
          />
        </svg>
      </div>

      <div class="intro-shape__shape" ref="shape-2">
        <svg viewBox="0 0 100 100" class="intro-shape__shape__svg">
          <defs>
            <linearGradient
              id="intro-shape-grad3"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" style="stop-color: #b901d7; stop-opacity: 1" />
              <stop
                offset="100%"
                style="stop-color: #fa2065; stop-opacity: 1"
              />
            </linearGradient>
          </defs>
          <polygon
            fill="url(#intro-shape-grad3)"
            stroke-width="12"
            stroke-linejoin="round"
            stroke="url(#intro-shape-grad3)"
            points="87.09,62.05 50.00,89.00 12.91,62.05 27.08,18.45 72.92,18.45"
          />
        </svg>
      </div>

      <div class="intro-shape__shape" ref="shape-3">
        <svg viewBox="0 0 100 100" class="intro-shape__shape__svg">
          <defs>
            <linearGradient
              id="intro-shape-grad4"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" style="stop-color: #ff5c2b; stop-opacity: 1" />
              <stop
                offset="100%"
                style="stop-color: #fa2065; stop-opacity: 1"
              />
            </linearGradient>
          </defs>
          <circle fill="url(#intro-shape-grad4)" cx="50" cy="50" r="42" />
        </svg>
      </div>
    </div>
  </Transition>
</template>
<script>
import {
  constrain,
  easeInOutCubic,
  easeOutCubic,
  lerp,
} from "@/scripts/helpers.js";
import { windowWidth, windowHeight } from "@/scripts/windowSize.js";
import { noise } from "@/scripts/perlin.js";
import { defaultGradient } from "@/scripts/globals.js";
import { keyframer } from "@/scripts/helpers.js";
import * as Background from "@/scripts/background.js";

const FRAME_TIME = 16.67;

const SPLASH_ANIM_TIME = 60;
const SPLASH_X_RANDOM_BASE = 0.2;
const SPLASH_Y_BASE = -0.25;
const SPLASH_Y_RANDOM_START = 0.1;
const SPLASH_Y_RANDOM_MULT = 0.2;
const SPLASH_OPACITY_THRESHOLD = 0.8;

const EXPAND_ANIM_TIME = 300;
const EXPAND_SPIN_SPACING = Math.PI / 2;
const EXPAND_ORIGIN_SPEED = 0.008;
const EXPAND_NOISE_TURBULENCE = 200;

// timing
const EXPAND_GRADIENT_THRESHOLD = 0.7;

// keyframes
const EXPAND_MOVE_LERP_FRAMES = [
  { value: 0, time: 0, ease: easeInOutCubic },
  { value: -0.3, time: 0.45, ease: easeInOutCubic },
  { value: -0.3, time: 0.5, ease: easeInOutCubic },
  { value: 1, time: 1, ease: null },
];

const EXPAND_SPIN_FRAMES = [
  { value: -0.06, time: 0, ease: null },
  { value: -0.02, time: 0.3, ease: null },
  { value: 0.01, time: 0.68, ease: easeInOutCubic },
  { value: 0.13, time: 0.72, ease: easeInOutCubic },
  { value: 0.14, time: 0.95, ease: easeInOutCubic },
  { value: 0.08, time: 1, ease: null },
];

const EXPAND_RAD_FRAMES = [
  { value: 0.05, time: 0, ease: easeInOutCubic },
  // {value: 0.03, time: 0.3, ease: easeInOutCubic},
  { value: 0.015, time: 0.5, ease: easeInOutCubic },
  { value: 0.011, time: 0.7, ease: null },
  { value: 2, time: 1, ease: null },
];

const EXPAND_SCALE_FRAMES = [
  { value: 1, time: 0, ease: null },
  { value: 1, time: 0.3, ease: easeInOutCubic },
  { value: 0.7, time: 0.5, ease: null },
  { value: 0.65, time: 0.7, ease: null },
  { value: 80, time: 0.9, ease: null },
  { value: 80, time: 1, ease: null },
];

const EXPAND_OPACITY_FRAMES = [
  { value: 1, time: 0, ease: null },
  { value: 1, time: 0.75, ease: easeOutCubic },
  { value: 0, time: 1, ease: null },
];

const EXPAND_BLUR_FRAMES = [
  { value: 0, time: 0, ease: null },
  { value: 0, time: 0.7, ease: easeOutCubic },
  { value: 2, time: 1, ease: null },
];

const EXPAND_NOISE_SCALE_FRAMES = [
  { value: 0, time: 0, ease: null },
  { value: 0.015, time: 0.3, ease: null },
  { value: 0.015, time: 0.7, ease: null },
  { value: 0, time: 1, ease: null },
];

export default {
  emits: ["colortrans", "colortransend"],
  data() {
    return {
      show: false,
      shapes: 4,
      posX: [0, 0, 0, 0],
      posY: [0, 0, 0, 0],
      scale: [1, 1, 1, 1],
      opacity: [1, 1, 1, 1],
      blur: [0, 0, 0, 0],
      currentAnimationRequest: null,
      time: 0,
      lastPerformance: 0,
      frameDelta: 1,
      vW: 0,
      vH: 0,
      vMin: 0,
      vMax: 0,

      // splash
      splashStart: false,
      splashStartTime: 0,
      splashLerp: 0,
      splashXSplatter: [0, 0, 0, 0],
      splashYMult: [0, 0, 0, 0],
      splashYStart: [0, 0, 0, 0],

      // expand
      expandOriginTarget: {
        x: -0.25,
        y: -0.25,
      },
      expandOriginPrevTarget: {
        x: -0.25,
        y: -0.25,
      },
      expandOrigin: {
        x: -0.25,
        y: -0.25,
      },
      expandOriginLerp: 0,
      expandStart: false,
      expandGradientSet: false,
      expandStartTime: 0,
      expandLerp: 0,
      expandSpinDelta: 0,
    };
  },
  methods: {
    toggleShow(show) {
      this.show = show;
    },
    startAnim(type) {
      this.stopAnim();
      this.toggleShow(true);
      this.frameDelta = 1;
      this.lastPerformance = performance.now();
      // this.expandOrigin = this.expandOriginTarget
      if (type === "expand") {
        this.currentAnimationRequest = requestAnimationFrame(
          this.animateExpand
        );
      } else if (type === "splash") {
        this.currentAnimationRequest = requestAnimationFrame(
          this.animateSplash
        );
      }
    },
    doExpand() {
      this.expandStart = true;
      this.expandStartTime = this.time;
    },
    doSplash(startHeight = -0.5) {
      this.splashStart = true;
      this.splashStartTime = this.time;
      for (let i = 0; i < this.shapes; i++) {
        this.splashXSplatter[i] =
          2 * (Math.random() - 0.5) * SPLASH_X_RANDOM_BASE;
        this.splashYStart[i] =
          2 * (Math.random() - 0.5) * SPLASH_Y_RANDOM_START - startHeight;
        this.splashYMult[i] =
          1 + 2 * (Math.random() - 0.5) * SPLASH_Y_RANDOM_MULT;
        this.scale[i] = 1;
        this.blur[i] = 0;
      }
    },
    stopAnim() {
      this.toggleShow(false);
      cancelAnimationFrame(this.currentAnimationRequest);
    },
    animateSplash() {
      this.frameDelta = (performance.now() - this.lastPerformance) / FRAME_TIME;
      this.lastPerformance = performance.now();

      this.time += this.frameDelta;

      if (this.splashStart)
        this.splashLerp = (this.time - this.splashStartTime) / SPLASH_ANIM_TIME;

      // end
      if (this.splashLerp > 1) {
        this.splashLerp = false;
        this.stopAnim();
        return;
      }

      const splashArc = 1 - (2 * this.splashLerp - 1) ** 2;
      const opacity =
        (1 - Math.max(this.splashLerp, SPLASH_OPACITY_THRESHOLD)) /
        (1 - SPLASH_OPACITY_THRESHOLD);

      for (let i = 0; i < this.shapes; i++) {
        this.posX[i] = this.splashLerp * this.splashXSplatter[i] * this.vW;
        this.posY[i] =
          (this.splashYStart[i] + splashArc) *
          SPLASH_Y_BASE *
          this.vH *
          this.splashYMult[i];
        this.opacity[i] = opacity;

        this.updateStyle(i);
      }

      this.currentAnimationRequest = requestAnimationFrame(this.animateSplash);
    },
    animateExpand() {
      this.frameDelta = (performance.now() - this.lastPerformance) / FRAME_TIME;
      this.lastPerformance = performance.now();

      this.time += this.frameDelta;

      if (this.expandStart)
        this.expandLerp = (this.time - this.expandStartTime) / EXPAND_ANIM_TIME;

      // start gradient a bit early
      if (
        this.expandLerp > EXPAND_GRADIENT_THRESHOLD &&
        !this.expandGradientSet
      )
        this.doExpandGradient();

      // end
      if (this.expandLerp > 1) {
        this.$emit("colortransend");
        this.expandStart = false;
        this.stopAnim();
        return;
      }

      const spinSpeed = keyframer(this.expandLerp, EXPAND_SPIN_FRAMES);
      const spinRad = keyframer(this.expandLerp, EXPAND_RAD_FRAMES);
      const scale = keyframer(this.expandLerp, EXPAND_SCALE_FRAMES);
      const opacity = keyframer(this.expandLerp, EXPAND_OPACITY_FRAMES);
      const blur = keyframer(this.expandLerp, EXPAND_BLUR_FRAMES);
      const noiseScale = keyframer(this.expandLerp, EXPAND_NOISE_SCALE_FRAMES);

      // lerp origin
      this.expandOriginLerp = constrain(
        this.expandOriginLerp + EXPAND_ORIGIN_SPEED * this.frameDelta,
        0,
        1
      );
      const originPerc = keyframer(
        this.expandOriginLerp,
        EXPAND_MOVE_LERP_FRAMES
      );
      this.expandOrigin.x = lerp(
        this.expandOriginPrevTarget.x,
        this.expandOriginTarget.x,
        originPerc
      );
      this.expandOrigin.y = lerp(
        this.expandOriginPrevTarget.y,
        this.expandOriginTarget.y,
        originPerc
      );

      // apply

      const noiseX =
        noise(this.expandLerp * EXPAND_NOISE_TURBULENCE, 100) *
        noiseScale *
        this.vMax;
      const noiseY =
        noise(this.expandLerp * EXPAND_NOISE_TURBULENCE, 200) *
        noiseScale *
        this.vMax;
      this.expandSpinDelta += spinSpeed * this.frameDelta;

      for (let i = 0; i < this.shapes; i++) {
        this.posX[i] =
          Math.cos(this.expandSpinDelta + i * EXPAND_SPIN_SPACING) *
            spinRad *
            this.vMin +
          this.expandOrigin.x * this.vW +
          noiseX;
        this.posY[i] =
          Math.sin(this.expandSpinDelta + i * EXPAND_SPIN_SPACING) *
            spinRad *
            this.vMin +
          this.expandOrigin.y * this.vH +
          noiseY;
        this.opacity[i] = opacity;
        this.scale[i] = scale;
        this.blur[i] = blur;

        this.updateStyle(i);
      }

      this.currentAnimationRequest = requestAnimationFrame(this.animateExpand);
    },
    doExpandGradient() {
      this.expandGradientSet = true;
      this.$emit("colortrans");
      Background.setColors(defaultGradient);
    },
    setExpandOriginTarget(x, y) {
      this.expandOriginLerp = 0;
      this.expandOriginPrevTarget.x = this.expandOrigin.x;
      this.expandOriginPrevTarget.y = this.expandOrigin.y;
      this.expandOriginTarget.x = x;
      this.expandOriginTarget.y = y;
    },
    updateStyle(index) {
      this.$refs[`shape-${index}`].style.transform = `translate3d(${
        this.posX[index]
      }px, ${this.posY[index]}px, ${index * 0.1}px) scale(${
        this.scale[index]
      })`;
      this.$refs[`shape-${index}`].style.opacity = this.opacity[index];
      this.$refs[`shape-${index}`].style.filter = `blur(${this.blur[index]}px)`;
    },
    setViewports() {
      this.vW = windowWidth;
      this.vH = windowHeight;
      this.vMin = Math.min(windowHeight, windowWidth);
      this.vMax = Math.max(windowHeight, windowWidth);
    },
  },
  mounted() {
    this.setViewports();
    window.addEventListener("resize", this.setViewports);
  },
  beforeUnmount() {
    this.stopAnim();
    window.removeEventListener("resize", this.setViewports);
  },
};
</script>
<style lang="scss">
.intro-shape {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  backface-visibility: hidden;

  &-leave-active,
  &-enter-active {
    transition: opacity 1s ease;
  }

  &-leave-to,
  &-enter-from {
    opacity: 0;
  }

  &__shape {
    pointer-events: none;
    position: fixed;
    z-index: $z-sticky;
    top: 50%;
    left: 50%;
    width: 1.5rem;
    height: 1.5rem;
    transform-origin: top left;
    will-change: transform;
    backface-visibility: hidden;

    &__svg {
      width: 100%;
      height: 100%;
      fill: #fff;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
