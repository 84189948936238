<template>
  <Transition>
    <!-- question -->
    <Page v-if="state === 0" class="default-page-anim">
      <SAM
        :data="page.question"
        class="page-content-anim page-content-margin"
      />
      <PageContent
        v-if="page.has_attachment"
        :data="page.attachment[0]"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="optionLabels" @choice="answer" />
    </Page>

    <!-- feedback -->
    <ScrollPage
      v-else-if="state === 1"
      class="default-page-anim"
      @next="detail"
    >
      <EmojiFeedback :data="feedback" :sound="sound" />
    </ScrollPage>

    <!-- tip -->
    <ScrollPage v-else class="default-page-anim" @next="next">
      <ActivityInfo :data="page.tip" class="page-content-anim" />
    </ScrollPage>
  </Transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import EmojiFeedback from "@/components/sections/chapter/activities/EmojiFeedback.vue";
import ActivityInfo from "@/components/content/ActivityInfo.vue";
import PageContent from "@/components/content/PageContent.vue";

import * as Background from "@/scripts/background.js";
import { shuffleArray } from "@/scripts/helpers.js";

const optionLabels = [];
const SOUND_MAP = ["wrong", "maybe", "correct"];

export default {
  emits: ["next", "progress"],
  components: {
    Page,
    PageContent,
    SAM,
    Dialogue,
    ScrollPage,
    EmojiFeedback,
    ActivityInfo,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    progress: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pageIndex: 0,
      lastPage: this.data.questions.length - 1,
      state: 0,
      page: null,
      feedback: null,
      sound: "maybe",
      optionLabels,
      score: 0,
    };
  },
  methods: {
    nextQuestion() {
      this.page = this.data.questions[this.pageIndex];
      this.optionLabels.length = 0;

      const options = this.page.options;
      shuffleArray(options);
      for (let i = 0; i < options.length; ++i) {
        this.optionLabels[i] = options[i].label;
      }
    },
    answer(index) {
      const score = parseInt(this.page.options[index].score);
      this.feedback = this.page.options[index].feedback;
      this.sound = SOUND_MAP[score];
      this.score += score;
      this.state = 1;
      this.$root.setHeaderProgress(
        this.pageIndex + 1,
        this.data.questions.length
      );
      Background.transitionDown();
      console.log("activity score", `+${score}`, this.score);
    },
    detail() {
      if (this.page.has_tip) {
        this.state = 2;
        Background.transitionDown();
      } else {
        this.next();
      }
    },
    next() {
      if (this.pageIndex === this.lastPage) {
        this.$emit("progress", 0);
        this.$emit("next", this.score);
      } else {
        this.pageIndex++;
        this.nextQuestion();
        this.state = 0;
        Background.transitionDown();
      }
    },
  },
  created() {
    this.$root.setHeaderProgressStyle("dot");
    this.$root.setHeaderDisplay(true);
    this.$root.setHeaderProgress(this.pageIndex, this.data.questions.length);
    this.nextQuestion();
  },
};
</script>
