<template>
  <div
    class="emoji-confetti"
    :style="{
      left: `${xOrigin}%`,
      top: `${yOrigin}%`,
    }"
  >
    <div
      class="emoji-confetti__particle"
      v-for="i in 5"
      :key="i"
      ref="particles"
    >
      <Emoji :name="emoji" />
    </div>
  </div>
</template>
<script>
import Emoji from "@/components/content/Emoji.vue";

import { map, easeOutElastic } from "@/scripts/helpers.js";
import { windowWidth, windowHeight } from "@/scripts/windowSize.js";
import { noise } from "@/scripts/perlin.js";
import { Clock } from "@/scripts/clock.js";

const PARTICLE_COUNT = 5;
const PARTICLE_X_RANGE = 0.5;
const PARTICLE_Y_RANGE = -0.5;
const NOISE_GAP = 100;
const NOISE_TURBULENCE = 0.5;
const LERP_RATE = 0.012;

export default {
  components: {
    Emoji,
  },
  props: {
    xOrigin: {
      type: Number,
      default: 50,
    },
    yOrigin: {
      type: Number,
      default: 50,
    },
    emoji: {
      type: String,
      default: "blessed",
    },
  },
  data() {
    return {
      lerp: [],
      posX: [],
      posY: [],
      scale: [],
      opacity: [],
      blur: [],
      vW: 0,
      vH: 0,
      vMin: 0,
      vMax: 0,
    };
  },
  methods: {
    initConfetti() {
      const lerpOffset = 1 / PARTICLE_COUNT;
      const randomDelay = Math.random() * 0.3;
      this.clock = new Clock();

      for (let i = 0; i < PARTICLE_COUNT; i++) {
        this.lerp.push(i * -lerpOffset - randomDelay);
        this.posX.push(0);
        this.posY.push(0);
        this.scale.push[0];
        this.opacity.push(0);
        this.blur.push(0);
      }
    },
    updateLoop() {
      this.clock.update();
      for (let i = 0; i < PARTICLE_COUNT; i++) {
        this.lerp[i] = (this.lerp[i] + LERP_RATE * this.clock.deltaTime) % 1;

        if (this.lerp[i] >= 0) {
          this.posX[i] =
            (noise(this.lerp[i] * NOISE_TURBULENCE, i * NOISE_GAP) - 0.5) *
            2 *
            PARTICLE_X_RANGE *
            this.vMin *
            this.lerp[i];
          this.posY[i] = this.lerp[i] * PARTICLE_Y_RANGE * this.vMin;
          this.scale[i] = easeOutElastic(map(this.lerp[i], 0, 0.8, 0, 1, true));
          this.opacity[i] = map(this.lerp[i], 0.3, 1, 1, 0, true);
          this.blur[i] = map(this.lerp[i], 0.3, 1, 0, 10, true);
        }

        this.updateStyles(i);
      }

      this.raf = requestAnimationFrame(this.updateLoop);
    },
    updateStyles(index) {
      this.$refs.particles[
        index
      ].style.transform = `translate(calc(${this.posX[index]}px - 50%), calc(${this.posY[index]}px - 50%)) scale(${this.scale[index]})`;
      this.$refs.particles[index].style.opacity = this.opacity[index];
      this.$refs.particles[index].style.filter = `blur(${this.blur[index]}px)`;
    },
    setViewports() {
      this.vW = windowWidth;
      this.vH = windowHeight;
      this.vMin = Math.min(windowHeight, windowWidth);
      this.vMax = Math.max(windowHeight, windowWidth);
    },
  },
  created() {
    this.initConfetti();
  },
  mounted() {
    this.setViewports();
    window.addEventListener("resize", this.setViewports);

    this.raf = requestAnimationFrame(this.updateLoop);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setViewports);
    cancelAnimationFrame(this.raf);
  },
};
</script>
<style lang="scss">
.emoji-confetti {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 0;
  height: 0;

  &__particle {
    position: absolute;
    top: 0;
    left: 0;
    width: 8rem;
    height: 8rem;
    will-change: transform;

    .emoji {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
