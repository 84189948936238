<template>
  <Transition>
    <ScrollPage v-if="currentPage === 0" class="default-page-anim" @next="next">
      <SAM
        :data="data.create_safety_plan.intro.content"
        class="page-content-anim page-content-margin"
      />
    </ScrollPage>
    <ScrollPage
      v-else-if="currentPage === 1"
      class="alert-page-in default-page-out"
      @next="toFirstChoice"
    >
      <div class="alert info info--black" v-html="alertMessage"></div>
      <AutoplayAudio id="safety-plan-alert" loop :fade-out="750" />
    </ScrollPage>
    <Page v-else-if="currentPage === 2" class="default-page-anim">
      <SAM
        :data="data.create_safety_plan.first_contact_question.content"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="contactOptions" @choice="firstContactChosen" />
    </Page>
    <Page v-else-if="currentPage === 3" class="default-page-anim">
      <SAM
        :data="data.create_safety_plan.second_contact_question.content"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="secondContactOptions" @choice="secondContactChosen" />
    </Page>
    <Page v-else-if="currentPage === 4" class="default-page-anim">
      <SAM
        :data="data.create_safety_plan.safe_location_question.content"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="safeLocationOptions" @choice="safeLocationChosen" />
    </Page>
    <Page v-else-if="currentPage === 5" class="default-page-anim">
      <SAM
        :data="data.create_safety_plan.professional_question.content"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="professionalOptions" @choice="professionalChosen" />
    </Page>
    <ScrollPage
      v-else-if="currentPage === 6"
      class="default-page-anim"
      @next="next"
    >
      <SafetyPlan class="page-content-anim page-content-margin" />
    </ScrollPage>
    <Page v-else-if="currentPage === 7" class="default-page-anim">
      <SAM
        :data="data.create_safety_plan.confirm.content"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="confirmOptions" @choice="confirm" />
    </Page>
    <ScrollPage
      v-else-if="currentPage === 8"
      class="default-page-anim"
      @next="toEnd"
    >
      <SAM
        :data="data.create_safety_plan.reminder.content"
        class="page-content-anim page-content-margin"
      />
    </ScrollPage>
    <Page v-else class="default-page-anim">
      <SAM :data="forkMessage" class="page-content-anim page-content-margin" />
      <Dialogue :options="forkOptions" @choice="finish" />
    </Page>
  </Transition>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import SafetyPlan from "@/components/content/SafetyPlan.vue";
import AutoplayAudio from "@/components/content/AutoplayAudio.vue";

import * as Background from "@/scripts/background.js";
import UserData from "@/scripts/userData.js";
import * as Analytics from "@/scripts/analytics.js";
import * as AudioManager from "@/scripts/audioManager.js";
import { data } from "@/scripts/data.js";
import {
  markdown,
  parse,
  parseCount,
  getMessageCount,
} from "@/scripts/insert.js";

const QUIZ_START_PAGE = 2;

function getOptionsLabels(options) {
  const labels = [];
  for (let i = 0; i < options.length; ++i) {
    labels[i] = `${options[i].label} ${options[i].emoji}`;
  }
  return labels;
}

function getBooleanOptionsLabels(options) {
  return [options.positive_label, options.negative_label];
}

export default {
  components: {
    ScrollPage,
    Page,
    SAM,
    Dialogue,
    SafetyPlan,
    AutoplayAudio,
  },
  emits: ["toInbox", "toKeyConcepts"],
  data() {
    const { contacts, locations, professionals, confirm, fork } =
      data.create_safety_plan;

    const split = data.create_safety_plan.alert.split(/\r?\n/);
    let alertMessage = "";
    for (const line of split) {
      if (line) {
        alertMessage += '<p class="alert__p">' + markdown(parse(line)) + "</p>";
      }
    }

    return {
      data,
      alertMessage,
      firstChoice: 0,
      contactOptions: getOptionsLabels(contacts),
      secondContactOptions: [],
      safeLocationOptions: getOptionsLabels(locations),
      professionalOptions: getOptionsLabels(professionals),
      confirmOptions: getBooleanOptionsLabels(confirm.options),
      forkMessage: parseCount(
        getMessageCount() === 1 ? fork.content_single : fork.content_multiple
      ),
      forkOptions: getBooleanOptionsLabels(fork.options),
      currentPage: 0,
      menuRevealed: false,
    };
  },
  methods: {
    parseCount,
    markdown,
    toEnd() {
      this.next();
      this.$root.iconStopPulse();
      this.endAudioTimeout = setTimeout(this.playMessageSound, 1250);
    },
    playMessageSound() {
      AudioManager.play("ui-new-message");
    },
    toFirstChoice() {
      this.currentPage = QUIZ_START_PAGE;
      this.$root.setHeaderTitle(data.create_safety_plan.section_title);
      this.$root.setHeaderProgressStyle("dot");
      this.$root.setHeaderDisplay(true);
      this.$root.setHeaderProgress(0, 4);
      Background.transitionDown();
    },
    next() {
      this.currentPage++;
      Background.transitionDown();
    },
    firstContactChosen(choice) {
      this.firstChoice = choice;

      this.safetyPlan = [];
      this.safetyPlan[0] = data.create_safety_plan.contacts[choice];

      // fill the list of secondary contact options with the remaining options
      this.secondContactOptions.length = 0;
      for (let i = 0; i < this.contactOptions.length; ++i) {
        if (i !== choice) {
          this.secondContactOptions.push(this.contactOptions[i]);
        }
      }

      this.$root.setHeaderProgress(1, 4);
      this.next();
    },
    secondContactChosen(choice) {
      this.safetyPlan[1] =
        data.create_safety_plan.contacts[
          choice < this.firstChoice ? choice : choice + 1
        ];

      this.$root.setHeaderProgress(2, 4);
      this.next();
    },
    safeLocationChosen(choice) {
      this.safetyPlan[2] = data.create_safety_plan.locations[choice];

      this.$root.setHeaderProgress(3, 4);
      this.next();
    },
    professionalChosen(choice) {
      this.safetyPlan[3] = data.create_safety_plan.professionals[choice];

      // save safety plan
      UserData.setSafetyPlan(this.safetyPlan);

      this.$root.setHeaderProgress(4, 4);
      this.next();
    },
    confirm(choice) {
      if (choice === 0) {
        const [primary, secondary, location, professional] =
          this.safetyPlan.map((item) => item.label);

        Analytics.trackEvent("create_safety_plan", {
          primary,
          secondary,
          location,
          professional,
        });

        // complete safety plan marks the end of the intro
        UserData.setIntro(true);

        this.$root.setHeaderDisplay(false);
        this.timeout = setTimeout(this.showMenuIcon, 1000);
        this.$root.iconStartPulse();
        this.next();
      } else {
        this.toFirstChoice();
      }
    },
    finish(choice) {
      // show the icon if it isn't already showing
      if (!this.menuRevealed) {
        clearTimeout(this.timeout);
        this.showMenuIcon();
      }

      this.$emit(choice === 0 ? "toInbox" : "toKeyConcepts");
    },
    showMenuIcon() {
      this.menuRevealed = true;
      this.$root.iconShow();
    },
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.endAudioTimeout);
  },
  mounted() {
    Analytics.trackProgress("create_safety_plan");
    AudioManager.loadIntroFiles();
  },
};
</script>

<style lang="scss">
@keyframes alert-enter-anim {
  0% {
    opacity: 0;
    filter: blur($blur);
    transform: translate3d(0, 10vh, $depth) scale(0.7);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes alert-pulse {
  0% {
    transform: none;
  }

  50% {
    transform: scale(1.01);
  }

  100% {
    transform: none;
  }
}

$duration: 1s;
$in-delay: 0.1s;
$ease: $ease-in-out-quad;
$depth: -10vh;

.alert-page-in {
  animation: alert-enter-anim $duration $in-delay $ease-out-cubic both;

  @media (prefers-reduced-motion) {
    animation: none;
  }
}

.alert {
  animation: alert-pulse 1s $ease-in-out-sine infinite;
  line-height: 1.75;

  &__p {
    margin: 1rem 0 0;

    &:first-child {
      margin: 0;
    }
  }
}
</style>
