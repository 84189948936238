// USER DATA STORAGE

// layer between the UserData and the Storage
// - fetch object from storage at startup
// - read values from the data object in app memory
// - write values to the object in app memory, then save entire object to storage

// do not wait for storage to synchronise
// potential progress loss is preferred over blocking gameplay

import Storage from "@/scripts/storage.js";
import UserApi from "@/scripts/userApi.js";
import PersistentData from "@/scripts/persistentData.js";
import SessionData from "@/scripts/sessionData.js";
import {
  getUserLevel,
  firstIncompleteChapter,
  createChapterProgress,
  createProgress,
} from "@/scripts/helpers.js";

let readyPromise;

// user data - ALL user data from api
let data;

let user;

// current save - progress for current profile/lang
let currentSave;

// local data - things that aren't sent to the server
let local = {};

function start() {
  console.log("-- USER DATA -- START");
  readyPromise = Storage.ready().then(initFromStorage, initBasic);
}

let isGuest = false;
function setGuestMode(bool) {
  isGuest = bool;

  if (isGuest) {
    currentSave = createNewSave(PersistentData.currentLang);
  }
}

function createNewSave(langId) {
  return {
    lang: langId,
    nickname: "",
    music: "",
    startTime: 0,
    safetyPlan: [],
    intro: false,
    concept: 0,
    feedback: false,
    progress: createProgress(PersistentData.data.chapters),
    outro: false,
    playerLevel: 0,
    news: Math.floor(Math.random() * PersistentData.data.news.length),
  };
}

function getSaveForLang(profile, lang) {
  // prefer existing save data
  // or create a new save with defaults
  if (profile.saves) {
    const save = profile.saves[lang];
    if (save) {
      console.log("save exists", save);
      currentSave = save;
    } else {
      console.log("new save");
      currentSave = createNewSave(lang);
      profile.saves[lang] = currentSave;
    }
  } else {
    console.log("new save (first)");
    currentSave = createNewSave(lang);
    profile.saves = {
      [lang]: currentSave,
    };
  }
}

function loadSave() {
  if (isGuest) {
    validate();
    return;
  }

  console.log(
    "UserData.loadSave()",
    SessionData.hasProfiles,
    local.activeProfile,
    PersistentData.currentLang
  );

  // assumes user data exists
  // selects the "currentSave" from the user data

  // profiles user
  if (SessionData.hasProfiles) {
    for (const profile of user.profiles) {
      if (profile.profileName === local.activeProfile) {
        getSaveForLang(profile, PersistentData.currentLang);
        break;
      }
    }
  }

  // non-profiles user
  else {
    getSaveForLang(user, PersistentData.currentLang);
  }

  validate();
}

function findProfileByName(profiles, name) {
  for (const profile of profiles) {
    if (profile.profileName === name) {
      return profile;
    }
  }
}

// INTERFACE WITH USERAPI
// TODO - refactor

async function sync() {
  if (isGuest) return;

  console.log("USER DATA START - UserData.sync()");
  // sync with the remote data
  await UserApi.sync();
  console.log("user data synchronised");
}

// get local data to send to server
function getPostData() {
  return data.userData;
}

function pullData(remoteData) {
  console.log("UserData.pullData()", remoteData);

  // replace data object with the one from the server
  data.userData = user = remoteData;

  // keep current save alive if missing
  keepSaveAlive();
  save();
  loadSave();
}

function validate() {
  if (!((user && currentSave) || isGuest)) return;

  const data = PersistentData.data;

  // news - reset if out of range
  if (data.news.length <= currentSave.news) {
    currentSave.news = 0;
  }

  // concepts - reset if out of range
  if (data.key_concepts.length <= currentSave.concepts) {
    currentSave.concepts = 0;
  }

  // progress - clear changed chapters
  const oldProgress = currentSave.progress;
  const newProgress = {};
  for (const group of data.chapters) {
    for (const chapter of group) {
      const id = chapter.post_id;
      const old = oldProgress[id];
      if (old && chapter.post_modified_time_gmt <= old.timestamp) {
        newProgress[id] = old;
      } else {
        console.log(`chapter ${id} changed or new`);
        newProgress[id] = createChapterProgress(chapter);
      }
    }
  }

  currentSave.progress = newProgress;

  // playerLevel - reset if range reduced
  currentSave.playerLevel = Math.min(
    currentSave.playerLevel,
    getUserLevel(data.chapters, newProgress)
  );

  // capter index - reset if exceeds available
  let availableChapters = 0;
  for (let i = 0; i < currentSave.playerLevel; ++i) {
    availableChapters += data.chapters[i].length;
  }

  if (local.chapterIndex >= availableChapters) {
    const index = firstIncompleteChapter(data.chapters, newProgress);
    local.chapterIndex = isNaN(index) ? availableChapters - 1 : index;
  }
}

function keepSaveAlive() {
  if (currentSave) {
    const langId = currentSave.lang;

    // profiles users
    if (SessionData.hasProfiles) {
      if (local.activeProfile) {
        const sameProfile = findProfileByName(
          user.profiles,
          local.activeProfile
        );
        if (sameProfile) {
          if (sameProfile.saves) {
            const sameSave = sameProfile.saves[langId];
            if (!sameSave) {
              sameProfile.saves[langId] = currentSave;
            }
          } else {
            sameProfile.saves = {
              [langId]: currentSave,
            };
          }
        } else {
          user.profiles.push({
            profileName: local.activeProfile,
            saves: {
              [langId]: currentSave,
            },
          });
        }
      }
    }

    // non-profiles
    else {
      const sameSave = user.saves[langId];
      if (!sameSave) {
        user.saves[langId] = currentSave;
      }
    }
  }
}

// STORAGE METHODS
// methods for saving the data object
// uses localForage via "Storage" wrapper

function ready() {
  return readyPromise;
}

// get the user data object from storage

async function initFromStorage() {
  console.log("init user data from storage");

  data = await Storage.getItem("userData");

  console.log("user data from storage", data);

  if (data) {
    user = data.userData;
    local = data.local;
    currentSave = null;
  } else {
    await initBasic();
  }
}

async function initBasic() {
  console.log("init user data from nothing");

  user = {};
  local = {};
  currentSave = null;

  data = {
    userData: user,
    local,
  };
}

// save latest user data object to storage
function save() {
  if (isGuest) return;

  Storage.setItem("userData", data);
}

// delete the user data (app and storage)
function clear() {
  currentSave = null;

  user = {
    appVersion: PersistentData.appVersion,
  };

  local = {
    chapterIndex: local.chapterIndex || 0,
  };

  data = {
    userData: user,
    local,
  };

  save();
}

async function nuke() {
  await Storage.removeItem("userData");
  await initBasic();
}

// INTERFACE

const UserData = {
  // api interfaces
  getPostData,
  pullData,
  validate,

  // collection interfaces
  ready,
  start,
  sync,
  clear,
  nuke,
  loadSave,
  setGuestMode,

  // getters and setters

  get currentSave() {
    return currentSave;
  },

  get profiles() {
    return user.profiles;
  },

  // nickname
  // string set in intro

  get nickname() {
    return currentSave?.nickname;
  },

  setNickname(value) {
    currentSave.nickname = value;
    // save with intro
  },

  // music
  // key for the music object

  get music() {
    return currentSave?.music;
  },

  setMusic(value) {
    currentSave.music = value;
    // save with intro
  },

  // startTime
  // epoch set in intro

  get startTime() {
    return currentSave.startTime;
  },

  setStartTime(value) {
    currentSave.startTime = value;
    // save with intro
  },

  // safetyPlan
  // object containing user's safety plan

  get safetyPlan() {
    return currentSave.safetyPlan;
  },

  setSafetyPlan(value) {
    currentSave.safetyPlan = value;
    // save with intro
  },

  // intro
  // boolean indicating if user completed intro

  get intro() {
    if (currentSave) {
      return currentSave.intro;
    } else {
      console.error(
        "accessed UserData.intro before save loaded - return false"
      );
      return false;
    }
  },

  setIntro(value) {
    currentSave.intro = value;
    save();
    sync();
  },

  // concept
  // integer for progress through key concepts

  get concept() {
    return currentSave.concept;
  },

  setConcept(value) {
    currentSave.concept = value;
    save();
    sync();
  },

  // feedback
  // boolean indicating if user completed feedback

  get feedback() {
    return currentSave.feedback;
  },

  setFeedback(value) {
    currentSave.feedback = value;
    save();
    sync();
  },

  // progress
  // array of chapter progress objects

  get progress() {
    return currentSave.progress;
  },

  // TODO - can this be like other setters not a "save"?
  saveProgress() {
    console.log("save the progress");
    save();
    sync();
  },

  // outro
  // boolean indicating completion of outro

  get outro() {
    return currentSave.outro;
  },

  setOutro(value) {
    currentSave.outro = value;
    save();
    sync();
  },

  // chapterIndex
  // bookmark current chapter

  get chapterIndex() {
    return local.chapterIndex;
  },

  setChapterIndex(value) {
    local.chapterIndex = value;
    save();
  },

  // playerLevel
  // track chapter unlocks
  // used to decide if inbox plays unlock anim

  get playerLevel() {
    return currentSave.playerLevel;
  },

  setPlayerLevel(value) {
    currentSave.playerLevel = value;
    save();
    sync();
  },

  // news
  // indeger for progress through news list

  get news() {
    return currentSave.news;
  },

  setNews(value) {
    currentSave.news = value;
    save();
    sync();
  },

  // local

  // activeProfile
  // track which profile is in use

  get activeProfile() {
    return local.activeProfile;
  },

  setActiveProfile(value) {
    local.activeProfile = value;
    save();
  },
};

// EXPORTS

// export the interface object
export default UserData;
