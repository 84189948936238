<template>
  <Transition name="header">
    <header
      v-show="show"
      class="header"
      :style="{
        '--header-title-width': `${titleWidth}px`,
        '--header-title-container-width': `${titleContainerWidth}px`,
        '--header-title-old-width': `${titleOldWidth}px`,
        '--header-title-new-width': `${titleNewWidth}px`,
        '--header-progress-dash': `${(progress / total) * 101 || 0}px`,
        '--header-prev-progress-dash': `${
          (prevProgress / prevTotal) * 101 || 0
        }px`,
        '--header-progress': (progress / total) * 100 || 0,
        '--header-progress-normalised': progress / total || 0,
        '--header-progress-width': `${progressWidth}px`,
        '--header-progress-hidden': !progress / total ? 'hidden' : 'visible',
      }"
    >
      <div class="header-wrap">
        <div
          ref="titleContainer"
          class="header__title-container"
          :class="{
            'header__title-container--overflow-hidden':
              titleOverflow && !titleOverflowShow,
            'header__title-container--overflow-shown':
              titleOverflow && titleOverflowShow,
          }"
        >
          <Transition
            type="transition"
            :name="
              prevTitle ? (transReverse ? 'title-back' : 'title') : 'title-none'
            "
          >
            <h1
              ref="title"
              :key="titleKey"
              class="header__title"
              :class="{
                'header__title--overflow-hidden':
                  titleOverflow && !titleOverflowShow,
                'header__title--overflow-shown':
                  titleOverflow && titleOverflowShow,
              }"
            >
              {{ title }}
            </h1>
          </Transition>
        </div>
        <div
          class="header__divider-container"
          :class="`header__divider-container--${progressStyle}`"
        >
          <div
            class="header__divider"
            :class="`header__divider--${progressStyle}`"
          ></div>
        </div>
        <div
          ref="progressContainer"
          class="header__progress-container"
          :class="`header__progress-container--${progressStyle}`"
        >
          <Transition :name="`progress-${progressStyle}`">
            <div
              v-if="progressStyle === 'pie'"
              class="header__progress-wrap header__progress-wrap--pie"
            >
              <HeaderPie :progress="progress / total" />
            </div>
            <div
              v-else-if="progressStyle === 'dot'"
              class="header__progress header__progress--dot"
            >
              <TransitionGroup name="progress-dot-dot" type="transition">
                <div
                  v-for="(item, i) in progressPattern"
                  :key="`dot-${i}`"
                  ref="dots"
                  class="header__progress__dot"
                  :class="[
                    {
                      'header__progress__dot--in': isIn,
                      'header__progress__dot--done': item === true,
                      'header__progress__dot--anim': progressDotWave,
                    },
                    `header__progress__dot--${i % 4}`,
                  ]"
                  :style="{
                    '--progress-wave-delay': `${i * 0.25}s`,
                    '--progress-wave-in-delay': `${i * 0.05}s`,
                  }"
                ></div>
              </TransitionGroup>
            </div>
            <div
              v-else-if="progressStyle === 'bar'"
              class="header__progress header__progress--bar"
            >
              <div class="header__progress__bar-circle"></div>
              <div class="header__progress__bar-end"></div>
            </div>
          </Transition>
        </div>
      </div>
    </header>
  </Transition>
</template>

<script>
import HeaderPie from "./HeaderPie.vue";

const HEADER_TRANS_TIME = 450;
const HEADER_TRANS_IN_DELAY = 750;
const HEADER_TITLE_WIDTH_TIME = 500;
const HEADER_DOT_ANIM_TIME = 800;
const HEADER_DOT_ANIM_GAP = 3500;

export default {
  components: {
    HeaderPie,
  },
  data() {
    return {
      title: "",
      prevTitle: "",
      titleKey: null, // key to trigger transition
      titleId: 0, // unique id for key and ref
      show: false,
      isIn: false,
      progressAnimationRetrigger: false,
      progressStyle: "dot",
      progress: 0,
      prevProgress: 0,
      total: 0,
      prevTotal: 0,
      progressPattern: [], // non linear progress shape
      savedState: {
        title: "",
        show: false,
        progressStyle: "dot",
        progress: 0,
        total: 0,
        progressPattern: [],
      }, // banked state for nav toggle
      titleWidth: 0,
      titleContainerWidth: 0,
      titleOldWidth: 0,
      titleNewWidth: 0,
      titleOverflow: false,
      titleOverflowShow: false,
      titleOverflowTimeout: null,
      titleOverflowShowInterval: null,
      progressWidth: 0,
      transReverse: false,
      progressDotWave: false,
    };
  },
  methods: {
    setTitle(title, reverse = false) {
      if (title === this.title) return;
      this.prevTitle = this.title;
      this.title = title;
      this.titleId++;
      this.titleKey = `header-title-${this.titleId}`;
      this.transReverse = reverse;

      // animate container
      // render frame after
      this.titleTimeout = requestAnimationFrame(this.updateTitleWidth);
    },
    setDisplayShow() {
      if (!this.titleWidth) this.updateTitleWidth();
      this.updateProgressWidth();
    },
    setDisplay(bool) {
      this.show = bool;
      if (this.show) {
        this.displayTimeout = requestAnimationFrame(this.setDisplayShow);
      }
    },
    setProgress(progress, total, pattern = null) {
      if (this.prevProgress !== progress) this.prevProgress = this.progress;
      this.progress = progress;
      this.prevTotal = this.total;
      if (total) this.total = total;

      // pattern
      if (pattern) {
        this.progressPattern = pattern;
      } else {
        // generate linear pattern
        this.progressPattern = [];
        for (let i = 0; i < this.total; i++) {
          if (i < this.progress) {
            this.progressPattern.push(true);
          } else {
            this.progressPattern.push(false);
          }
        }
      }

      this.setProgressTransitioning();
      this.widthRecalcRaf = requestAnimationFrame(this.recalcWidths);
    },
    setProgressStyle(style) {
      this.progressStyle = style;
      this.setProgressTransitioning();
      this.widthRecalcRaf = requestAnimationFrame(this.recalcWidths);
    },
    setProgressTransitioning() {
      this.progressAnimationRetrigger = true;
      this.progressAnimationRetriggerRaf = requestAnimationFrame(
        this.setProgressTransitioning2
      );
    },
    setProgressTransitioning2() {
      this.progressAnimationRetrigger = false;
    },
    // BANK STATE FOR TOGGLES
    saveState() {
      this.savedState.title = this.title;
      this.savedState.show = this.show;
      this.savedState.progressStyle = this.progressStyle;
      this.savedState.progress = this.progress;
      this.savedState.total = this.total;
      this.savedState.progressPattern = JSON.parse(
        JSON.stringify(this.progressPattern)
      ); // clone array
    },
    restoreState2() {
      this.setTitle(this.savedState.title);
      this.setProgressStyle(this.savedState.progressStyle);
      this.setProgress(
        this.savedState.progress,
        this.savedState.total,
        this.savedState.progressPattern
      );
    },
    restoreState() {
      const delay = this.savedState.show ? 0 : HEADER_TRANS_TIME;
      this.setDisplay(this.savedState.show);

      // if previous state was just hidden, set other properties after hidden again
      this.stateTimeout = setTimeout(this.restoreState2, delay);
    },
    updateTitleWidth() {
      const container = this.$refs.titleContainer;
      if (!container) return;
      const oldElement = container.firstElementChild;
      const newElement = container.lastElementChild;
      const oldLength = oldElement.offsetWidth || 0;
      const newLength = newElement.offsetWidth || 0;
      this.titleWidth = newLength;
      this.titleOldWidth = oldLength;
      this.titleNewWidth = newLength;
      clearTimeout(this.titleOverflowTimeout);
      clearInterval(this.titleOverflowShowInterval);
      this.titleOverflow = false;
      this.titleOverflowTimeout = setTimeout(
        this.checkTitleOverflow,
        HEADER_TITLE_WIDTH_TIME * 2
      );
    },
    checkTitleOverflow() {
      const title = this.$refs.title;
      if (!title) return;
      const container = this.$refs.titleContainer;
      this.titleContainerWidth = container.clientWidth;
      if (title.clientWidth > this.titleContainerWidth)
        this.titleOverflow = true;
      if (this.titleOverflow)
        this.titleOverflowShowInterval = setInterval(
          this.toggleTitleOverflowShow,
          5000
        );
    },
    toggleTitleOverflowShow() {
      this.titleOverflowShow = !this.titleOverflowShow;
    },
    updateProgressWidth() {
      // if using dot layout, calculate with dots to maintain flow when transitioning between dots
      if (this.progressStyle === "dot") {
        const arr = this.$refs.dots;
        let width = 0;
        if (arr && arr.length) {
          width =
            arr[arr.length - 1].offsetLeft +
            arr[arr.length - 1].offsetWidth -
            arr[0].offsetLeft;
        }
        this.progressWidth = width;
      } else {
        const container = this.$refs.progressContainer;
        this.progressWidth =
          container && container.lastElementChild
            ? container.lastElementChild.offsetWidth
            : 0;
      }
    },
    restoreDot() {
      this.progressDotWave = true;
    },
    retriggerDotAnim() {
      if (this.progressStyle !== "dot" || !this.show) return;
      this.progressDotWave = false;
      // 2 frame delay to ensure last state change was on a prev frame
      this.frameDelay = requestAnimationFrame(this.retriggerDotAnim2);
    },
    retriggerDotAnim2() {
      this.frameDelay = requestAnimationFrame(this.restoreDot);
    },
    onResize() {
      this.recalcWidths();
    },
    recalcWidths() {
      this.updateTitleWidth();
      this.widthRecalcRaf = requestAnimationFrame(this.updateProgressWidth);
    },
    setIn() {
      this.isIn = true;
    },
  },
  mounted() {
    this.recalcWidths();
    window.addEventListener("resize", this.onResize);

    // synced animation retrigger
    this.interval = setInterval(
      this.retriggerDotAnim,
      HEADER_DOT_ANIM_TIME + HEADER_DOT_ANIM_GAP
    );

    this.isInTimeout = setTimeout(
      this.setIn,
      HEADER_TRANS_TIME + HEADER_TRANS_IN_DELAY
    );
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
    clearInterval(this.interval);
    clearInterval(this.titleOverflowShowInterval);
    clearTimeout(this.isInTimeout);
    clearTimeout(this.stateTimeout);
    clearTimeout(this.titleOverflowTimeout);
    cancelAnimationFrame(this.displayTimeout);
    cancelAnimationFrame(this.titleTimeout);
    cancelAnimationFrame(this.widthRecalcRaf);
    cancelAnimationFrame(this.frameDelay);
    cancelAnimationFrame(this.progressAnimationRetriggerRaf);
  },
};
</script>

<style lang="scss">
$header-trans-time: 450ms;
$header-trans-in-delay: 750ms;
$header-title-width-time: 500ms;
$header-dot-anim-time: 800ms;
$header-dot-anim-gap: 3500ms;
$header-progress-trans-step: 450ms;
$header-title-margin: 1rem;

@keyframes header-enter-anim {
  0% {
    opacity: 0;
    filter: blur($blur);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

@keyframes header-leave-anim {
  0% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    filter: blur($blur);
    transform: translateY(-100%);
  }
}

.header {
  position: absolute;
  top: $page-top;
  width: 100%;
  z-index: $z-sticky;

  // HEADER TRANSITION
  &-enter-active {
    animation: header-enter-anim $header-trans-time $header-trans-in-delay
      ease-out both;

    .header__divider {
      animation: none;
    }
  }

  &-leave-active {
    animation: header-leave-anim $header-trans-time ease-in both;
  }

  // STYLE CHANGE TRANSITION

  .progress-dot-enter-active,
  .progress-pie-enter-active,
  .progress-bar-enter-active,
  .progress-none-enter-active,
  .progress-dot-leave-active,
  .progress-pie-leave-active,
  .progress-bar-leave-active,
  .progress-none-leave-active {
    position: absolute;
    transition: width, margin-left, opacity;
    transition-duration: $header-progress-trans-step;
    transition-delay: 0s, 0s, $header-progress-trans-step * 2;
    transition-timing-function: ease-in-out;
  }

  .progress-pie-enter-active {
    // use irrelevant property to time out
    transition: width, margin-left, opacity, color;
    transition-duration: $header-progress-trans-step,
      $header-progress-trans-step, $header-progress-trans-step,
      $header-progress-trans-step * 3;
    transition-delay: 0s, 0s, $header-progress-trans-step * 2, 0s;
    transition-timing-function: ease-in-out;
  }

  .progress-dot-enter-active {
    .header__progress__dot {
      opacity: 0;
    }
  }

  .progress-bar-enter-active {
    transition-delay: $header-progress-trans-step * 3, 0s,
      $header-progress-trans-step * 2;
  }

  .progress-dot-leave-active,
  .progress-pie-leave-active,
  .progress-bar-leave-active,
  .progress-none-leave-active {
    transition-delay: 0s;
  }

  .progress-dot-enter-from,
  .progress-pie-enter-from,
  .progress-bar-enter-from,
  .progress-none-enter-from {
    opacity: 0;
  }

  .progress-bar-enter-from {
    width: 1rem;
  }

  .progress-pie-enter-to {
    opacity: 1;
  }

  .progress-dot-leave-to,
  .progress-pie-leave-to,
  .progress-bar-leave-to,
  .progress-none-leave-to {
    opacity: 0;
  }

  &-wrap {
    display: flex;
    align-items: center;
    width: var(--container-width);
    margin: 0 auto;
  }

  &__title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    overflow: clip;
    width: var(--header-title-width, fit-content);
    margin-right: 1rem;
    transition: width $header-title-width-time ease, mask-position 1.75s ease;

    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 2rem,
      rgba(0, 0, 0, 1) calc(100% - 2rem),
      rgba(0, 0, 0, 0) 100%
    );
    mask-size: calc(100% + 4rem) 100%;
    mask-position: -2rem 0;

    &--overflow-hidden {
      mask-position: -4rem 0;
    }

    &--overflow-shown {
      mask-position: 0 0;
    }
  }

  &__title {
    white-space: nowrap;
    width: fit-content;
    margin-right: $header-title-margin;
    font-size: 1rem;
    font-weight: $semi-bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &--overflow-hidden {
      transition: transform 1.75s ease-in-out;
    }

    &--overflow-shown {
      transition: transform 1.75s ease-in-out;
      transform: translate3d(
        calc(var(--header-title-container-width, 0px) - 100%),
        0,
        0
      );
    }
  }

  // animation
  // normal scrolls <-, reverse scrolls ->
  .title-enter-active,
  .title-back-enter-active,
  .title-leave-active,
  .title-back-leave-active {
    transition: transform 0.5s ease;
  }

  .title-back-enter-active {
    order: -1;
    position: absolute;
    left: calc(var(--header-title-new-width, 0px) * -1);
  }

  .title-enter-from,
  .title-leave-from {
    transform: translateX(0px);
  }

  .title-enter-to,
  .title-leave-to {
    transform: translateX(
      calc(var(--header-title-old-width, 0px) * -1 - #{$header-title-margin})
    );
  }

  .title-back-enter-from {
    transform: translateX(#{$header-title-margin * -1});
  }

  .title-back-leave-from {
    transform: translateX(0px);
  }

  .title-back-enter-to {
    transform: translateX(var(--header-title-new-width, 0px));
  }

  .title-back-leave-to {
    transform: translateX(
      calc(var(--header-title-new-width, 0px) + #{$header-title-margin})
    );
  }

  &__divider {
    width: 0;
    height: 0.125rem;
    border-radius: 0.0625rem;
    background-color: #fff;
    animation: header-divider-in 0.75s ease $header-trans-in-delay 1 both;

    &--none {
      animation: header-divider-in 0.75s ease 1 both;
    }

    &-container {
      flex: 1 1 auto;
      display: flex;
    }
  }

  &__progress {
    &--pie {
      display: block;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 100%;
    }

    &--dot {
      width: fit-content;
      display: flex;
      margin-right: auto;
    }

    &--bar {
      display: flex;
      align-items: center;
      width: 100%;
      transition: width;
      transition-duration: $header-progress-trans-step;
      transition-timing-function: ease-in-out;
    }

    &-container {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 1rem;
      width: var(--header-progress-width, auto);
      height: 2rem;

      transition: width, margin-left;
      transition-duration: $header-progress-trans-step;
      transition-delay: $header-progress-trans-step;
      transition-timing-function: ease-in-out;

      &--bar {
        justify-content: flex-start;
        margin-left: 0;
        width: calc(
          (1 - var(--header-progress-normalised, 0)) *
            (100% - var(--header-title-width, 0px) - 1rem) +
            var(--header-progress-normalised, 0) * 1rem
        );
      }

      &--none {
        margin-left: 0;
        width: 0;
      }
    }

    &-wrap {
      &--pie {
        width: fit-content;
        height: fit-content;
        box-sizing: border-box;
      }
    }

    &__pie {
      height: 100%;
      stroke-width: 100px;
      stroke-dasharray: 0px 100px;
      transform: rotate(-90deg);
      transform-origin: center;
    }

    &__dot {
      border: 0.125rem solid #fff;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      margin: auto 0;
      margin-left: 0.25rem;
      opacity: 0;
      transition: background-color 0.25s ease,
        opacity 0.25s ease var(--progress-wave-in-delay, 0ms);

      &--in {
        opacity: 1;
      }

      &--anim {
        @include float-anim(
          header-progress-dot-float,
          0.25rem,
          $header-dot-anim-time,
          0s,
          $header-dot-anim-gap
        );
        animation-delay: var(--progress-wave-delay, 0s);
        animation-iteration-count: 1;
      }

      &--done {
        background-color: #fff;
      }

      &:first-child {
        margin-left: 0;
      }

      &.progress-dot-dot-enter-active,
      &.progress-dot-dot-leave-active {
        transition: background-color, transform, opacity;
        transition-duration: 0.25s;
        transition-timing-function: ease;
      }

      &.progress-dot-dot-enter-from {
        transform: translateY(-1rem);
        opacity: 0;
      }

      &.progress-dot-dot-leave-to {
        transform: translateY(1rem);
        opacity: 0;
      }
    }

    &__bar {
      &-end {
        flex: 1 0 auto;
        height: 0.125rem;
        background-color: #fff;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      &-circle {
        flex: 0 0 auto;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        border: 0.15rem solid #fff;
      }
    }
  }

  @keyframes header-divider-in {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
}
</style>
