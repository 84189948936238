<template>
  <Transition>
    <InfoBefore v-if="state === 0" :data="data.before" @next="toInfo" />
    <ScrollPage
      v-else-if="state === 1"
      @next="toAfter"
      class="default-page-anim"
    >
      <div class="page-content-anim info info--white">
        <p class="chapter-info__subtitle">{{ subtitle }}</p>
        <h2 class="chapter-info__title">{{ title }}</h2>
      </div>
      <div
        v-for="card in data.cards"
        class="page-content-anim card-margin info info--white"
      >
        <CardContent :blocks="card.blocks" />
      </div>
    </ScrollPage>
    <InfoAfter v-else-if="state === 2" :data="data.after" @next="next" />
  </Transition>
</template>

<script>
import InfoBefore from "./info/InfoBefore.vue";
import InfoAfter from "./info/InfoAfter.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import CardContent from "@/components/card/CardContent.vue";

import * as Background from "@/scripts/background.js";
import { parse } from "@/scripts/insert.js";
import SessionData from "@/scripts/sessionData.js";

export default {
  emits: ["next"],
  components: {
    InfoBefore,
    ScrollPage,
    CardContent,
    InfoAfter,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      state: this.data.before ? 0 : 1,
      title: parse(this.data.title),
      subtitle: SessionData.lang.Chapter.search_results_subtitle,
    };
  },
  methods: {
    toInfo() {
      this.$root.shapeOut();
      this.state = 1;
      Background.transitionDown();
    },
    toAfter() {
      if (this.data.after) {
        this.state = 2;
        Background.transitionDown();
      } else {
        this.next();
      }
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss">
.chapter-info {
  &__subtitle {
    font-size: 0.75rem;
    margin: -0.25rem 0 0.5rem;
  }

  &__title {
    margin: 0 0 -0.375rem;
    font-size: 1.25rem;
    font-weight: $bold;
  }
}
</style>
