const elements = [];
const callbacks = [];

export function observe(el, fn) {
  elements.push(el);
  callbacks.push(fn);
}

function mutationObserved(mutations) {
  mutations.forEach(iterateMutated);
}

function iterateMutated(mutation) {
  mutation.removedNodes.forEach(testRemoved);
}

function testRemoved(node) {
  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i];
    if (node === element || node.contains(element)) {
      callbacks[i]();

      // remove the event
      elements.splice(i, 1);
      callbacks.splice(i, 1);

      --i;
    }
  }
}

const observer = new MutationObserver(mutationObserved);
observer.observe(document.body, { subtree: true, childList: true });
