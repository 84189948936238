const LOAD_EVENT_NAME = "onyoutubecriptload";
const SCRIPT_ID = "youtube-iframe-api";

let readyCallback;

function createScript() {
  const elementById = document.getElementById(SCRIPT_ID);
  if (!elementById) {
    const script = document.createElement("SCRIPT");
    script.src = "https://www.youtube.com/iframe_api";
    script.async = true;
    script.id = SCRIPT_ID; // for checking across multiple components

    document.head.appendChild(script);
  }
  window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
}

// window callback
function onYouTubeIframeAPIReady() {
  const event = new CustomEvent(LOAD_EVENT_NAME);
  window.dispatchEvent(event);
}

export function isLoaded() {
  return typeof YT === "object";
}

export function onReady(callback) {
  if (isLoaded()) {
    callback();
  } else {
    readyCallback = callback;
    createScript();
    window.addEventListener(LOAD_EVENT_NAME, readyCallback);
  }
}

export function cancelReady() {
  window.removeEventListener(LOAD_EVENT_NAME, readyCallback);
  readyCallback = null;
}
