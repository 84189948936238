<template>
  <div
    class="globe"
    :class="{
      'globe--show': show,
    }"
  >
    <canvas class="globe__canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { country } from "@/scripts/globals.js";
import OfflineMedia from "@/scripts/offlineMedia.js";
import { noop } from "@/scripts/globals.js";

const IN_TIME = 3750;

const ORIGIN = 3 * Math.PI;

const renderSettings = {
  canvas: null,
  alpha: true,
  antialias: false,
};

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dimmed: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["loaded"],
  watch: {
    show(newValue) {
      if (newValue) {
        this.startAnimation();
      }
    },
  },
  methods: {
    startAnimation() {
      // only run once, when all checks pass
      if (this.waiting && this.show && this.loaded && this.ready) {
        this.waiting = false;

        // draw start state
        this.startTime = performance.now();
        this.animate();

        this.$emit("loaded");
      }
    },
    animate(t) {
      // rotate to show target (first concept)
      if (this.target) {
        let alpha = (t - this.startTime) / IN_TIME;
        if (alpha < 1) {
          alpha = 1 - (1 - alpha) ** 3;
          this.model.rotation.y = alpha * this.xTarget + (1 - alpha) * ORIGIN;
          this.modelYPivot.rotation.x = alpha * this.yTarget;
        } else {
          this.model.rotation.y = this.xTarget;
          this.modelYPivot.rotation.x = this.yTarget;
        }
      }

      // spin the globe (news reel)
      else {
        this.model.rotation.y = 0.005 * t;
      }

      this.renderer.render(this.scene, this.camera);

      this.raf = requestAnimationFrame(this.animate);
    },
    modelLoaded(gltf) {
      this.model = gltf.scene;
      this.modelYPivot.add(this.model);

      this.loaded = true;
      this.startAnimation();
    },
    fromStorage() {
      OfflineMedia.getItem(this.url).then(this.storageSuccess, this.fromServer);
    },
    storageSuccess(cachedModel) {
      if (cachedModel) {
        const loader = new GLTFLoader();
        this.model = loader.load(
          URL.createObjectURL(cachedModel),
          this.modelLoaded,
          noop,
          this.fromServer
        );
      } else {
        this.fromServer();
      }
    },
    fromServer() {
      const loader = new GLTFLoader();
      this.model = loader.load(
        this.url,
        this.modelLoaded,
        noop,
        this.continueWithoutModel
      );
    },
    continueWithoutModel() {
      // continue the news screen without the globe
      this.$emit("loaded");
    },
    resize() {
      const width = this.$refs.canvas.clientWidth;
      this.renderer.setSize(width, width);
    },
  },
  created() {
    this.waiting = true;
    this.ready = false;
    this.loaded = false;

    // create three.js scene
    this.scene = new THREE.Scene();

    // start loading the globe model
    this.url = require("@/assets/models/low_poly_earth.glb");
    if (OfflineMedia.available) {
      this.fromStorage();
    } else {
      this.fromServer();
    }

    // create lights
    this.light1 = new THREE.AmbientLight(0xffffff, this.dimmed ? 0.2 : 0.4);
    this.scene.add(this.light1);

    this.light2 = new THREE.DirectionalLight(0xffffff, this.dimmed ? 0.8 : 0.6);
    this.light2.position.set(-3, 3, 0.25);
    this.scene.add(this.light2);

    this.modelYPivot = new THREE.Object3D();
    this.scene.add(this.modelYPivot);

    // create perspective camera
    this.camera = new THREE.PerspectiveCamera(24, 1, 0.01, 100);
    this.camera.position.y = 1;
    this.camera.position.z = 5;
    this.camera.rotation.x = Math.atan2(-1, 5);

    // calculate target position
    if (this.target) {
      // angle offset to center lat/long 0,0
      this.xTarget = Math.PI * (country.long / -180) - Math.PI / 3.5;
      this.yTarget = Math.PI * (country.lat / 180) - Math.PI / 12;
    }
  },
  mounted() {
    renderSettings.canvas = this.$refs.canvas;
    this.renderer = new THREE.WebGLRenderer(renderSettings);
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setPixelRatio(1.5);
    this.renderer.shadowMap.enabled = false;

    window.addEventListener("resize", this.resize);
    this.resize();

    this.ready = true;
    this.startAnimation();
  },
  beforeUnmount() {
    cancelAnimationFrame(this.raf);
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="scss">
.globe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;

  pointer-events: none;
  user-select: none;

  opacity: 0;
  transform: translateX(100%);
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-timing-function: ease;

  &--show {
    opacity: 1;
    transform: translateX(0);
  }

  &__canvas {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
