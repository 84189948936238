// PREVENT HISTROY GESTURES
// browsers often trigger back/forward on horizontal scroll gestures
// modern browsers are prevented by "overscroll-behavior: none" on the document
// older browsers will need touch and wheel events blocked

function preventHistroy(e) {
  e.preventDefault();
}

if (!("overscrollBehavior" in document.body.style)) {
  window.addEventListener("touchstart", preventHistroy);
  window.addEventListener("wheel", preventHistroy);
}
