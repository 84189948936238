<template>
  <div class="first-concept default-page-out">
    <div class="first-concept__globe" aria-hidden="true">
      <div ref="globe" class="first-concept__globe-wrap">
        <Globe :show="currentData.shape_animation === 'location'" target />
      </div>
    </div>
    <Transition appear>
      <ScrollPage
        ref="page"
        :key="state"
        class="default-page-anim"
        @next="next"
      >
        <SAM
          v-if="hasSAM"
          ref="content"
          :data="currentData.content"
          class="page-content-anim page-content-margin"
        />
        <PageContent
          v-if="hasContent"
          :data="currentData"
          class="page-content-anim page-content-margin"
        />
        <!-- additional elements -->
        <Transition>
          <div
            v-if="currentData.shape_animation === 'time'"
            ref="time"
            class="page-content-anim first-concept__time"
          >
            <div
              v-for="i in 12"
              class="first-concept__time__mark"
              :style="{
                '--mark-rot': `${i * 30}deg`,
              }"
            ></div>
          </div>
        </Transition>
        <MusicEmojis
          v-if="musicCondition"
          :name="userData.music"
          show
          class="first-concept__music"
        />
      </ScrollPage>
    </Transition>
  </div>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import Globe from "@/components/content/Globe.vue";
import MusicEmojis from "@/components/content/MusicEmojis.vue";
import PageContent from "@/components/content/PageContent.vue";
import SAM from "@/components/content/SAM.vue";

import * as Background from "@/scripts/background.js";
import UserData from "@/scripts/userData.js";
import * as Analytics from "@/scripts/analytics.js";
import * as AudioManager from "@/scripts/audioManager.js";
import * as Data from "@/scripts/data.js";

const targetMap = {
  time: "time",
  location: "globe",
};

export default {
  emits: ["fin"],
  components: {
    SAM,
    ScrollPage,
    Globe,
    MusicEmojis,
    PageContent,
  },
  data() {
    const data = Data.data.first_concept.first_key_concept;
    return {
      state: 0,
      data,
      currentData: data[0],
      prevData: data[0],
      userData: UserData,
      hasSAM: false,
      hasContent: false,
      lastPage: data.length - 1,
    };
  },
  computed: {
    musicCondition() {
      if (!this.currentData.content) return;
      return this.currentData.content.match(/\[MUSIC\]/gim) ? true : false;
    },
  },
  methods: {
    next() {
      if (this.state === this.lastPage) {
        this.$emit("fin");
      } else {
        this.state++;
        this.prevData = this.currentData;
        this.currentData = this.data[this.state];
        this.updateSAM();
        this.frameDelay = requestAnimationFrame(this.updateShapes);
        Background.transitionDown();
      }
    },
    updateSAM() {
      // from KeyConcepts
      if (this.currentData.acf_fc_layout === "comment") {
        this.hasContent = false;
        this.hasSAM = true;
      } else {
        this.hasContent = true;
        this.hasSAM = !!this.currentData.content;
      }
    },
    updateShapes() {
      if (
        this.currentData.shape_animation &&
        (this.currentData.shape_animation !== this.prevData.shape_animation ||
          this.state == 0)
      ) {
        // only update if changed
        this.$root.shapeState(this.currentData.shape_animation);
        this.$root.shapeIn();
      } else if (this.currentData.shape_animation) {
        this.$root.shapeNext();
      } else {
        this.$root.shapeOut();
      }

      const target = targetMap[this.currentData.shape_animation] || "content";
      const ref = this.$refs[target];

      if (target === "content" && ref) {
        this.$root.shapeTarget(ref.$el.nextElementSibling);
      } else if (ref) {
        this.$root.shapeTarget(ref);
      }
    },
  },
  mounted() {
    this.$root.setHeaderDisplay(false);

    Analytics.trackProgress("first_key_concept");
    AudioManager.loadConceptsFiles();
    AudioManager.loadGenreFiles();

    this.updateSAM();

    this.shapeDelay = requestAnimationFrame(this.updateShapes);
  },
  beforeUnmount() {
    this.$root.shapeOut();

    cancelAnimationFrame(this.shapeDelay);
    cancelAnimationFrame(this.frameDelay);
  },
};
</script>

<style lang="scss">
.first-concept {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;

  &__content {
    &--time,
    &--location {
      margin-bottom: 25vh;
    }
  }

  &__globe {
    position: absolute;
    bottom: 0;
    right: calc(calc(100vw - 100%) * -0.5);
    width: 100vmin;
    height: 100vmin;
    overflow: hidden;
    overflow: clip;
    pointer-events: none;

    &-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 8rem;
      margin-left: 40vmin;
    }
  }

  &__music {
    margin-top: 6rem;
  }

  &__time {
    position: absolute;
    bottom: 0;
    right: var(--container-indent);
    width: var(--container-width);
    height: var(--container-width);
    margin-right: -3.5rem;
    z-index: -1;

    border-radius: 100%;
    box-shadow: $fragment-shadow;
    background: rgba(255, 255, 255, 0.1);
    // backdrop-filter: $fragment-blur;
    // backface-visibility: hidden;

    &__mark {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.25rem;
      height: 2rem;
      background-color: #000;
      border-radius: 0.125rem;
      transform: translate(-50%, -50%) rotate(var(--mark-rot)) translateY(9rem);
    }
  }
}
</style>
