<template>
  <div class="page scroll-page" :class="scrollClass" ref="page" tabindex="0">
    <div class="page__inner container" :class="innerClass" ref="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import * as Background from "@/scripts/background.js";
import { focusElement } from "@/scripts/helpers.js";
import * as Scroll from "@/scripts/scroll.js";

// COMPONENT

export default {
  emits: ["next"],
  props: {
    innerClass: {
      type: String,
      default: null,
    },
    swipe: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollTop: 0,
      scrollMax: 0,
      scrollPrev: 0,
      scrollClass: null,
    };
  },
  watch: {
    swipe: function (newVal) {
      if (newVal) {
        this.$root.setPage(this);
        this.resize();
        this.showPrompt();
      }
    },
  },
  methods: {
    removeEvents() {
      window.removeEventListener("resize", this.resize);
      this.$refs.page.removeEventListener("scroll", this.pageScroll);
      cancelAnimationFrame(this.raf);
      clearTimeout(this.timeout);

      this.$root.promptReset();
    },

    // check if the user is scrolled to the end of the page
    testScroll() {
      if (this.swipe) {
        this.scrollTop = this.$refs.page.scrollTop;

        if (Scroll.atEnd) {
          if (Math.floor(this.scrollTop) < this.scrollMax) {
            Scroll.reset();
            this.$root.promptSetEnd(false);
          }
        } else if (Math.floor(this.scrollTop) >= this.scrollMax) {
          Scroll.reachedEnd();
          this.$root.promptSetEnd(true);
        }
      }
    },

    resize() {
      this.scrollMax = Math.ceil(
        this.$refs.content.offsetHeight - this.$refs.page.offsetHeight - 10
      );
      this.testScroll();
    },

    // scroll event
    pageScroll() {
      this.testScroll();
      Background.updateScrollY(this.scrollTop - this.scrollPrev);
      this.$root.hideSwipePrompt();
      this.scrollPrev = this.scrollTop;
    },

    toNextPage() {
      this.removeEvents();

      Scroll.reset();

      this.$emit("next");
    },

    // ANIMATION LOOP

    animate() {
      this.$root.promptValue(Scroll.amount);

      if (Scroll.amount === 1) {
        this.toNextPage();
      } else {
        this.raf = requestAnimationFrame(this.animate);
      }
    },

    showPrompt() {
      this.$root.promptShow();
    },
    start() {
      this.raf = requestAnimationFrame(this.animate);

      // enable scroll
      this.$refs.page.addEventListener("scroll", this.pageScroll);
      window.addEventListener("resize", this.resize);
      this.resize();

      this.scrollClass = "scroll-page--scroll";

      // reveal prompt
      if (this.swipe) {
        this.$root.setPage(this);
        this.timeout = setTimeout(this.showPrompt, 3000);
      }
    },
  },
  mounted() {
    Scroll.reset();

    this.timeout = setTimeout(this.start, 1000);

    focusElement(this.$refs.page);
  },
  beforeUnmount() {
    this.removeEvents();
  },
};
</script>

<style lang="scss">
.scroll-page {
  @include hide-scrollbar;

  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  outline: none;
  touch-action: none;

  &--scroll {
    overflow-y: auto;
    touch-action: pan-y;
  }
}
</style>
