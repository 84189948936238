<template>
  <Transition>
    <Page ref="page" class="hotspot-page default-page-anim">
      <div
        class="hotspot__heading-wrap"
        :style="{ height: `${headingHeight}px` }"
      >
        <div class="hotspot__heading-container" ref="headingContainer">
          <Transition>
            <div :key="`heading-${currentPage}`" class="hotspot__heading">
              <SAM :data="page.instruction" class="page-content-margin" />
            </div>
          </Transition>
        </div>
      </div>
      <div class="page-content-margin hotspot-container">
        <Transition>
          <div :key="currentPage" class="fragment hotspot">
            <ImageAttachment :image="page.image" class="hotspot__image" />
            <button
              type="button"
              ref="button"
              class="hotspot__button"
              :class="{
                'hotspot__button--pulse-in': hotspotPulseState == 1,
                'hotspot__button--pulse-out': hotspotPulseState == 2,
                'hotspot__button--disable': buttonClick,
              }"
              :disabled="buttonClick"
              :tabindex="buttonClick ? '-1' : null"
              :style="{
                top: `${hotspot.y}%`,
                left: `${hotspot.x}%`,
              }"
              :aria-label="hotspotButtonLabel"
              @click="hotspotClick"
              @mouseenter="hotspotMouseIn"
              @mouseleave="hotspotMouseOut"
            >
              <span class="hotspot__button__pulse" aria-hidden="true"></span>
            </button>
            <div
              v-if="hasTip"
              class="hotspot__tip"
              ref="tip"
              :style="{
                '--offset-x': `${hotspotTransOffset}%`,
              }"
            >
              <div
                class="hotspot__buffer"
                :style="`width: ${hotspot.x}%`"
              ></div>
              <div
                class="hotspot__content info info--black"
                :class="[
                  hotspot.y < 50
                    ? 'hotspot__content--below'
                    : 'hotspot__content--above',
                  {
                    'hotspot__content--show': showTip,
                    'hotspot__content--out': tipOut,
                  },
                ]"
              >
                {{ page.tip }}
                <button
                  type="button"
                  class="hotspot__next"
                  :disabled="!showTip"
                  :tabindex="showTip ? null : '-1'"
                  @click="onTipNext"
                >
                  {{ buttonLabel }}
                  <svg
                    class="hotspot__next__icon"
                    viewBox="0 0 17 20"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M1.5 2.20577L15 10L1.5 17.7942L1.5 2.20577Z"
                      stroke="#000"
                      stroke-width="3"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Page>
  </Transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import ImageAttachment from "@/components/content/image/ImageAttachment.vue";
import { smoothScrollTo } from "@/scripts/helpers.js";
import * as AudioManager from "@/scripts/audioManager.js";
import { windowHeight } from "@/scripts/windowSize.js";
import SessionData from "@/scripts/sessionData.js";

const PULSE_DUR = 1400;
const PULSE_GAP = 1600;

export default {
  emits: ["next"],
  components: {
    Page,
    SAM,
    ImageAttachment,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const page = this.data.steps[0];

    return {
      currentPage: 0,
      hasTip: page.has_tip && page.tip,
      showTip: false,
      tipOut: false,
      buttonLabel: SessionData.lang.Interactions.next_button_label,
      buttonClick: false,
      lastPage: this.data.steps.length - 1,
      page,
      headingHeight: 0,
      hotspot: {},
      hotspotTransOffset: 0,
      hotspotPulseState: 0,
      hotspotTimeout: null,
      hotspotButtonLabel: SessionData.lang.Interactions.hotspot_label,
    };
  },
  methods: {
    hotspotClick() {
      this.buttonClick = true;
      if (this.hasTip) {
        // decide if the tip is above or below the hotspot
        const box = this.$refs.button.getBoundingClientRect();
        const style = this.$refs.tip.style;
        if (box.bottom + box.top < windowHeight) {
          style.top = `${this.hotspot.y}%`;
          style.bottom = "auto";
        } else {
          style.top = "auto";
          style.bottom = `${100 - this.hotspot.y}%`;
        }

        this.hotspotOffset();
        this.showTip = true;
        AudioManager.play("ui-input-button-misc");
      } else {
        AudioManager.play("ui-input-button-select");
        this.next();
      }
    },
    onTipNext() {
      this.next();
      AudioManager.play("ui-input-button-select");
    },
    next() {
      this.showTip = false;
      this.tipOut = true;
      this.timeout = setTimeout(
        this.currentPage === this.lastPage ? this.fin : this.nextPage,
        500
      );
    },
    nextPage() {
      this.currentPage++;

      // update data for new page
      this.page = this.data.steps[this.currentPage];
      this.hasTip = this.page.has_tip && this.page.tip;
      this.updateHotspot();

      // reset display state and anims
      this.tipOut = false;
      this.buttonClick = false;
      clearTimeout(this.hotspotTimeout);

      // start animating new page
      smoothScrollTo(this.$refs.page.$el, 0);
      this.hotspotTimeout = setTimeout(this.hotspotPulse, PULSE_GAP);

      // animate heading slightly late???
      this.timeout = setTimeout(this.updateHeading);
    },
    fin() {
      this.$emit("next", 0);
    },
    updateHeading() {
      this.headingHeight = this.$refs.headingContainer.offsetHeight;
    },
    updateHotspot() {
      this.hotspot = JSON.parse(this.page.hotspot);
    },

    // pulse anim loop
    hotspotPulse() {
      this.hotspotPulseState = 1;
      this.hotspotTimeout = setTimeout(this.hotspotPulse2, PULSE_DUR / 2);
    },
    hotspotPulse2() {
      this.hotspotPulseState = 2;
      this.hotspotTimeout = setTimeout(this.hotspotPulse3, PULSE_DUR / 2);
    },
    hotspotPulse3() {
      this.hotspotPulseState = 0;
      this.hotspotTimeout = setTimeout(this.hotspotPulse, PULSE_GAP);
    },

    hotspotMouseIn() {
      clearTimeout(this.hotspotTimeout);
    },
    hotspotMouseOut() {
      this.hotspotTimeout = setTimeout(this.hotspotPulse, PULSE_GAP);
    },
    hotspotOffset() {
      if (!this.hasTip) return;
      const box1 = this.$refs.button.getBoundingClientRect();
      const box2 = this.$refs.tip.getBoundingClientRect();
      this.hotspotTransOffset = ((box1.x - box2.x) / box2.width) * 100;
    },
    resize() {
      this.updateHeading();
      this.hotspotOffset();
    },
  },
  mounted() {
    this.updateHotspot();
    this.resize();
    window.addEventListener("resize", this.resize);
    this.hotspotPulse();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
    clearTimeout(this.hotspotTimeout);
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss">
$button-radius: 2.25rem;
$duration: 1s;
$shadow: 0 0 1.25rem 0.3125rem rgba(0, 0, 0, 0.25);
$pulse-dur: 1400ms;

@keyframes hotspot-next-click {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.hotspot-container {
  position: relative;
  perspective: $perspective;
}

.hotspot {
  position: relative;
  width: 100%;
  padding: 0;
  transform-style: preserve-3d;

  @keyframes hotspot-in {
    0% {
      transform: translateY(100vh) translateZ(1vmin) rotateX(-2deg);
    }
    50% {
      transform: translateZ(1vmin) rotateX(-2deg);
    }
    100% {
      transform: none;
    }
  }

  @keyframes hotspot-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.v-enter-active {
    animation: hotspot-in $duration ease 1 both;
  }

  &.v-leave-active {
    animation: hotspot-out $duration ease 1 both;
    position: absolute;
  }

  &__heading-wrap {
    transition: height 0.5s ease;
    overflow: visible;
  }

  &__heading-container {
    position: relative;
  }

  &__heading {
    &.v-enter-active,
    &.v-leave-active {
      transition: transform 0.5s ease, opacity 0.5s ease;
    }

    &.v-leave-active {
      position: absolute;
    }

    &.v-enter-from {
      transform: translate(100vw);
      opacity: 0;
    }

    &.v-leave-to {
      transform: translate(-100vw);
      opacity: 0;
    }
  }

  &__image {
    border-radius: $content-radius;
  }

  &__button {
    @include reset-button;

    position: absolute;
    width: 2 * $button-radius;
    height: 2 * $button-radius;
    transform: translate(-50%, -50%);
    border-radius: $button-radius;

    &::before {
      content: "";
      display: block;
      box-sizing: border-box;
      width: 2 * $button-radius;
      height: 2 * $button-radius;
      border-radius: $button-radius;
      background: rgba(255, 255, 255, 0.2);
      border: solid 0.3125rem white;
      box-shadow: $shadow;
      opacity: 0;
      transform: scale(0.8);
      transition-property: transform, opacity;
      transition-duration: $pulse-dur * 0.4;
      transition-timing-function: $ease-in-out-cubic;
      transition-delay: $pulse-dur * 0.2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      box-sizing: border-box;
      width: 2.5rem;
      height: 2.5rem;
      margin: -1.25rem;
      border: solid 0.0625rem #000;
      border-radius: 1.25rem;
      opacity: 0;
      transform: scale(0.8);
      transition-property: transform, opacity;
      transition-duration: $pulse-dur * 0.4;
      transition-timing-function: $ease-in-out-cubic;
    }

    &--pulse-in {
      &::before,
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &--pulse-out {
      &::before,
      &::after {
        opacity: 0;
        transform: scale(1);
      }
    }

    @media (hover: hover) {
      &:hover,
      &:focus-within {
        &::before,
        &::after {
          opacity: 1;
          transform: scale(1);
          transition-duration: 0.35s;
        }

        &::before {
          transition-delay: 0.05s;
        }
      }
    }

    &__pulse {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      border: solid 0.3125rem #fff;
      pointer-events: none;
      opacity: 0;

      @keyframes hotspot-button-pulse {
        0% {
          // maintain border width
          width: 100%;
          height: 100%;
          opacity: 1;
        }

        100% {
          width: 300%;
          height: 300%;
          opacity: 0;
        }
      }
    }

    &--disable {
      pointer-events: none;

      &::before,
      &::after {
        opacity: 1;
        transform: scale(1);
        transition-duration: 0.35s;
      }

      &::before {
        transition-delay: 0.05s;
      }

      .hotspot__button__pulse {
        animation: hotspot-button-pulse 0.5s ease-out 1;
      }
    }
  }

  &__tip {
    pointer-events: none;
    position: absolute;
    top: 0;
    display: flex;
    margin: -2rem;
  }

  &__buffer {
    flex: 0 1 auto;
    min-width: 1rem;
  }

  &__content {
    flex: 0 1 auto;
    box-shadow: $shadow;
    transition: transform 0.45s $ease-out-back, opacity 0.35s $ease-out-quart;
    opacity: 0;
    line-height: 1.5;

    &--above {
      margin: 0 0 $button-radius * 2 + 0.5rem;
      transform: scale(0.8) translateY(2rem);
      transform-origin: var(--offset-x, left) bottom;
    }

    &--below {
      margin: $button-radius * 2 + 0.5rem 0 0;
      transform: scale(0.8) translateY(-2rem);
      transform-origin: var(--offset-x, left) top;
    }

    &--show {
      pointer-events: auto;
      opacity: 1;
      transform: translateY(0);
    }

    &--out {
      // background: red;
      transform: translateY(-2rem);
      transition-delay: 0.15s;

      & > .hotspot__next {
        animation: hotspot-next-click 0.3s $ease-out-cubic;
      }
    }
  }

  &__next {
    @include reset-button;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1.25rem;
    margin-block-start: 1.25rem;
    border-radius: 1.5rem;
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    font-weight: $bold;
    text-transform: uppercase;
    transition: transform 0.25s $ease-in-out-cubic;

    @media (hover: hover) {
      &:hover,
      &:focus-within {
        transform: scale(1.05);
      }
    }

    &__icon {
      margin-left: 0.5rem;
      height: 1em;
      width: auto;
    }
  }
}
</style>
