<template>
  <Transition>
    <!-- navigation screen if there are multiple activities -->
    <Page v-if="state === 'menu'" class="default-page-anim">
      <SAM :data="menuSam" class="page-content-anim page-content-margin" />
      <ol class="activity-nav page-content-margin">
        <li
          class="activity-nav__item page-content-anim card-margin"
          v-for="(activity, i) in activities"
        >
          <button
            type="button"
            class="activity-nav__button info info--white"
            :class="{
              'activity-nav__button--done': progress[i],
              'activity-nav__button--chosen':
                activityChosen && i === activityIndex,
              'activity-nav__button--remove':
                activityChosen && i !== activityIndex,
            }"
            :disabled="activityChosen"
            @click="chooseActivity(i)"
          >
            <span class="activity-nav__label">{{ activity.title }}</span>
            <svg
              viewBox="0 0 100 100"
              class="activity-nav__shape"
              :class="
                progress[i]
                  ? 'activity-nav__shape--quad'
                  : 'activity-nav__shape--tri'
              "
              :style="`animation-delay:${i * 0.3 + 1.5}s`"
              aria-hidden="true"
            >
              <polygon
                v-if="progress[i]"
                fill="#FFF"
                stroke-width="12"
                stroke-linejoin="round"
                stroke="#FFF"
                points="90.00,50.00 50.00,90.00 10.00,50.00 50.00,10.00"
              />
              <polygon
                v-else
                fill="transparent"
                stroke-width="12"
                stroke-linejoin="round"
                stroke="currentColor"
                points="91.00,50.00 29.50,85.51 29.50,14.49"
              />
            </svg>
          </button>
        </li>
      </ol>
    </Page>

    <!-- activity sequence -->
    <HotspotSequence
      v-else-if="state === 'activity'"
      :data="activity"
      @next="finishedActivity"
    />

    <!-- revisit -->
    <Page v-else class="default-page-anim">
      <SAM
        v-for="copy in revisitSam"
        :data="copy"
        class="page-content-anim page-content-margin"
      />
      <Dialogue :options="revisitOptions" @choice="revisitChoice" />
    </Page>
  </Transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import HotspotSequence from "./HotspotSequence.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import * as Data from "@/scripts/data.js";
import * as AudioManager from "@/scripts/audioManager.js";

export default {
  emits: ["next", "progress"],
  components: {
    Page,
    SAM,
    Dialogue,
    HotspotSequence,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    progress: {
      type: Array,
      required: true,
    },
  },
  data() {
    const activities = this.data.questions;

    const onlyOne = activities.length === 1;

    if (onlyOne) {
      return {
        onlyOne,
        state: "activity",
        activity: activities[0],
      };
    }

    const data = {
      onlyOne,
      state: "menu",

      menuSam: this.data.prompt,

      activities,
      activity: null,
      activityChosen: false,
      activityIndex: null,
    };

    if (this.isFinished()) {
      data.revisiting = true;

      const fork = Data.data.return.chapter_forks.replay_hotspot;
      data.revisitSam = fork.prompt.split(/\r?\n/);
      data.revisitOptions = [fork.options.results, fork.options.replay];
    } else {
      data.revisiting = false;
    }

    return data;
  },
  methods: {
    isFinished() {
      for (const activity of this.progress) {
        if (!activity) return false;
      }
      return true;
    },
    chooseActivity(index) {
      this.activityChosen = true;
      this.activityIndex = index;
      this.activity = this.activities[index];
      AudioManager.play("ui-input-button-misc");
      this.clickTimeout = setTimeout(this.toActivity, 600);
    },
    toActivity() {
      this.state = "activity";
    },
    finishedActivity() {
      // one and done
      if (this.onlyOne) {
        this.$emit("progress", 0);
        this.$emit("next", 0);
      }

      // replaying users can select another activity or view results
      else if (this.revisiting) {
        this.state = "revisit";
      }

      // update progress then check completion
      else {
        this.$emit("progress", this.activityIndex);
        this.$nextTick(this.checkCompletion);
      }
    },
    checkCompletion() {
      if (this.isFinished()) {
        this.$emit("next", 0);
      } else {
        this.toMenu();
      }
    },
    revisitChoice(index) {
      if (index === 0) {
        this.$emit("next", 0);
      } else {
        this.toMenu();
      }
    },
    toMenu() {
      this.activityChosen = false;
      this.state = "menu";
    },
  },
  beforeUnmount() {
    clearTimeout(this.clickTimeout);
  },
};
</script>

<style lang="scss">
@keyframes activityNavQuad {
  0% {
    transform: none;
  }

  5% {
    transform: scale(1.1);
  }

  10% {
    transform: none;
  }
}

@keyframes activityNavTri {
  0% {
    transform: none;
  }

  5% {
    transform: translateX(10%);
  }

  10% {
    transform: none;
  }
}

@keyframes activity-nav-remove {
  0% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0px);
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
    filter: blur(10px);
  }
}

@keyframes activity-nav-chosen {
  0% {
    transform: scale(1.01);
  }

  20% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.02);
  }
}

.activity-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__item {
    &:first-child {
      margin: 0 !important;
    }
  }

  &__button {
    appearance: none;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 0;
    border: 0;

    font-family: inherit;
    font-weight: $bold;
    font-size: 1.5rem;
    line-height: 1.25;

    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &--done {
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      padding-right: $content-padding - 0.125rem;
    }

    &--remove {
      animation: activity-nav-remove 0.5s ease-out both;
    }

    &--chosen {
      animation: activity-nav-chosen 0.5s ease-out both;
    }

    transition: transform 0.3s ease-out;

    &:hover {
      transform: scale(1.01);
    }
  }

  &__label {
    margin: -0.25rem 0;
  }

  &__shape {
    flex: 0 0 auto;
    width: 2rem;
    height: 2rem;
    color: var(--color-2);
    margin: 0 0 0 1rem;
    transition: transform 0.3s ease-out;

    &--quad {
      animation: activityNavQuad 3s ease-out infinite;
    }

    &--tri {
      animation: activityNavTri 3s ease-out infinite;
    }
  }
}
</style>
