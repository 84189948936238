import UserData from "@/scripts/userData.js";
import PersistentData from "@/scripts/persistentData.js";
import SessionData from "@/scripts/sessionData.js";
import Time from "@/scripts/time.js";
import { country } from "@/scripts/globals.js";
import { getUserLevel } from "@/scripts/helpers.js";
import { data } from "@/scripts/data.js";
import musicGenres from "@/data/musicGenres.json";

// COUNTRY

// get contry from user's timezone
const countryName = country.name;

// MESSAGE COUNT

let messageCount;
let needNewMessageCount = true;

export function getMessageCount() {
  if (needNewMessageCount) {
    needNewMessageCount = false;

    const chapters = PersistentData.data.chapters;
    const progress = UserData.progress;
    const userLevel = SessionData.isFacilitated
      ? chapters.length
      : getUserLevel(chapters, progress);

    messageCount = 0;
    for (let i = 0; i < userLevel; ++i) {
      messageCount += chapters[i].length;
    }

    requestAnimationFrame(unblockMessageCount);
  }

  return messageCount;
}
function unblockMessageCount() {
  needNewMessageCount = true;
}

// EMAIL / USERNAME

let username;

export function setUsername(newUsername) {
  console.log("set username", newUsername);

  username = newUsername
    .replace(/(?<after>:)/giu, "$1<wbr>")
    .replace(/(?<before>[/~.,\-_?#%])/giu, "<wbr>$1")
    .replace(/(?<beforeAndAfter>[=&@])/giu, "<wbr>$1<wbr>");
}

// MUSIC

function getMusic() {
  return data.bootup.music_genre.options[
    musicGenres[UserData.music].labelKey
  ].toLowerCase();
}

// PLAY TIME

function getPlayTime() {
  // minutes

  let interval = (Date.now() - UserData.startTime) / 1000 / 60;
  let rounded = Math.round(interval);

  if (rounded < 60) return `${rounded} minutes`;

  // hours

  interval /= 60;
  rounded = Math.round(interval);

  if (rounded === 1) {
    return `${rounded} hour`;
  } else if (rounded < 24) {
    return `${rounded} hours`;
  }

  // days

  interval /= 24;
  rounded = Math.round(interval);

  if (rounded === 1) {
    return `${rounded} day`;
  } else if (rounded < 7) {
    return `${rounded} days`;
  }

  // weeks

  rounded = Math.round(interval / 7);

  if (rounded === 1) {
    return `${rounded} week`;
  } else if (rounded < 5) {
    return `${rounded} weeks`;
  }

  // months

  rounded = Math.round(interval / 30);

  if (rounded === 1) {
    return `${rounded} month`;
  } else if (interval < 365) {
    return `${rounded} months`;
  }

  // years

  rounded = Math.round(interval / 365);

  if (rounded === 1) {
    return `${Math.floor(interval / 365)} year`;
  }
  return `${Math.floor(interval / 365)} years`;
}

// PARSERS

export function parse(text) {
  if (text) {
    return text
      .replace(/\[username\]/gi, username)
      .replace(/\[nickname\]/gi, UserData.nickname)
      .replace(/\[location\]/gi, countryName)
      .replace(/\[time\]/gi, Time.getString)
      .replace(/\[music\]/gi, getMusic)
      .replace(/\[messages\]/gi, getMessageCount)
      .replace(/\[character\]/gi, SessionData.character.name)
      .replace(/\[age\]/gi, getPlayTime);
  }
}

export function markdown(text) {
  if (text)
    return text
      .replace(/\*\*([^*]*)\*\*/gim, "<strong>$1</strong>")
      .replace(/\*([^*]*)\*/gim, "<em>$1</em>")
      .replace(/\r?\n/gim, "<br>");
}

export function parseEllipsis(text) {
  if (text)
    return text
      .replace(/…/g, "...")
      .replace(
        /\.{3,}/g,
        '&#8288;<span class="ellipsis__1">.</span>&#8288;<span class="ellipsis__2">.</span>&#8288;<span class="ellipsis__3">.</span>'
      );
}

function messageCountPing() {
  return `<span class="count"><span class="count__text">${getMessageCount()}</span><svg class="count__svg" width="100" height="100" preserveAspectRatio="none"><defs><mask id="mask" x="0" y="0" width="100%" height="100%"><rect id="alpha" x="0" y="0" width="100%" height="100%" fill="#FFF" /><text id="title" x="50%" y="0" dy="1em" fill="#000">${getMessageCount()}</text></mask></defs><circle class="count__bg" cx="50%" cy="50%" r="50%" mask="url(#mask)" /></svg></span>`;
}

export function parseCount(text) {
  return text.replace(/\[messages\]/gi, messageCountPing);
}
