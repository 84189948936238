import { globals } from "./_globals.js";
import { constrain } from "@/scripts/helpers.js";

const IN_TIME = 500;
const NEXT_TIME = 750;
const JUMP_PERIOD = 2000;
const JUMP_GAP = 250;

const SNAP_VMAX = 2;
const SNAP_SELFVMAX = 4;
const SNAP_ACCELSTEP = 0.5;
const SNAP_DECELDIST = 12;
const SNAP_TURNFACTOR = 1;

const BASE_VMAX = 0.75;
const BASE_SELFVMAX = 0.75;
const BASE_ACCELSTEP = 0.5;
const BASE_DECELDIST = 15;
const BASE_TURNFACTOR = 0.05;

const SHAPE_TOPMARGIN = 2;

export default {
  // maintain for constant updating
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  doIn: false,
  isDoingNext: false,
  inJumpDist: false,
  shapeJumped: [],
  runningJumpTime: 0,
  lastJumpTime: 0,
  init() {
    this.setTarget();
    this._reset();
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      shape.resetProps();
      if (globals.isOut)
        shape.setProp("pos", { x: 0, y: globals.rem * -2, z: 0 });
      shape.addForceRand(globals.rem / 4);
      shape.addForce(
        0,
        0,
        ((Math.random() * 0.7 + 0.3 - 0.5) * globals.rem) / 4
      );
    }
  },
  update() {
    this.setTarget();
    this.runningJumpTime += globals.clock.deltaRaw;

    // get average distance
    let totalDist = 0;
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      totalDist += shape.targetDist.hyp;
    }
    const avgDist = totalDist / globals.shapes.length;
    // catch false negatives from going out of range while jumping
    if (!this.isDoingNext && avgDist <= globals.rem * 2) this.inJumpDist = true;

    const canJump = !this.isDoingNext && this.inJumpDist;
    let clampY = Infinity;

    if (canJump) {
      // timing
      if (this.runningJumpTime >= JUMP_PERIOD) {
        this.runningJumpTime = 0;
        this.lastJumpTime = globals.clock.currentTime;
        for (let i = 0; i < globals.shapes.length; i++) {
          this.shapeJumped[i] = false;
        }
      }
    } else {
      if (globals.targetElement)
        clampY =
          globals.targetElement.getBoundingClientRect().y -
          globals.rem * SHAPE_TOPMARGIN;
    }

    // main loop
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];

      if (!globals.isLeaving || globals.isOut)
        shape.setTarget(this.originX + i * 2.5 * globals.rem, this.originY);

      if (canJump) {
        if (
          globals.clock.currentTime >= this.lastJumpTime + i * JUMP_GAP &&
          !this.shapeJumped[i]
        ) {
          this.shapeJumped[i] = true;
          shape.setProp("selfVMax", 0);
          shape.setProp("turnFactor", 0.9);
          shape.setPropLerp("selfVMax", globals.rem, 1000);
          shape.setPropLerp("turnFactor", 0.05, 1000);
          shape.addForce(0, -globals.rem / 4, 0);
        }
      } else if (!this.doIn) {
        // nudge to boundary
        shape.pos.y -=
          (shape.pos.y - Math.min(shape.pos.y, clampY)) *
          constrain(0.2 * globals.clock.deltaTime, 0.2, 1);
      }

      shape.update();
    }
  },
  setTarget() {
    // if no client width element no longer in dom
    if (
      globals.prevTargetElement &&
      globals.prevTargetElement.clientWidth &&
      this.isDoingNext
    ) {
      const box = globals.prevTargetElement.getBoundingClientRect();
      this.originX = box.x + globals.rem / 2;
      this.originY = box.y - globals.rem * SHAPE_TOPMARGIN;
    } else if (globals.targetElement) {
      const box = globals.targetElement.getBoundingClientRect();
      this.originX = box.x + globals.rem / 2;
      this.originY = box.y - globals.rem * SHAPE_TOPMARGIN;
    }
  },
  _reset() {
    this.doIn = false;
    this.isDoingNext = false;
    this.inJumpDist = false;
    this.runningJumpTime = globals.clock.currentTime;
    this.lastJumpTime = globals.clock.currentTime;
    this.shapeJumped = [];
    for (let i = 0; i < globals.shapes.length; i++) {
      this.shapeJumped.push(false);
    }
  },
  onResize() {
    this.setTarget();
  },
  in() {
    this._reset();
    this.doIn = true;
    this.timeout = setTimeout(this.in2, IN_TIME);
  },
  in2() {
    this.doIn = false;
  },
  out() {
    clearTimeout(this.timeout);
    for (const shape of globals.shapes) {
      shape.addForce(0, -globals.rem * Math.random() * 2 * 0.1, 0);
      shape.setTarget(globals.vW, globals.rem * -2);
    }
  },
  kill() {
    clearTimeout(this.timeout);
  },
  onVisibilityChange() {
    this._reset();
  },
  next() {
    this._reset();
    this.isDoingNext = true;
    for (const shape of globals.shapes) {
      shape.setPropLerp("vMax", globals.rem * SNAP_VMAX, 150);
      shape.setPropLerp("accelStep", globals.rem * SNAP_ACCELSTEP, 150);
      shape.setPropLerp("decelDist", globals.rem * SNAP_DECELDIST, 150);
      shape.setPropLerp("selfVMax", globals.rem * SNAP_SELFVMAX, 150);
      shape.setPropLerp("turnFactor", SNAP_TURNFACTOR, 150);
    }
    this.timeout = setTimeout(this.next2.bind(this), NEXT_TIME * 0.5);
  },
  next2() {
    if (globals.stateKey !== "bounce") return;
    for (const shape of globals.shapes) {
      shape.setPropLerp("vMax", globals.rem * BASE_VMAX, NEXT_TIME / 2);
      shape.setPropLerp("selfVMax", globals.rem * BASE_SELFVMAX, NEXT_TIME / 2);
      shape.setPropLerp(
        "accelStep",
        globals.rem * BASE_ACCELSTEP,
        NEXT_TIME / 2
      );
      shape.setPropLerp(
        "decelDist",
        globals.rem * BASE_DECELDIST,
        NEXT_TIME / 2
      );
      shape.setPropLerp("turnFactor", BASE_TURNFACTOR, NEXT_TIME / 2);
    }

    this.timeout = setTimeout(this.next3.bind(this), NEXT_TIME * 0.5);
  },
  next3() {
    if (globals.stateKey !== "bounce") return;
    this._reset();
    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      shape.addForce(
        -globals.rem * 0.125 * Math.random(),
        -globals.rem * 0.25 * Math.random()
      );
    }
  },
};
