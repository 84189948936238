<template>
  <ImageAttachment v-if="image" :image="image" class="card-image" />
</template>

<script>
import ImageAttachment from "@/components/content/image/ImageAttachment.vue";

export default {
  components: {
    ImageAttachment,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: this.data.image,
    };
  },
};
</script>

<style lang="scss">
.card-image {
  margin: 2rem auto 0;
  width: 60%;
  border-radius: 0.5rem;

  &:first-child {
    margin: 0 auto;
  }
}
</style>
