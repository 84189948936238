import { Batch } from "@/scripts/batch.js";

// CALLBACKS
// if other components need to know when the connection status changes

const onlineCallbacks = new Batch();
const offlineCallbacks = new Batch();
const readyCallbacks = new Batch();

export function addOnlineEvent(fn) {
  onlineCallbacks.add(fn);
}

export function removeOnlineEvent(fn) {
  onlineCallbacks.remove(fn);
}

export function addOfflineEvent(fn) {
  offlineCallbacks.add(fn);
}

export function removeOfflineEvent(fn) {
  offlineCallbacks.remove(fn);
}

export function addReadyEvent(fn) {
  readyCallbacks.add(fn);
}

export function removeReadyEvent(fn) {
  readyCallbacks.remove(fn);
}

// MONITOR ONLINE STATUS

// navigator.onLine is not enough alone
// it will only check that you can access a network
// not that you can access the internet

// we keep track of whether the website server is reachable (data.json)
// then combine both values together

// status vars
export let isOnline;
export let isOffline;

function setOnline() {
  if (!isOnline) {
    console.log("-- CONNECTION -- ONLINE");

    isOnline = true;
    isOffline = false;
    onlineCallbacks.run();
  }
}

function setOffline() {
  if (!isOffline) {
    console.log("-- CONNECTION -- OFFLINE");

    isOnline = false;
    isOffline = true;
    offlineCallbacks.run();
  }
}

export function fetchSuccess() {
  setOnline();
}

export function fetchError() {
  setOffline();
}

// SETUP

export function start() {
  console.log("-- CONNECTION -- START");

  isOnline = false;
  isOffline = false;

  if (navigator.onLine === false) {
    setOffline();
  }

  window.addEventListener("offline", setOffline);
}

export function stop() {
  // clear callbacks
  onlineCallbacks.clear();
  offlineCallbacks.clear();
  readyCallbacks.clear();
}
