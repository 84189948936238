import { createApp } from "vue";
// import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import * as ServiceWorker from "./registerServiceWorker.js";
import { Amplify } from "@aws-amplify/core";
import awsConfig from "./aws-exports.js";
import * as Analytics from "./scripts/analytics.js";
import "./scripts/preventHistroyGestures.js";
import "./scripts/preventFocusScroll.js";
// import "./scripts/debug.js";

import UserData from "@/scripts/userData.js";
import PersistentData from "@/scripts/persistentData.js";
import OfflineMedia from "@/scripts/offlineMedia.js";
import * as Connection from "@/scripts/connection.js";
import * as Data from "@/scripts/data.js";
import * as Input from "@/scripts/input.js";
import * as Scroll from "@/scripts/scroll.js";
import * as AudioManager from "@/scripts/audioManager.js";
import * as Background from "@/scripts/background.js";

// const routes = [{ path: "/" }, { path: "/:code" }];
// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

// start analytics
Analytics.loadGTM();
Analytics.setItem("app_ver", process.env.VUE_APP_BUILD_NUM);

// start all async dependencies
ServiceWorker.start();
UserData.start();
PersistentData.start();
OfflineMedia.start();
Connection.start();
Data.start();

// eagerly load audio
AudioManager.loadInitFiles();
AudioManager.loadInboxFiles();
AudioManager.loadConceptsFiles();
AudioManager.attachUnlock();

// window watchers
Input.start();
Scroll.start();

Amplify.configure({
  ...awsConfig,
  Analytics: {
    disabled: true,
  },
});

const app = createApp(App);
// app.use(router);
app.mount("#app");

Background.start();
