import { globals } from "./_globals.js";

export default {
  // state
  doIn: false,
  doOut: false,
  // vals
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  inBaseAngle: (-Math.PI * 3) / 2,
  baseAngle: (-Math.PI * 3) / 2,
  originAngle: -Math.PI / 2,
  topAngleOffset: Math.PI / 11,
  init() {
    this._setTarget();
    this.doIn = false;
    this.doOut = false;
    for (const shape of globals.shapes) {
      shape.resetProps();
      shape.turnFactor = 0.08;
      shape.setPropLerp("decelDist", globals.rem / 2);
      shape.accelExternalDamp = 0.1;

      if (globals.isOut) {
        shape.setProp("scale", 0);
        shape.pos.x = this.originX;
        shape.pos.y = this.originY;
      }
      shape.orbitRadius = 0;
      shape.setTarget(this.originX, this.originY);
    }
    globals.shapes[0].setPropLerp("scale", 0.3);
    globals.shapes[1].setPropLerp("scale", 1.6);
    globals.shapes[2].setPropLerp("scale", 1.5);
    globals.shapes[3].setPropLerp("scale", 1.5);
    // this._swing()
  },
  update() {
    this._setTarget();
    if (this.doIn || this.doOut) {
      this.baseAngle = (this.baseAngle + 0.1) % (Math.PI * 2);
      this.originAngle =
        (this.baseAngle / (Math.PI * 2)) ** 3 * Math.PI * 2 - Math.PI / 2;

      this._swing();

      // one cycle
      if (this.baseAngle >= 0) {
        this.doIn = false;
        // this.doOut = false
      }
    } else {
      globals.shapes[1].addForceJitter(0.1, 0.1, 0.1);
      globals.shapes[2].addForceJitter(0.1, 0.1, 0.1);
      globals.shapes[3].addForceJitter(0.1, 0.1, 0.1);
    }

    globals.shapes[0].rot = (this.originAngle / Math.PI) * 180 - 25;
    globals.shapes[1].rot = (this.originAngle / Math.PI) * 180 + 40;
    globals.shapes[2].rot = (this.originAngle / Math.PI) * 180;
    globals.shapes[3].rot = (this.originAngle / Math.PI) * 180;

    for (const shape of globals.shapes) {
      shape.update();
    }
  },
  setTarget() {},
  _setTarget() {
    // recursive for animation
    if (globals.targetElement) {
      const box = globals.targetElement.getBoundingClientRect();
      this.originX = box.x + box.width / 2;
      this.originY = globals.targetElement.offsetTop - 2 * globals.rem;
    } else {
      this.originX = globals.vW / 2;
      this.originY = globals.vH / 2;
    }
  },
  onResize() {
    this.originX = globals.vW / 2;
    this.originY = globals.vH / 2;
  },
  _swing() {
    globals.shapes[0].setTarget(this.originX, this.originY);
    globals.shapes[1].setTarget(
      this.originX + Math.cos(this.originAngle) * 1.5 * globals.rem,
      this.originY + Math.sin(this.originAngle) * 1.5 * globals.rem
    );
    globals.shapes[2].setTarget(
      this.originX +
        Math.cos(this.originAngle + this.topAngleOffset) * 3.5 * globals.rem,
      this.originY +
        Math.sin(this.originAngle + this.topAngleOffset) * 3.5 * globals.rem
    );
    globals.shapes[3].setTarget(
      this.originX +
        Math.cos(this.originAngle - this.topAngleOffset) * 3.5 * globals.rem,
      this.originY +
        Math.sin(this.originAngle - this.topAngleOffset) * 3.5 * globals.rem
    );
  },
  in() {
    this.doIn = true;
    this.baseAngle = this.inBaseAngle;
  },
  out() {
    this.doOut = true;
  },
  kill() {},
  onVisibilityChange() {},
  next() {},
};
