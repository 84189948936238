<template>
  <div class="menu-icon" :class="{ 'menu-icon--show': show }">
    <div ref="light" class="menu-icon__light"></div>
    <button
      type="button"
      class="menu-icon__button"
      @click="toggle"
      :aria-expanded="menuOpen"
      :aria-label="label"
      :disabled="!show"
      ref="button"
    >
      <svg viewBox="0 0 100 100" class="menu-icon__shape" aria-hidden="true">
        <polygon
          class="menu-icon__background"
          :class="{ 'menu-icon__background--show': fill }"
          fill="#FFF"
          points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
        />
        <polygon
          fill="none"
          stroke-width="12"
          stroke-linejoin="round"
          stroke="#FFF"
          points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
        />
      </svg>
      <svg
        viewBox="0 0 100 100"
        class="menu-icon__glow"
        :class="{ 'menu-icon__glow--show': pulse }"
        aria-hidden="true"
      >
        <polygon
          fill="none"
          stroke-width="12"
          stroke-linejoin="round"
          stroke="#FFF"
          points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
        />
      </svg>
      <svg
        viewBox="0 0 100 100"
        class="menu-icon__pulse"
        ref="pulse"
        aria-hidden="true"
      >
        <polygon
          fill="none"
          stroke-width="3"
          stroke-linejoin="round"
          stroke="#FFF"
          points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import SessionData from "@/scripts/sessionData.js";

export default {
  emits: ["toggle"],
  props: {
    menuOpen: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    pulse: {
      type: Boolean,
      reqired: true,
    },
  },
  data() {
    return {
      label: SessionData.lang.Menu.menu_button_label || "Menu",
    };
  },
  computed: {
    fill() {
      return this.menuOpen || this.pulse;
    },
  },
  methods: {
    toggle() {
      this.$emit("toggle");
      this.impulse = 0.25;
      this.baseScale = 0.7;
    },

    animate(t) {
      this.pulseAmount *= 0.9;
      if (this.pulse) {
        this.r += 0.01;
        this.pulseAmount += 0.1;
      } else {
        this.r += 0.001;
      }

      this.baseScale = this.baseScale * 0.95 + 0.05;

      const angle = t * 0.004;
      const renderScale = 0.5 - 0.5 * Math.cos(this.baseScale * Math.PI);
      const extraScale =
        this.pulseAmount * (0.5 + 0.5 * Math.sin(angle)) ** 2.5;

      this.r += this.impulse + 0.01 * extraScale * extraScale * extraScale;
      this.impulse *= 0.96;

      this.$refs.button.style.transform = `rotate(${this.r}rad) scale(${
        renderScale * (1 + 0.15 * extraScale)
      })`;

      this.$refs.pulse.style.transform = `scale(${
        1 + (0.5 + 0.5 * Math.sin(angle - 0.4)) ** 4
      })`;
      this.$refs.pulse.style.opacity =
        this.pulseAmount *
        0.4 *
        (0.5 + 0.5 * Math.sin(angle + Math.PI * 0.5 - 0.4)) ** 5;

      this.$refs.light.style.opacity =
        this.pulseAmount * (0.2 + 0.2 * extraScale * extraScale);

      this.raf = requestAnimationFrame(this.animate);
    },
  },
  mounted() {
    this.impulse = 0;
    this.pulseAmount = 0;
    this.baseScale = 1;
    this.r = 0;
    this.raf = requestAnimationFrame(this.animate);
  },
  beforeUnmount() {
    cancelAnimationFrame(this.raf);
  },
};
</script>

<style lang="scss">
.menu-icon {
  position: fixed;
  bottom: $page-bottom;
  left: var(--container-indent);
  z-index: $z-menu;

  user-select: none;
  pointer-events: none;

  opacity: 0;
  filter: blur(10px);
  transform: scale(0.7);
  transition: opacity 0.5s ease-out, filter 0.5s ease-out,
    transform 0.5s ease-out;

  &--show {
    filter: blur(0px);
    opacity: 1;
    transform: none;
    pointer-events: auto;
  }
}

.menu-icon__button {
  @include reset-button;

  will-change: transform;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  animation: shapeRotate 60s linear infinite;
}

.menu-icon__shape {
  width: 3rem;
  height: 3rem;
}

.menu-icon__glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  opacity: 0;
  filter: blur(10px);

  &--show {
    opacity: 1;
  }
}

.menu-icon__pulse {
  will-change: opacity, transform;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  opacity: 0.5;
}

.menu-icon__background {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &--show {
    opacity: 1;
  }
}

.menu-icon__light {
  will-change: opacity;

  pointer-events: none;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 16rem;
  height: 16rem;
  margin: -8rem;

  background: radial-gradient(closest-side, #fff, transparent);
}
</style>
