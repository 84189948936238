<template>
  <div class="info info--white activity-info">
    <CardContent :blocks="data" />
  </div>
</template>

<script>
import CardContent from "@/components/card/CardContent.vue";

export default {
  components: {
    CardContent,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.activity-info {
  font-weight: $semi-bold;

  &::before {
    content: "?";
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 0 0 1.75rem;
    border-radius: 100%;
    border: 0.2rem solid var(--color-2);
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    color: var(--color-2);
  }
}
</style>
