import { globals } from "./_globals.js";
import { lerp, map, recursiveOffsetLeft } from "@/scripts/helpers.js";

export default {
  // maintain for constant updating
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  prevOriginX: globals.vW / 2,
  prevOriginY: globals.vH / 2,
  originLerpX: globals.vW / 2,
  originLerpY: globals.vH / 2,
  originLerpAlpha: 0,
  originLerpStartTime: 0,
  phase: 0,
  radius: globals.rem * 2,
  init() {
    this.setTarget();
    for (const shape of globals.shapes) {
      shape.resetProps();
      if (globals.isOut)
        shape.setProp("pos", { x: globals.rem * -2, y: this.originY, z: 50 });
    }
  },
  update() {
    this.originLerpAlpha = map(
      performance.now(),
      this.originLerpStartTime,
      this.originLerpStartTime + 1000,
      0,
      1,
      true
    );
    this.phase = -performance.now() / 300;

    this.originLerpX = lerp(
      this.prevOriginX,
      this.originX,
      this.originLerpAlpha
    );
    this.originLerpY = lerp(
      this.prevOriginY,
      this.originY,
      this.originLerpAlpha
    );

    for (let i = 0; i < globals.shapes.length; i++) {
      const shape = globals.shapes[i];
      const phase = this.phase + (i * Math.PI) / 2;
      shape.setTarget(
        this.originLerpX + Math.cos(phase) * this.radius,
        this.originLerpY + Math.sin(phase) * this.radius
      );
      shape.update();
    }
  },
  setTarget() {
    this._resetLerp();
    this.radius = globals.rem * 2;
    if (globals.targetElement && !globals.isLeaving) {
      this.originX =
        recursiveOffsetLeft(globals.targetElement) +
        globals.targetElement.offsetWidth / 2;
      this.originY = globals.targetElement.offsetTop - globals.rem * 4;
    } else {
      this.originX = globals.vW + 2 * globals.rem;
      this.originY = globals.targetElement
        ? globals.targetElement.offsetTop - globals.rem * 4
        : globals.vH / 2;
    }
  },
  _resetLerp() {
    this.prevOriginX = this.originLerpX;
    this.prevOriginY = this.originLerpY;
    this.originLerpStartTime = performance.now();
  },
  onResize() {
    this.setTarget();
  },
  in() {},
  out() {
    this._resetLerp();
    this.setTarget();
  },
  kill() {},
  onVisibilityChange() {},
  next() {},
};
