<template>
  <Transition>
    <!-- SAM comment before -->
    <ScrollPage
      v-if="state === 0"
      class="default-page-anim"
      @next="commentFinished"
    >
      <SAM :data="data.before" class="page-content-anim page-content-margin" />
    </ScrollPage>

    <!-- stories -->
    <Page v-else-if="state === 1" class="outro-reel-anim">
      <div ref="emote" class="outro">
        <div class="outro__card fragment">
          <EmojiConfetti
            v-if="outcome.particles && outcome.particles[1]"
            :xOrigin="95"
            :yOrigin="110"
            :emoji="outcome.particles[1].emoji"
          />
          <div class="outro__reel">
            <div
              v-for="i in screens.length"
              :key="`reel-${i}`"
              class="outro__reel-item"
              :class="{
                'outro__reel-item--active': i - 1 === screenIndex,
                'outro__reel-item--prev': i <= screenIndex,
              }"
            ></div>
          </div>
          <div class="outro__header">
            <ProfilePicture :image="avatar" />
            <span class="fragment__name">{{ name }}</span>
          </div>
        </div>
        <div class="outro__content">
          <TransitionGroup name="outro-emoji">
            <div
              v-for="emoji in screen.emoji"
              :key="`emoji-${emoji.emoji}`"
              class="outro__emoji"
              :class="`outro__emoji--${emoji.position}`"
            >
              <div class="outro__emoji-wrap">
                <Emoji class="outro__emoji-img" :name="emoji.emoji" />
              </div>
            </div>
          </TransitionGroup>
          <EmojiConfetti
            v-if="outcome.particles && outcome.particles[0]"
            :xOrigin="0"
            :yOrigin="70"
            :emoji="outcome.particles[0].emoji"
          />
          <TransitionGroup name="outro-heading">
            <div
              ref="heading"
              v-for="text in screen.text"
              :key="`text-${Math.random()}`"
              class="outro__heading"
              :class="`outro__heading--${text.position}`"
            >
              <h2
                ref="headingInner"
                class="outro__heading__inner"
                :class="[`outro__heading__inner--${text.position}`]"
              >
                {{ text.content }}
              </h2>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </Page>

    <!-- after -->
    <ScrollPage
      v-else-if="state === 2"
      :key="outcomeCommentsCurrentPage"
      class="default-page-anim"
      @next="outcomeCommentNext"
    >
      <SAM
        ref="outcomeCommentContent"
        :data="outcomeComments[outcomeCommentsCurrentPage].comment"
        class="page-content-anim page-content-margin"
      />
    </ScrollPage>
  </Transition>
</template>
<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import SAM from "@/components/content/SAM.vue";
import Emoji from "@/components/content/Emoji.vue";
import ProfilePicture from "@/components/content/ProfilePicture.vue";
import EmojiConfetti from "@/components/sections/chapter/chapter_outro/EmojiConfetti.vue";
import { rem } from "@/scripts/windowSize.js";
import SessionData from "@/scripts/sessionData.js";

const SCREEN_HOLD_TIME = 5000;

export default {
  components: {
    Page,
    ScrollPage,
    SAM,
    Emoji,
    ProfilePicture,
    EmojiConfetti,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    path: {
      type: Number,
      default: 0,
    },
  },
  emits: ["fin"],
  data() {
    const { avatar, name } = SessionData.character;

    return {
      name,
      avatar,

      state: 0, // comment, screens, outcome comment
      screenIndex: -1,
      screens: null,
      screen: null,
      screenInterval: null,
      outcomeCommentIndex: 0,
      outcomeComments: null,
      outcomeCommentsCurrentPage: 0,
      outcomeCommentPrevShape: "bounce",
      outcomeCommentCurrentShape: null,

      fontScaleSizes: [],
    };
  },
  methods: {
    commentFinished() {
      this.$root.iconHide();
      this.nextScreen();
      this.state = 1;
      this.screenInterval = setInterval(this.nextScreen, SCREEN_HOLD_TIME);
      this.$root.setHeaderDisplay(false);
    },
    screensFinished() {
      clearInterval(this.screenInterval);
      this.state = 2;
      this.$root.iconShow();
      // allow ref to render
      this.frameDelay = requestAnimationFrame(this.updateOutcomeShape);
    },
    endCommentFinished() {
      this.$root.shapeOut();
      this.$emit("fin");
    },
    nextScreen() {
      this.screenIndex++;
      if (this.screenIndex === this.screens.length) {
        this.screensFinished();
      } else {
        this.screen = this.screens[this.screenIndex];
        this.frameDelay = requestAnimationFrame(this.nextScreen2);
      }
    },
    nextScreen2() {
      this.scaleText(true);
    },
    updateOutcomeShape() {
      this.$root.shapeTarget(
        this.$refs.outcomeCommentContent.$el.nextElementSibling
      );

      if (this.outcomeCommentCurrentShape !== this.outcomeCommentPrevShape) {
        this.outcomeCommentPrevShape = this.outcomeCommentCurrentShape;
        this.outcomeCommentCurrentShape =
          this.outcomeComments[this.outcomeCommentsCurrentPage].shape_animation;
      }

      if (
        this.outcomeCommentCurrentShape &&
        this.outcomeCommentCurrentShape !== this.outcomeCommentPrevShape
      ) {
        this.$root.shapeState(this.outcomeCommentCurrentShape);
        this.$root.shapeIn();
      }
    },
    outcomeCommentNext() {
      if (this.outcomeCommentsCurrentPage === this.outcomeComments.length - 1) {
        this.endCommentFinished();
        return;
      }

      this.outcomeCommentsCurrentPage++;
      // render new frame
      this.frameDelay = requestAnimationFrame(this.outcomeCommentNext2);
    },
    outcomeCommentNext2() {
      this.updateOutcomeShape();
      this.$root.shapeNext();
    },
    scaleText(newText = false, iteration = 0) {
      // limit to 60 iterations (60 * 0.1 = 6em), should be max font size of these
      if (iteration > 60) return;

      // each headingInner should have its corresponding heading
      let checkAgain = false;
      const headingInnerArr = this.$refs.headingInner;
      const headingArr = this.$refs.heading;
      const newIteration = iteration + 1;

      if (newText) {
        this.fontScaleSizes = [];
      }

      for (let i = 0; i < headingInnerArr.length; i++) {
        const headingInner = headingInnerArr[i];
        const heading = headingArr[i];
        let lastSize = this.fontScaleSizes[i] || 1;
        // allow 2rem overflow
        if (
          headingInner.clientWidth > heading.clientWidth + 2 * rem ||
          headingInner.clientHeight > heading.clientHeight
        ) {
          checkAgain = true;
          lastSize -= 0.1;
        } else if (
          headingInner.clientWidth < heading.clientWidth + 2 * rem &&
          headingInner.clientHeight < heading.clientHeight
        ) {
          checkAgain = true;
          lastSize += 0.1;
        }
        this.fontScaleSizes[i] = lastSize;
        headingInner.style.setProperty(
          "--font-size",
          `${this.fontScaleSizes[i]}em`
        );
      }

      if (checkAgain) {
        this.frameDelay = requestAnimationFrame(() => {
          this.scaleText(false, newIteration);
        });
      }
    },
  },
  created() {
    this.outcome = this.data.outcomes[`path_${this.path + 1}`];
    this.screens = this.outcome.screens;
    this.outcomeComments = this.outcome.after;
  },
  beforeUnmount() {
    cancelAnimationFrame(this.frameDelay);
    clearInterval(this.screenInterval);
  },
};
</script>

<style lang="scss">
$screen-hold-time: 5000ms;

.outro-reel-anim {
  // overflow: visible;
  // mask: none;
  // contain: none;

  &.v-enter-active {
    transition: transform 0.75s ease 0.5s, opacity 0.75s ease 0.5s;
  }

  &.v-leave-active {
    transition: transform 0.75s ease, opacity 0.75s ease;
  }

  &.v-enter-from {
    transform: translateX(100%);
    opacity: 0;
    @media (prefers-reduced-motion) {
      transform: none;
    }
  }

  &.v-leave-to {
    transform: translateX(-100%);
    opacity: 0;
    @media (prefers-reduced-motion) {
      transform: none;
    }
  }
}

.outro {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 100%;

  &__card {
    position: absolute;
    z-index: 0; // force stacking context for ios rendering
    flex: 1 0 auto;
    overflow: hidden;
    overflow: clip;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  &__reel {
    display: flex;
    width: calc(100% + 4px);
    height: 0;
    margin: 0 -2px;

    &-item {
      flex: 1 0 auto;
      margin: 0 2px;
      height: 0.75rem;
      background-color: rgba(255, 255, 255, 0.5);

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 100%;
        background-color: white;
      }

      @keyframes chapter-outro-reel {
        0% {
          width: 0;
        }

        100% {
          width: 100%;
        }
      }

      &--active {
        &::after {
          animation: chapter-outro-reel $screen-hold-time linear 1 both;
        }
      }

      &--prev {
        &::after {
          width: 100%;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    margin: $content-padding;
    margin-bottom: 0;
    z-index: 1;
  }

  &__content {
    position: absolute;
    z-index: 0; // stacking context
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .outro-heading-enter-active {
    @include scale-in-bounce(
      $name: chapter-outro-heading-trans-in,
      $dur: 1s,
      $delay: var(--intro-anim-delay, 0.5s)
    );
  }

  .outro-heading-leave-active {
    @include scale-out(
      $name: chapter-outro-heading-trans-out,
      $dur: 0.5s,
      $delay: 0s
    );
  }

  &__heading {
    --intro-anim-delay: 0.5s;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    z-index: 1;
    transform-origin: top left;

    &__inner {
      width: fit-content;
      text-align: center;
      line-height: 0.9;
      font-style: italic;
      font-size: 1em; // fallback
      font-size: max(var(--font-size), 1rem);
      margin: 0;

      &--1 {
        transform: translate(-50%, -50%) rotate(10deg);
      }

      &--2 {
        transform: translate(-50%, -50%) rotate(10deg);
      }

      &--3 {
        transform: translate(-50%, -50%) rotate(-10deg);
      }

      &--4 {
        transform: translate(-50%, -50%) rotate(-10deg);
      }
    }

    &--1 {
      top: 25%;
      left: 60%;
      width: 80%;
      font-size: 3rem;
      max-height: 1em;
    }

    &--2 {
      top: 50%;
      font-size: 4rem;
      max-height: 1.5em;
    }

    &--3 {
      --intro-anim-delay: 0.85s;
      top: 50%;
      font-size: 6rem;
      max-height: 1.5em;
    }

    &--4 {
      --intro-anim-delay: 0.85s;
      top: 75%;
      left: 40%;
      width: 80%;
      font-size: 2.5rem;
      max-height: 2em;
    }
  }

  .outro-emoji-enter-active {
    @include scale-in-bounce(
      $name: chapter-outro-emoji-trans-in,
      $dur: 1s,
      $delay: var(--intro-anim-delay, 0.5s)
    );
  }

  .outro-emoji-leave-active {
    @include scale-out(
      $name: chapter-outro-emoji-trans-out,
      $dur: 0.5s,
      $delay: 0s
    );
  }

  &__emoji {
    --intro-anim-delay: 0.65s;
    position: absolute;
    top: calc(50% - 5rem);
    left: calc(50% - 5rem);
    width: 10rem;
    height: auto;

    &-img {
      width: 100%;
      height: auto;
    }

    &--1,
    &--2 {
      .outro__emoji-wrap {
        @include float-anim(
          $name: chapter-outro-emote-float-1,
          $amt: 0.5rem,
          $delay: 0s
        );
      }

      .outro__emoji-img {
        @include float-rotate-anim(
          $name: chapter-outro-emote-rotate-1,
          $amt: 3deg,
          $offset: -20deg,
          $dur: 10s
        );
      }
    }

    &--3,
    &--4 {
      --intro-anim-delay: 1s;

      .outro__emoji-wrap {
        @include float-anim(
          $name: chapter-outro-emote-float-2,
          $amt: 0.5rem,
          $delay: -2s
        );
      }

      .outro__emoji-img {
        @include float-rotate-anim(
          $name: chapter-outro-emote-rotate-2,
          $amt: 3deg,
          $offset: 20deg,
          $dur: 10s,
          $delay: -4s
        );
      }
    }

    &--1 {
      top: calc(30% - 5rem);
      left: calc(10% - 5rem);
    }

    &--2 {
      top: calc(10% - 5rem);
      left: calc(90% - 5rem);
    }

    &--3 {
      top: calc(95% - 9rem);
      left: calc(30% - 9rem);
      width: 18rem;
    }

    &--4 {
      top: calc(90% - 9rem);
      left: calc(82% - 9rem);
      width: 18rem;
    }
  }
}
</style>
