import { passiveEvent } from "@/scripts/globals.js";
import { windowHeight } from "@/scripts/windowSize.js";

// WINDOW SIZE
// track window size to decide how much the user must swipe

let swipeRequirement = 0;

function resize() {
  swipeRequirement = windowHeight * 0.1;
}

window.addEventListener("resize", resize);
window.addEventListener("load", resize);

// SWIPE INTERACTIONS

export let atEnd = false;

export let amount = 0;

function updateSwipe(delta) {
  amount = Math.max(0, Math.min(1, delta));
}

// TOUCH

const touchPositions = [];

let usingTouch = false;
let touchCount = 0;
let touchDelta = 0;

function cancelTouch() {
  usingTouch = false;
  touchDelta = 0;
  touchCount = 0;
  touchPositions.length = 0;
}

function touchEnd() {
  if (usingTouch && --touchCount === 0) {
    cancelTouch();
  }
}

function touchStart(e) {
  if (atEnd) {
    usingTouch = true;

    ++touchCount;

    for (let i = 0; i < e.changedTouches.length; ++i) {
      const touch = e.changedTouches[i];
      touchPositions[touch.identifier] = touch.clientY;
    }
  }
}

function touchMove(e) {
  if (usingTouch) {
    for (let i = 0; i < e.changedTouches.length; ++i) {
      const touch = e.changedTouches[i];
      touchDelta -=
        (touch.clientY - touchPositions[touch.identifier]) /
        e.changedTouches.length;
      touchPositions[touch.identifier] = touch.clientY;
    }

    if (touchDelta < 0) {
      touchDelta = 0;
      updateSwipe(0);
    } else {
      updateSwipe(touchDelta / swipeRequirement);
    }
  }
}

// MOUSE WHEEL

let wheelStill = true;
let wheelDebounceTimeout;
let wheelAmount = 0;
let wheelDelta = 0;
let wheelMax = 0;
let usingWheel = false;

// maintain a buffer of the last N values
// these are averaged to soften the random jitter of momentum scrolls

const BUFFER_SIZE = 10;
const buffer = [];
let bufferIndex = 0;
let bufferAvg;
let prevBufferAvg;

function restartWheel() {
  // zero the buffer
  for (let i = 0; i < BUFFER_SIZE; ++i) {
    buffer[i] = 0;
  }

  // reset everything to do with tracking the buffer
  wheelMax = 0;
  bufferAvg = 0;
  prevBufferAvg = 0;

  // copy the wheel amount from the swipe amount?
  wheelAmount = amount * swipeRequirement;
}
restartWheel();

function cancelWheel() {
  usingWheel = false;
  wheelAmount = 0;
}

function wheel(e) {
  wheelDelta = e.deltaY;

  if (wheelDelta < 0) {
    restartWheel();
  } else {
    // if this is the first wheel use in 200ms, consider it a new use
    if (atEnd && wheelStill) {
      usingWheel = true;
      restartWheel();
    }

    // WHEEL BUFFER
    // wheel values jitter A LOT
    // smooth the change over time with a rolling avaerge

    // record current delta into the buffer
    bufferIndex = (bufferIndex + 1) % BUFFER_SIZE;
    buffer[bufferIndex] = wheelDelta;

    // average the values in the buffer
    bufferAvg = 0;
    for (let i = 0; i < BUFFER_SIZE; ++i) {
      bufferAvg += buffer[i];
    }
    bufferAvg /= BUFFER_SIZE;

    // detect if the average is increasing
    if (wheelMax < bufferAvg) {
      wheelMax = bufferAvg;
    }

    // if the average is decreasing, allow wheel use
    else if (
      atEnd &&
      prevBufferAvg < wheelMax * 0.9 &&
      bufferAvg > prevBufferAvg + (wheelMax - prevBufferAvg) * 0.2
    ) {
      usingWheel = true;
      restartWheel();
    }

    prevBufferAvg = bufferAvg;

    // if using wheel, add delta to the current progress
    if (usingWheel) {
      wheelAmount += wheelDelta;
      updateSwipe(wheelAmount / swipeRequirement);
    }
  }

  clearTimeout(wheelDebounceTimeout);
  wheelDebounceTimeout = setTimeout(wheelDebounce, 200);
  wheelStill = false;
}

function wheelDebounce() {
  wheelStill = true;
  usingWheel = false;
}

function animate() {
  if (!(usingTouch || usingWheel)) {
    amount *= 0.9;
  }

  raf = requestAnimationFrame(animate);
}

export function reset() {
  cancelTouch();
  cancelWheel();
  atEnd = false;
  amount = 0;
}

export function reachedEnd() {
  atEnd = true;
}

let raf;
export function start() {
  window.addEventListener("touchstart", touchStart, passiveEvent);
  window.addEventListener("touchend", touchEnd, passiveEvent);
  window.addEventListener("touchcancel", touchEnd, passiveEvent);
  window.addEventListener("touchmove", touchMove, passiveEvent);

  window.addEventListener("wheel", wheel, passiveEvent);

  raf = requestAnimationFrame(animate);
}

export function stop() {
  window.removeEventListener("touchstart", touchStart, passiveEvent);
  window.removeEventListener("touchend", touchEnd, passiveEvent);
  window.removeEventListener("touchcancel", touchEnd, passiveEvent);
  window.removeEventListener("touchmove", touchMove, passiveEvent);

  window.removeEventListener("wheel", wheel, passiveEvent);

  cancelAnimationFrame(raf);
}
