import { globals } from "./_globals.js";
import { propLerpFunctions } from "./_mixins.js";

import {
  lerp,
  recursiveOffsetLeft,
  recursiveOffsetTop,
} from "@/scripts/helpers.js";

import * as AudioManager from "@/scripts/audioManager.js";

const IN_TIME = 3750;
const PING_DELAY = 200;

export default {
  originX: globals.vW / 2,
  originY: globals.vH / 2,
  radius: globals.vMin / 2,
  isIn: false,
  propLerp: {},
  init() {
    this.isIn = false;
    this.setTarget();
    for (const shape of globals.shapes) {
      shape.resetProps();
      shape.setProp("vMax", globals.rem * 2);
      shape.setProp("selfVMax", globals.rem * 1.25);
      shape.turnFactor = 0.2;
      shape.setTarget(this.originX, this.originY);
      shape.orbitRadius = this.radius;
    }
  },
  update() {
    if (this.isIn) {
      globals.shapes[1].setTarget(this.originX, this.originY);
      globals.shapes[0].setTarget(
        this.originX - globals.rem,
        lerp(this.originY, globals.vH / 2, 0.33)
      );
      globals.shapes[2].setTarget(
        this.originX - globals.rem * 2,
        lerp(this.originY, globals.vH / 2, 0.66)
      );
      globals.shapes[3].setTarget(
        this.originX - globals.rem * 3,
        globals.vH / 2
      );
    } else {
      for (const shape of globals.shapes) {
        shape.setTarget(this.originX, this.originY);
      }
    }
    globals.shapes[1].rot = 60;
    this._updatePropLerp();
    for (const shape of globals.shapes) {
      shape.update();
    }
  },
  _setPropLerp: propLerpFunctions._setPropLerp,
  _updatePropLerp: propLerpFunctions._updatePropLerp,
  setTarget() {
    if (globals.targetElement) {
      this.originX =
        recursiveOffsetLeft(globals.targetElement) +
        globals.targetElement.offsetWidth / 2;
      this.originY =
        recursiveOffsetTop(globals.targetElement) +
        globals.targetElement.offsetHeight / 2 -
        3 * globals.rem;
      this.radius = globals.targetElement.offsetWidth / 2;
    }
  },
  onResize() {
    this.setTarget();
  },
  in() {
    AudioManager.play("concepts-location-scan");
    this.timeout = setTimeout(this.in2.bind(this), IN_TIME / 2);
  },
  in2() {
    if (globals.stateKey !== "location") return;
    for (const shape of globals.shapes) {
      shape.setPropLerp("orbitRadius", 0, IN_TIME / 2);
    }
    this.timeout = setTimeout(this.in3.bind(this), IN_TIME / 2);
  },
  in3() {
    if (globals.stateKey !== "location") return;
    this.isIn = true;
    globals.shapes[1].setPropLerp("scale", 2, 700);
    for (const shape of globals.shapes) {
      shape.setPropLerp("accelStep", 0.3);
    }
    this.timeout = setTimeout(this.in4.bind(this), PING_DELAY);
  },
  in4() {
    AudioManager.play("concepts-location-ping");
  },
  out() {
    clearTimeout(this.timeout);
  },
  kill() {
    clearTimeout(this.timeout);
  },
  onVisibilityChange() {},
  next() {
    for (const shape of globals.shapes) {
      shape.addForce(
        (Math.random() - 0.5) * 2 * 8,
        -20 * (Math.random() - 0.5) * 2,
        0
      );
    }
  },
};
