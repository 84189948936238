<template>
  <Transition>
    <!-- chapter intro - only shown the first time -->
    <ScrollPage
      v-if="state === 'first'"
      class="chapter-first default-page-out"
      @next="toStoryInfo"
    >
      <SAM
        :data="chapter.story_intro.content"
        class="page-content-anim page-content-margin"
      />
    </ScrollPage>

    <!-- chapter menu - shown on repeat visits if the story is completed -->
    <Page v-else-if="state === 'menu'" class="chapter-first default-page-out">
      <SAM :data="menuPrompt" class="page-content-anim page-content-margin" />
      <Dialogue :options="menuOptions" @choice="menuChoice" />
    </Page>

    <!-- story info -->
    <ChapterInfo
      v-else-if="state === 'story-info'"
      :data="chapter.story_info"
      @next="toStory"
    />

    <!-- story -->
    <ChapterStory
      v-else-if="state === 'story'"
      :data="chapter.story_screens"
      @fin="finishedStory"
    />

    <!-- mid chapter - only shown the first time -->
    <ChapterMid
      v-else-if="state === 'middle'"
      :data="chapter.mid_inbox"
      @next="toActivityInfo"
    />

    <!-- activity info -->
    <ChapterInfo
      v-else-if="state === 'activity-info'"
      :data="chapter.activity_info"
      @next="toActivity"
    />

    <!-- activity -->
    <component
      v-else-if="state === 'activity'"
      :is="activityType"
      :data="activityData"
      :progress="activityProgress"
      @progress="activityUpdate"
      @next="activityFinished"
    />

    <!-- results -->
    <ChapterResults
      v-else-if="state === 'results'"
      :data="chapter"
      :score="score"
      @next="finishedResults"
    />

    <!-- story outro - only shown the first time through -->
    <ChapterOutro
      v-else
      :data="chapter.story_outro"
      :path="sessionPath"
      @fin="finishedOutro"
    />
  </Transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import ScrollPage from "@/components/pages/ScrollPage.vue";
import ChapterInfo from "@/components/sections/chapter/ChapterInfo.vue";
import ChapterStory from "@/components/sections/chapter/ChapterStory.vue";
import ChapterMid from "@/components/sections/chapter/ChapterMid.vue";
import ChapterResults from "@/components/sections/chapter/ChapterResults.vue";
import ChapterOutro from "@/components/sections/chapter/ChapterOutro.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";

// activities
import MultichoiceActivity from "./activities/MultichoiceActivity.vue";
import SwipeActivity from "./activities/SwipeActivity.vue";
import HotspotActivity from "./activities/HotspotActivity.vue";

import * as Background from "@/scripts/background.js";
import UserData from "@/scripts/userData.js";
import * as AudioManager from "@/scripts/audioManager.js";
import { data } from "@/scripts/data.js";
import {
  trackEvent,
  trackProgress,
  convertStoryPath,
  convertActivityScore,
} from "@/scripts/analytics.js";
import SessionData from "@/scripts/sessionData.js";
import {
  chapterIsComplete,
  chapterActivitiesCompleted,
} from "@/scripts/helpers.js";

const activityMap = {
  MultichoiceActivity: "multichoice_activity",
  SwipeActivity: "swipe_activity",
  HotspotActivity: "hotspot_activity",
};

export default {
  components: {
    Page,
    ScrollPage,
    ChapterInfo,
    ChapterStory,
    ChapterMid,
    MultichoiceActivity,
    SwipeActivity,
    HotspotActivity,
    ChapterResults,
    ChapterOutro,
    SAM,
    Dialogue,
  },
  emits: ["toInbox", "next"],
  props: {
    chapter: {
      type: Object,
      required: true,
    },
  },
  data() {
    const chapterProgress = UserData.progress[this.chapter.post_id];

    // current chapter data needs to be globaly accessible for metadata inserts
    SessionData.setCharacter(this.chapter.character);

    const activityType = this.chapter.activity_type;
    const {
      replay: {
        options: { activity, inbox, story },
      },
    } = data.return.chapter_forks;

    return {
      score: 0,
      replaying: chapterIsComplete(chapterProgress),
      state: chapterProgress.story ? "menu" : "first",
      sessionPath: chapterProgress.path,
      currentSection: 0,

      activityType,
      activityData: this.chapter[activityMap[activityType]],
      activityProgress: this.getProgress().activities,

      menuPrompt: data.return.chapter_forks.replay.prompt,
      menuOptions: [story, activity, inbox],
    };
  },
  methods: {
    getProgress() {
      return UserData.progress[this.chapter.post_id];
    },
    toStoryInfo() {
      trackEvent("chapter_story_start");
      this.state = "story-info";
      Background.transitionDown();
    },
    toStory() {
      this.state = "story";
      Background.transitionDown();
    },
    finishedStory(path) {
      const progress = this.getProgress();

      trackEvent("chapter_story_end", {
        prev_story_path: this.replaying
          ? convertStoryPath(progress.path)
          : null,
        story_path: convertStoryPath(path),
      });

      // save progress
      progress.story = true;
      progress.path = path;
      UserData.saveProgress();
      this.sessionPath = path;

      // leave story
      this.state = chapterActivitiesCompleted(progress) ? "outro" : "middle";
      Background.transitionDown();
    },
    toActivityInfo() {
      trackEvent("chapter_activity_start");
      this.state = "activity-info";
      Background.transitionDown();
    },
    toActivity() {
      this.activityProgress = this.getProgress().activities;
      this.state = "activity";
      Background.transitionDown();
    },

    // ACTIVITY

    activityUpdate(index) {
      const progress = this.getProgress();

      progress.activities[index] = true;

      // force update in the activity
      this.activityProgress = progress.activities;

      UserData.saveProgress();
    },
    activityFinished(score) {
      const progress = this.getProgress();

      trackEvent("chapter_activity_end", {
        prev_activity_score: this.replaying
          ? convertActivityScore(
              progress.score,
              this.activityType,
              this.activityData.questions.length
            )
          : null,
        activity_score: convertActivityScore(
          score,
          this.activityType,
          this.activityData.questions.length
        ),
      });

      // save progress
      progress.score = score;
      UserData.saveProgress();
      this.score = score;

      // to results
      this.state = "results";
      Background.transitionDown();
    },
    finishedResults() {
      if (this.replaying) {
        this.state = "menu";
      } else {
        this.state = "outro";
      }
      Background.transitionDown();
    },
    finishedOutro() {
      if (this.replaying) {
        this.state = "menu";
        Background.transitionDown();
      } else {
        this.$emit("next");
      }
    },

    menuChoice(index) {
      if (index === 0) {
        this.state = "story-info";
      } else if (index === 1) {
        this.state = "activity-info";
      } else {
        this.$emit("toInbox");
      }
      Background.transitionDown();
    },
  },
  mounted() {
    const progress = this.getProgress();

    trackProgress("chapter");
    trackEvent("chapter_start", {
      chapter_name: this.chapter.title,
      chapter_id: this.chapter.chapter_id,
      is_replay: this.replaying,
      story_length: this.chapter.story_screens.length,
      story_path: this.replaying ? convertStoryPath(progress.path) : null,
      activity_type: this.acitivtyType,
      activity_length: this.activityData.questions.length,
      activity_score: this.replaying
        ? convertActivityScore(
            progress.score,
            this.activityType,
            this.activityData.questions.length
          )
        : null,
    });

    this.$root.setHeaderDisplay(true);
    this.$root.setHeaderProgress(0, 1);
    this.$root.setHeaderTitle(this.chapter.title);
    this.$root.setHeaderProgressStyle("none");

    AudioManager.loadChapterFiles();
    AudioManager.loadGenreFiles();
  },
  beforeUnmount() {
    trackEvent("chapter_end");
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss">
$duration: 1.25s;
$ease: $ease-in-out-quad;
$inbox-delay: 1.35s;

.chapter-first {
  --page-delay: #{$inbox-delay};

  &.v-enter-active {
    transition-property: opacity, filter, transform;
    transition-duration: $duration;
    transition-timing-function: $ease-out-cubic, $blur-curve, $ease-out-quart;

    transition-delay: $inbox-delay;

    & > .page__inner {
      transition: transform $duration $inbox-delay $ease-out-cubic;
    }
  }

  &.v-enter-from {
    opacity: 0;
    filter: blur($blur);
    transform: translate(0, 50vh);

    @media (prefers-reduced-motion) {
      transform: none;
    }

    & > .page__inner {
      transform: scale(0.75)
        translateX(calc(min(85vw, $container-width-lg) * 0.1));
      @media (prefers-reduced-motion) {
        transform: none;
      }
    }
  }
}
</style>
