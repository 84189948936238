<template>
  <p
    v-for="text in blocks"
    ref="text"
    class="sam"
    :class="gradientClass"
    v-bind="$attrs"
    v-html="text"
  ></p>
</template>

<script>
import { parse } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: String,
      required: true,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // split on new lines
    const lines = this.data.split(/\r?\n/);

    // discard empty lines
    // parse markdown on each line
    const blocks = [];
    for (const line of lines) {
      if (line.length !== 0) {
        blocks.push(parse(line));
      }
    }

    return {
      blocks,
      gradientClass: this.gradient ? "sam--gradient" : null,
    };
  },
};
</script>

<style lang="scss">
.sam {
  margin: 0;
  @include sam-text;

  &--gradient {
    background-color: $swipesafe-red;
    background-image: url("@/assets/images/gradient.png");
    background-size: cover;
    background-clip: text;
    color: transparent;

    &::selection {
      color: #fff;
    }
  }
}

@keyframes scaleIn {
  0% {
    filter: blur($blur);
    transform: scale(0.3) translate3d(0, 0, 0);
    opacity: 0;
  }

  100% {
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes opaque {
  0% {
    background: #fff;
  }

  100% {
    background: #fff;
  }
}

@keyframes pulse {
  0% {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(4);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

$count-delay: 0.75s;
$count-duration: 0.5s;
$count-total: $count-delay + $count-duration + 0.2s;

.count {
  display: inline-block;
  position: relative;
  animation: scaleIn $count-duration $count-delay $ease-in-out-cubic both;
  will-change: transform;

  &::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    border: solid 0.0625rem #fff;

    top: 50%;
    left: 50%;
    margin: -0.05em 0 0;

    transform: translate3d(-50%, -50%, 0);

    animation: pulse 0.5s $count-total $ease-out-cubic both;
  }

  &__text {
    animation: fadeIn 2s $count-total $ease-out-quint both;
  }

  &__svg {
    display: block;
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    margin: -0.05em 0 0;
    box-sizing: border-box;

    top: 50%;
    left: 50%;

    transform: translate3d(-50%, -50%, 0);
    animation: fadeOut 0.2s $count-total $ease-out-cubic both;

    text {
      @include sam-text;
      text-anchor: middle;
      stroke: none;
      stroke-width: 0;
    }
  }

  &__bg {
    fill: #fff;
  }
}

@keyframes ellipsis {
  0% {
    opacity: 0.5;
  }

  30% {
    opacity: 1;
  }

  60% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.5;
  }
}

.ellipsis {
  &__1 {
    animation: ellipsis 3s $ease-in-out-sine infinite both;
  }

  &__2 {
    animation: ellipsis 3s 0.75s $ease-in-out-sine infinite both;
  }

  &__3 {
    animation: ellipsis 3s 1.5s $ease-in-out-sine infinite both;
  }
}
</style>
