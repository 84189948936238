<template>
  <p ref="root" class="hello" v-if="hasText">
    <span
      v-for="(text, i) in split"
      ref="text"
      class="hello__text"
      :style="`animation-delay: ${i * 1.25 + 0.5}s`"
      >{{ text }}</span
    >
  </p>
</template>

<script>
import { parse } from "@/scripts/insert.js";

const BASE_EM = 2;
const RESIZE_INCREMENT_EM = 0.1;
const MAX_RESIZE_ITERATIONS = 15; // 15 * 0.1em = -1.5em

export default {
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  data() {
    // split on new lines
    const split = this.data.split(/\r?\n/);

    // parse each block of copy
    for (let i = 0; i < split.length; ++i) {
      split[i] = parse(split[i]);
    }

    return {
      split,
      hasText: split.length !== 0,
    };
  },
  methods: {
    scaleText() {
      const root = this.$refs.root;

      if (!root || !this.$refs.text) return;

      const rootWidth = root.clientWidth;
      let fontSize;
      let iteration;

      // shrink text in <span> elements
      // until they are smaller than the root <p>
      for (const text of this.$refs.text) {
        fontSize = BASE_EM;
        iteration = 0;
        while (
          text.clientWidth > rootWidth &&
          iteration < MAX_RESIZE_ITERATIONS
        ) {
          fontSize -= RESIZE_INCREMENT_EM;
          text.style.fontSize = `${fontSize}em`;
          iteration++;
        }
      }
    },
  },
  mounted() {
    this.scaleText();
    window.addEventListener("resize", this.scaleText);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.scaleText);
  },
};
</script>

<style lang="scss">
@keyframes intro-color-sam-in {
  0% {
    opacity: 0;
    filter: blur(10px);
    transform: translate3d(0, 0, 0) scale(0.75);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.hello {
  @include sam-text;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;

  &__text {
    display: block;
    width: fit-content;
    margin: 0 auto;

    animation: intro-color-sam-in 1.5s ease 0.5s both;

    // support adding multiple blocks in content entry
    &:nth-child(odd) {
      min-width: 50%;
    }

    &:nth-child(even) {
      font-size: 2em;
    }
  }
}
</style>
