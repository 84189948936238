<template>
  <figure
    class="fragment social-image"
    :class="classMod"
    :style="aspectRatioPadding"
  >
    <ImageAttachment
      @load="onLoad"
      :image="data.image"
      class="social-image__image"
    />
    <div class="social-image__content">
      <div
        class="social-image__copy"
        :class="{
          'social-image__copy--loaded': hasImage,
        }"
      >
        <ProfilePicture class="social-image__picture" />
        <div class="social-image__text">
          <div v-if="data.meta" class="fragment__name">
            {{ parse(data.meta) }}
          </div>
          <div v-if="data.text" class="social-image__body">
            {{ parse(data.text) }}
          </div>
        </div>
      </div>
      <div class="social-image__comments" v-if="hasComments">
        <div v-for="comment in data.comments" class="social-image__comment">
          <div class="social-image__comment__inner">
            <div class="social-image__comment__name">
              {{ parse(comment.sender) }}
            </div>
            <div class="social-image__comment__body">
              {{ parse(comment.message) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </figure>
</template>

<script>
import ProfilePicture from "@/components/content/ProfilePicture.vue";
import ImageAttachment from "@/components/content/image/ImageAttachment.vue";
import { parse } from "@/scripts/insert.js";
import { data } from "@/scripts/data.js";

export default {
  components: {
    ProfilePicture,
    ImageAttachment,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { image, has_comments, comments } = this.data;

    const { width, height } = data.attachments[image];

    const hasComments = has_comments && comments.length !== 0;

    return {
      aspectRatioPadding: `--padding: ${(100 * height) / width}%`,
      hasComments,
      classMod: hasComments ? "social-image--comments" : null,
      hasImage: false,
    };
  },
  methods: {
    parse,
    onLoad() {
      this.hasImage = true;
    },
  },
};
</script>

<style lang="scss">
$comment-overflow: 1rem;

@keyframes social-image-comment-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.7);
  }

  50% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.02);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.social-image {
  display: flex;
  position: relative;
  padding: 0;

  &--comments {
    margin-bottom: $comment-overflow;
  }

  // &::before {
  //   content: "";
  //   display: block;
  //   padding: var(--padding, 0) 0 0;
  // }
}

.social-image__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.social-image__copy {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: auto;
  box-sizing: border-box;
  width: 100%;
  z-index: 0;
  padding: $content-padding;
  border-radius: $content-radius $content-radius 0 0;
  overflow: hidden;
  overflow: clip;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.9), transparent);
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &--loaded {
    &::before {
      opacity: 1;
    }
  }
}

.social-image__picture {
  flex: 0 0 auto;
}

.social-image__text {
  align-self: center;
}

.social-image__body {
  margin: 1rem 0 0;
  font-weight: $semi-bold;
}

.social-image__image {
  width: 100%;
  margin-right: -100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
  border-radius: $content-radius;
}

.social-image__comments {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: 1rem -1 * $comment-overflow -1 * $comment-overflow 4.5rem;
}

.social-image__comment {
  --bubble-delay: var(--delay);
  @for $i from 2 through 10 {
    &:nth-child(#{$i}) {
      --bubble-delay: calc(var(--delay) + #{($i - 1) * 0.25s});
    }
  }

  position: relative;
  margin-top: $fragment-content-margin;

  &:first-child {
    margin-top: 0;
  }

  &__inner {
    padding: $fragment-inner-padding;
    animation: chat-text-fade 1s calc(var(--bubble-delay) + 0.3s)
      $ease-out-cubic both; // anim from group chat
  }

  &__name {
    font-size: 0.875rem;
    margin: 0 0 0.25rem;
  }

  &__body {
    font-weight: $bold;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: $fragment-radius;
    background: var(--color-2);

    transform-origin: right bottom;
    animation: chat-message-in 0.5s var(--bubble-delay) $ease-out-quart both; // anim from group chat
  }
}
</style>
