<template>
  <ul v-if="data.list.length" class="list">
    <li
      v-for="(item, i) in data.list"
      :key="`list-item-${i}`"
      class="list__item"
    >
      <svg
        viewBox="0 0 100 100"
        class="list__bullet"
        :class="`list__bullet--${i}`"
        aria-hidden="true"
      >
        <polygon
          fill="none"
          stroke-width="12"
          stroke-linejoin="round"
          points="63.29,90.9 15.21,75.27 15.21,24.73 63.29,9.1 93,50"
          transform-origin="50 50"
          :transform="`rotate(${Math.floor((72 * i) / data.list.length)})`"
          class="list__polygon"
        />
      </svg>
      <div v-html="markdown(parse(item.text))"></div>
    </li>
  </ul>
</template>

<script>
import { markdown, parse } from "@/scripts/insert.js";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    markdown,
    parse,
  },
};
</script>

<style lang="scss">
@keyframes spin {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(1.2);
  }

  10% {
    transform: scale(1);
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 2rem 0 0 -0.25rem;
  font-weight: $medium;

  &:first-child {
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 1.25rem 0 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5;

    &:first-child {
      margin: 0;
    }
  }

  &__bullet {
    flex: 0 0 2em;
    width: 2em;
    height: 1.25em;
    margin: 0.5rem 1.5rem auto 0;
    stroke: var(--color-2);

    @for $i from 0 through 10 {
      &--#{$i} {
        animation: grow 3s 0.3s * $i linear infinite both;
      }
    }
  }

  &__polygon {
    animation: spin 20s linear infinite both;
  }
}
</style>
