import PersistentData from "@/scripts/persistentData.js";
import * as Connection from "@/scripts/connection.js";

let gtmScriptTag;
let loaded = false;

export function loadGTM() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
  loadRemoteGtmScripts();
}

function loadRemoteGtmScripts() {
  if (Connection.isOnline) {
    const firstScriptTag = document.getElementsByTagName("script")[0];

    gtmScriptTag = document.createElement("script");
    gtmScriptTag.async = true;
    gtmScriptTag.onload = onScriptLoad;
    gtmScriptTag.onerror = onScriptError;
    gtmScriptTag.src =
      "https://www.googletagmanager.com/gtm.js?id=" + process.env.VUE_APP_GTM;
    firstScriptTag.parentNode.insertBefore(gtmScriptTag, firstScriptTag);
  }
}

function onScriptLoad() {
  loaded = true;
}

function onScriptError() {
  gtmScriptTag.remove();
}

// push to data layer without an event
export function setItem(key, value) {
  window.dataLayer.push({ [key]: value });
}

let isOffloading = false;

export function trackEvent(eventName, dataLayer = {}) {
  if (!loaded && Connection.isOnline) {
    loadRemoteGtmScripts();
  }
  const newEvent = {
    event: eventName,
    ...dataLayer,
  };
  if (Connection.isOnline) {
    window.dataLayer.push(newEvent);
  } else {
    // flag the event as delayed
    newEvent.delayed = true;
    if (PersistentData.gtmEvents) {
      // stored in reverse order to be offloaded later
      PersistentData.setGtmEvents([newEvent, ...PersistentData.gtmEvents]);
    } else {
      PersistentData.setGtmEvents([newEvent]);
    }
    return;
  }
  if (!isOffloading && PersistentData.gtmEvents) {
    // attempt offloading
    offloadEvents();
  }
}

function offloadEvents() {
  // flag asap to avoid duplicated offloading
  isOffloading = true;
  while (PersistentData.gtmEvents.length) {
    const gtmEvents = PersistentData.gtmEvents;
    // clear the queue immediately in case new events need to be added
    PersistentData.setGtmEvents([]);

    for (let i = gtmEvents.length - 1; i >= 0; i--) {
      if (Connection.isOffline) {
        // add offloading events back to the end of the queue
        PersistentData.setGtmEvents([
          ...PersistentData.gtmEvents,
          ...gtmEvents,
        ]);
        isOffloading = false;
        return;
      }
      window.dataLayer.push(gtmEvents[i]);
      // remove from array to handle disconnection during this loop
      gtmEvents.splice(i, 1);
    }
  }
  isOffloading = false;
}

export let userId = undefined;

export function trackSession(cognitoUser) {
  // parse user attributes into gtm data layers
  const hasProfiles = cognitoUser.attributes["custom:hasProfiles"] === "True";
  userId = cognitoUser.attributes.sub || cognitoUser.attributes.username;
  let layers = {
    method: "AWS Cognito",
    user_id: userId,
    region: cognitoUser.attributes["custom:region"],
    group: cognitoUser.attributes["custom:group"],
    isFacilitated: cognitoUser.attributes["custom:isFacilitated"] === "True",
    isInSchool: cognitoUser.attributes["custom:isInSchool"] === "True",
    hasProfiles: hasProfiles,
  };
  if (!hasProfiles) {
    layers = {
      ...layers,
      ...{
        gender: cognitoUser.attributes.gender,
        age: cognitoUser.attributes["custom:age"],
      },
    };
  }
  trackEvent("login", layers);
}

export function destorySession() {
  userId = undefined;
  window.dataLayer.push({
    user_id: userId,
    age: undefined,
    gender: undefined,
    group: undefined,
    isFacilitated: undefined,
    isInSchool: undefined,
    hasProfiles: undefined,
  });
}

export function trackProgress(section) {
  trackEvent("page_progress", { section: section });
}

export function convertStoryPath(pathIndex) {
  return ["A", "B", "C"][pathIndex];
}

export function convertActivityScore(totalScore, activityType, activityLength) {
  switch (activityType) {
    case "HotspotActivity":
      return 100;
    case "SwipeActivity":
      return (totalScore * 100) / activityLength;
    case "MultichoiceActivity":
      return (totalScore * 100) / (activityLength * 2);
  }
}
