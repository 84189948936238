<template>
  <Transition>
    <!-- select profile -->
    <Page v-if="inProfileList" class="default-page-anim profiles" ref="page">
      <!-- SAM text -->
      <div class="page-content-anim profile-select__group" ref="samGroup">
        <SAM
          :data="samSelect"
          class="profile-select__sam"
          :class="{ 'profile-select__sam--show': inSelectState }"
          gradient
          ref="samSelect"
        />
        <SAM
          :data="samDelete"
          class="profile-select__sam"
          :class="{ 'profile-select__sam--show': inDeleteState }"
          gradient
          ref="samDelete"
        />
      </div>

      <!-- list of profiles -->
      <ul class="profile-select__list page-content-margin">
        <li
          v-for="(profile, i) in profiles"
          class="profile-select__item page-content-anim"
        >
          <button
            type="button"
            :disabled="selectDisabled"
            class="profile-select__button profile-select__profile"
            :class="profileButtonClass(i)"
            @click="onProfileClick(i)"
          >
            <span class="profile-select__name">{{ profile.profileName }}</span>
            <span class="profile-select__delete" aria-hidden="true">
              <ImageAsset
                ref="trash"
                image="delete.svg"
                width="23"
                height="24"
                alt=""
                class="profile-select__delete__icon"
              />
            </span>
          </button>
        </li>
      </ul>

      <!-- Add profile button -->
      <div
        v-if="canCreate"
        class="profile-select__add-container page-content-anim"
        :style="`--delay: calc(var(--page-delay) + ${profiles.length * 0.25}s)`"
        :class="{
          'profile-select__button--remove': hasChosen && chosen !== -1,
        }"
      >
        <button
          type="button"
          :aria-label="addLabel"
          :disabled="createDisabled"
          class="profile-select__button profile-select__add"
          :class="addButtonClass"
          @click="onAddClick"
        >
          <span class="profile-select__add__inner"></span>
        </button>
      </div>

      <!-- buttons along screen bottom -->
      <Teleport to="body">
        <Transition>
          <div
            class="profile-select__bottom"
            :class="
              hasChosen
                ? 'profile-select__bottom--out'
                : 'profile-select__bottom--in'
            "
          >
            <div v-if="inDeleteState" class="profile-select__buttons">
              <button
                type="button"
                :disabled="hasChosen || !inDeleteState"
                class="profile-select__tool"
                @click="onBackClick"
              >
                {{ backLabel }}
              </button>
            </div>
            <div v-else class="profile-select__buttons">
              <button
                type="button"
                :disabled="deleteDisabled"
                class="profile-select__tool"
                :class="{
                  'profile-select__tool--disabled': !canDelete,
                }"
                @click="onDeleteClick"
              >
                <ImageAsset
                  image="delete.svg"
                  width="23"
                  height="24"
                  alt=""
                  class="profile-select__tool__icon"
                  aria-hidden="true"
                />
                {{ deleteLabel }}
              </button>
              <button
                type="button"
                class="profile-select__tool profile-select__tool--right"
                :disabled="logoutDisabled"
                @click="onLogoutClick"
              >
                {{ logOutLabel }}
                <ImageAsset
                  image="profile-logout.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="profile-select__tool__icon profile-select__tool__icon--right"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </Transition>
      </Teleport>
    </Page>

    <!-- confirm delete -->
    <Page v-else-if="state === 'confirm'" class="default-page-anim">
      <SAM
        :data="samConfirm"
        class="page-content-anim page-content-margin"
        gradient
      />
      <Dialogue :options="confirmOptions" @choice="confirmChoice" gradient />
    </Page>

    <!-- create profile -->
    <ProfileCreate
      v-else-if="state === 'create'"
      @success="toSelect"
      @error="toError"
    />

    <!-- error/offline -->
    <ScrollPage
      v-else-if="state === 'error'"
      class="default-page-anim"
      @next="afterError"
    >
      <SAM
        :data="samError"
        class="page-content-anim page-content-margin"
        gradient
      />
    </ScrollPage>
  </Transition>
</template>

<script>
import ScrollPage from "@/components/pages/ScrollPage.vue";
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import ImageAsset from "@/components/content/image/ImageAsset.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import ProfileCreate from "./ProfileCreate.vue";

import { API } from "@aws-amplify/api";
import UserData from "@/scripts/userData.js";
import * as Data from "@/scripts/data.js";
import * as Connection from "@/scripts/connection.js";
import * as AudioManager from "@/scripts/audioManager.js";
import * as Analytics from "@/scripts/analytics.js";
import SessionData from "@/scripts/sessionData.js";

function getProfiles() {
  const profiles = UserData.profiles;

  if (profiles) {
    const shallowCopy = [];
    for (const profile of profiles) {
      shallowCopy.push(profile);
    }
    console.log("copy of profiles", shallowCopy);
    return shallowCopy;
  } else {
    return [];
  }
}

export default {
  emits: ["setProfile", "logout"],
  components: {
    Page,
    SAM,
    ImageAsset,
    ProfileCreate,
    Dialogue,
    ScrollPage,
  },
  data() {
    const {
      data: {
        account: {
          profiles: {
            profile_picker: {
              prompt: samSelect,
              create_button_label: addLabel,
              delete_button_label: deleteLabel,
            },
            profile_cleaner: {
              select: { prompt: samDelete, back_button_label: backLabel },
              confirm: {
                prompt: samConfirmTemplate,
                options: { yes: deleteConfirmYes, no: deleteConfirmNo },
              },
            },
          },
        },
      },
    } = Data;

    const profiles = getProfiles();

    return {
      isOnline: Connection.isOnline,
      state: profiles.length === 0 ? "create" : "select",

      profiles,
      hasChosen: false,
      chosen: 0,

      // select screen
      samSelect,
      addLabel,
      deleteLabel,
      logOutLabel: SessionData.lang.Menu.log_out_button_label || "Log out",

      // delete screen
      samDelete,
      backLabel,

      // confirm screen
      samConfirmTemplate,
      samConfirm: "",
      confirmOptions: [deleteConfirmYes, deleteConfirmNo],

      // error screen
      samError: SessionData.lang.Errors.network_error,
    };
  },
  computed: {
    inSelectState() {
      return this.state === "select";
    },
    inDeleteState() {
      return this.state === "delete";
    },
    inProfileList() {
      return this.inSelectState || this.inDeleteState;
    },

    canDelete() {
      // return this.isOnline && this.profiles.length > 1;
      return this.isOnline;
    },
    canCreate() {
      return this.isOnline && this.profiles.length < 4;
    },

    selectDisabled() {
      return this.hasChosen || !this.inProfileList;
    },
    deleteDisabled() {
      return this.hasChosen || !this.canDelete || !this.inSelectState;
    },
    logoutDisabled() {
      return this.hasChosen || !this.inSelectState;
    },

    createDisabled() {
      return this.hasChosen || !this.canCreate || !this.inSelectState;
    },

    addButtonClass() {
      let classes = "profile-select__button--" + this.profiles.length;

      if (this.inSelectState) {
        classes += " profile-select__add--show";
      }

      if (this.hasChosen && this.chosen === -1) {
        classes += " profile-select__button--chosen";
      }

      return classes;
    },
  },
  methods: {
    profileButtonClass(index) {
      let classes = "profile-select__button--" + index;

      if (this.inDeleteState) {
        classes += " profile-select__profile--delete";
      }

      if (this.hasChosen) {
        classes +=
          index === this.chosen
            ? " profile-select__button--chosen"
            : " profile-select__button--remove";
      }

      return classes;
    },
    onProfileClick(index) {
      if (!this.hasChosen) {
        AudioManager.play("ui-input-button-select");
        this.hasChosen = true;
        this.chosen = index;

        if (this.inDeleteState) {
          clearInterval(this.interval);
          this.$refs.trash[index].$el.style.removeProperty("transform");
          this.timeout = setTimeout(this.toConfirm, 600);
        } else {
          this.timeout = setTimeout(this.fin, 600);
        }
      }
    },
    onAddClick() {
      if (!this.hasChosen && this.canCreate) {
        AudioManager.play("ui-input-button-misc");
        this.hasChosen = true;
        this.chosen = -1;
        this.timeout = setTimeout(this.toCreate, 600);
      }
    },

    toCreate() {
      this.state = "create";
    },
    toConfirm() {
      this.samConfirm = this.samConfirmTemplate.replace(
        /\[nickname\]/gi,
        this.profiles[this.chosen].profileName
      );
      this.state = "confirm";
    },

    // bottom nav buttons
    onDeleteClick() {
      if (!this.hasChosen && this.canDelete) {
        AudioManager.play("ui-input-button-misc");
        this.state = "delete";

        this.interval = setInterval(this.deleteShake, 100);

        // adjust the height of the sam text
        this.$refs.samGroup.style.height =
          this.$refs.samDelete.offsetHeight + "px";
        this.$refs.page.$el.scroll({ top: 0, behaviour: "smooth" });
        this.$refs.page.$el.focus();
      }
    },
    onBackClick() {
      if (!this.hasChosen) {
        AudioManager.play("ui-input-button-misc");
        this.state = "select";

        clearInterval(this.interval);
        for (const icon of this.$refs.trash) {
          icon.$el.style.removeProperty("transform");
        }

        // adjust the height of the sam text
        this.$refs.samGroup.style.height =
          this.$refs.samSelect.offsetHeight + "px";
        this.$refs.page.$el.scroll({ top: 0, behaviour: "smooth" });
        this.$refs.page.$el.focus();
      }
    },
    onLogoutClick() {
      if (!this.hasChosen) {
        AudioManager.play("ui-input-button-misc");
        this.$emit("logout");
      }
    },

    // CREATE

    createSuccess() {
      Analytics.trackEvent("create_profile");
      this.hasChosen = false;
      this.profiles = getProfiles();
      this.state = "select";
    },
    createError() {
      this.state = "error";
    },

    // DELETE

    deleteShake() {
      this.shakeMode = this.shakeMode ? 0 : 1;

      for (let i = 0; i < this.$refs.trash.length; ++i) {
        this.$refs.trash[i].$el.style.transform = `rotate(${
          i % 2 === this.shakeMode ? "-" : ""
        }${3 + 5 * Math.random() ** 3}deg)`;
      }
    },
    confirmChoice(index) {
      this.hasChosen = false;

      if (index === 0) {
        const { profileName } = this.profiles[this.chosen];
        API.del(
          "AppAPI",
          "/app/profile/" + encodeURIComponent(profileName)
        ).then(this.deleteSuccess, this.toError);
        this.state = null;
      } else {
        this.state = "select";
      }
    },
    deleteSuccess(remoteData) {
      UserData.pullData(remoteData);
      Analytics.trackEvent("delete_profile");
      this.raf = requestAnimationFrame(this.toSelect);
    },

    toSelect() {
      this.profiles = getProfiles();
      this.hasChosen = false;
      this.state = this.profiles.length === 0 ? "create" : "select";
    },
    toError() {
      this.state = "error";
    },

    // error
    async afterError() {
      this.state = null;
      await UserData.sync();
      this.raf = requestAnimationFrame(this.toSelect);
    },

    fin() {
      const selectedProfile = this.profiles[this.chosen];
      const { profileName } = selectedProfile;
      Analytics.trackEvent("select_profile", {
        gender: selectedProfile.gender,
        age: selectedProfile.age,
        user_id: Analytics.userId + " - " + profileName,
      });
      this.$emit("setProfile", profileName);
    },

    onlineEvent() {
      this.isOnline = Connection.isOnline;
    },
  },
  mounted() {
    Connection.addOnlineEvent(this.onlineEvent);
    Connection.addOfflineEvent(this.onlineEvent);
    Analytics.setItem("user_id", Analytics.userId);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
    Connection.removeOnlineEvent(this.onlineEvent);
    Connection.removeOfflineEvent(this.onlineEvent);
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/generate-gradient.scss";

$profile-button-border: 0.25rem;
$profile-button-padding: 1.5rem;
$profile-button-space: 0.5rem;
$profile-button-icon: 1.25rem;

@keyframes profile-list-item-remove {
  0% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0px);
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
    filter: blur(10px);
  }
}

@keyframes profile-list-item-chosen {
  0% {
    transform: scale(1.01);
  }

  20% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.02);
  }
}

@keyframes tool-disabled-anim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

@keyframes profile-sam-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
    // transform: translate3d(1rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes profile-sam-out {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }
}

.profile-select {
  // sam text
  &__group {
    position: relative;
    transition: height 0.75s ease-in-out;
  }

  &__sam {
    position: absolute;
    top: 0;

    visibility: hidden;
    transition: visibility 0s 0.3s;
    animation: profile-sam-out 0.3s 0s $ease-in-cubic;

    &--show {
      position: relative;

      visibility: visible;
      transition: none;
      animation: profile-sam-in 0.3s 0.3s $ease-out-cubic backwards;
    }
  }

  // list
  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  $profile-delete-anim-delay: calc(var(--delay) * 0.2 + 0.25s);

  &__button {
    @include reset-button;

    background-size: 150% 100%;
    background-position: right center;

    transition: background-size 0.45s ease, transform 0.3s ease-out;

    &--remove {
      animation: profile-list-item-remove 0.5s 0s ease-out both !important;
    }

    &--chosen {
      animation: profile-list-item-chosen 0.5s 0s ease-out both !important;
    }

    @media (hover: hover) {
      &:hover,
      &:focus-within {
        background-size: 250% 140%;
      }
    }

    $blocks: 5;
    $colors: ($swipesafe-orange $swipesafe-yellow $swipesafe-orange),
      ($swipesafe-orange $swipesafe-magenta),
      ($swipesafe-blue $swipesafe-yellow $swipesafe-green),
      ($swipesafe-yellow $swipesafe-green $swipesafe-red),
      ($swipesafe-aqua $swipesafe-red $swipesafe-blue);
    $rotations: (-80deg -10deg 90deg -100deg -80deg);

    @for $i from 1 through $blocks {
      $color: nth($colors, $i);
      $rotation: nth($rotations, $i);

      &--#{$i - 1} {
        @include generate-gradient($color, $rotation);
      }
    }
  }

  &__profile {
    appearance: none;
    display: block;

    width: 100%;
    border: 0;
    border-radius: $fragment-radius;
    padding: 0;

    font-family: inherit;
    font-size: $dialogue-font-size;
    font-weight: $bold;
    line-height: 1.25;
    text-wrap: balance;
    text-align: left;

    cursor: pointer;

    &:hover {
      transform: scale(1.01);
    }

    &::before {
      content: "";

      $inset: $profile-button-border * 3;

      will-change: top, padding;
      position: absolute;

      display: flex;
      justify-content: flex-end;

      z-index: 1;
      top: $inset;
      right: $inset;
      bottom: $inset;
      left: $inset;

      border-radius: $fragment-radius - $inset;
      padding: $profile-button-padding - $inset;
      background: black;

      filter: blur($inset);
      opacity: 0;

      transition: all 0.4s $profile-delete-anim-delay $ease-out-cubic,
        opacity 0.4s $profile-delete-anim-delay $ease-out-quart;
    }

    &--delete {
      & > .profile-select__name {
        color: #fff;
        padding-right: 3.5rem;
        transition: color 0.3s $profile-delete-anim-delay $ease-out-quart,
          padding-right 0s $profile-delete-anim-delay;
      }

      &::before {
        opacity: 1;

        top: $profile-button-border;
        right: $profile-button-border;
        bottom: $profile-button-border;
        left: $profile-button-border;

        border-radius: $fragment-radius - $profile-button-border;
        padding: $profile-button-padding - $profile-button-border;
        filter: blur($profile-button-border * 0.25);
      }

      & > .profile-select__delete {
        transform: translate3d(0, -50%, 0);
        opacity: 1;
        transition: all 0.3s $profile-delete-anim-delay $ease-out-quart;
      }
    }
  }

  &__name {
    display: block;
    position: relative;
    z-index: 10;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: $profile-button-padding $profile-button-padding - 0.25rem;

    color: #000;
    transition: color 0.3s $profile-delete-anim-delay $ease-out-quart,
      padding-right 0s calc(0.1s + $profile-delete-anim-delay);
  }

  &__delete {
    position: absolute;
    z-index: 2;
    right: $profile-button-padding;
    top: 50%;
    margin-top: -0.125rem;
    transform: translate3d(1rem, -50%, 0);
    opacity: 0;
    transition: all 0s calc($profile-delete-anim-delay + 0.3s),
      opacity 0.3s $profile-delete-anim-delay $ease-out-quart;

    &__icon {
      display: block;
      width: $profile-button-icon;
      will-change: transform;
      transition: transform 100ms $ease-in-out-sine;
    }
  }

  &__profile-delete-icon {
    flex: 0 0 auto;
    display: block;
    width: $profile-button-icon;
  }

  // add button

  &__add-container {
    display: flex;
    margin-top: 1rem;

    &--remove {
      animation: profile-list-item-remove 0.5s 0s ease-out both !important;
    }
  }

  &__add {
    pointer-events: none;
    position: relative;
    width: 4rem;
    height: 4rem;
    padding: 0.25rem;
    border-radius: 50%;
    margin: 0 0 0 auto;

    opacity: 0;
    transition: opacity 0.3s calc(var(--delay) * 0.1 + 0.25s) ease-in-out;

    &--show {
      pointer-events: auto;
      opacity: 1;
    }

    &__inner {
      display: block;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background: #000;
      mix-blend-mode: multiply;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.1875rem;
        height: 1.0625rem;
        margin: -0.53125rem -0.09375rem;
        border-radius: 1rem;
        background: #fff;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.0625rem;
        height: 0.1875rem;
        margin: -0.09375rem -0.53125rem;
        border-radius: 1rem;
        background: #fff;
      }
    }
  }

  // buttons at the bottom

  @keyframes profile-tools-in {
    0% {
      opacity: 0;
      transform: translateY(200%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes profile-tools-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &__bottom {
    position: absolute;
    bottom: max($v-indent, calc(1rem + env(safe-area-inset-bottom, 0)));
    left: var(--container-indent);
    z-index: $z-menu;

    display: flex;
    width: var(--container-width);

    &--in {
      animation: profile-tools-in 0.5s ease-out both;
    }

    &--out {
      animation: profile-tools-out 0.5s ease-out both;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 0;

    margin: 0 0 -1rem -1rem;
    width: calc(100% + 2rem);

    transition: opacity 0.5s ease-in-out;

    &--disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__tool {
    @include reset-button;

    position: absolute;
    bottom: 0;

    box-sizing: border-box;
    max-width: 50%;
    padding: 1rem 1rem 0.9rem 2.5rem;

    &--right {
      right: 0;
      text-align: right;
      padding: 1rem 2.5rem 0.9rem 1rem;
    }

    color: #fff;
    font-size: 0.75rem;
    line-height: 1.125;
    font-weight: $semi-bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-wrap: balance;

    &--disabled {
      opacity: 0.3;
      cursor: auto;
      animation: tool-disabled-anim 0.3s ease-out both;
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 1rem;

      height: 1.125em;
      width: auto;

      transform: translateY(-50%);

      &--right {
        left: auto;
        right: 1rem;
      }
    }
  }
}
</style>
